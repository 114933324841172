import * as Firebase from "firebase/app";
import { doc, setDoc } from "firebase/firestore";
import {
  Select,
  Layout,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Spin,
  Button,
  Modal,
  Radio,
  DatePicker,
  TimePicker,
  Form,
  Input,
} from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"

function Editalert(props) {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(false);
  const [doctype, setDoctype] = useState();
  const [datetype, setDatetype] = useState();
  const [timetype, setTimetype] = useState();
  const [visible, setVisible] = useState(false);
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const loadProfile = () => {
    setDoctype(props.notificationdetails.status);
    form.setFieldsValue({
      matchtype: props.notificationdetails.type,
      topic: props.notificationdetails.topic,
      desc: props.notificationdetails.desc,
      title: props.notificationdetails.title,
    });
  };
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    loadProfile();
  }, [loaded, props]);

  const handleChange = (e) => {
    if (e.target.value == "Scheduled") {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setDoctype(e.target.value);
  };
  const onDateChange = (e) => {
    setDatetype(e.target.value);
  };
  const onTimeChange = (e) => {
    setTimetype(e.target.value);
  };
  const onFinish = async (values) => {
    setLoaded(true);
    console.log(values);
    const res = await setDoc(doc(db, "notification", props.notificationdetails.notifId), {
      notifId: props.notificationdetails.notifId,
      channelId: `com.sherpaanalytics.${currentTournamentDetails.majorUsedDocId}.generic`,
      title: values.title,
      channelName: "Generic",
      type: "Generic",
      desc: values.desc,
      topic: values.topic,
    });

    Modal.success({
      content: "Notification has been updated successfully",
      onOk() {
        setLoaded(false);
        props.onClose();
        props.notification();
      },
    });
  };

  return (
    <>
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="title" label="Title" rules={[{ required: true, message: "Please input your Title!" }]}>
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="topic" label="Topic" rules={[{ required: true, message: "Please input your Topic!" }]}>
              <Input placeholder="Topic" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="desc" label="Desc" rules={[{ required: true, message: "Please input your Desc!" }]}>
              <Input placeholder="Desc" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Radio.Group onChange={handleChange} value={doctype}>
              <Radio value="Sent">Now</Radio>
              <Radio value="Scheduled">Scheduled</Radio>
            </Radio.Group>
          </Col>
        </Row>
        {visible ? (
          <>
            <Row gutter={24} className="rtmp1">
              <Col span={12} style={{ textAlign: "left" }}>
                <DatePicker onChange={onDateChange} />
              </Col>
              <Col span={12} style={{ textAlign: "left" }}>
                <TimePicker onChange={onTimeChange} />
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Editalert;
