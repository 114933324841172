
import { Layout, Card, Input, Table, Space, Modal, PageHeader, Spin, Drawer, Form, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import { domain } from "../../configuration/ApiConfiguration";
import { Editor } from "@tinymce/tinymce-react";
import Moment from "react-moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { EyeOutlined } from "@ant-design/icons";
import { json } from "react-router-dom";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function EmailStatus() {
    const [emailInfoList, setEmailInfoList] = useState([]);
    const [EmailInfoFilteredList, setEmailInfoFilteredList] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [row, setRow] = useState(null);

    const getCCemails = (row) => {
        let finalData;
        let dataStr = `${row},`;
        let val = dataStr.split(",").length;
        if(val-1 == 1) {
            finalData = row;
        } else {
            let emails = row;
            let dataStr1 = emails.replace(/,/g, ", ");
            finalData = dataStr1;
        }
        let data = finalData;
        return data;
    }

    const showDrawer = async (row) => {
        setVisible(true);
        setRow(row);
        // setOperation("Edit")
        // setID(matchid.id)
        // setSelectedOption(matchid.platform)
        // form.setFieldsValue({
        //     appId: matchid.appId,
        //     name: matchid.name,
        //     description: matchid.description,
        //     fmCoins: matchid.fmCoins,
        //     price: matchid.price,
        //     provider: matchid.provider,
        //     cutOffPrice: matchid.cutOffPrice,
        //     light: matchid.style.light ? matchid.style.light : "#000000",
        //     dark: matchid.style.dark ? matchid.style.dark : "#000000",
        // });
    };

    const onClose = () => {
        // setID("")
        setVisible(false);
        // setStatusVisible(false);
        // setDeleteVisible(false);
        // form.resetFields();
        // setOperation("Add")
    };

    const onSearch = (e) => {
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = EmailInfoFilteredList.filter((item) => {
                return Object.keys(item).some((key) => {
                    if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
                    return false;
                });
            });
            setEmailInfoList(filteredData);
        } else {
            setEmailInfoList(EmailInfoFilteredList);
        }
    };

    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) return;
    }, [user, loading]);

    useEffect(async () => {
        ListAll();
    }, []);

    const ListAll = async (e) => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/email/notifications/v1`,
        }).then((response) => {
            setLoaded(false);

            let datas = response.data.data.sort(function (a, b) {
                return new Date(b.createdDate) - new Date(a.createdDate);
            });

            let validRowsCollect = [];
            for (let i = 0; i < datas.length; i++) {
                if (datas[i].createdDate) {
                    validRowsCollect.push(datas[i]);
                }
            }

            setEmailInfoList(validRowsCollect)
            setEmailInfoFilteredList(datas)
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <>
                <Sidermenu menu="email_status" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <div>
                                    <PageHeader
                                        className="site-page-header"
                                        title="Email Status List"
                                        extra={
                                            <Space direction="horizontal">
                                                {" "}
                                                <Search placeholder="Search here" onChange={onSearch} enterButton />
                                            </Space>
                                        }
                                    />
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={emailInfoList}
                                        scroll={{ y: "250" }}
                                    >
                                        <Column title="Name" dataIndex="name" key="name" />
                                        <Column title="From Address" dataIndex="fromAddress" key="fromAddress" />
                                        <Column title="To Address" dataIndex="toAddress" key="toAddress" />
                                        <Column
                                            title="Created Date"
                                            dataIndex="createdDate"
                                            key="createdDate"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text ? (
                                                        <Moment format="DD-MM-YYYY hh:mm:ss a">
                                                            {text}
                                                        </Moment>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        <Column
                                            title="Sent Date"
                                            dataIndex="mailSentDate"
                                            key="mailSentDate"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text ? (
                                                        <Moment format="DD-MM-YYYY hh:mm:ss a">
                                                            {text}
                                                        </Moment>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        <Column title="Mail Status" dataIndex="mailStatus" key="mailStatus"
                                            render={((text, record) => {
                                                return (
                                                    <Space size="middle">
                                                        {text ? (
                                                            <span className={text == 'SENT' ? 'text-success' : text == 'FAILED' ? 'text-danger' : 'text-warning'}>
                                                                {text}
                                                            </span>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Space>
                                                )
                                            }

                                            )}
                                        />
                                        <Column
                                            title="View Details"
                                            key="id"
                                            render={(id) => (
                                                <Space size="middle">
                                                    {id !== undefined ? (
                                                        <>
                                                            <EyeOutlined onClick={() => showDrawer(id)} title="View Details" style={{ fontSize: '18px', color: '#08c' }} />
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />


                                    </Table>
                                </div>
                            </Card>
                        </div>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}

                        {row ?

                            <Drawer title="View Details" placement="right" onClose={onClose} visible={visible} className="drawer-align-cls email-drawer-align-cls">
                                <br />
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Name</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.name}</p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>From Address</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.fromAddress}</p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Email Password</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.emailPassword}</p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>To Address</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.toAddress}</p>
                                {(row.ccEmails) ?
                                    <>
                                        <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>CC Emails</h4>
                                        <p style={{ fontSize: "14px", marginBottom: "3rem" }}>
                                        { getCCemails(row.ccEmails) }
                                        </p>
                                    </>
                                    : ""}

                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Created Date</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>
                                    <Moment format="DD-MM-YYYY hh:mm:ss a">
                                        {row.createdDate}
                                    </Moment>
                                </p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Sent Date</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>
                                    <Moment format="DD-MM-YYYY hh:mm:ss a">
                                        {row.mailSentDate}
                                    </Moment>
                                </p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Mail Status</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.mailStatus}</p>
                                {(row.attachments[0]) ?
                                    <>
                                        <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Attachments</h4>
                                        <ol style={{ paddingLeft: "16px", marginBottom: "3rem" }}>
                                            {row.attachments.map((item, index) => (
                                                <li style={{ marginBottom: "15px" }} key={index}>
                                                    {item.filename}
                                                    <a style={{ marginLeft: "15px" }} href={`${domain}/betops/asset/get-by-file/v1?appId=dt20-ops&fileName=${item.path}`}>Download</a>
                                                </li>
                                            ))}
                                        </ol>
                                    </>
                                    : ""}
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Email Notification Id</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.emailNotificationId}</p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Subject</h4>
                                <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{row.subject}</p>
                                <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Body</h4>
                                <Editor
                                    className="form-control"
                                    apiKey="41cqkuqsb1u75egnxve2kvlz6bri8i033od1xtjt9k4yi9fy"
                                    value={row.body}
                                    disabled={true}
                                    init={{
                                        height: 350,
                                        width: "100%",
                                        menubar: false,
                                        branding: false,
                                        contenteditable: true,
                                        content_css_cors: true,
                                        plugins: [
                                            "advlist autolink lists link charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code",
                                        ],
                                        toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat"
                                    }}
                                />
                                {/* <Form layout="vertical">
        <Row gutter={24}>
            <Col span={24} style={{ textAlign: "right" }}>
                <br/>
                <Form.Item label="Subject">
                    <Input type="text" value={row.subject} />
                </Form.Item>{" "}
            </Col>
        </Row>
    </Form> */}
                            </Drawer> : ""
                        }


                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout >
    );
}

export default EmailStatus;
