import { collection, getDocs } from "firebase/firestore";
import { Select, Layout, Card, Col, Input, Table, Tag, Space, Drawer, PageHeader, Spin, List } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

import { EyeOutlined } from "@ant-design/icons";
import localStore from "../utils/services";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function Fantasytransactions() {
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);

  //const [scheduleData, setScheduleData] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const [transactionsDataDup, setTransactionsDetailsDup] = useState([]);
  const [tournamentValue, setTournamentsValue] = useState("All Tournaments");
  const [matchYearValue, setMatchYearValue] = useState("All Years");

  //const [isModalVisible, setIsModalVisible] = useState(false);
  //const [modaltitle, setModaltitle] = useState("");

  const [matchyears, setMatchyear] = useState([]);
  const [tournaments, setTournaments] = useState([]);

  const [playersSquad, setPlayersSquad] = useState([]);
  const [matchPlayers, setMatchPlayers] = useState([]);

  const [transactionsDetails, setTransactionsDetails] = useState([]);

  const showDrawer = (matchid) => {

    frameMatchPlayers(matchid);
    setVisible(true);
  };

  const fetchTransactionsDetails = async () => {
    const _transactions = collection(db, "fantasy_trans");
    const _snapshot = await getDocs(_transactions);
    const _resultArray = [];
    let _tournaments = [];
    let _year = [];

    _snapshot.docs.forEach(function (item, index) {
      let _data = item.data();
      let _obj = { ..._data };

      if (_data && _data.matchId && _data.endTs) {
        let _time = String(_data.endTs.seconds).padEnd(13, "0");
        _obj.endTs = String(new Date(Number(_time)));
        _obj.key = index;
   
        _resultArray.push(_obj);

        if (_year.indexOf(_obj.year) === -1) _year.push(_obj.year);
        if (_tournaments.indexOf(_obj.tournament) === -1) _tournaments.push(_obj.tournament);
      }
    });
    if (_tournaments.length > 1) {
      _tournaments = ["All Tournaments", ..._tournaments];
    }
    setTournaments(_tournaments);
    setMatchyear(_year);
    setTransactionsData(_resultArray);

    setTransactionsDetails(_resultArray);
    setTransactionsDetailsDup(_resultArray);
    setTournamentsValue("All Tournaments");
    setMatchYearValue("All Years");
  };

  const fetchSchedule = async () => {
    const _scheduleCol = collection(db, "fantasy_schedule");
    const _scheduleSnapshot = await getDocs(_scheduleCol);
    const _resultArray = [];

    _scheduleSnapshot.docs.map((doc) => {
      _resultArray.push(doc.data());
    });

    let _players = [];
    let fantasyLineup = [];
    
    for (let k in _resultArray) {

      if (_resultArray[k] && _resultArray[k].docType == "FANTASY_LINEUP_MATCH" && _resultArray[k]["tournament"] == "IPL" && _resultArray[k]["year"] == "2022") {
        _players = _players.concat(_resultArray[k].ht.players || [])
        _players = _players.concat(_resultArray[k].rt.players || [])
        let docName = _resultArray[k]["tournament"] + "_" + _resultArray[k]["year"] + "_FANTASY_LINEUP";
        fantasyLineup[docName] = _players;
      }
    }
    setPlayersSquad(_players);
    setLoaded(false);
  };
  const handleTournament = (e) => {
    if (e) {
      setTournamentsValue(e);
      const _filteredData = transactionsData.filter(function (item) {
        if (e == "All Tournaments") return true;
        return item.tournament == e;
      });
      setTransactionsDetails(_filteredData);
      let _year = [];
      _filteredData.forEach(function (item) {
        if (_year.indexOf(item.year) === -1) {
          _year.push(item.year);
        }
      });
      if (_year.length > 1) {
        _year = ["All Years", ..._year];
      }
      setMatchyear(_year);
      setMatchYearValue(_year[0]);
    }
  };
  const handleYear = (e) => {
    if (e) {
      setMatchYearValue(e);
      const _filteredData = transactionsData.filter(function (item) {
        if (e == "All Years" && tournamentValue == "All Tournaments") return true;
        if (e == "All Years") {
          return item.tournament == tournamentValue;
        }
        if (tournamentValue == "All Tournaments") {
          return item.year == e;
        }
        return item.tournament == tournamentValue && item.year == e;
      });
      setTransactionsDetails(_filteredData);
    }
  };
  const frameMatchPlayers = (obj) => {
    let _playersList = [];
    for (let i = 0; i < obj.playerlist.length; i++) {
      let _playersLists = playersSquad.filter((list) => list.mIdPId == obj.playerlist[i].mIdPId || list.fi == obj.playerlist[i].fi)
      if (_playersLists.length > 0) {
        _playersList.push(_playersLists[0])
      }
    }
    setMatchPlayers(_playersList);
  }
  const sortArray = (type) => {
    const types = {
      matchId: "matchId",
    };
    const sortProperty = types[type];
    const sorted = [...transactionsDetails].sort((a, b) => {
      return b[sortProperty] - a[sortProperty];
    });
    setTransactionsDetails(sorted);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    sortArray(sorter.field);
    if (sorter.order == undefined) {
      setTransactionsDetails(transactionsDetails.reverse());
    } else if (sorter.order == "descend") {
      sortArray(sorter.field);
    } else {
      setTransactionsDetails(transactionsDetails.reverse());
    }
  };
  const fetchSearch = async () => {

    if (tournamentValue === "All Tournaments" && matchYearValue === "All Years") {
      setTransactionsDetails(transactionsDataDup);
    }
    else {
      const filteredData = transactionsDataDup
        .filter(function (item) {
          return item.tournament == tournamentValue && item.year == matchYearValue;
        })
        .map(function (item) {
          return item;
        });
      setTransactionsDetails(filteredData);
    }

  };

  const onSearch = (e) => {

    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = transactionsDataDup.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setTransactionsDetails(filteredData);
    } else {
      fetchSearch();
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(async () => {
    let _config = await localStore.getItem();
    fetchTransactionsDetails();
    fetchSchedule();
  }, [loaded]);

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="fantasy_transactions" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                  className="site-page-header"
                  title="Fantasy Transactions"
                  extra={
                    <Space key="p1" direction="horizontal">
                      <Search placeholder="Search here" onChange={onSearch} enterButton />{" "}
                      <Select
                        id="match"
                        size="default"
                        value={tournamentValue}
                        placeholder="All Tournaments"
                        style={{ width: "200px" }}
                        onChange={(e) => handleTournament(e)}
                      >
                        {tournaments.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}{" "}
                          </Option>
                        ))}
                      </Select>
                      ,
                      <Select
                        id="years"
                        size="default"
                        placeholder="All Years"
                        value={matchYearValue}
                        style={{ width: "200px" }}
                        onChange={(e) => handleYear(e)}
                      >
                        {matchyears.map((name, pos) => {
                          return (
                            <Option key={pos} value={name}>
                              {name}{" "}
                            </Option>
                          );
                        })}
                      </Select>
                    </Space>
                  }
                />
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  dataSource={transactionsDetails}
                  onChange={handleTableChange}
                  scroll={{ y: "250" }}
                >
                  <Column title="Match ID" dataIndex="matchId" key="matchid" sorter="true" />
                  <Column title="Year" dataIndex="year" key="year" sorter="true" />
                  <Column title="UID" dataIndex="uid" key="uid" sorter="true" />
                  <Column title="Tournament" dataIndex="tournament" key="tournament" sorter="true" />
                  <Column title="End Ts" dataIndex="endTs" key="endTs" sorter="true" />
                  <Column title="Points Act" dataIndex="pointsAct" key="pointsAct" sorter="true" />
                  <Column title="Points Est" dataIndex="pointsEst" key="pointsEst" sorter="true" />

                  <Column
                    title="Quick Actions"
                    key="matchId"
                    render={(matchId, index) => (
                      <Space key={index} size="middle">
                        {matchId !== undefined ? (
                          <a onClick={() => showDrawer(matchId)}>
                            <EyeOutlined style={{ fontSize: "18px", color: "#08c" }} /> Players
                          </a>
                        ) : (
                          ""
                        )}
                      </Space>
                    )}
                  />
                </Table>
              </Card>
            </div>

            <Drawer title="Fantasy List" placement="right" onClose={onClose} visible={visible} size="large">
              <List
                itemLayout="horizontal"
                dataSource={matchPlayers}
                renderItem={(item, index) => (
                  <List.Item>
                    <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                      <span className="playersList" style={{ whiteSpace: "break-spaces", display: "flex", justifyContent: "flex-start", fontWeight: "bold" }}>
                        <Col span={4} className="rtside">
                          <span>
                            <img src={item.playerPhoto} width={"100px"} />
                          </span>
                        </Col>
                        <Col span={20}>
                          <p className="players">
                            <span className="playerKeys">Name :</span> <span>{item.name} </span>
                            <span className="playerKeys">Nationality : </span> <span>{item.nat}</span>
                          </p>
                          <p className="players">
                            <span className="playerKeys">Batting Type :</span> <span>{item.batType}</span> <span className="playerKeys">Batting(L/R) : </span>
                            <span>{item.bats}</span>
                          </p>
                          <p className="players">
                            <span className="playerKeys">Bowler Type :</span> <span>{item.bowlertType}</span>{" "}
                            <span className="playerKeys">Bowling Type : </span>
                            <span>{item.bowType}</span>
                          </p>
                          <p className="players">
                            <span className="playerKeys">Salary (INR Cr) :</span> <span>{item.salaryRsCrore}</span>
                          </p>
                        </Col>
                      </span>
                    </Tag>
                  </List.Item>
                )}
              />
            </Drawer>

            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Fantasytransactions;
