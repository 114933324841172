import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { Chart } from "react-google-charts";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Option } = Select;

const { Content } = Layout;
function UpcomingMatches() {
    const [loaded, setLoaded] = useState(true);
    const [highlightBox, setHighlightBox] = useState("1")
    const [user, loading, error] = useAuthState(auth);
    const { TabPane } = Tabs;

    useEffect(async () => {
        setLoaded(true)
        setLoaded(false)
    }, []);
    const highlight = (arg) => {

        setHighlightBox(arg)
    }
    return (

        <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <Card bordered={false} bodyStyle={{ padding: "8px" }} style={{ borderBottom: "2px solid #2196F3" }}>
                <div><span style={{ fontWeight: "600" }}>Upcoming Matches</span></div>
            </Card>
            <br />
            <div className="row">
                <div class="col-md-3 mb-2">
                    <div class="card p-1 ml-1 mr-1">
                        <div class="card-header p-0 bg-white" style={{ borderBottom: "1px solid #2222221A" }}>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small class="fw-bold text-muted">T20 IPL 2022</small>
                                    <small class="fw-bold text-muted float-end">(IND)</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0" style={{ borderBottom: "1px solid #2222221A" }}>
                            <div class="row mt-1">
                                <div class="col-md-5 col-5">
                                    <img src="images/CSK.png" className="rounded-circle" width="30px" />
                                    <small className="fw-bold" style={{ color: "#2196F3" }}> CSK</small>
                                </div>
                                <div class="col-md-2 col-2 text-danger fw-bold text-danger">vs</div>
                                <div class="col-md-5 col-5">
                                    <img src="images/RR.png" className="rounded-circle" width="30px" />
                                    <small className="fw-bold" style={{ color: "#2196F3" }}> RR</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small className="fw-bold text-muted">12 Dec / 6:30 PM PST</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-0 bg-white">
                            <div class="row">
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Match 02</small>
                                </div>
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Round 01</small>
                                </div>
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Group B</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-2">
                    <div class="card p-1 ml-1 mr-1">
                        <div class="card-header p-0 bg-white" style={{ borderBottom: "1px solid #2222221A" }}>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small class="fw-bold text-muted">T20 IPL 2022</small>
                                    <small class="fw-bold text-muted float-end">(IND)</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0" style={{ borderBottom: "1px solid #2222221A" }}>
                            <div class="row mt-1">
                                <div class="col-md-5 col-5">
                                    <img src="images/MI.png" className="rounded-circle" width="30px" />
                                    <small className="fw-bold" style={{ color: "#2196F3" }}> MI</small>
                                </div>
                                <div class="col-md-2 col-2 text-danger fw-bold text-danger">vs</div>
                                <div class="col-md-5 col-5">
                                    <img src="images/GT.png" className="rounded-circle" width="30px" />
                                    <small className="fw-bold" style={{ color: "#2196F3" }}> GT</small>
                                </div>
                            </div>
                        </div>

                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small className="fw-bold text-muted">12 Dec / 6:30 PM PST</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-0 bg-white">
                            <div class="row">
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Match 03</small>
                                </div>
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Round 01</small>
                                </div>
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Group B</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-2">
                    <div class="card p-1 ml-1 mr-1">
                        <div class="card-header p-0 bg-white">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small class="fw-bold text-muted">T20 IPL 2022</small>
                                    <small class="fw-bold text-muted float-end">(IND)</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0" style={{ borderBottom: "1px solid #2222221A" }}>
                            <div class="row mt-1">
                                <div class="col-md-5 col-5">
                                    <img src="images/DC.png" className="rounded-circle" width="30px" />
                                    <small className="fw-bold" style={{ color: "#2196F3" }}> DC</small>
                                </div>
                                <div class="col-md-2 col-2 text-danger fw-bold">vs</div>
                                <div class="col-md-5 col-5">
                                    <img src="images/MI.png" className="rounded-circle" width="30px" />
                                    <small className="fw-bold" style={{ color: "#2196F3" }}> MI</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small className="fw-bold text-muted">12 Dec / 6:30 PM PST</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-0 bg-white">
                            <div class="row">
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Match 01</small>
                                </div>
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Round 01</small>
                                </div>
                                <div class="col-md-4 col-4">
                                    <small className="fw-bold text-muted">Group B</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Content >

    );
}

export default UpcomingMatches;
