import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { Select, Spin, Layout, Input, Menu, Form, Card, Row, Popover, Col, Table, Tag, Space, Button, Modal, Drawer, Divider, PageHeader } from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Addtournament from "./addtournament";
import Editdashboard from "./editdashboard";
import { Link } from "react-router-dom";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import ReactJson from "react-json-view";
import Moment from "react-moment";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  LoginOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option } = Select;
const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const doctypearray = ["SCHEDULE", "PLAY_OFF", "INSIGHTS"];
function Tournament() {
  const [form] = Form.useForm();
  const [rowInfo, setRowInfo] = useState("");
  const [StatusVisible, setStatusVisible] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [pushdata, setPushdata] = useState([]);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [editmatchid, setEditmatchid] = useState([]);
  const [matchinfo, setMatchinfo] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [fullRes, setFullRes] = useState([]);
  const [matchdetails, setMatchDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [matchyear, setMatchyear] = useState([]);
  const [matchValue, setMatchValue] = useState("");
  const [drawertype, setDrawertype] = useState("");
  const [title, setTitle] = useState("");
  const [currentindex, setCurrentindex] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleJson = () => {
    //downloadFile(arraycheck,documentID.documentID)
    setJsonviewer(false);
  };

  const sportsListAndInfoConfig = async () => {
    const response1 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sports/v1`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        localStorage.setItem("sportsList", response.data.data);
        // await sportInfo(response.data.data[0]);
      }
      else {
      }
    }).catch((error) => {
    })
  }

  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setMatchDetails([]);
    setTitle("Add Tournament");
    setDrawertype("Add Tournament");
    setVisible(true);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const showDrawer = (matchid, index) => {
    console.log(index);
    const res = matchid.tournamentName;
    setTitle(res);
    setCurrentindex(index);
    setMatchDetails(matchid);
    setDrawertype("Edit Tournament");
    setVisible(true);
  };
  const datesplit = (text) => {
    const res = text.split("_");
    var currentTimeInSeconds = Math.floor(res[1] / 1000);
    return currentTimeInSeconds;
  };
  const deleterecord = async (matchid, index1) => {
    setLoaded(true);
    const response = await axios({
      method: 'delete',
      url: `${domain}/betops/tournament/config/delete/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${matchid.tournamentName}`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        await Modal.success({
          content: "Deleted Successfully",
          onOk() {
            fetchDashboard();
            sportsListAndInfoConfig();
          },
        });
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  };
  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setRowInfo("")
    setStatusVisible(false);
  };

  const statusTheTournament = async () => {
    setStatusVisible(false);
    setLoaded(true);
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/tournament/config/toggle-status/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${rowInfo.tournament}`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      onClose();
      if (response.data.status === "SUCCESS") {
        Modal.success({
          content: response.data.data,
        });
        setTimeout(async () => {
          fetchDashboard();
          sportsListAndInfoConfig();
        }, 2000);
      }
      else if (response.data.status === "FAIL") {
        setLoaded(false);
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
      else {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later.",
        });
      }
    })
      .catch((error) => {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later.",
        });
      })
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const content = (notifId) => {
    return (
      <div>
        <p>{notifId.title}</p>
        <p>{notifId.desc}</p>
      </div>
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeStatus = async (rowData) => {
    setRowInfo(rowData)
    setStatusVisible(true);
  };

  const fetchDashboard = async () => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/tournament/config/get-data/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setFullRes(response.data.data.tournamentData);
        let tempArr = [];
        for (let i = 0; i < response.data.data.tournamentData.tournament.length; i++) {
          // if (response.data.data.tournamentData.tournament[i].active == true) {
            tempArr.push(response.data.data.tournamentData.tournament[i]);
          // }
        }
        setSchedule(tempArr);
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  };

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    fetchDashboard();
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    // if (loaded) {
    //   setTimeout(() => {
    //     setLoaded(false);
    //   }, 2000);
    // }
  }, [loaded]);
  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="tournament" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                  className="site-page-header"
                  title="Tournament"
                  extra={
                    <>
                      {" "}
                      <Space direction="horizontal">
                        {" "}
                        <Search placeholder="Search here" onChange={onSearch} enterButton />
                        <a onClick={() => addnew()}>
                          <span class="addbet">Add New</span>  <PlusCircleTwoTone style={{ fontSize: "20px", color: "#08c", textAlign: "center" }} />

                        </a>
                      </Space>{" "}
                    </>
                  }
                />
                <Table dataSource={schedule} pagination={{ pageSize: 10 }} scroll={{ y: "340" }}>
                  <Column title="Tournament" dataIndex="tournament" key="tournament" />
                  <Column title="Tournament Title" dataIndex="tournamentTitle" key="tournamentTitle" />
                  <Column title="Status" dataIndex="active" key="active"
                    render={(text, record) => (
                      <Space size="middle">
                        {text == true ? (
                          <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                        ) : (
                          <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                        )}
                      </Space>
                    )}
                  />
                  {/* <Column title="Sport" dataIndex="sport" key="sport" /> */}
                  <Column
                    title="Quick Actions"
                    key="notifId"
                    render={(notifId, record, index) => (
                      <Space size="middle">
                        {/* <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} /> */}
                        <LoginOutlined onClick={() => changeStatus(notifId)} title="Change Tournament Status" style={{ fontSize: "18px", color: "#08c" }} />
                        <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                      </Space>
                    )}
                  />
                </Table>
              </Card>
            </div>

            <Modal title="Status" onCancel={onClose} visible={StatusVisible}
              footer={[<>  <Button key="submit" type="primary" onClick={statusTheTournament}>
                Ok
              </Button>
                <Button key="submit" type="primary" onClick={onClose}>
                  Cancel
                </Button></>
              ]}
            >
              Are you sure you want to Change the Tournament Status?
            </Modal>

            <Modal title="Notification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              Are you sure you want to Notification?
            </Modal>
            <Modal title="Json" visible={jsonviewer} onOk={handleJson} onCancel={jsoncancel}>
              <ReactJson src={jsondata} />
            </Modal>

            <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="small">
              <Addtournament
                onClose={onClose}
                notification={fetchDashboard}
                drawertype={drawertype}
                matchdetails={matchdetails}
                tournamentform={form}
                fetchindex={currentindex}
                fullres={fullRes}
                schedule={schedule}
              />
            </Drawer>
            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}

            {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Tournament;
