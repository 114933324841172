import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { db, auth, logInWithEmailAndPassword, currentTournamentDetails, logout } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserOutlined, LockOutlined, PoweroffOutlined } from "@ant-design/icons";
import { collection, getDocs, query, where, onSnapshot } from "firebase/firestore";
import { Row, Col, Button, Form, Input, Divider, Tag, Modal, Space } from "antd";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import "./login.css";
import "antd/dist/antd.min.css";
function Login() {
  const [email, setEmail] = useState("");
  const [loginload, setLoginload] = useState(false);
  const [password, setPassword] = useState("");
  const [adminUsers, setAdminUsers] = useState("");
  const [form] = Form.useForm();
  const [status, setStatusMsg] = useState({ error: false, message: "Not a valid admin User" });
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(async () => {
    setStatusMsg({ error: false, message: "" });
    if (loading) {
      return;
    }

    if (user) {
      //console.log('loggin success');
      //navigate(-1);
    }

  }, [user, loading]);

  const onFinish = async (values) => {
    setLoginload(true);
    setStatusMsg({ error: false, message: "Not a valid admin User" });
    const q = query(collection(db, "admin_users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    let _userObj = null;
    querySnapshot.forEach((doc) => {
      _userObj = doc.data();
    });

    if (_userObj) {
      if (_userObj.active) {
        let res = await logInWithEmailAndPassword(email, password);
        if (res == false) {
          setStatusMsg({ error: true, message: "Invalid email / password" });
          setLoginload(false);
        } else {
          const response = await axios({
            method: 'get',
            url: `${domain}/betops/auth/user/email/v2.0?email=${email}`,
            headers: { "Authorization": `Bearer ${res._tokenResponse.idToken}` }
          }).then((response) => {
            if (response.data.status === "SUCCESS") {
              localStorage.setItem("aT", res._tokenResponse.idToken);
              if (window.history.length > 2)
                window.history.back();
              else
                navigate('/overview');
            }
            else {
              if (response.data.error.message == "Auth token is not valid") {
                Modal.error({
                  content: response.data.error.message,
                  onOk() {
                    logout();
                  }
                });
              } else {
                Modal.error({
                  content: response.data.error.message
                });
              }
              setLoginload(false);
            }
          })
            .catch((error) => {
              Modal.error({
                content: "Unable to process your Request, Please try again later."
              });
              setLoginload(false);
            })
        }
      } else {
        setPassword("");
        setStatusMsg({ error: true, message: "Not a valid / Inactive admin User" });
        setLoginload(false);
      }
    } else {
      setPassword("");
      setStatusMsg({ error: true, message: "Not a valid / Inactive admin User" });
      setLoginload(false);
    }
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="logo">
          <img src={currentTournamentDetails.logo}></img>
        </div>
        <Divider>
          <span>Super User</span>
        </Divider>
        <span className="httxt">Hi there !</span>

        <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="User email" rules={[{ required: true, message: "Please enter your email" }]}>
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  type="text"
                  block="true"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="e-mail"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="password" rules={[{ required: true, message: "Please enter your password" }]}>
                <Input
                  type="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  block="true"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: "right" }}> <span style={{ color: "#2196f3" }}>
            Forgot Password ?
          </span></div>

          {status.error ? <Tag color="error">{status.message}</Tag> : <Tag color="white">{""}</Tag>}
          <Col span={24} style={{ marginTop: "8px", marginBottom: "32px" }}>
            <Form.Item>
              {loginload ?
                <Button size="large" block type="primary" loading>
                  Loading
                </Button>
              //   <Button type="primary" loading>
              //   Loading
              // </Button>
                :
                <Button size="large" block type="primary" htmlType="submit">
                  Login
                </Button>
              }


            </Form.Item>
          </Col>
          <div style={{ textAlign: "center" }}> <span>
            Need Access? <span style={{ color: "#2196f3" }}>
              - Request Access
            </span>
          </span></div>
        </Form>
        <span style={{ fontSize: "smaller", color: "#cccccc" }}>version {currentTournamentDetails.version}</span>
      </div>
    </div>
  );
}
export default Login;
