import { Select, Layout, Card, Input, Table, Space, Button, Modal, Drawer, PageHeader, Spin, Tabs, Row, Col, Form, Divider } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import Moment from "react-moment";
import UserWalletTransactions from "./UserWalletTransactions";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import {
    EyeOutlined,
    LoginOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth, currentTournamentDetails } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { domain } from "../configuration/ApiConfiguration";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
function UserWallet() {
    const [StatusVisible, setStatusVisible] = useState(false);
    const [ID, setID] = useState("");
    const [walletList, setWalletList] = useState([]);
    const [WalletFilteredList, setWalletFilteredList] = useState([]);
    const [usertransactions, setUsertransactions] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);

    const showDrawer = async (matchid) => {
        setUsertransactions(true);

        setID(matchid)
    };
    const changeStatus = async (matchid) => {
        setID(matchid.id)
        setStatusVisible(true);
    };
    const statusThePackage = async () => {
        setLoaded(true);
        let data = {
            "id": ID
        }
        const response = await axios.post(
            `${domain}/betops/iap/toggle-status/v1`, data)
            .then((response) => {

                onClose()
                setLoaded(false);
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.data,
                    });
                    setTimeout(async () => {
                        ListAll();
                    }, 2000);
                }
                else if (response.data.status === "FAIL") {
                    Modal.error({
                        content: response.data.data,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    const onClose = () => {
        setID("")
        setUsertransactions(false);
    };
    const onSearch = (e) => {

        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = WalletFilteredList.filter((item) => {
                return Object.keys(item).some((key) => {
                    if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
                    return false;
                });
            });
            setWalletList(filteredData);
        } else {
            setWalletList(WalletFilteredList);
        }
    };
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);
    useEffect(async () => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/wallet/get-list/v1?appId=${currentTournamentDetails.shortCode}-com`,
        }).then((response) => {

            setLoaded(false);
            if (response.data.status === "SUCCESS") {
                setWalletList(response.data.data)
                setWalletFilteredList(response.data.data)
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: response.data.data,
                });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }, []);
    const ListAll = async (e) => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/iap/get-list/v1?appId=${currentTournamentDetails.shortCode}-com`,
        }).then((response) => {

            setLoaded(false);
            if (response.data.status === "SUCCESS") {
                setWalletList(response.data.data)
                setWalletFilteredList(response.data.data)
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: response.data.data,
                });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    const handleTableChange = (pagination, filters, sorter) => {

        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setWalletList(walletList.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {
            setWalletList(walletList.reverse());
        }
    }
    const sortArray = (type) => {
        const types = {
            fmCoins: "fmCoins",
            price: "price",
        };
        const sortProperty = types[type];
        const sorted = [...walletList].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });

        setWalletList(sorted);
    };
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="user_wallet" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                {usertransactions ? <UserWalletTransactions ID={ID} onClose={onClose} /> : <div>
                                    <PageHeader
                                        className="site-page-header"
                                        title="Wallet List"
                                        extra={
                                            <Space direction="horizontal">
                                                {" "}
                                                <Search placeholder="Search here" onChange={onSearch} enterButton />
                                            </Space>
                                        }
                                    />
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={walletList}
                                        // pagination={{ defaultPageSize: 10, showSizeChanger: true, total: 500, pageSizeOptions: ['10', '20', '30'] }}
                                        onChange={handleTableChange}
                                        scroll={{ y: "250" }}
                                    >
                                        <Column title="Platform" dataIndex="platform" key="platform" />
                                        <Column title="App Id" dataIndex="aid" key="aid" />
                                        <Column title="FM Coins" dataIndex="fmCoins" key="fmCoins" sorter="true" />
                                        <Column
                                            title="Created Date"
                                            dataIndex="createdDate"
                                            key="createdDate"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text ? (
                                                        <Moment unix format="DD-MM-YYYY">
                                                            {text._seconds}
                                                        </Moment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        <Column
                                            title="Modified Date"
                                            dataIndex="modifiedDate"
                                            key="modifiedDate"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text ? (
                                                        <Moment unix format="DD-MM-YYYY">
                                                            {text._seconds}
                                                        </Moment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        <Column
                                            title="Quick Actions"
                                            key="uid"
                                            render={(uid) => (
                                                <Space size="middle">
                                                    {uid !== undefined ? (
                                                        <>
                                                            {/* <LoginOutlined onClick={() => changeStatus(id)} title="Change Package status" style={{ fontSize: "18px", color: "#08c" }} /> */}
                                                            <EyeOutlined onClick={() => showDrawer(uid)} title="View Transactions" style={{ fontSize: '18px', color: '#08c' }} />
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                </div>}

                            </Card>
                        </div>

                        <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                            footer={[<>  <Button key="submit" type="primary" onClick={statusThePackage}>
                                Ok
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>

                            ]}
                        >
                            Are you sure you want to Change the Package Status?
                        </Modal>
                        <Drawer title="Update Package" placement="right" onClose={onClose} visible={visible} className="matchcard">

                        </Drawer>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
                    </Content>

                    <Footersection />
                </Layout>
            </>

        </Layout >
    );
}
export default UserWallet;
