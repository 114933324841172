import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { Spin, Select, Switch, Layout, Menu, Card, Row, Col, Table, Tag, Space, Button, Modal, Drawer, Form, Input, Divider } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
function Editbets(props) {
  const [loaded, setLoaded] = useState(false);
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [result, setResult] = useState();
  const [betinfo, setBetinfo] = useState(props.matchid.betinfo);
  const [hedgebet, setHedgebet] = useState(false);
  const [checkarray, setCheckarray] = useState([]);
  const [drawertitle, setDrawertitle] = useState();
  const [drawertype, setDrawertype] = useState();
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [values, setValues] = useState({
    betLegend: props.matchid.betLegend,
    subLabel: props.matchid.subLabel,
    tournament: props.matchid.tournament,
    season: props.matchid.year,
    betNum: props.matchid.betNum,
    sideLabel: props.matchid.sideLabel,
    odds: props.matchid.odds,
    bet: props.matchid.bet,
    win: props.matchid.win,
    betMode: props.matchid.betMode,
  });
  const addside = () => {
    setChildrenDrawer(true);
    setDrawertype("add");
    editform.setFieldsValue({
      sideLabel: "",
      odds: "",
      bet: "",
      win: "",
      winresult: "",
    });
  };
  const showDrawer = (betId) => {
    setDrawertype("edit");
    setDrawertitle(betId.betId);
    setChildrenDrawer(true);
    setHedgebet(betId.hedgeBet !== undefined ? betId.hedgeBet : hedgebet); 
    editform.setFieldsValue({
      sideLabel: betId.sideLabel,
      odds: betId.odds,
      sideNum: betId.sideNum,
      bet: betId.bet,
      win: betId.win,
      betLegend: props.matchid.betLegend,
      subLabel: props.matchid.subLabel,
      tournament: props.matchid.tournament,
      season: props.matchid.year,
      winresult: betId.betResult == -1 ? "LOSS" : "WIN",
      betMode: props.matchid.betMode,
    });
  };
  const onClose = () => {
    setChildrenDrawer(false);
  };
  const onHedge = (checked) => {
    setHedgebet(checked);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const deleterecord = (betid) => {
    const final = betinfo.filter((number) => {
      return number.betId !== betid.betId;
    });
    setBetinfo(final);
    setCheckarray(final);
  };
  const loadProfile = () => {
    setBetinfo(props.matchid.betinfo);
    const winningside = props.matchid.betinfo;
    const finaldata = props.matchid.betinfo
      .filter(function (item) {
        return item.betResults == 1;
      })
      .map(function (item) {
        return item;
      });
    form.setFieldsValue({
      betLegend: props.matchid.betLegend,
      subLabel: props.matchid.subLabel,
      tournament: props.matchid.tournament,
      season: props.matchid.year,
      betNum: props.matchid.betNum,
      sideLabel: props.matchid.sideLabel,
      odds: props.matchid.odds,
      bet: props.matchid.bet,
      win: props.matchid.win,
      winresult: props.matchid.betResult,
      betMode: props.matchid.betMode,
      sideNum: props.matchid.sideNum,
    });
  };
  useEffect(() => {

    loadProfile();
  }, [props.matchid, props.matchid.betinfo]);
  const onSave = async (values) => {

    const finalarray = props.matchid.betinfo;
    let obj = {};
    if (drawertype == "edit") {
      finalarray.forEach(function (fn, j) {
        if (fn.betId == drawertitle) {
          var newStr = fn.betId.split(":");
          var updated = Number(newStr[4]) + 1;
          var newbetID = newStr[0] + ":" + newStr[1] + ":" + newStr[2] + ":" + newStr[3] + ":" + updated;
          fn.betId = newbetID;
          fn.bet = Number(values.bet);
          fn.win = Number(values.win);
          fn.sideNum = values.sideNum;
          fn.odds = Number(values.odds);
          fn.betResult = values.winresult == "WIN" ? 1 : -1;
          fn.sideLabel = values.sideLabel;
          if (fn.hedgeBet !== undefined) {
            fn.hedgeBet = hedgebet;
          } else {
            fn.hedgeBet = hedgebet;
          }
        }
      });
      setBetinfo(finalarray);
    }
    setResult(values);
    setChildrenDrawer(false);
  };
  const onFinish = async (values) => {
    if(props.popupbets == "true") {
      let item3 = props.newDoc;
        if (item3.betNum == props.matchid.betNum) {
          item3.betLegend = values.betLegend;
          item3.betMode = values.betMode;
          item3.subLabel = values.subLabel;
          item3.betNum = values.betNum;
          var snmum = item3.sideList.length + 1;
          if (drawertype == "add") {
            var newbetID = props.matchid.tournament + ":" + props.matchid.matchid + ":" + values.betNum + ":" + snmum + ":" + 1;
            item3.sideList.push({
              betId: newbetID,
              bet: Number(result.bet),
              win: Number(result.win),
              sideNum: snmum,
              odds: Number(result.odds),
              betResult: result.winresult == "WIN" ? 1 : -1,
              sideLabel: result.sideLabel,
              hedgeBet: hedgebet,
            });
          } else {
            item3.sideList = betinfo;
          }
        }
        localStorage.setItem("oddsObj", JSON.stringify(item3));
        props.updateMatchdetails();
    } else {
      const matchDocRef = doc(db, "bet_schedule", props.matchid.documentID);
      const docSnap = await getDoc(matchDocRef);
      const arraycheck = docSnap.data();
      await setDoc(matchDocRef, docSnap.data());
      let mainarray = [];
      mainarray.push(docSnap.data());
      mainarray[0].bets.betList.forEach(function (item3, index3) {
        if (item3.betNum == props.matchid.betNum) {
          item3.betLegend = values.betLegend;
          item3.betMode = values.betMode;
          item3.subLabel = values.subLabel;
          item3.betNum = values.betNum;
          var snmum = item3.sideList.length + 1;
          if (drawertype == "add") {
            var newbetID = props.matchid.tournament + ":" + props.matchid.matchid + ":" + values.betNum + ":" + snmum + ":" + 1;
            item3.sideList.push({
              betId: newbetID,
              bet: Number(result.bet),
              win: Number(result.win),
              sideNum: snmum,
              odds: Number(result.odds),
              betResult: result.winresult == "WIN" ? 1 : -1,
              sideLabel: result.sideLabel,
              hedgeBet: hedgebet,
            });
          } else {
            item3.sideList = betinfo;
          }
        }
      });
  
      let obj = {};
      mainarray.forEach(function (val, i) {
        obj = {
          val,
        };
      });
  
      const res = await updateDoc(matchDocRef, obj.val);
  
      Modal.success({
        content: "Updated Successfully",
        onOk() {
          setLoaded(false);
          props.onClose();
          props.updateMatchdetails();
        },
      });
    }
  };

  const title = props.matchid.tournamenttitle + " | Game " + props.matchid.matchno;
  return (
    <>
      <Divider orientation="left" orientationMargin="0" className="dividertext">
        <h4>Bet Info - Main</h4>
      </Divider>
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Number</p>
            <Form.Item name="betNum" label="" rules={[{ required: true, message: "Please input your betno!" }]}>
              <Input type="text" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Tournament</p>
            <Form.Item name="tournament" label="" rules={[{ required: true, message: "Please input your Tournament!" }]}>
              <Input placeholder="Comments" disabled={true} />
            </Form.Item>{" "}
          </Col>

          <Col span={12} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Season</p>
            <Form.Item name="season" label="" rules={[{ required: true, message: "Please input your Season!" }]}>
              <Input placeholder="Comments" disabled={true} />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={10} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Label</p>
            <Form.Item name="subLabel" label="" rules={[{ required: true, message: "Please input your Bet Label!" }]}>
              <Input placeholder="Comments" />
            </Form.Item>{" "}
          </Col>

          <Col span={6} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Legend</p>
            <Form.Item name="betLegend" label="" rules={[{ required: true, message: "Please input your Bet Legend!" }]}>
              <Input placeholder="Comments" />
            </Form.Item>{" "}
          </Col>
          <Col span={8} style={{ textAlign: "left" }}>
            <p className="mainlabel"> Bet Mode</p>
            <Form.Item name="betMode" label="" rules={[{ required: true, message: "Please Select Bet Mode!" }]}>
              <Select dropdownStyle={{ zIndex: 4500 }} defaultValue="Select Bet Mode" style={{ width: "100%" }}>
                {props.betmode.map((bvalue, j) => (
                  <Option key={j} value={bvalue}>
                    {bvalue}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <div style={{ width: "100%", position: "relative" }}>
          <h3>Bet Info - Side</h3>
          <span className="addsides">
            <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
            <a onClick={() => addside()}>
              <span className="addschedules"> Add Side</span>
            </a>
          </span>
        </div>

        <Table locale="No Data" dataSource={betinfo} pagination={{ pageSize: 10 }} scroll={{ y: "250" }}>
          <Column title="Bet ID" dataIndex="betId" key="betId" sorter="true" />
          <Column title="Side Label" dataIndex="sideLabel" key="sideLabel" />
          <Column
            title="Bet Results"
            key="betResult"
            dataIndex="betResult"
            render={(text, record) => (
              <Space size="middle">
                <span className={text == 1 ? "winlabel" : "losslabel"}>{text == 1 ? "WIN" : "LOSS"}</span>
              </Space>
            )}
          />
          <Column
            title="Quick Actions"
            key="betId"
            render={(record) => (
              <Space size="middle">
                <EditTwoTone onClick={() => showDrawer(record)} style={{ fontSize: "18px", color: "#08c" }} />
                <DeleteTwoTone onClick={() => deleterecord(record)} style={{ fontSize: "18px", color: "#08c" }} />
              </Space>
            )}
          />
        </Table>
        {loaded == true ? (
          <div className="loading-overlay">
            <Spin size="large" />{" "}
          </div>
        ) : (
          ""
        )}

          {props.popupbets == "true" ? (        
        <Row gutter={24}>
        <Col span={24}>
          <Form.Item shouldUpdate {...tailLayout}>
            {() => (
              <Button
                type="primary"
                className="updatematchbtn"
                htmlType="submit"
                disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              >
                Continue
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
          ) : (
            <Row gutter={24}>
            <Col span={24}>
              <Form.Item shouldUpdate {...tailLayout}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    Update
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          )}

      </Form>

      <Drawer zIndex="4000" title={drawertitle} width={320} closable={true} onClose={onClose} visible={childrenDrawer}>
        <Form style={{ paddingTop: "15px" }} form={editform} className="betss" name="horizontal_login" layout="vertical" onFinish={onSave}>
          {drawertype == "edit" ? (
            <Row gutter={24}>
              <Col span={24} style={{ textAlign: "left" }}>
                <Form.Item name="sideNum" label="Select Side Num" rules={[{ required: true, message: "Please input your Side Num!" }]}>
                  <Select dropdownStyle={{ zIndex: 4500 }} defaultValue="Select  Side Num" style={{ width: "100%" }}>
                    {props.matchid.betinfo.map((item, i) => (
                      <Option key={i} value={item.sideNum}>
                        Side Num - {item.sideNum}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>{" "}
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="sideLabel" label="Side label" rules={[{ required: true, message: "Please input your Side label!" }]}>
                <Input placeholder="Side label" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="odds" label="Bet odds" rules={[{ required: true, message: "Please input your Bet odds!" }]}>
                <Input placeholder="Bet odds" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="bet" label="Bet Value" rules={[{ required: true, message: "Please input your Bet!" }]}>
                <Input placeholder="Bet" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="win" label="Win value" rules={[{ required: true, message: "Please input your Win!" }]}>
                <Input placeholder="Win" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="winresult" label="Select Winning Result" rules={[{ required: true, message: "Please Select Winning Result!" }]}>
                <Select dropdownStyle={{ zIndex: 4500 }} style={{ width: "100%" }}>
                  {props.winningarray.map((item1, j) => (
                    <Option key={j} value={item1}>
                      {item1}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="rtmp1">
            <Col span={24} style={{ textAlign: "left" }}>
              <p className="mainlabel">Hedge Bet</p>
              <Switch checked={hedgebet} onChange={onHedge} />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default Editbets;
