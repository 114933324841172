import { Spin, Layout, Input, Card, Tabs, Table, Space, Button, Modal, PageHeader } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import AndroidLanding from "./AndroidLanding";
import IOSLanding from "./IOSLanding";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import Moment from "react-moment";
import axios from "axios";
import {
    PlusCircleTwoTone,
    DeleteTwoTone,
    LoginOutlined,
    DeleteOutlined,
    EditTwoTone
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth, currentTournamentDetails } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { domain } from "../../configuration/ApiConfiguration";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
const { TabPane } = Tabs;
function LandingPage() {
    const [ID, setID] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [StatusVisible, setStatusVisible] = useState(false);
    const [DeleteVisible, setDeleteVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [jsonviewer, setJsonviewer] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [keyData, setKeyData] = useState(null);
    const [scheduleAndriod, setScheduleAndriod] = useState([]);
    const [scheduleIOS, setScheduleIOS] = useState([]);
    const [Type, setType] = useState("android");
    const [matchdetails, setMatchDetails] = useState([]);
    const [currApp, setCurrentObj] = useState({});
    const [drawertype, setDrawertype] = useState("");
    const [title, setTitle] = useState("");
    const [currentindex, setCurrentindex] = useState("");
    const addnew = () => {
        setDrawertype("Create Landing");
    };

    const back = () => {
        setDrawertype("");
    };

    const showDrawer = (matchid, index1) => {
        const res = matchid.tournament;
        setTitle(res);
        setCurrentindex(index1);
        setMatchDetails(matchid);
        setDrawertype("Edit Landing");
        setVisible(true);
    };
    const deleteLanding = async (matchid) => {

        setID(matchid)
        setDeleteVisible(true);
    };
    const changeStatus = async (matchid) => {
        setID(matchid)
        setStatusVisible(true);
    };

    const statusTheLanding = async () => {
        setStatusVisible(false);
        setLoaded(true);
        let id = ID
        let data = {
            "appId": currentTournamentDetails.shortCode + "-ops",
            "version": id.version,
            "docType": id.docType,
            "platform": id.platform
        }
        const response = await axios.post(
            `${domain}/betops/config/landing/toggle-status/v1`, data)
            .then((response) => {
                onClose();
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.data,
                    });
                    setTimeout(async () => {
                        fetchDashboard();
                    }, 2000);
                }
                else if (response.data.status === "FAIL") {
                    setLoaded(false);
                    Modal.error({
                        content: response.data.error.message,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }

    // const deleteThePackage = async () => {
    //     setLoaded(true);
    //     let id = ID;
    //     let data = {
    //         "appId": currentTournamentDetails.shortCode + "-ops",
    //         "version": id.version,
    //         "docType": id.docType,
    //         "platform": id.platform
    //     }
    //     const response = await axios.delete(
    //         `${domain}/betops/config/landing/delete/v1`, { data: data })
    //         .then((response) => {

    //             onClose()
    //             setLoaded(false);
    //             if (response.data.status === "SUCCESS") {
    //                 Modal.success({
    //                     content: response.data.data,
    //                 });
    //                 setTimeout(async () => {
    //                     fetchDashboard();
    //                 }, 2000);
    //             }
    //             else if (response.data.status === "FAIL") {
    //                 Modal.error({
    //                     content: response.data.data,
    //                 });
    //             }
    //             else {
    //                 setLoaded(false);
    //                 Modal.error({
    //                     content: "Unable to process your Request, Please try again later.",
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             setLoaded(false);
    //             Modal.error({
    //                 content: "Unable to process your Request, Please try again later.",
    //             });
    //         })
    // }

    const onClose = () => {
        setCurrentObj({ add: null });
        setVisible(false);
        setID("")
        setDeleteVisible(false);
        setStatusVisible(false);
    };

    const onSearch = (e) => {
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            let data = schedule.filter(list => list.platform === Type);
            let filteredData = data.find(obj => obj.version === lowercasedFilter);
            if(filteredData != undefined) {
                let tempArr = [];
                tempArr.push(filteredData);
                if (Type === "android")
                    setScheduleAndriod(tempArr)
                else
                    setScheduleIOS(tempArr)
            } else {
                setScheduleAndriod([]);
                setScheduleIOS([]);
            }
        } else {
            fetchDashboard();
        }
    };

    const fetchDashboard = async () => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/config/landing/get-all/v2?appId=${currentTournamentDetails.shortCode}-ops`
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                let IosData = [];
                let androidData = [];
                for (let i = 0; i < response.data.data.landingList.length; i++) {
                    if (response.data.data.landingList[i].platform === "ios") {
                        IosData.push(response.data.data.landingList[i])
                    }
                    else if (response.data.data.landingList[i].platform === "android") {
                        androidData.push(response.data.data.landingList[i])
                    }
                }
                setSchedule(response.data.data.landingList);
                setScheduleAndriod(androidData);
                setScheduleIOS(IosData);
                setKeyData(response.data.data.keyData);               
            }
            else {
                Modal.error({
                    content: response.data.error.message
                });
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
            setLoaded(false);
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        // // if (!user) return navigate("/login");
    }, [user, loading]);
    const callback = (key) => {

        setType(key)
    };
    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                fetchDashboard();
            }, 2000);
        }

    }, [loaded]);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="landing_page" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background landing-page" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        { drawertype != "" ?
                            <img onClick={back} src="images/backarrow1.png" className="backbtn"></img>
                        : ""}
                        {drawertype == "Edit Landing" ? Type === "android" ? <AndroidLanding platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} /> : <IOSLanding platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} />
                            : drawertype == "Create Landing" ? Type === "android" ? <AndroidLanding platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} /> : <IOSLanding platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} />
                                : <>
                                    <div className="site-card-border-less-wrapper">
                                        <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                            <PageHeader
                                                className="site-page-header"
                                                title="Landing List"
                                                extra={
                                                    <>
                                                        {" "}
                                                        <Space direction="horizontal">
                                                            {" "}
                                                            <Search placeholder="Search Version" onChange={onSearch} enterButton />
                                                            <a onClick={() => addnew()}>
                                                                <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                            </a>
                                                        </Space>{" "}
                                                    </>
                                                }
                                            />
                                            <Tabs tabPosition="left" defaultActiveKey="android" onChange={callback}>
                                                <TabPane tab="Android App" key="android">
                                                    <Table
                                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                                        dataSource={scheduleAndriod}
                                                        scroll={{ y: "340" }}
                                                    >
                                                        <Column title="App Id" dataIndex="aid" key="aid" />
                                                        <Column title="Platform" dataIndex="platform" key="platform" />
                                                        <Column title="Version" dataIndex="version" key="version" />
                                                        <Column title="Status" dataIndex="active" key="active"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                {text == true ? (
                                                                    <p style={{marginBottom: "0"}} className="text-success"> Active </p>
                                                                ) : (
                                                                    <p style={{marginBottom: "0"}} className="text-danger"> In Active </p>
                                                                )}
                                                            </Space>
                                                            )}
                                                        />
                                                        {/* <Column
                                                            title="Created Date"
                                                            dataIndex="createdDate"
                                                            key="createdDate"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                    {text ? (
                                                                        <Moment unix format="DD-MM-YYYY">
                                                                            {text._seconds}
                                                                        </Moment>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Space>
                                                            )}
                                                        />
                                                        <Column
                                                            title="Modified Date"
                                                            dataIndex="modifiedDate"
                                                            key="modifiedDate"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                    {text ? (
                                                                        <Moment unix format="DD-MM-YYYY">
                                                                            {text._seconds}
                                                                        </Moment>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Space>
                                                            )}
                                                        /> */}
                                                        <Column
                                                            title="Quick Actions"
                                                            key="notifId"
                                                            render={(notifId, value, index) => (
                                                                <Space size="middle">
                                                                    {/* <DeleteOutlined onClick={() => deleteLanding(notifId)} title="Delete Landing" style={{ fontSize: "18px", color: "#08c" }} /> */}
                                                                    <LoginOutlined onClick={() => changeStatus(notifId)} title="Change Landing Status" style={{ fontSize: "18px", color: "#08c" }} />
                                                                    <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                                </Space>
                                                            )}
                                                        />
                                                    </Table>
                                                </TabPane>
                                                <TabPane tab="IOS App" key="ios">
                                                    <Table
                                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                                        dataSource={scheduleIOS}
                                                        scroll={{ y: "340" }}
                                                    >
                                                        <Column title="App Id" dataIndex="aid" key="aid" />
                                                        <Column title="Platform" dataIndex="platform" key="platform" />
                                                        <Column title="Version" dataIndex="version" key="version" />
                                                        <Column title="Status" dataIndex="active" key="active"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                    {text == true ? (
                                                                        <p style={{marginBottom: "0"}} className="text-success"> Active </p>
                                                                    ) : (
                                                                        <p style={{marginBottom: "0"}} className="text-danger"> In Active </p>
                                                                    )}
                                                                </Space>
                                                            )}
                                                        />


                                                        {/* <Column
                                                            title="Created Date"
                                                            dataIndex="createdDate"
                                                            key="createdDate"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                    {text ? (
                                                                        <Moment unix format="DD-MM-YYYY">
                                                                            {text._seconds}
                                                                        </Moment>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Space>
                                                            )}
                                                        />
                                                        <Column
                                                            title="Modified Date"
                                                            dataIndex="modifiedDate"
                                                            key="modifiedDate"
                                                            render={(text, record) => (
                                                                <Space size="middle">
                                                                    {text ? (
                                                                        <Moment unix format="DD-MM-YYYY">
                                                                            {text._seconds}
                                                                        </Moment>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Space>
                                                            )}
                                                        /> */}
                                                        <Column
                                                            title="Quick Actions"
                                                            key="notifId"
                                                            render={(notifId, value, index) => (
                                                                <Space size="middle">
                                                                    {/* <DeleteOutlined onClick={() => deleteLanding(notifId)} title="Delete Landing" style={{ fontSize: "18px", color: "#08c" }} /> */}
                                                                    <LoginOutlined onClick={() => changeStatus(notifId)} title="Change Landing Status" style={{ fontSize: "18px", color: "#08c" }} />
                                                                    <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                                </Space>
                                                            )}
                                                        />
                                                    </Table>
                                                </TabPane>
                                            </Tabs>
                                        </Card>
                                    </div>
                                    {/* <Modal title="Delete" onCancel={onClose} visible={DeleteVisible}
                                        footer={[<>  <Button key="submit" type="primary" onClick={deleteThePackage}>
                                            Ok
                                        </Button>
                                            <Button key="submit" type="primary" onClick={onClose}>
                                                Cancel
                                            </Button></>
                                        ]}
                                    >
                                        Are you sure you want to Delete the Package?
                                    </Modal> */}
                                    <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                                        footer={[<>  <Button key="submit" type="primary" onClick={statusTheLanding}>
                                            Ok
                                        </Button>
                                            <Button key="submit" type="primary" onClick={onClose}>
                                                Cancel
                                            </Button></>
                                        ]}
                                    >
                                        Are you sure you want to Change the Landing Status?
                                    </Modal>
                                    {loaded == true ? (
                                        <div className="loading-overlay">
                                            <Spin size="large" />{" "}
                                        </div>
                                    ) : (
                                        ""
                                    )}</>}

                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default LandingPage;
