import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { Chart } from "react-google-charts";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Option } = Select;

const { Content } = Layout;
function ActiveTournament() {
    const [loaded, setLoaded] = useState(true);
    const [highlightBox, setHighlightBox] = useState("1")
    const [user, loading, error] = useAuthState(auth);
    const { TabPane } = Tabs;

    useEffect(async () => {
        setLoaded(true)
        setLoaded(false)
    }, []);
    const highlight = (arg) => {

        setHighlightBox(arg)
    }
    return (

        <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <Card bordered={false} bodyStyle={{ padding: "8px" }} style={{ borderBottom: "2px solid #2196F3" }}>
                <div><span style={{ fontWeight: "600" }}>Active Tournament</span></div>
            </Card>
            <br />
            <div className="row">
                <div className="col-md-3 m-1" style={{ background: "#fff" }}>
                    <div className="row" style={{ padding: "10px" }}>
                        <div className="col-md-4"><span><img style={{ height: "-webkit-fill-available", border: "1px solid #dee2e6", borderRadius: "0.25rem" }} src="images/IPLT20.png" width="100px" /></span></div>
                        <div className="col-md-8" style={{ paddingLeft: "21px" }}>
                            <small class="fw-bold" style={{ fontWeight: "600", fontSize: "11px", color: "#2196F3" }}>T20 IPL 2022</small>
                            <span style={{ float: "right" }} ><img src="images/India.png" className="rounded-circle" width="30px" /></span>
                            <hr style={{ margin: "0.6rem 0", color: "#E8E8E8" }} />
                            <small className="fw-bold text-muted" style={{ fontSize: "11px" }}>12 July 2022 - 15 August 2022</small>
                            <hr style={{ margin: "0.6rem 0", color: "#E8E8E8" }} />
                            <small className="fw-bold text-muted" style={{ fontSize: "11px" }}>12 teams</small>
                            <small className="fw-bold text-muted" style={{ float: "right", fontSize: "11px" }}>24 Games</small>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 m-1" style={{ background: "#fff" }}>
                    <div className="row" style={{ padding: "10px" }}>
                        <div className="col-md-4"><span><img style={{ height: "-webkit-fill-available", border: "1px solid #dee2e6", borderRadius: "0.25rem" }} src="images/T20BBL.png" width="100px" /></span></div>
                        <div className="col-md-8" style={{ paddingLeft: "21px" }}>
                            <small class="fw-bold" style={{ fontWeight: "600", fontSize: "11px", color: "#2196F3" }}>T20 BBL 2022</small>
                            <span style={{ float: "right" }} ><img src="images/Australia.png" className="rounded-circle" width="30px" /></span>
                            <hr style={{ margin: "0.6rem 0", color: "#E8E8E8" }} />
                            <small className="fw-bold text-muted" style={{ fontSize: "11px" }}>12 July 2022 - 15 August 2022</small>
                            <hr style={{ margin: "0.6rem 0", color: "#E8E8E8" }} />
                            <small className="fw-bold text-muted" style={{ fontSize: "11px" }}>12 teams</small>
                            <small className="fw-bold text-muted" style={{ float: "right", fontSize: "11px" }}>24 Games</small>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 m-1" style={{ background: "#fff" }}>
                    <div className="row" style={{ padding: "10px" }}>
                        <div className="col-md-4"><span><img style={{ height: "-webkit-fill-available", border: "1px solid #dee2e6", borderRadius: "0.25rem" }} src="images/T20BBL.png" width="100px" /></span></div>
                        <div className="col-md-8" style={{ paddingLeft: "21px" }}>
                            <small class="fw-bold" style={{ fontWeight: "600", fontSize: "11px", color: "#2196F3" }}>T20 BBL 2022</small>
                            <span style={{ float: "right" }} ><img src="images/Pakistan.png" className="rounded-circle" width="30px" /></span>
                            <hr style={{ margin: "0.6rem 0", color: "#E8E8E8" }} />
                            <small className="fw-bold text-muted" style={{ fontSize: "11px" }}>12 July 2022 - 15 August 2022</small>
                            <hr style={{ margin: "0.6rem 0", color: "#E8E8E8" }} />
                            <small className="fw-bold text-muted" style={{ fontSize: "11px" }}>12 teams</small>
                            <small className="fw-bold text-muted" style={{ float: "right", fontSize: "11px" }}>24 Games</small>
                        </div>
                    </div>
                </div>
            </div >
        </Content >

    );
}

export default ActiveTournament;
