import { collection, getDocs, query, where } from "firebase/firestore";
import { Select, Layout, Tooltip, Card, Col, Input, Pagination, Form, Button, Table, DatePicker, Space, Drawer, Modal, PageHeader, Spin, List } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import moment from "moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { domain } from "../../configuration/ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import {
    EyeOutlined,
    DownCircleOutlined,
    CopyOutlined
} from "@ant-design/icons";
import localStore from "../../utils/services";
import { resetWarned } from "react-bootstrap-typeahead/types/utils/warn";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function Donate() {
    const { RangePicker } = DatePicker;
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [dataEntering, setDataEntering] = useState(false);
    //const [scheduleData, setScheduleData] = useState([]);
    const [donateData, setDonateData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [transactionsBet, setTransactionsBet] = useState([]);
    const [donateDataDup, setDonateDetailsDup] = useState([]);
    const [donateDetails, setDonateDetails] = useState([]);
    const [limit, setLimit] = useState(10);
    const [startAt, setStartAt] = useState(0);
    const [totalRows, setTotalRows] = useState();
    const [filterValue, setFilterValue] = useState("");
    const [searchInputValue, setSearchInputValue] = useState();

    const showDrawer = async (matchid) => {
        setDataFilter(matchid);
        setVisible(true);
    };

    const getUTCtoLocalDate = (date) => {
        // var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
        // return newDate;   
        return date.toUTCString();
    }

    const onChange = (value, dateString) => {
        if (dateString[0] == "") {
            fetchDonateDetails(limit, 0, null);
            return;
        }
        if (dateString) {
            var startDate = `${dateString[0]}`;
            var sDateTimeArr = startDate.split(" ");
            var sDateArr = sDateTimeArr[0].split("-");
            var sTimeArr = sDateTimeArr[1].split(":");
            let sDate = new Date(sDateArr[2], sDateArr[1] - 1, sDateArr[0], sTimeArr[0], sTimeArr[1]);
            let finalsDate = getUTCtoLocalDate(sDate);
            let finalsDateFormat = `${moment(finalsDate).utc().format('YYYY-MM-DD')} ${moment(finalsDate).utc().format('HH:mm:ss')}`;

            var endDate = `${dateString[1]}`;
            var eDateTimeArr = endDate.split(" ");
            var eDateArr = eDateTimeArr[0].split("-");
            var eTimeArr = eDateTimeArr[1].split(":");
            let eDate = new Date(eDateArr[2], eDateArr[1] - 1, eDateArr[0], eTimeArr[0], eTimeArr[1]);
            let finaleDate = getUTCtoLocalDate(eDate);
            let finaleDateFormat = `${moment(finaleDate).utc().format('YYYY-MM-DD')} ${moment(finaleDate).utc().format('HH:mm:ss')}`;

            let filterParams = `&searchType=date&startDate=${finalsDateFormat}&endDate=${finaleDateFormat}`;
            fetchDonateDetails(limit, 0, filterParams);
        }
        if (value) {
            console.log('Selected Time: ', value);
            console.log('Formatted Selected Time: ', dateString);
            let actualFeedBackList = donateDataDup
            let filters = actualFeedBackList.filter((list) => {
                let actualDate = moment(list.createDate._seconds * 1000)
                if (value[0]._d.getTime() <= actualDate._d.getTime() && value[1]._d.getTime() >= actualDate._d.getTime()) {

                }
                return (
                    value[0]._d.getTime() <= actualDate._d.getTime() && value[1]._d.getTime() >= actualDate._d.getTime()
                )
            })
            setDonateDetails(filters)
        }
        else {
            setDonateDetails(donateDataDup)
        }
    };

    const filterSearchEnable = () => {
        return (
            <>
                {filterValue != "" ? (
                    <Search placeholder="Search here" value={searchInputValue} onChange={onSearch} enterButton />
                ) : (
                    <></>
                )}

            </>
        )
    }

    const onPagingChange = (e) => {
        setStartAt(e - 1);
        fetchDonateDetails(limit, e - 1, null);
    }

    const fetchDonateDetails = async (limit, startAt, filterParams) => {
        setLoaded(true);
        let makeUrl;
        if (filterParams) {
            makeUrl = `${domain}/betops/payment/trans/v2?limit=${limit}&startAt=${startAt}${filterParams}`
        } else {
            makeUrl = `${domain}/betops/payment/trans/v2?limit=${limit}&startAt=${startAt}`
        }
        const response = await axios({
            method: 'get',
            url: makeUrl,
            // url: `${domain}/betops/payment/trans/v2?limit=${limit}&startAt=${startAt}`,
        }).then(async (response) => {
            setLoaded(false);
            if (response.data.status === "SUCCESS") {
                setTotalRows(response.data.data.total);
                let datas = response.data.data.transactions.sort(function (a, b) {
                    return moment(b.createDate._seconds * 1000) - moment(a.createDate._seconds * 1000);
                });
                if (!filterParams) {
                    await setDonateDetailsDup(datas)
                    await setDonateDetails(datas)
                } else {
                    await setDonateDetails(datas)
                }
            }
            else {
                setLoaded(false);
                setDonateDetails([]);
                setTotalRows(0);
                // Modal.error({
                //     content: response.data.data,
                // });
            }
        })
            .catch((error) => {
                setLoaded(false);
                setDonateDetails([]);
                setTotalRows(0);
                // Modal.error({
                //     content: "Unable to process your Request, Please try again later.",
                // });
            })
        setLoaded(false)
    };
    const sortArray = (type) => {
        const types = {
            matchId: "matchId",
        };
        const sortProperty = types[type];
        const sorted = [...donateDetails].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });
        setDonateDetails(sorted);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setDonateDetails(donateDetails.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {
            setDonateDetails(donateDetails.reverse());
        }
    };

    const splitUid = (data) => {
        var value = data;
        var lastFour = value.substr(value.length - 4);
        return (
            <>
                {lastFour}
            </>
        );
    }

    const mergeUnameUid = (text, record) => {
        return (
            <>
                {text} - {splitUid(record.uid)}
            </>
        );
    }

    const findPayImage = (text) => {
        if (text == "google_pay") {
            return "images/gpay.png";
        } else if (text == "apple_pay") {
            return "images/apay.png";
        } else if (text == "card") {
            return "images/card.png";
        }
    }

    const clonefn = () => {
        const text = document.getElementById('clonetext');
        text.style.display = 'block';
        setTimeout(() => {
            text.style.display = 'none';
        }, 2000);
    }

    const handleFilterChange = (e) => {
        if (dataEntering) {
            setSearchInputValue();
            fetchDonateDetails(10, 0, null);
        }
        setFilterValue(e);
        setDataEntering(true);
    }

    const onSearch = async (e) => {
        setSearchInputValue(e.target.value);
        if (e.target.value !== "") {
            // const lowercasedFilter = e.target.value.toLowerCase();
            let filterParams;
            let filterData = "";
            if (filterValue === "uid") {
                filterData = donateDataDup.filter(list => list.uid === e.target.value)
                if (filterData.length > 0) {
                    filterParams = `&searchType=uid&search=${e.target.value}`;
                    await fetchDonateDetails(limit, 0, filterParams);
                } else {
                    setDonateDetails([]);
                    setTotalRows(0);
                    return false;
                }
            }
            else if (filterValue === "transId") {
                filterData = donateDataDup.filter(list => list.transId === e.target.value)
                if (filterData.length > 0) {
                    filterParams = `&searchType=transId&search=${e.target.value}`;
                    await fetchDonateDetails(limit, 0, filterParams);
                } else {
                    setDonateDetails([]);
                    setTotalRows(0);
                    return false;
                }
            }
        } else {
            await fetchDonateDetails(10, 0, null);
            // setDonateDetails(donateDataDup);
        }
    };

    const OldonSearch = async (e) => {
        setSearchInputValue(e.target.value);
        if (e.target.value !== "") {
            // const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = donateDataDup.filter(async (item) => {
                let result;
                let uidSplit = splitUid(item.uid);
                if (filterValue == 'uid') {
                    if (item.uid === e.target.value) {
                        let filterParams = `&searchType=uid&search=${e.target.value}`;
                        await fetchDonateDetails(limit, 0, filterParams);
                        result = item.uid === e.target.value;
                    }
                    // else {
                    //     // if (uidSplit.props.children === e.target.value) {
                    //     //     result = uidSplit.props.children === e.target.value;
                    //     // }
                    // }

                } else if (filterValue == 'transId') {
                    let filterParams = `&searchType=transId&search=${e.target.value}`;
                    await fetchDonateDetails(limit, 0, filterParams);
                    result = item.transId === e.target.value;
                    // setTotalRows(1);
                }      
                return result;   
                // return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
            });
            setDonateDetails(filteredData);
        } else {
            await fetchDonateDetails(10, 0, null);
            // setDonateDetails(donateDataDup);
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    // const downloadURI = (uri, name) => {
    //     var link = document.createElement("a");
    //     link.download = name;
    //     link.href = uri;
    //     document.body.appendChild(link);
    //     link.click();
    //   }

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
    }, [user, loading]);
    useEffect(async () => {
        fetchDonateDetails(10, 0, null);
    }, []);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Donate Transaction"
                                    extra={
                                        <Space key="p1" direction="horizontal">




                                            {/* <Col span={24} style={{ textAlign: 'left' }}> */}

                                            {/* <Form.Item
                                                    name="transId"
                                                    label="Select "
                                                    rules={[{ required: true, message: 'Please Select Year!' }]}
                                                >
                                                    <Select size="default" mode="multiple" placeholder="Select Season" style={{ width: '100%' }}>
                                                        {donateDetails.uid.map((item1, index1) => (
                                                            <Option key={index1} value={item1}>{item1} </Option>
                                                        ))}
                                                    </Select></Form.Item> */}
                                            {/* </Col> */}






                                            <Select
                                                size="default"
                                                placeholder="Select Filter"
                                                style={{ width: "200px" }}
                                                onChange={handleFilterChange}
                                                options={[
                                                    {
                                                        value: 'uid',
                                                        label: 'User Id',
                                                    },
                                                    {
                                                        value: 'transId',
                                                        label: 'Transaction Id',
                                                    },
                                                    {
                                                        value: 'createDate',
                                                        label: 'Created Date'
                                                    },
                                                ]}
                                            />

                                            {filterValue == "createDate" ? (
                                                <RangePicker
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="DD-MM-YYYY HH:mm"
                                                    onChange={onChange}
                                                />
                                            ) : (
                                                filterSearchEnable()
                                            )}


                                        </Space>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={donateDetails}
                                    pagination={false}
                                    onChange={handleTableChange}
                                    scroll={{ y: "250" }}
                                >



                                    <Column title="User Name" dataIndex="userName" key="userName"
                                        render={(text, record) => (
                                            <>
                                                <CopyToClipboard text={record.uid}>
                                                    <div className="clonediv">
                                                        <Tooltip title="click to copy">
                                                            <span style={{ cursor: "pointer" }} onClick={() => clonefn()}>
                                                                {mergeUnameUid(text, record)}
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </CopyToClipboard>
                                            </>
                                        )}
                                    />





                                    {/* <Column title="User Id" dataIndex="uid" key="uid" /> */}
                                    <Column title="Transaction Id" dataIndex="transId" key="transId" />
                                    {/* <Column
                                        title="Created Date"
                                        dataIndex="createDate"
                                        key="createDate"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text !== undefined ? (
                                                    <Moment format="DD-MM-YYYY HH:mm">
                                                        {text}
                                                    </Moment>
                                                          <Moment unix format="DD-MM-YYYY HH:mm">
                                                          {text._seconds}
                                                      </Moment>                                                  
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    /> */}
                                    <Column
                                        title="Created Date"
                                        dataIndex="createDate"
                                        key="createDate"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text !== undefined ? (
                                                    <Moment unix format="DD-MM-YYYY HH:mm">
                                                        {text._seconds}
                                                    </Moment>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />
                                    <Column title="Amount" dataIndex="amount" key="amount" />
                                    <Column title="Currency" dataIndex="currency" key="currency" />
                                    <Column title="Payment Method" dataIndex="paymentMethod" key="paymentMethod"
                                        render={((text, record) => {
                                            return (
                                                <Space>
                                                    {text ? (
                                                        <img src={findPayImage(text)}></img>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Space>
                                            )
                                        }

                                        )}
                                    />
                                    <Column title="Status" dataIndex="status" key="status"
                                        render={((text, record) => {
                                            return (
                                                <Space>
                                                    {text ? (
                                                        <p style={{ wordWrap: "break-word", width: "100px" }} className={text == 'succeeded' ? 'text-success' : text == 'canceled' ? 'text-danger' : text == 'processing' ? 'text-primary' : 'text-body'}>
                                                            {text}
                                                        </p>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Space>
                                            )
                                        }

                                        )}
                                    />
                                    <Column
                                        title="Quick Actions"
                                        key="matchId"
                                        style={{ textAlign: "center" }}
                                        render={(matchId, index) => (
                                            <Space key={index} size="middle">
                                                {matchId !== undefined ? (
                                                    <>
                                                        <a onClick={() => showDrawer(matchId)}>
                                                            <EyeOutlined style={{ fontSize: "18px", color: "#08c" }} />
                                                        </a>

                                                        {/* <a onClick={() => showDrawer(matchId)}>
                                                        <DownCircleOutlined style={{ fontSize: "18px", color: "#08c" }} />
                                                    </a> */}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />













                                    {/* <Column title="Mobile" dataIndex="mobile" key="mobile"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text ? text : "NA"}
                                            </Space>
                                        )} />
                                    <Column title="Subject" dataIndex="subject" key="subject"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text.length < 12 ? text : text.substring(0, 12) + "..."}
                                            </Space>
                                        )}
                                    /> */}
                                </Table>
                                <Pagination className="float-end paginationTable manualPagination"
                                    total={totalRows}
                                    showSizeChanger={false}
                                    current={startAt + 1}
                                    pageSize={limit}
                                    onChange={onPagingChange}
                                />
                            </Card>
                        </div>

                        <Drawer title="Receipt Details" placement="right" onClose={onClose} visible={visible} size="middle">
                            <br />
                            <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Receipt URL</h4>
                            {/* <div style={{ width: "100%" }}> */}

                            <p style={{ fontSize: "14px", marginBottom: "2rem", wordWrap: "break-word" }}>{dataFilter.receiptUrl ? dataFilter.receiptUrl : "NA"}</p>

                            {dataFilter.receiptUrl &&
                                <center><CopyToClipboard text={dataFilter.receiptUrl}>
                                    <div className="clonediv">
                                        <img onClick={() => clonefn()} src="images/clone.png" style={{ width: "36px", cursor: "pointer" }} />
                                        <p id="clonetext">Copied</p>
                                    </div>
                                </CopyToClipboard></center>
                            }
                            {/* <FontAwesomeIcon icon="fa-solid fa-copy" /> */}

                            {/* <Button icon={<DownCircleOutlined />}>Download Receipt</Button> */}
                            {/* <p onClick={downloadURI(dataFilter.receiptUrl, "data.html")}></p> */}
                            {/* </div> */}
                            {/* word-wrap: break-word; */}
                            {/* <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Body</h4>
                            <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{dataFilter.body}</p> */}


                        </Drawer>

                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default Donate;
