
import { collection, getDocs, query, where, doc, getDoc, setDoc, updateDoc, limit } from "firebase/firestore";
import { Select, Spin, Layout, Row, Collapse, Col, Table, Space, Tag, Drawer, PageHeader, Button, Modal, Form, Input, Divider } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import Addbets from "./addbets";
import Editbets from "./editbets";
import Moment from "react-moment";
import {
    EditTwoTone,
    PlusOutlined,
    DeleteTwoTone,
    CodepenOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.min.css"
const { Column } = Table;
function BetsInfo(props) {
    const [modalVisible, setModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteBet, setDeleteBet] = useState(null)
    const [valuess, setValuess] = useState(null)
    const [modelShowTxt, setModelShowTxt] = useState(false)
    const [modelShowRaw, setModelShowRaw] = useState(false)
    const [matchInfoFilter, setMatchInfoFilter] = useState(null)
    const [schedule, setSchedule] = useState(null)
    const [loaded, setLoaded] = useState(true);

    const [teams, setTeams] = useState([]);
    const winningarray = ["WIN", "LOSS"];
    const betmode = ["GENERAL", "CUSTOM"];
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState("Select Tournaments");
    const [matchValue, setMatchValue] = useState("Select Year");
    const [docid, setDocid] = useState();
    const [title, setTitle] = useState("");
    const [drawertype, setDrawertype] = useState("");
    const [editmatchid, setEditmatchid] = useState([]);
    const [teaminfo, setTeaminfo] = useState([]);
    const showDrawer = (matchid) => {

        setDrawertype("editbet");
        setEditmatchid(matchid);
        setTitle(matchid.matchtitle + " Bet - " + matchid.betNum);
        setVisible(true);
    };

    const addnew = () => {
        setDrawertype("addbet");
        setTitle(props.matchid.tournament + " - " + props.matchValue);
        setVisible(true);
    };
    useEffect(() => {
        betDetails();
    }, []);
    const betDetails = async () => {
        setLoaded(true)

        let dodcid = ""
        const scheduleCol = collection(db, "bet_schedule");
        const queryRef = query(scheduleCol, where("tournament", "==", props.matchid.tournament), where("year", "==", props.matchValue), where("matchId", "==", props.matchid.matchid), limit(1));
        const scheduleSnapshot = await getDocs(queryRef);
        const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
        const result = scheduleSnapshot.docs.map((doc) => {
            dodcid = doc.id
        });

        const finaldata = scheduleList
        const tableData = [];
        setDocid(finaldata[0].documentID);
        const finaldata1 = finaldata.map(function (element, mid) {
            let i = 1;
            if (element.bets !== undefined) {
                const list2 = element.bets.betList.map(function (element2, mid2) {
                    tableData.push({
                        startdate: props.matchid.startdate,
                        winnerActual: element2.winnerActual,
                        betLegend: element2.betLegend,
                        betMode: element2.betMode,
                        betType: element2.betType,
                        winnerActualFavOrDog: element2.winnerActualFavOrDog,
                        betNum: element2.betNum,
                        year: element.year,
                        matchtitle: props.matchid.matchtitle,
                        tourID: props.matchid.tourID,
                        matchno: props.matchid.matchno,
                        tournament: props.matchid.tournament,
                        tournamenttitle: props.matchid.tournamenttitle,
                        hticon: props.matchid.hticon,
                        ht: props.matchid.ht,
                        rt: props.matchid.rt,
                        matchid: props.matchid.matchid,
                        rticon: props.matchid.rticon,
                        matchTs: props.matchid.matchTs,
                        subLabel: element2.subLabel,
                        betinfo: element2.sideList,
                        documentID: dodcid,
                        mtype: props.matchid.mtype,
                    });
                });
            }
        });
        setSchedule(tableData)
        setLoaded(false)
    }
    const deleterecordModel = (matchid) => {
        setDeleteModalVisible(true)
        setDeleteBet(matchid)
    }
    const deleterecord = async () => {
        setDeleteModalVisible(false)
        setLoaded(true);

        const matchDocRef = doc(db, "bet_schedule", deleteBet.documentID);
        const docSnap = await getDoc(matchDocRef);
        const arraycheck = docSnap.data();
        await setDoc(matchDocRef, docSnap.data());
        let mainarray = [];
        mainarray.push(docSnap.data());
        const final = mainarray[0].bets.betList.filter((number) => {
            return number.betNum !== deleteBet.betNum;
        });
        mainarray[0].bets.betList = final
        let obj = {};
        mainarray.forEach(function (val, i) {
            obj = {
                val,
            };
        });
        const checkarray = [];
        await updateDoc(matchDocRef, obj.val);
        setLoaded(false);
        setTimeout(() => {
            Modal.success({
                content: "Deleted Successfully",
                onOk() {
                    betDetails();
                },
            });
        }, 1000);
    };
    const onClose = () => {
        setLoaded(false);
        setVisible(false);
        setModelShowTxt(false)
        setModelShowRaw(false)
    };
    const updateMatchdetails = async () => {
        betDetails();
        // props.handleBack();
    };
    const handleCancel = () => {
        setModalVisible(false);
        setDeleteModalVisible(false)
    };
    const handleOk = (arg) => {

        setMatchInfoFilter(arg)
        setModalVisible(true);
    };
    const handleBetRawUpdate = async (values) => {
        setLoaded(true);
        const matchDocRef = doc(db, "schedule", props.matchid.documentID);
        const docSnap = await getDoc(matchDocRef);
        const dsddsf = docSnap.data()
        await setDoc(matchDocRef, docSnap.data());
        let mainarray = [];
        mainarray.push(docSnap.data());
        mainarray.forEach(function (item, index) {
            if (props.matchid.mtype == "schedule") {
                item.htl.forEach(function (item1, index1) {
                    item1.rtl.forEach(function (item2, index2) {
                        if (item2.matchId == props.matchid.matchid) {
                            item2.bets.betList.forEach(function (item3, index3) {
                                if (item3.betNum == matchInfoFilter.betNum) {
                                    item3.betLegend = matchInfoFilter.betLegend;
                                    item3.betMode = matchInfoFilter.betMode;
                                    item3.subLabel = matchInfoFilter.subLabel;
                                    item3.betNum = matchInfoFilter.betNum;
                                    item3.sideList = matchInfoFilter.betinfo;
                                }
                            });
                        }
                    });
                });
            } else {
                item.playOff.forEach(function (itemplayoff, playoffindex1) {
                    if (itemplayoff.matchId == props.matchid.matchid) {
                        itemplayoff.bets.betList.forEach(function (playoff, index5) {
                            if (playoff.betNum == matchInfoFilter.betNum) {
                                playoff.betLegend = matchInfoFilter.betLegend;
                                playoff.betMode = matchInfoFilter.betMode;
                                playoff.subLabel = matchInfoFilter.subLabel;
                                playoff.betNum = matchInfoFilter.betNum;
                                playoff.sideList = matchInfoFilter.betinfo;
                            }
                        });
                    }
                });
            }
        });

        let obj = {};
        mainarray.forEach(function (val, i) {
            obj = {
                val,
            };
        });
        const res = await updateDoc(matchDocRef, obj.val);

        setLoaded(false);
        Modal.success({
            content: "Updated Successfully",
            onOk() {
                onClose();
                updateMatchdetails();
            },
        });
    };
    const handleChanges = (e) => {
        setMatchInfoFilter(JSON.parse(e.target.value))
    }
    return <div>
        <PageHeader
            className="site-page-header"
            title="Active Bets"
            extra={[
                <>
                    <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                    <a onClick={() => addnew()}>
                        <span className="addbet">New Bet</span>
                    </a>
                </>,
            ]}
        />
        <Table
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            dataSource={schedule}
            scroll={{ y: "250" }}
        >
            <Column title="Match No" dataIndex="matchno" key="matchno" />
            <Column title="Bet No" dataIndex="betNum" key="betNum" sorter="true" />
            <Column title="Bet Title" dataIndex="subLabel" key="subLabel" style={{ width: "200px" }} />
            <Column title="Match Title" dataIndex="matchtitle" key="matchtitle" />
            {/* <Column
                title="Match Date"
                dataIndex="matchTs"
                key="matchTs"
                render={(text, recod) => (
                    <Space size="middle">
                        {text !== "Invalid date" ? (
                            <Moment unix format="DD-MM-YYYY">
                                {text}
                            </Moment>
                        ) : (
                            ""
                        )}
                    </Space>
                )}
            /> */}


            <Column
                title="Match Date"
                dataIndex="matchTs"
                key="matchTs"
                render={(text, record) => (
                    <Space size="middle">
                        {text !== undefined ? (
                            <Moment format="DD-MM-YYYY hh:mm a">
                                {text}
                            </Moment>
                        ) : (
                            ""
                        )}
                    </Space>
                )}
            />
            <Column title="Bet Mode" dataIndex="betMode" key="betMode" />
            <Column
                title="Quick Actions"
                key="matchId"
                render={(matchId) => (
                    <Space size="middle">
                        <EditTwoTone title="Edit" onClick={() => showDrawer(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                        <CodepenOutlined onClick={() => handleOk(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                        <DeleteTwoTone onClick={() => deleterecordModel(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                    </Space>
                )}
            />
        </Table>
        <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="large">
            {drawertype == "editbet" ? (
                <Editbets updateMatchdetails={updateMatchdetails} matchid={editmatchid} visible={visible} winningarray={winningarray} betmode={betmode} onClose={onClose} />
            ) : (
                <Addbets
                    teaminfo={teams}
                    teams={searchValue}
                    matchtitle={matchValue}
                    matchdetails={schedule}
                    winningarray={winningarray}
                    betmode={betmode}
                    onClose={onClose}
                    updateMatchdetails={updateMatchdetails}
                />
            )}
        </Drawer>
        <Modal title="Raw Update" onCancel={handleCancel} visible={modalVisible}
            footer={[
                <Button key="submit" type="primary" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    onClick={handleBetRawUpdate}
                >
                    Update
                </Button>
            ]}
        >
            <Row gutter={12}>
                <Col span={20} style={{ textAlign: "right" }}>
                    <Input.TextArea value={JSON.stringify(matchInfoFilter, null, 4)} onChange={handleChanges} rows={20} />
                </Col>
            </Row>
        </Modal>
        <Modal title="Delete Bet" onCancel={handleCancel} visible={deleteModalVisible}
            footer={[
                <Button key="submit" type="primary" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    onClick={deleterecord}
                >
                    Delete
                </Button>
            ]}
        >
        </Modal>
        {
            loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )
        }
    </div >
}
export default BetsInfo;