import { getFirestore, collection, getDocs, doc, setDoc, addDoc, getDoc, updateDoc, query, where, orderBy, limit } from "firebase/firestore";
import {
    Select, Layout, Row, Col, Table, Spin, Tag, Upload, Button, Modal, Form, Input,
    Card, Popover, Space, notification, Checkbox, Drawer, PageHeader, Tabs, Divider, Collapse
} from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import { UploadOutlined, EditTwoTone } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import axios from "axios";
import { domain } from "../../src/configuration/ApiConfiguration";
import Editbets from "./editbets";
import { Validator } from "jsonschema";
import Moment from "react-moment";
import { async } from "@firebase/util";
import { json } from "react-router-dom";

const { TabPane } = Tabs;
const { TextArea, Search } = Input;
const temptableData = [];
const SCHEDULE_SCHEMA = require("../Jsondata/schema/schedule.json");
const { Column } = Table;
const { Option } = Select;
const { Panel } = Collapse;
const doctypearray = [
    { key: 1, value: "SCHEDULE" },
    { key: 2, value: "PLAY_OFF" },
    { key: 3, value: "INSIGHTS" },
    { key: 4, value: "FANTASY_LINEUP" },
    { key: 5, value: "AUCTION" },
    { key: 6, value: "BETODDS" }
];

function FantasyBulkupdate(props) {
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(false);
    const [yearlist, setYearlist] = useState([]);
    const [dataset, setDataset] = useState([]);
    const [doctype, setDoctype] = useState(props.doctype);
    const [msgerror, setMsgerror] = useState("");
    const [filelist, setFilelist] = useState(false);
    const [open, setOpen] = useState(false);
    const [popupClose, setpopupClose] = useState(true);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [matchesValue, setMatchesValue] = useState("All Matches");
    const winningarray = ["WIN", "LOSS"];
    const betmode = ["GENERAL", "CUSTOM"];
    const [schedule, setSchedule] = useState([]);
    const [teaminfo, setTeaminfo] = useState(null);
    const [argStore, setArgStore] = useState(null);

    const [confs, setConfs] = useState(null);
    const [confDivs, setConfDivs] = useState(null);

    const [tournamentValue, setTournamentValue] = useState("IPL");
    const [seasonyearValue, setSeasonyearValue] = useState(2023);
    const [seasonType, setSeasonType] = useState("REGULAR");

    const [dbTournamentList, setDbTournamentList] = useState([]);
    const [dbSeasonList, setDbSeasonList] = useState([]);
    const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

    const [tableData, setTableData] = useState(null);
    const [filteredTableData, setFilteredTableData] = useState([]);

    const [jsonObject, setJsonObject] = useState(null);
    const [updatedJsonObject, setUpdatedJsonObject] = useState(null);
    const [oldDoc, setOldDoc] = useState(null);
    const [updatedOldDoc, setUpdatedOldDoc] = useState(null);

    const [tabActiveKey, setTabActiveKey] = useState("1");

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const fileReader = (result) => {
        setFilelist(true);
        setDataset(result);
        setJsonObject(JSON.parse(result));
    };

    const SortandRemoveDuplicateObject = async (val) => {
        const sortByKey = (key) => (a, b) => a[key] - b[key];
        const sortByMatchid = sortByKey("matchid");
        const sortval = val.sort(sortByMatchid);
        const uniqueIds = [];
        const unique = sortval.filter(element => {
            const isDuplicate = uniqueIds.includes(element.matchid);
            if (!isDuplicate) {
                uniqueIds.push(element.matchid);
                return true;
            }
            return false;
        });
        return unique;
    }

    const loadProfile = () => {
        localStorage.removeItem("oddsEditMatchNo");
        setMsgerror("");
        var currentyear = new Date().getFullYear();
        const yearlist = [];
        for (var i = 1980; i <= currentyear; i++) {
            yearlist.push(i);
        }
        setYearlist(yearlist.reverse());
    };

    useEffect(() => {
        loadProfile();
        sportConfigSetting();
    }, []);

    const sportConfigSetting = () => {
        let config11 = localStorage.getItem("sportsList")
        let config12 = config11.split(",");
        setDbTournamentList(config12);

        let config21 = localStorage.getItem("sportYearsList")
        let config22 = config21.split(",");
        setDbSeasonList(config22);

        let config31 = localStorage.getItem("sportSeasonsList")
        let config32 = config31.split(",");
        setDbSeasonTypeList(config32);
    }

    const handleChange = (e) => {
        setDoctype(e);
    };

    const getScheduleSchema = async (docType) => {
        localStorage.removeItem("schemaRes");
        let appId;
        if (currentTournamentDetails.shortName == "NFL") {
            appId = "fc-ops";
        } else {
            appId = "dt20-ops";
        }
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/scheduleschema/get/specific-schema/v2?appId=${appId}&docType=${docType}&seasonType=REGULAR`
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                localStorage.setItem("schemaRes", JSON.stringify(response.data.data));
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }

    const validateScheduleJson = async (jsonObj, docType = "") => {
        let jsonSchema = null;
        try {
            let jsonValidator = new Validator();
            if (docType === "SCHEDULE") {
                await getScheduleSchema("SCHEDULE");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "PLAY_OFF") {
                await getScheduleSchema("PLAY_OFF");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "INSIGHTS") {
                await getScheduleSchema("INSIGHTS");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "FANTASY_LINEUP") {
                await getScheduleSchema("FANTASY_LINEUP");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "AUCTION") {
                await getScheduleSchema("AUCTION");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "BETODDS") {
                await getScheduleSchema("BETODDS");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "SCHEMA") {
                return "SCHEMA";
            }

            if (jsonSchema) {
                jsonValidator.attributes.contains = function validateContains(instance, schema, options, ctx) {
                    if (typeof instance !== "string") return;
                    if (schema.contains.indexOf(instance) < 0) {
                        return "does not contain the string " + JSON.stringify(schema.contains);
                    }
                };
                let _data = jsonObj || {};
                let validate = jsonValidator.validate(_data, jsonSchema);
                if (validate.errors.length == 0) return null;
                else return validate.errors;
            }
        } catch (err) {
            return null;
        }
        return null;
    };

    const handleTournament = async (e) => {
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${e}`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                let config21 = response.data.data.yearsList;
                setDbSeasonList(config21);

                let config31 = response.data.data.seasonsList;
                setDbSeasonTypeList(config31);
            }
            else {
            }
        }).catch((error) => {
        })
    }

    const onSearch = (e) => {
        var result = temptableData.find(obj => {
            return obj.matchid === e.target.value
        })

        if (result) {
            setTableData([result]);
        } else {
            setTableData(filteredTableData);
        }
    };

    const onFinish = async (values) => {
        setLoaded(true);

        setTournamentValue(values.tournament);
        setSeasonyearValue(values.season);
        setSeasonType(values.seasontype);

        setTabActiveKey("1");
        form.resetFields(["newDoc"]);

        let errorObj = null;
        // if (jsonObject) errorObj = await validateScheduleJson(jsonObject, doctype);
        if (jsonObject.docType != doctype) {
            setLoaded(false);
            Modal.error({
                content: "Please upload valid json file",
                onOk() {
                    setLoaded(false);
                },
            });
            return;
        } else {
            if (errorObj != null) {
                setLoaded(false);
                Modal.error({
                    title: doctype + " Json Schema Validation ",

                    content: (
                        <div style={{ width: "100%", height: "300px", overflow: "auto", padding: "8px" }}>
                            <h4 style={{ color: "#274f9d" }}>Found {errorObj.length} Error(s)</h4>
                            {errorObj.map((item, index) => {
                                let _type = item.path[item.path.length - 1];
                                let _key = item.path.length > 0 ? item.path.join(" >> ") : item.argument;
                                let _msg = item.message;
                                if (item.name == "required") {
                                    _type = item.path.length > 0 ? item.path[0] : "";
                                    _msg = item.argument + " " + "required";
                                }
                                _msg = _type + " " + _msg;

                                return (
                                    <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                                        <span style={{ whiteSpace: "break-spaces", display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex", justifyContent: "left" }}>
                                                <b style={{ paddingRight: "18px", color: "#274f9d" }}>Key</b>:<span> {_key.trim()}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "left" }}>
                                                <b style={{ paddingRight: "12px", color: "purple" }}>Error</b>:<span> {_msg.trim()}</span>{" "}
                                            </div>
                                        </span>
                                    </Tag>
                                );
                            })}
                        </div>
                    ),
                    onOk() {
                        setLoaded(false);
                    },
                });
                return;
            }
        }
        console.log("****Tournament", jsonObject.tournament, values.tournament);
        console.log("****Season", jsonObject.year, values.season);
        console.log("****Doctype", jsonObject.docType, doctype);

        if (jsonObject.tournament == values.tournament && jsonObject.year == values.season && jsonObject.seasonType == values.seasontype && jsonObject.docType == doctype) {
        // if (jsonObject.tournament == values.tournament && jsonObject.year == values.season && jsonObject.docType == doctype) {
            // let tempConfs = [];
            // let playoffjson = jsonObject.confs.map(function (confs, element) {
            //     tempConfs.push({
            //         confname: confs.conf
            //     });
            // });
            // setConfs(tempConfs);

            await getCurrentStageTabKey();

            setOpen(true);
            setLoaded(false);
        } else {
            setLoaded(false);
            Modal.error({
                content: "Please check the tournament details in json file",
                onOk() {
                    setLoaded(false);
                },
            });
        }

    };

    const hideModal = async () => {
        setConfirmModalVisible(true);
        // setOpen(false);
    }

    const hideTheModal = async () => {
        setOpen(false);
        await onClose();
        localStorage.removeItem("oddsEditMatchNo");
    }

    const onClose = () => {
        setConfirmModalVisible(false);
    };

    const onClose1 = () => {
        setLoaded(false);
    };
    
    const updateDocument = async () => {
        let editedObj = null;
        let value = document.getElementById('newDoc').value;
        editedObj = JSON.parse(value);
        setLoaded(true);
        editedObj.appId = `${currentTournamentDetails.shortCode}-ops`;

        if(!editedObj.gameProvider) {
            editedObj.gameProvider = [
                {
                    key: "YH",
                    label: "9PDST"
                },
                {
                    key: "DK",
                    label: "9PK-"
                },
                {
                    key: "FD",
                    label: "9PK+"
                }]; 
        }

        if(!editedObj.minMaxLimitOrder) {
            editedObj.minMaxLimitOrder = ["budget", "player", "oneTeam","estPts"];
        }

        if(!editedObj.seasonType) {
            editedObj.seasonType = "REGULAR";
          }
          if(!editedObj.active) {
            editedObj.active = true;
          }

        const body = editedObj;
        const response = await axios.post(
            `${domain}/betops/collection/fantasy/update/v1`, body, {
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // }
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setLoaded(false);
                Modal.success({
                    content: "Fantasy Document Updated Successfully",
                    onOk() {
                        window.location.reload(true);
                        props.onClose();
                        props.schedulereload();
                    },
                });
            } else {
                setLoaded(false);
                props.onClose();
                Modal.error({
                    content: response.data.error.message
                });
            }
        })
            .catch((error) => {
                console.log(error);
            })

        setTabActiveKey("1");
        setOpen(false);
    }

    const updateMatch = async (isRawUpdate) => {
        let value = null;
        let editedObj = null;
        if (isRawUpdate) {
            value = document.getElementById('newDoc').value;
            editedObj = JSON.parse(value);
        } else {
            editedObj = {
                betNum: 0
            }
        }

        const args = {
            message: 'Success',
            description:
                'Bet Successfully Updated',
        };

        let _i = -1;
        let _j = -1;
        let _v = -1;
        for (let i = 0; i < jsonObject.htl.length; i++) {
            for (let j = 0; j < jsonObject.htl[i].rtl.length; j++) {
                let roadTeam = jsonObject.htl[i].rtl[j];
                if (roadTeam.matchId == localStorage.getItem("oddsEditMatchNo")) {
                    for (let v = 0; v < jsonObject.htl[i].rtl[j].bets.betList.length; v++) {
                        let betinfo = jsonObject.htl[i].rtl[j].bets.betList[v];
                        if (betinfo.betNum == editedObj.betNum) {
                            _i = i;
                            _j = j;
                            _v = v;
                            break;
                        } else {
                            if (JSON.parse(localStorage.getItem("oddsObj"))) {
                                let obj = JSON.parse(localStorage.getItem("oddsObj"));
                                if (betinfo.betNum == obj.betNum) {
                                    _i = i;
                                    _j = j;
                                    _v = v;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (_i != -1 && _j != -1 && _v != -1) {
            if (isRawUpdate) {
                jsonObject.htl[_i].rtl[_j].bets.betList[_v] = editedObj;
            } else {
                if (JSON.parse(localStorage.getItem("oddsObj"))) {
                    let obj = JSON.parse(localStorage.getItem("oddsObj"));
                    jsonObject.htl[_i].rtl[_j].bets.betList[_v] = obj;
                }
            }
            notification.success(args)
        }


        let _k = -1;
        let _m = -1;
        for (let k = 0; k < jsonObject.playOff.length; k++) {
            let playOff = jsonObject.playOff[k];
            if (playOff.matchId == localStorage.getItem("oddsEditMatchNo")) {
                for (let m = 0; m < jsonObject.playOff[k].bets.betList.length; m++) {
                    let betinfo = jsonObject.playOff[k].bets.betList[m];
                    if (betinfo.betNum == editedObj.betNum) {
                        _k = k;
                        _m = m;
                        break;
                    } else {
                        if (JSON.parse(localStorage.getItem("oddsObj"))) {
                            let obj = JSON.parse(localStorage.getItem("oddsObj"));
                            if (betinfo.betNum == obj.betNum) {
                                _k = k;
                                _m = m;
                                break;
                            }
                        }
                    }
                }
            }
        }
        if (_k != -1 && _m != -1) {
            if (isRawUpdate) {
                jsonObject.playOff[_k].bets.betList[_m] = editedObj;
            } else {
                if (JSON.parse(localStorage.getItem("oddsObj"))) {
                    let obj = JSON.parse(localStorage.getItem("oddsObj"));
                    jsonObject.playOff[_k].bets.betList[_m] = obj;
                }
            }
            notification.success(args)
        }
    }

    const backTab = (e) => {
        setTabActiveKey("1");
        setpopupClose(true);
    }

    const toLower = (value) => {
        return value.toLowerCase();
    };

    const allMatches = async (dataTeam) => {
        let tempTeam = dataTeam || teaminfo;
        setLoaded(true);
        const scheduleData = [];
        const playoffData = [];
        for (let j = 0; j < jsonObject.htl.length; j++) {
            const finaldata = jsonObject.htl[j].rtl;
            const finaldata1 = finaldata.map(function (element, mid) {
                if (element.bets !== undefined) {
                    const list2 = element.bets.betList.map(function (element2, mid2) {
                        let e = tempTeam.filter(list => list.matchid == element.matchId);
                        e = e[0];
                        scheduleData.push({
                            startdate: e.startdate,
                            winnerActual: element2.winnerActual,
                            betLegend: element2.betLegend,
                            betMode: element2.betMode,
                            betType: element2.betType,
                            winnerActualFavOrDog: element2.winnerActualFavOrDog,
                            betNum: element2.betNum,
                            year: e.year,
                            seasonType: e.seasonType,
                            matchtitle: e.matchtitle,
                            // tourID: e.tourID,
                            matchno: e.matchno,
                            tournament: e.tournament,
                            tournamenttitle: e.tournamenttitle,
                            hticon: e.hticon,
                            ht: e.ht,
                            rt: e.rt,
                            matchid: e.matchid,
                            rticon: e.rticon,
                            matchTs: e.matchTs,
                            subLabel: element2.subLabel,
                            betinfo: element2.sideList,
                            // documentID: dodcid,
                            mtype: e.mtype
                        });
                    });
                }
            });
        }

        const playofffinaldata = jsonObject.playOff;
        const playofffinaldata1 = playofffinaldata.map(function (element, mid) {
            if (element.bets !== undefined) {
                const list2 = element.bets.betList.map(function (element2, mid2) {
                    let e = tempTeam.filter(list => list.matchid == element.matchId);
                    e = e[0];
                    playoffData.push({
                        startdate: e.startdate,
                        winnerActual: element2.winnerActual,
                        betLegend: element2.betLegend,
                        betMode: element2.betMode,
                        betType: element2.betType,
                        winnerActualFavOrDog: element2.winnerActualFavOrDog,
                        betNum: element2.betNum,
                        year: e.year,
                        seasonType: e.seasonType,
                        matchtitle: e.matchtitle,
                        // tourID: e.tourID,
                        matchno: e.matchno,
                        tournament: e.tournament,
                        tournamenttitle: e.tournamenttitle,
                        hticon: e.hticon,
                        ht: e.ht,
                        rt: e.rt,
                        matchid: e.matchid,
                        rticon: e.rticon,
                        matchTs: e.matchTs,
                        subLabel: element2.subLabel,
                        betinfo: element2.sideList,
                        // documentID: dodcid,
                        mtype: e.mtype
                    });
                });
            }
        });
        var array3 = scheduleData.concat(playoffData);
        const check = array3.sort((a, b) => a.matchid - b.matchid);
        setTableData(check);
        setFilteredTableData(check);
        setLoaded(false);
        setMatchesValue("All Matches");
    }

    const handleMatches = async (e) => {
        setMatchesValue(e);
        if (e == "All Matches") {
            setTableData(filteredTableData);
        } else {
            let tempArr = [];
            for (let k = 0; k < filteredTableData.length; k++) {
                if (filteredTableData[k].matchid == e) {
                    tempArr.push(filteredTableData[k]);
                }
            }
            setTableData(tempArr);
        }
    };

    const getCurrentEditTabKey = () => {
        localStorage.removeItem("oddsObj");
    }

    const getCurrentStageTabKey = (e) => {
        // if (e == undefined) {
        //     let tempGroups = [];
        //     let playoffStageGroups = jsonObject.confs[0].confDivs.map(function (confDivs, element) {
        //         tempGroups.push({
        //             groupname: confDivs.confDiv,
        //             teams: confDivs.team
        //         });
        //     });
        //     setConfDivs(tempGroups);
        // } else if (e == 8) {
        //     let a = parseInt(e);
        // } else {
        //     let a = parseInt(e);
        //     let tempGroups = [];
        //     let playoffStageGroups = jsonObject.confs[a].confDivs.map(function (confDivs, element) {
        //         tempGroups.push({
        //             groupname: confDivs.confDiv,
        //             teams: confDivs.team
        //         });
        //     });
        //     setConfDivs(tempGroups);
        // }
    }

    return (
        <>
            <Modal
                title="Check Important Fields Values Before Update"
                className="checkFieldsModal checkFieldsModal-odds checkFieldsModal-playoff"
                open={open}
                onCancel={hideModal}
                zIndex="2000"
                modalOptions={{ dismissible: false }}
                keyboard={false}
                maskClosable={false}
                closable={popupClose}
            >
                <div>
                    {loaded == true ? (
                        <div className="loading-overlay">
                            <Spin size="large" />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    <Tabs activeKey={tabActiveKey}>
                        <TabPane tab="Table View" key="1">
                            <Space direction="horizontal" className="tablefilter">
                                {" "}                           
                            </Space>

                            <button type="button" className="ant-btn ant-btn-primary savebtn" onClick={updateDocument}>Upload</button>

                            <Form
                                layout="horizontal"
                                form={form}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Form.Item name="newDoc">
                                            <TextArea id="newDoc" style={{ height: "469px" }} defaultValue={JSON.stringify(jsonObject, null, 4)} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>

                            <div className="stageTabs" style={{ display: "none" }}>
                                {confs ? (
                                    <Tabs onChange={getCurrentStageTabKey}>
                                        {confs.map((item, index) => (
                                            <TabPane tab={item.confname} key={index}>
                                                {confDivs ? (
                                                    <>
                                                        <Collapse defaultActiveKey={['0']}>
                                                            {confDivs.map((item, index1) => (
                                                                <Panel header={item.groupname} key={index1}>
                                                                    <Table
                                                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                                                        dataSource={item.teams}
                                                                        style={{ maxHeight: "84vh" }}
                                                                        scroll={{ y: "250" }}
                                                                    >
                                                                        <Column title="Team Name" dataIndex="name" key="name" />
                                                                        <Column title="Order" dataIndex="order" key="order" />
                                                                        <Column title="Rank" dataIndex="rank" key="rank" />
                                                                        <Column title="Advanced" dataIndex="advanced" key="advanced" />

                                                                        <Column
                                                                            width="140px"
                                                                            title="Quick Actions"
                                                                            key="matchId"
                                                                            align="center"
                                                                            render={(matchId) => (
                                                                                <Space size="middle">
                                                                                    <EditTwoTone title="Edit" style={{ fontSize: '18px', color: '#08c' }} />

                                                                                </Space>
                                                                            )}
                                                                        />

                                                                    </Table>
                                                                </Panel>
                                                            ))}
                                                        </Collapse>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </TabPane>
                                        ))}
                                        <TabPane tab="Playoff" key="8">

                                        </TabPane>
                                    </Tabs>
                                ) : (
                                    <></>
                                )}
                            </div>

                            {jsonObject &&
                                <h1 className="popuptitle popuptitle1">FANTASY DOCUMENT <span>-</span> {jsonObject.tournament} <span>-</span> {jsonObject.year} <span>-</span> {jsonObject.seasonType}</h1>
                            }
                        </TabPane>
                        <TabPane tab="Raw View" key="2" className="edittab">
                            <div className="screendiv">
                                {argStore && updatedJsonObject &&
                                    <h1 className="popuptitle popuptitle2">MATCH {argStore.matchno} <span>-</span> {argStore.matchtitle} <span>-</span> BET {updatedJsonObject.betNum}</h1>
                                }
                                <img onClick={backTab} src="images/backarrow1.png" className="backbtn"></img>
                                <Tabs onChange={getCurrentEditTabKey}>
                                    <TabPane tab="Text View" key="1">
                                        <div className="editbet">
                                            <Editbets popupbets="true" newDoc={updatedJsonObject} updateMatchdetails={() => updateMatch(false)} matchid={argStore} winningarray={winningarray} betmode={betmode} onClose={onClose1} />
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Raw View" key="2">
                                        <Form
                                            layout="horizontal"
                                            form={form}
                                        >
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h6 style={{ marginBottom: "15px" }}>Existing Document</h6>
                                                    <Form.Item>
                                                        <TextArea style={{ height: "469px" }} value={JSON.stringify(updatedOldDoc, null, 4)} disabled />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h6 style={{ marginBottom: "15px" }}>New Document</h6>
                                                    <Form.Item name="newDoc">
                                                        <TextArea id="newDoc" style={{ height: "469px" }} defaultValue={JSON.stringify(updatedJsonObject, null, 4)} />
                                                    </Form.Item>
                                                    <Button type="primary" className="updatematchbtn" onClick={() => updateMatch(true)}>Continue</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </TabPane>
                    </Tabs>

                </div>
            </Modal>
            <Modal zIndex="2500" title="Confirm" onCancel={onClose} open={confirmModalVisible}
                footer={[<>  <Button key="submit" type="primary" onClick={hideTheModal}>
                    Ok
                </Button>
                    <Button key="submit" type="primary" onClick={onClose}>
                        Cancel
                    </Button></>

                ]}
            >
                Are you sure want to leave this window ?
            </Modal>

            <Form className="drawer-align-cls" form={props.form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="tournament" label="Select Tournaments" rules={[{ required: true, message: "Please Select Tournaments!" }]}>
                            <Select size="default" onChange={handleTournament} placeholder="Select Tournaments" style={{ width: "100%" }}>
                                {dbTournamentList.map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>{" "}
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="season" label="Select Season" rules={[{ required: true, message: "Please Select Season!" }]}>
                            <Select size="default" placeholder="Select Season" style={{ width: "100%" }}>
                                {dbSeasonList.map((item1, index1) => (
                                    <Option key={index1} value={item1}>
                                        {item1}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="seasontype" label="Select SeasonType" rules={[{ required: true, message: "Please Select SeasonType!" }]}>
                            <Select size="default" placeholder="Select SeasonType" style={{ width: "100%" }}>
                                {dbSeasonTypeList.map((item2, index2) => (
                                    <Option key={index2} value={item2}>
                                        {item2}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>{" "}
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="doctype" label="DocType">
                            <Input defaultValue={doctype} style={{ width: "100%" }} disabled />
                        </Form.Item>{" "}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Upload
                            {...props}
                            accept="application/json"
                            showUploadList={filelist}
                            onChange={(e) => {
                                setUploadedFile(e.file);
                            }}
                            beforeUpload={(file) => {
                                //File Type Check
                                if (file.type !== "application/json") {
                                    setMsgerror(`${file.name} is not a json file`);
                                    setFilelist(false);
                                } else {
                                    setFilelist(true);
                                    setMsgerror(`${file.name}`);
                                    const reader = new FileReader();

                                    reader.onload = (e) => {
                                        fileReader(e.target.result);
                                    };
                                    reader.readAsText(file);

                                    // Prevent upload
                                    return false;
                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Select</Button>
                            {msgerror}
                        </Upload>{" "}
                    </Col>
                </Row>
                {props.doctype == 'SCHEDULE' ? (
                    <Row gutter={24} className="updatebtn">
                        <Col span={24}>
                            <Form.Item {...tailFormItemLayout}>
                                {filelist == true ? (
                                    <Button type="primary" htmlType="submit">
                                        Continue
                                    </Button>
                                ) : (
                                    <Button disabled type="primary" htmlType="submit">
                                        Continue
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={24} className="updatebtn">
                        <Col span={24}>
                            <Form.Item {...tailFormItemLayout}>
                                {filelist == true ? (
                                    <Button type="primary" htmlType="submit">
                                        {/* Update */}
                                        Continue
                                    </Button>
                                ) : (
                                    <Button disabled type="primary" htmlType="submit">
                                        {/* Update */}
                                        Continue
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                )}

            </Form>
            {loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default FantasyBulkupdate;
