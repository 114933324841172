import "./App.css";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Login from "./Login/loginUser"

import Overview from "./Modules/overview";
import Schedule from "./Modules/schedule";
import Betlist from "./Modules/betlist";
import Playofflist from "./Modules/playofflist";
import Insightlist from "./Modules/insightlist";
import Fantasylist from "./Modules/fantasylist";
import UserTransactions from "./Modules/UserTransactions";
import Fantasytransactions from "./Modules/Fantasytransactions";
import BetTransactions from "./Modules/BetTransactions";
import Customalert from "./Modules/customalert";
import PackageManager from "./Modules/PackageManager";
import PurchaseTransactions from "./Modules/Purchase/PurchaseTransactions";
import BoardRule from "./Modules/BoardRule";
import EmailStatus from "./Modules/EmailPage/EmailStatus";
import UserWallet from "./Modules/UserWallet";
import Matchdetails from "./Modules/matchdetails";
import Dashboard from "./configuration/dashboard";
import LandingPage from "./Modules/LandingPage/LandingPage";
import Tournament from "./configuration/tournament";
import Landinginfo from "./configuration/landinginfo";
import LocationService from "./Modules/LocationService/LocationService";
import Dreamt20 from "./configuration/dreamt20";
import FantasyCafe from "./configuration/FantasyCafe";
import { BrowserRouter as Router, Routes, Route, Switch } from "react-router-dom";
import { logout, currentTournamentDetails } from "./settings/config";
import Certificate from "./Modules/Certificate/Certificate";
import Gamers from "./Modules/gamers";
import VersionsControl from "./configuration/versionsControl";
import EmailPage from "./Modules/EmailPage/EmailPage";
import Feedback from "./Modules/Feedback/Feedback";
import Donate from "./Modules/Donate/Donate";
import LeaderBoard from "./Modules/LeaderBoard/LeaderBoard";
import TeamList from "./Modules/Teams/TeamList";
import Campaigns from "./Modules/Campaigns/Campaigns";
import AddCampaign from "./Modules/Campaigns/AddCampaign";

/// Start function of the application
function App() {
  // if (window.performance) {
  //   if (window.performance.navigation.type == 1) {
  //     logout();
  //     setTimeout(() => window.location = "/login", 1500);
  //     return <></>
  //   }
  // }
  return (
    <Router>
      <Routes>
        <Route path="/team_list" exact element={<TeamList />} />
        <Route path="/leader_board" exact element={<LeaderBoard />} />
        <Route path="/feed_back" exact element={<Feedback />} />
        <Route path="/donate_transaction" exact element={<Donate />} />
        <Route path="/board_rule" exact element={<BoardRule />} />
        <Route path="/email_status" exact element={<EmailStatus />} />
        <Route path="/send_email" exact element={<EmailPage />} />
        <Route path="/campaigns" exact element={<Campaigns />} />
        <Route path="/create_campaign" exact element={<AddCampaign />} />        
        <Route path="/Certificate" exact element={<Certificate />} />
        <Route path="/landing_page" exact element={<LandingPage />} />
        <Route path="/user_wallet" exact element={<UserWallet />} />
        <Route path="/package_manager" exact element={<PackageManager />} />
        <Route path="/purchase_transactions" exact element={<PurchaseTransactions />} />
        <Route path="/location_services" exact element={<LocationService />} />
        <Route path="/user_transactions" exact element={<UserTransactions />} />
        <Route path="/gamers" exact element={<Gamers />} />
        <Route path="/bet_transactions" exact element={<BetTransactions />} />
        <Route path="/versions" exact element={<VersionsControl />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/overview" exact element={<Overview />} />
        <Route path="/matchdetails" exact element={<Matchdetails />} />
        <Route path="/schedule" exact element={<Schedule />} />
        <Route path="/betlist" exact element={<Betlist />} />
        <Route path="/playofflist" exact element={<Playofflist />} />
        <Route path="/insightlist" exact element={<Insightlist />} />
        <Route path="/fantasylist" exact element={<Fantasylist />} />
        <Route path="/customalert" exact element={<Customalert />} />
        <Route path="/dashboard" exact element={<Dashboard />} />
        <Route path="/tournament" exact element={<Tournament />} />
        <Route path="/fantasy_transactions" exact element={<Fantasytransactions />} />
        <Route path="/landinginfo" exact element={<Landinginfo />} />
        {currentTournamentDetails.shortName === "NFL" ? (
          <Route path="/fantasycafe" exact element={<FantasyCafe />} />
        ) : (
          // <Route path="/dreamt20" exact element={<Dreamt20 />} />
          <Route path="/dreamt20" exact element={<FantasyCafe />} />
        )}
        <Route path="/*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
