import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Layout, Modal, Spin } from "antd";
import LocationMap from "./LocationMap";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { domain } from "../../configuration/ApiConfiguration";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
const { Content } = Layout;
function LocationService(props) {
    const [loaded, setLoaded] = useState(true);
    const [map, setMap] = useState(false);
    const [isDeleteMapVisible, setIsDeleteMapVisible] = useState(false);
    const [flag, setFlag] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [data, setData] = useState([]);

    useEffect(async () => {
        const response = await axios.post(
            `${domain}/betops/geo/list/v1`)
            .then((response) => {
                setLoaded(false);
                if (response.data.status === 200) {
                    setData(response.data.message)
                }
                else {
                    setData([]);
                    Modal.error({
                        content: response.data.message,
                    });
                }
            })
            .catch((error) => {
                setData([]);
                setLoaded(false);
                // Modal.error({
                //     content: "Unable to process your Request, Please try again later.",
                // });
            })
    }, [])
    const retrivejson = (arg, e) => {

        setMap(true)
        setFlag("View")
        setCountryCode(arg)

    }
    const deletejson = (arg, e) => {

        setIsDeleteMapVisible(true)
        setCountryCode(arg)

    }
    const DeleteMap = async () => {

        const body = {
            "countryCode": countryCode,
        }
        const response = await axios.post(
            `${domain}/betops/geo/delete/v1`, body)
            .then(async (response) => {
                setLoaded(false);
                if (response.data.status === 200) {
                    setIsDeleteMapVisible(false)
                    setCountryCode("")
                    Modal.success({
                        content: "Deleted Successfully",
                    });
                    const res = await axios.post(
                        `${domain}/betops/geo/list/v1`)
                        .then((response) => {
                            setLoaded(false);
                            if (response.data.status === 200) {
                                setData(response.data.message)
                            }
                            else {
                                setData([]);
                                Modal.error({
                                    content: response.data.message,
                                });
                            }
                        })
                        .catch((error) => {
                            setData([]);
                            setLoaded(false);
                            // Modal.error({
                            //     content: "Unable to process your Request, Please try again later.",
                            // });
                        })
                }
                else {
                    setIsDeleteMapVisible(false)
                    setCountryCode("")
                    Modal.error({
                        content: response.data.message,
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                setIsDeleteMapVisible(false)
                setCountryCode("")
                // Modal.error({
                //     content: "Unable to process your Request, Please try again later.",
                // });
            })

    }
    const handleCancel = () => {
        setMap(false)
        setIsDeleteMapVisible(false)
        setCountryCode("")
    }
    const addnewJson = (arg, e) => {

        setFlag("Add")
        setMap(true)
    }
    const CloseMap = async () => {

        setMap(false)
        const res = await axios.post(
            `${domain}/betops/geo/list/v1`)
            .then((response) => {
                setLoaded(false);
                if (response.data.status === 200) {
                    setData(response.data.message)
                }
                else {
                    setData([]);
                    Modal.error({
                        content: response.data.message,
                    });
                }
            })
            .catch((error) => {
                setData([]);
                setLoaded(false);
                // Modal.error({
                //     content: "Unable to process your Request, Please try again later.",
                // });
            })
    }
    return (
        map ? <LocationMap countryCode={countryCode} CloseMap={CloseMap} flag={flag} /> :
            <Layout style={{ minHeight: "100vh" }} hasSider>
                <Sidermenu menu="location_services" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <h2>Location Service Management</h2>
                        <div>
                            <Button className="btn btn-primary topbtnalign" onClick={addnewJson}>Add new</Button>
                            <br />
                            <br />
                            <table style={{ width: "50%" }} className="table table-bordered">
                                <thead> <tr><th>Map</th><th>View</th><th>Delete</th></tr></thead>
                                <tbody>
                                    {data.length === 0 ? <tr><td colSpan={3} className="text-center">No Records Found</td></tr> : data.map((list, index) => {

                                        return (
                                            <tr>
                                                <td> {list.name}</td>
                                                <td><a href=" #" title="Edit Map" onClick={retrivejson.bind(this, list.countryCode)}><EyeOutlined /></a></td>
                                                <td><a href=" #" title="Delete Map" onClick={deletejson.bind(this, list.countryCode)}><DeleteOutlined /></a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Modal title="Delete Map" onCancel={handleCancel} visible={isDeleteMapVisible}
                                footer={[
                                    <Button key="submit" type="primary" onClick={handleCancel}>
                                        Cancel
                                    </Button>,
                                    <Button
                                        type="primary"
                                        onClick={DeleteMap}
                                    >
                                        Ok
                                    </Button>
                                ]}
                            >
                                Are you sure, You want delete this Map?
                            </Modal>
                        </div>
                    </Content>
                    <Footersection />
                </Layout>
                {loaded == true ? (
                    <div className="loading-overlay">
                        <Spin size="large" />{" "}
                    </div>
                ) : (
                    ""
                )}
            </Layout>
    )
}
export default LocationService;