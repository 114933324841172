import { Select, Layout, Card, Input, Table, Space, Button, Modal, Drawer, PageHeader, Spin, Tabs, Row, Col, Form, Divider } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import Moment from "react-moment";
import { domain } from "../configuration/ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth, currentTournamentDetails } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Search } = Input;
const { Column } = Table;
function UserWalletTransactions(props) {
    const [TransactionList, setTransactionList] = useState([]);
    const [TransactionFilteredList, setTransactionFilteredList] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(async () => {

        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/wallet/get/user/trans/v1?appId=${currentTournamentDetails.shortCode}-com&uid=${props.ID.uid}`,
        }).then((response) => {

            setLoaded(false);
            if (response.data.status === "SUCCESS") {
                setTransactionList(response.data.data)
                setTransactionFilteredList(response.data.data)
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: response.data.message,
                });
                props.onClose();
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }, []);
    const onSearch = (e) => {

        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = TransactionFilteredList.filter((item) => {
                return Object.keys(item).some((key) => {
                    if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
                    return false;
                });
            });
            setTransactionList(filteredData);
        } else {
            setTransactionList(TransactionFilteredList);
        }
    };
    return (<>   <PageHeader
        className="site-page-header"
        title="Wallet Transaction List"
        extra={
            <Space direction="horizontal">
                {" "}
                <Search placeholder="Search here" onChange={onSearch} enterButton />
            </Space>
        }
    />
        <Button type="primary" onClick={props.onClose}>Back</Button>
        <br />
        <br />
        <Table
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            dataSource={TransactionList}
            scroll={{ y: "250" }}
        >
            <Column title="Title" dataIndex="title" key="title" />
            <Column title="App Id" dataIndex="aid" key="aid" />
            <Column title="TransMode" dataIndex="transMode" key="transMode" />
            <Column title="TransSrc" dataIndex="transSrc" key="transSrc" />
            <Column title="TransType" dataIndex="transType" key="transType" />
            <Column title="UpdateFmCoins" dataIndex="updateFmCoins" key="updateFmCoins" />

            <Column
                title="Created Date"
                dataIndex="createdDate"
                key="createdDate"
                render={(text, record) => (
                    <Space size="middle">
                        {text ? (
                            text.split("T")[0]
                        ) : (
                            ""
                        )}
                    </Space>
                )}
            />
        </Table>
        {
            loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )
        }</>

    );
}
export default UserWalletTransactions;
