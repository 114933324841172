import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { Chart } from "react-google-charts";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Option } = Select;

const { Content } = Layout;
function LiveMatches() {
    const [loaded, setLoaded] = useState(true);
    const [highlightBox, setHighlightBox] = useState("1")
    const [user, loading, error] = useAuthState(auth);
    const { TabPane } = Tabs;

    useEffect(async () => {
        setLoaded(true)
        setLoaded(false)
    }, []);
    const highlight = (arg) => {

        setHighlightBox(arg)
    }
    return (

        <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <Card bordered={false} bodyStyle={{ padding: "8px" }} style={{ borderBottom: "2px solid #2196F3" }}>
                <div><span style={{ fontWeight: "600" }}>Live Matches</span></div>
            </Card>
            <br />
            <div className="row">
                <div class="col-md-3 mb-2">
                    <div class="card p-1 ml-1 mr-1">
                        <div class="card-header p-0 bg-white">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small class="fw-bold text-muted">T20 IPL 2022 | Match 01</small>
                                    <small class="text-white float-end badge bg-danger">LIVE</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <img src="images/PK.png" className="rounded-circle" width="15px" />
                                    <small className="fw-bold text-muted"> Punjab Kings</small>
                                </div>
                                <div class="col-md-6 col-6">
                                    <small className="fw-bold" style={{ color: "#2196F3" }}>
                                        <span class="ml-2">5/0</span> (0.5/5)
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <img src="images/DC.png" className="rounded-circle" width="15px" />
                                    <small className="fw-bold text-muted"> Delhi Capitals</small>
                                </div>
                                <div class="col-md-6 col-6">
                                    <small>
                                        <span class="fw-normal ml-2 text-muted">Yet To Bat</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-0 bg-white">
                            <small className="fw-bold text-muted">Punjab Kings batting</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-2">
                    <div class="card p-1 ml-1 mr-1">
                        <div class="card-header p-0 bg-white">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small class="fw-bold text-muted">T20 BBL 2022 | Match 02</small>
                                    <small class="text-white float-end badge bg-danger">LIVE</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <img src="images/MI.png" className="rounded-circle" width="15px" />
                                    <small className="fw-bold text-muted"> Mumbai Indians</small>
                                </div>
                                <div class="col-md-6 col-6">
                                    <small className="fw-bold text-muted">
                                        <span class="ml-2">5/5</span> (0.5/5)
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <img src="images/GT.png" className="rounded-circle" width="15px" />
                                    <small className="fw-bold text-muted"> Gujarat Titans</small>
                                </div>
                                <div class="col-md-6 col-6">
                                    <small style={{ color: "#2196F3" }}>
                                        <span class="fw-bold ml-2">1/0</span> (0.2/5)
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-0 bg-white">
                            <small className="fw-bold text-muted">Mumbai Indians batting</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-2">
                    <div class="card p-1 ml-1 mr-1">
                        <div class="card-header p-0 bg-white">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <small class="fw-bold text-muted">T20 IPL 2022 | Match 03</small>
                                    <small class="text-white float-end badge bg-danger">LIVE</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <img src="images/RR.png" className="rounded-circle" width="15px" />
                                    <small className="fw-bold text-muted"> Rajastan Royals</small>
                                </div>
                                <div class="col-md-6 col-6">
                                    <small class="fw-bold" style={{ color: "#2196F3" }}>
                                        <span class="fw-bold ml-2">5/0</span> (0.5/5)
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <img src="images/DC.png" className="rounded-circle" width="15px" />
                                    <small className="fw-bold text-muted"> Delhi Capitals</small>
                                </div>
                                <div class="col-md-6 col-6">
                                    <small>
                                        <span class="fw-normal ml-2 text-muted">Yet To Bat</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-0 bg-white">
                            <small className="fw-bold text-muted">Rajastan Royals batting</small>
                        </div>
                    </div>
                </div>
            </div >
        </Content >
    );
}

export default LiveMatches;
