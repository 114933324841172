import { Layout, Space, Divider, Button } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import { currentTournamentDetails, logout } from "../settings/config";
import SessionTimeout from '../settings/SessionTimeout';

const { Header } = Layout;
function Headersection() {
  let isAuthenticated = true
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 1000);
  }, []);
  return (
    <Header className="site-layout-background" style={{ height: "50px" }}>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={() => logout()} />
      <Space className="headerlogo" align="center" direction="horizontal">
        <img src={currentTournamentDetails.logo}></img>
      </Space>
      <Space className="headermenu" align="center" split={<Divider type="vertical" />}>
        <div style={{ margin: "6px" }}> <span className="todaydate" style={{ color: "#2196f3" }}>
          <Moment format="hh:mm:ss a">{time}</Moment>
        </span><span style={{ marginTop: "-30%", position: "relative", top: "111%", left: "50.5%", fontWeight: "bold" }}>
            <Moment format="D MMM YYYY">{time}</Moment>
          </span></div>


        <span className="admintitle">Admin</span>
        <span size="middle">
          <img src="images/power.svg" onClick={() => logout()}></img>
        </span>
      </Space>
    </Header>
  );
}
export default Headersection;

