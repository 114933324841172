import { collection, getDocs } from "firebase/firestore";
import { Select, Layout, Card, Col, Input, Table, Checkbox, PageHeader, Tag, Tooltip, Switch, Spin, Alert, Modal, Button, Space } from "antd";
import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.min.css";
import Headersection from "../../Layout/header";
import { Editor } from "@tinymce/tinymce-react";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { domain } from "../../configuration/ApiConfiguration";
import axios from "axios";
import {
    PlusCircleTwoTone,
    MinusCircleOutlined,
    RightCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import Password from "antd/lib/input/Password";
import { currentTournamentDetails } from "../../settings/config";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;

function EmailPage() {
    const [checkedBox, setcheckedBox] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isSelectBox, setIsSelectBox] = useState(false);
    const [isInputBox, setIsInputBox] = useState(false);
    const [file, setFile] = useState();
    const [passwordToggle, setPasswordToggle] = useState(true)
    const [body, setBody] = useState("Hi <strong>#NAME#,</strong>");
    const [subject, setSubject] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [ccEmail, setccEmail] = useState([]);
    const [password, setPassword] = useState("");
    const [Error, setError] = useState("");
    const [Errors, setErrors] = useState("");
    const [checkList, setCheckList] = useState([]);
    const [dataSet, setDataSet] = useState([]);
    const [dataSetFilter, setDataSetFilter] = useState([]);
    const [fileAttachmentArr, setFileAttachmentArr] = useState([]);
    const [credentialsList, setCredentialsList] = useState([]);
    const fileReader = new FileReader();
    const [arrays, setArray] = useState(0)
    const [addDetails, setAddDetails] = useState({ name: "", emailId: "" })
    //Tag Start


    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
        gettingCredentials();
    }, [inputValue]);
    const handleClose = (removedTag) => {

        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };
    const showInput = () => {

        setInputVisible(true);
    };
    const handleInputChange = (e) => {

        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {

        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };
    const handleEditInputChange = (e) => {

        setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };
    //Tag End
    const onClose = () => {
        setVisible(false);
        setError("");
        setErrors("")
        setAddDetails({ name: "", emailId: "" })
    };
    const togglePassword = (e) => {
        let id = e.currentTarget.id.split("_")[0];
        let password = document.getElementById(id);
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        let passwordToggles = !passwordToggle;
        setPasswordToggle(passwordToggles)
    }

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        if (e && e.target.files[0]) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };
            fileReader.readAsText(e.target.files[0]);
        }
    };

    const attachmentsOnChange = (e) => {
        const SendFiles = new FormData();
        let fileArr = [];
        for (let i = 0; i < e.target.files.length; i++) {
            fileArr.push(e.target.files[i]);
            SendFiles.append('file', fileArr[i]);
        }

        SendFiles.append('file', fileArr);
        SendFiles.append('tournament', currentTournamentDetails.shortName);
        SendFiles.append('appId', `${currentTournamentDetails.shortCode}-com`);

        const response = axios.post(
            `${domain}/betops/email/upload/files/v2`, SendFiles, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setFileAttachmentArr(response.data.data);
            }
        })
            .catch((error) => {
                console.log(error);
            })
    };

    const onChangeSelectAll = (e) => {

        let checkListClear = checkList
        for (let i = 0; i < checkListClear.length; i++) {
            if (e) {
                checkListClear[i].checked = true
                setcheckedBox(true);
            } else {
                checkListClear[i].checked = false
                setcheckedBox(false);
            }
        }
        setCheckList(checkListClear)
        setArray(arrays + 1)
    }
    const csvFileToArray = string => {
        var lines = string.replace(/\r/g, '');
        lines = lines.split('\n');
        var result = [];
        var headers = lines[0].split(",");
        let checkk = []
        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");
            var liness = 1;
            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
                if (!currentline[j]) {
                    liness = 0;
                }
            }
            if (liness) {
                checkk.push({ checked: true })
                result.push(obj);
            }

        }
        setcheckedBox(true)
        setCheckList(checkk)
        setDataSet(result)
        setDataSetFilter(result)
        return JSON.stringify(result); //JSON
    };

    const changeSelect = (index, e) => {
        let checkListData = checkList
        checkListData[index].checked = e.target.checked;
        setCheckList(checkListData)
        let checkedFil = true
        for (let i = 0; i < checkListData.length; i++) {
            if (!checkListData[i].checked) {
                checkedFil = false
            }
        }
        if (!checkedFil) {
            setcheckedBox(false)
        }
        else {
            setcheckedBox(true)
        }
        setArray(arrays + 1)
    }
    const removeMailIdRata = (index, e) => {
        let checkListData = checkList
        let dataSetData = dataSet
        checkListData.splice(index, 1)
        dataSetData.splice(index, 1)
        if (checkListData.length === 0) {
            document.getElementById("csvFileInput").value = ""
        }
        setCheckList(checkListData)
        setDataSet(dataSetData)
        setArray(arrays + 1)
    }
    const addMailIdData = () => {
        let details = addDetails
        if (!details.name || !details.emailId) {
            setError("Please fill all the fields")
            return false
        }
        else {
            setError("")
        }
        let dataSetData = dataSet
        let checkListData = checkList
        checkListData.push({ checked: true })
        dataSetData.push({ name: details.name, emailId: details.emailId })
        setcheckedBox(true)
        setCheckList(checkListData)
        setDataSet(dataSetData)
        setArray(arrays + 1)
        setVisible(false);
        setAddDetails({ name: "", emailId: "" })
    }
    const addMailIdDataPopUp = () => {
        setVisible(true)
    }
    const handleChangeAddDetails = (e) => {
        let details = addDetails
        details[e.target.name] = e.target.value
        setAddDetails(details)
        setArray(arrays + 1)
    }
    const onSearch = (e) => {
        let checkListClear = checkList
        for (let i = 0; i < checkListClear.length; i++) {
            checkListClear[i].checked = false
        }
        setCheckList(checkListClear)
        setcheckedBox(false)
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = dataSetFilter.filter((item) => {
                return Object.keys(item).some((key) => {
                    if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
                    return false;
                });
            });
            setDataSet(filteredData);
        } else {
            setDataSet(dataSetFilter);
        }
    }

    const downloadSampleCsv = () => { 
            const fileUrl = 'samplecsv.csv';
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'sample.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);          
    }

    const SendEmail = async (e) => {
        let dataSetData = dataSet
        let checkListData = checkList
        let ccFinalEmails = tags;
        let payload;
        payload = {
            "emails": [],
            "subject": subject,
            "body": body,
            "fromAddress": fromEmail,
            "emailPassword": password,
            "ccEmails": ccFinalEmails,
            "attachments": fileAttachmentArr,
        }

        for (let i = 0; i < dataSetData.length; i++) {
            if (checkListData[i].checked) {
                payload.emails.push({ name: dataSetData[i].name, emailId: dataSetData[i].emailId })
            }
        }

        if (payload.emails.length === 0) {

            setErrors("Please Check atleast one Email")
            setTimeout(() => {
                setErrors("")
            }, 5000);
            return false
        }
        if (!subject || !body) {
            setErrors("Body and Subject is required")
            setTimeout(() => {
                setErrors("")
            }, 5000);
            return false
        }
        if (!fromEmail || !password) {
            setErrors("From address and password is required")
            return false
        }
        setLoaded(true)

        const response = await axios.post(
            `${domain}/betops/email/send/v1`, payload)
            .then((response) => {

                setLoaded(false);
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: "Email send successfully",
                        onOk() {
                            window.location.reload()
                        }
                    });

                }
                else if (response.data.status === "FAIL") {
                    Modal.error({
                        content: response.data.status,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    const handleEditorChange = (e) => {
        setBody(e)
    }

    const gettingCredentials = async () => {
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/email/get/users/v1`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setCredentialsList(response.data.data.users);
                // let config21 = response.data.data.yearsList;
                // setDbSeasonList(config21);

                // let config31 = response.data.data.seasonsList;
                // setDbSeasonTypeList(config31);
            }
            else {
            }
        }).catch((error) => {
        })


    }

    const handleFromAddressType = async (e) => {
        setFromEmail("");
        setPassword("");
        if (e == "new") {
            setIsInputBox(true);
            setIsSelectBox(false);
        } else {
            setIsInputBox(false);
            setIsSelectBox(true);
        }
    }

    const handleFromAddress = async (e, index) => {
        setFromEmail(e);
        let password = credentialsList && credentialsList.filter(obj => obj.email === e) || [];
        if(password.length > 0) {
            setPassword(password[0].pwd);
        }
        

        // const response2 = await axios({
        //   method: 'get',
        //   url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${e}`,
        // }).then(async (response) => {
        //   if (response.data.status === "SUCCESS") {
        //     let config21 = response.data.data.yearsList;
        //     setDbSeasonList(config21);

        //     let config31 = response.data.data.seasonsList;
        //     setDbSeasonTypeList(config31);
        //   }
        //   else {
        //   }
        // }).catch((error) => {
        // })

        // setTournamentValue(e);
        // getMatches(matchlistTabType, e, seasonyearValue, seasonType);
    }

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <>
                <Sidermenu menu="send_email" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="email-page-design site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Send Email"
                                    extra={
                                        <Space direction="horizontal" style={{ marginLeft: "auto", width: "100%" }}>
                                            <Search placeholder="Search Recipients" onChange={onSearch} enterButton />
                                            <Button className="sendbtn" type="primary" onClick={SendEmail}>
                                                Send Email ➜
                                                {/* <RightCircleOutlined /> */}
                                            </Button>
                                        </Space>
                                    }
                                />
                                <div className="row">
                                    <div className="col-md-12">{Errors ? <Alert message={Errors} type="error" showIcon /> : ""}</div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="col-form-label">From Address <span>*</span></label>
                                                {/* <h1 className="text-danger">{Errors}</h1> */}
                                                <div className="font-weight-bold">
                                                    <Select className="maininput" size="default" placeholder="Select Type" style={{ width: "200px" }} onChange={(e) => handleFromAddressType(e)}>
                                                        {/* {fromAddressTypeList.map((item, index) => (
                                                            <Option key={index} value={item.email}>
                                                                {item.email}{" "}
                                                            </Option>
                                                        ))} */}
                                                        <option value="existing">Existing</option>
                                                        <option value="new">New</option>
                                                    </Select>


                                                    {isSelectBox ?
                                                        <Select className="maininput" size="default" placeholder="Select Email" style={{ width: "200px" }} onChange={(e) => handleFromAddress(e)}>
                                                            {credentialsList.map((item, index) => (
                                                                <Option key={index} value={item.email}>
                                                                    {item.email}{" "}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                        : ""}

                                                    {isInputBox ?
                                                        <input autocomplete="off" className="maininput" placeholder="Enter new email" type="text" onChange={((e) => setFromEmail(e.target.value))} value={fromEmail}></input>
                                                        : ""}



                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {isInputBox ?
                                                       <>
                                                        <label className="col-form-label">Password <span>*</span></label>
                                                <div className="font-weight-bold">
                                                    <div class="input-group">
                                                        <input autocomplete="off" className="maininput input form-control" placeholder="Enter password" id="newPassword" type="password" onChange={((e) => setPassword(e.target.value))} value={password} />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">
                                                                {!passwordToggle ? <svg name="newPassword" onClick={togglePassword} id="newPassword_new" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                </svg> : <svg onClick={togglePassword} name="newPassword" id="newPassword_new" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z" />
                                                                </svg>}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                       </>
                                                        : ""}
                                               
                                            </div>
                                        </div>
                                        <label className="col-form-label">CC Emails</label>
                                        <div className="form-weight-bold">
                                            {tags.map((tag, index) => {
                                                if (editInputIndex === index) {
                                                    return (
                                                        <Input
                                                            ref={editInputRef}
                                                            key={tag}
                                                            size="small"
                                                            className="tag-input"
                                                            value={editInputValue}
                                                            onChange={handleEditInputChange}
                                                            onBlur={handleEditInputConfirm}
                                                            onPressEnter={handleEditInputConfirm}
                                                        />
                                                    );
                                                }
                                                const isLongTag = tag.length > 20;
                                                const tagElem = (
                                                    <Tag
                                                        className="edit-tag"
                                                        key={tag}
                                                        closable={true}
                                                        onClose={() => handleClose(tag)}
                                                    >
                                                        <span
                                                            onDoubleClick={(e) => {
                                                                if (index !== 0) {
                                                                    setEditInputIndex(index);
                                                                    setEditInputValue(tag);
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        >
                                                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                        </span>
                                                    </Tag>
                                                );
                                                return isLongTag ? (
                                                    <Tooltip title={tag} key={tag}>
                                                        {tagElem}
                                                    </Tooltip>
                                                ) : (
                                                    tagElem
                                                );
                                            })}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {inputVisible && (
                                                        <Input
                                                            ref={inputRef}
                                                            type="text"
                                                            size="small"
                                                            className="tag-input maininput"
                                                            value={inputValue}
                                                            onChange={handleInputChange}
                                                            onBlur={handleInputConfirm}
                                                            onPressEnter={handleInputConfirm}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {!inputVisible && (
                                                <Tag className="site-tag-plus" onClick={showInput}>
                                                    <PlusOutlined /> New CC
                                                </Tag>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                            <Button className="downloadcsvbtn" onClick={downloadSampleCsv}>Download Sample CSV{" "} <img src="images/downloadcsv1.png"></img>
                                            </Button>
                                            </div>
                                        </div>
                                        
                                        <label className="col-form-label">To Address <span>*</span></label>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input
                                                        type={"file"}
                                                        id={"csvFileInput"}
                                                        accept={".csv"}
                                                        onChange={handleOnChange}
                                                        className="chooseinput"
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    Add New <PlusCircleTwoTone onClick={addMailIdDataPopUp} style={{ color: "#1890ff", fontSize: "20px" }} />
                                                </div>
                                                {dataSet.length > 0 ? <div className="col-md-3">
                                                    Select All <Switch checked={checkedBox} onChange={onChangeSelectAll} />
                                                </div> : ""}
                                            </div>
                                        </form>
                                        <br />
                                        <div className="tablediv">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    {dataSet.map((list, index) => {
                                                        return (<>
                                                            <tr>
                                                                <td className="text-center">
                                                                    <Checkbox onChange={changeSelect.bind(this, index)} checked={checkList[index].checked} />
                                                                </td>
                                                                <td>
                                                                    <p>{list.name}</p> <p>{list.emailId}</p>{" "}
                                                                </td>
                                                                <td className="text-center">
                                                                    <MinusCircleOutlined style={{ color: "#cb3837", fontSize: "20px" }} onClick={removeMailIdRata.bind(this, index)} />
                                                                </td>
                                                            </tr>

                                                            {/* </Card> */}
                                                        </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <label className="col-form-label">Add Attachments</label>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {/* accept={".csv"} */}
                                                    <input
                                                        multiple
                                                        type={"file"}
                                                        id={"attachmentsFileInput"}
                                                        onChange={attachmentsOnChange}
                                                        className="chooseinput"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="col-form-label">Subject <span>*</span></label>
                                                <div className="font-weight-bold">
                                                    <input className="maininput" placeholder="Enter subject" type="text" onChange={((e) => setSubject(e.target.value))} value={subject}></input>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Body <span>*</span></label>
                                                <div className="font-weight-bold">
                                                    <Editor
                                                        className="form-control"
                                                        apiKey="lbuv0djkgu6zowtihu0s8w0jyxca18tgpvhrbd6ra5kpmxak"
                                                        // initialValue={body}
                                                        value={body}
                                                        // disabled={true}
                                                        init={{
                                                            height: 350,
                                                            width: "100%",
                                                            menubar: false,
                                                            branding: false,
                                                            contenteditable: true,
                                                            content_css_cors: true,
                                                            plugins: [
                                                                "advlist autolink lists link charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code",
                                                            ],
                                                            toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat"
                                                        }}

                                                        onEditorChange={handleEditorChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>


                        <Modal title="Add" onCancel={onClose} visible={visible}
                            footer={[<>  <Button key="submit" type="primary" onClick={addMailIdData}>
                                Add
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>
                            ]}
                        >
                            <div className="drawer-align-cls">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Name <span>*</span></label>
                                        <input type="text" placeholder="Name" title="Name" name="name" value={addDetails.name} className="form-control m-1" onChange={handleChangeAddDetails} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Email Id <span>*</span></label>
                                        <input type="text" placeholder="Email Id" title="Email Id" name="emailId" value={addDetails.emailId} className="form-control m-1" onChange={handleChangeAddDetails} />
                                    </div>
                                    <div className="col-md-12 text-danger">{Error}</div>
                                </div>
                            </div>
                        </Modal>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>
        </Layout>
    );
}

export default EmailPage;
