import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Season() {
  const [infoform] = Form.useForm();

  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [title, setTitle] = useState("");
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [tournament, setTournament] = useState([]);
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Season");
    setVisible(true);
  };
  const showDrawer = (matchid, index) => {
    setCurrentindex(index);
    infoform.setFieldsValue({
      tournamentName: matchid.tournament,
      season: matchid.year,
    });
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const deleterecord = async (matchid, index1) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());

    let filtered = arraycheck.season.filter(function (value, index, arr) {
      return index !== index1;
    });
    arraycheck.season = filtered;
    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };
  const onClose = () => {
    infoform.resetFields();
    setVisible(false);
  };

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const onInfofinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    if (title === "New Season") {
      arraycheck.season.push({
        tournament: values.tournamentName,
        year: values.season,
      });
    } else {
      arraycheck.season[currentindex] = {
        tournament: values.tournamentName,
        year: values.season,
      };
    }

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: title === "New Season" ? "New Season Added Successfully" : "Updated Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };

  const fetchDashboard = async () => {
    const scheduleCol = collection(db, "configuration");
    const scheduleSnapshot = await getDocs(scheduleCol);
    const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
    let tabarray = [];
    scheduleSnapshot.docs.map((doc) => {
      if (doc.id === currentTournamentDetails.majorUsedDocId) {
        var docdata = doc.data();
        if (docdata !== undefined) {
          let _tmp = docdata.season.map((obj, index) => {
            // obj.key = 'id' + index
            return obj;
          })
          setSchedule(_tmp);
        }
      }
      if (doc.id === "admin_console") {
        var docdata = doc.data();
        if (docdata !== undefined) {
          setTournament(docdata.tournament);
        }
      }
    });
  };
  useEffect(() => {
    var currentyear = new Date().getFullYear();
    const yearlist = [];
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i);
    }
    setYearlist(yearlist.reverse());
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <div>
      <Space direction="horizontal" className="rightspace">
        {" "}
        <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
        <a onClick={() => addnew()}>
          <span className="addschedule">Add New</span>
        </a>
      </Space>
      <Table dataSource={schedule} pagination={{ pageSize: 10 }} scroll={{ y: "340" }}>
        <Column title="Tournament" dataIndex="tournament" key="tournament" />
        <Column title="Year" dataIndex="year" key="year" />

        <Column
          title="Quick Actions"
          key="notifId"
          render={(notifId, record, index) => (
            <Space key={index} size="middle">
              <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
              <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
            </Space>
          )}
        />
      </Table>{" "}
      <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="small">
        <Form name="horizontal_login" layout="vertical" form={infoform} onFinish={onInfofinish}>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="tournamentName" label="Select Tournament" rules={[{ required: true, message: "Please Select Tournament!" }]}>
                <Select size="default" placeholder="Select Tournament" style={{ width: "100%" }}>
                  {tournament.map((item1, index1) => (
                    <Option key={index1} value={item1.tournamentName}>
                      {item1.tournamentName}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="season" label="Select Year" rules={[{ required: true, message: "Please Select Season!" }]}>
                <Select size="default" placeholder="Select Season" style={{ width: "100%" }}>
                  {yearlist.map((item1, index1) => (
                    <Option key={index1} value={item1}>
                      {item1}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  {title === "New Season" ? "Add" : "Update"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Season;
