import { Spin, Layout, Input, Card, Table, Space, Button, Modal, PageHeader, Form, Tooltip } from "antd";
import { collection, getDocs, doc, where, query, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails, logout } from "../../settings/config";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
//import "antd/dist/antd.min.css"
import "antd/dist/antd.min.css";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import axios from "axios";
import {
    PlusCircleTwoTone,
    DeleteTwoTone,
    LoginOutlined,
    DeleteOutlined,
    EditTwoTone,
    CopyOutlined
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { domain } from "../../configuration/ApiConfiguration";
import AddCampaign from "./AddCampaign";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
function Campaigns() {
    const [form] = Form.useForm();
    const [ID, setID] = useState("");
    const [rowInfo, setRowInfo] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [StatusVisible, setStatusVisible] = useState(false);
    const [DeleteVisible, setDeleteVisible] = useState(false);
    const [campaignList, setCampaignList] = useState([]);
    const [keyData, setKeyData] = useState(null);
    const [sportsConfig, setSportsConfig] = useState(null);
    const [tempCampList, setTempCampList] = useState([]);
    const [matchdetails, setMatchDetails] = useState([]);
    const [drawertype, setDrawertype] = useState("");
    const addnew = () => {
        setDrawertype("New Campaign");
    };
    const showDrawer = (matchid, index1) => {
        setMatchDetails(matchid);
        setDrawertype("Edit Campaign");
    };

    const statusTheCampaign = async () => {
        setStatusVisible(false);
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/dl/campaign/toggle_status/v2.0?appId=${currentTournamentDetails.shortCode}-ops&campId=${rowInfo.campId}`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            onClose();
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data,
                });
                setTimeout(async () => {
                    fetchDashboard();
                }, 2000);
            }
            else if (response.data.status === "FAIL") {
                setLoaded(false);
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }

    const changeStatus = async (rowData) => {
        setRowInfo(rowData)
        setStatusVisible(true);
    };

    const onClose = () => {
        setDrawertype("");
        setID("")
        setDeleteVisible(false);
        setStatusVisible(false);
        setRowInfo("")
        form.resetFields();
    };

    const onSearch = (e) => {
        if (e.target.value !== "") {
            let filteredData = tempCampList.find(obj => `${obj.code}` === e.target.value);
            let tempArr = [];
            tempArr.push(filteredData);
            if (tempArr[0].code) {
                setCampaignList(tempArr);
            }
        } else {
            fetchDashboard();
        }
    };

    const makeShortCampId = (campId) => {
        var value = campId;
        var firstFour = value.substr(0, 4);
        var lastFour = value.substr(value.length - 4);
        return (
            <>
                <span style={{ color: "rgb(0, 136, 204)" }}>{firstFour}</span>xx-xx<span style={{ color: "rgb(0, 136, 204)" }}>{lastFour}</span>
            </>
        );
    }

    const fetchDashboard = async () => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/dl/campaign/get-all/v2.0?appId=${currentTournamentDetails.shortCode}-ops`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                setTempCampList(response.data.data.campaign_list);
                setCampaignList(response.data.data.campaign_list);
                setKeyData(response.data.data.keyData);
                // setSportsConfig(response.data.data.sportConfig);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        fetchDashboard();
    }, [user, loading]);

    useEffect(async () => {
    }, [loaded]);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="view_campaigns" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        {drawertype == "Edit Campaign" ? <AddCampaign campaignform={form} innerRoute="true" Flag="Edit Campaign" Campaign={matchdetails} keydata={keyData} onClose={onClose} getList={fetchDashboard} /> : drawertype == "New Campaign" ? <AddCampaign campaignform={form} innerRoute="true" onClose={onClose} getList={fetchDashboard} keydata={keyData} Flag="New Campaign" />
                            : <>
                                <div className="site-card-border-less-wrapper">
                                    <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                        <PageHeader
                                            className="site-page-header"
                                            title="Campaign List"
                                            extra={
                                                <>
                                                    {" "}
                                                    <Space direction="horizontal">
                                                        {" "}
                                                        <Search placeholder="Search by Code" onChange={onSearch} enterButton />
                                                        <a onClick={() => addnew()}>
                                                            <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                        </a>
                                                    </Space>{" "}
                                                </>
                                            }
                                        />
                                        <Table
                                            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                            dataSource={campaignList}
                                            scroll={{ y: "340" }}
                                        >
                                            <Column title="Code" dataIndex="code" key="code" />
                                            <Column title="Campaign Id" dataIndex="campId" key="campId"
                                                render={(text, record) => (
                                                    <>
                                                        <CopyToClipboard text={text}>
                                                            <div className="clonediv">
                                                                <Tooltip title="click to copy">
                                                                    <span style={{ cursor: "pointer" }}>
                                                                    {makeShortCampId(text)}
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </CopyToClipboard>


                                                        {/* <Space size="middle">
                                                            <p>{makeShortCampId(text)}</p>
                                                        </Space> */}
                                                    </>
                                                )}
                                            />
                                            <Column title="Type" dataIndex="type" key="type" />
                                            <Column title="Title" dataIndex="title" key="title" />
                                            {/* <Column title="Description" dataIndex="desc" key="desc" /> */}
                                            <Column
                                                title="Start Date"
                                                dataIndex="startDate"
                                                key="startDate"
                                                render={(text, record) => (
                                                    <Space size="middle">
                                                        {text && text !== undefined ? (
                                                            // <Moment format="DD-MM-YYYY hh:mm a">
                                                            //     {text}
                                                            // </Moment>

                                                            <Moment unix format="DD-MM-YYYY hh:mm a">
                                                                {text._seconds}
                                                            </Moment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Space>
                                                )}
                                            />
                                            <Column
                                                title="End Date"
                                                dataIndex="endDate"
                                                key="endDate"
                                                render={(text, record) => (
                                                    <Space size="middle">
                                                        {text && text !== undefined ? (
                                                            // <Moment format="DD-MM-YYYY hh:mm a">
                                                            //     {text}
                                                            // </Moment>

                                                            <Moment unix format="DD-MM-YYYY hh:mm a">
                                                                {text._seconds}
                                                            </Moment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Space>
                                                )}
                                            />
                                            <Column title="Status" dataIndex="active" key="active"
                                                render={(text, record) => (
                                                    <Space size="middle">
                                                        {text == true ? (
                                                            <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                                        ) : (
                                                            <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                                        )}
                                                    </Space>
                                                )}
                                            />
                                            <Column
                                                title="Quick Actions"
                                                key="documentID"
                                                render={(documentID, value) => (
                                                    <Space size="middle">
                                                        {/* <DeleteOutlined onClick={() => deleteLanding(documentID)} title="Delete Landing" style={{ fontSize: "18px", color: "#08c" }} /> */}
                                                        <LoginOutlined onClick={() => changeStatus(documentID)} title="Change Campaign Status" style={{ fontSize: "18px", color: "#08c" }} />
                                                        <EditTwoTone onClick={() => showDrawer(documentID)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    </Space>
                                                )}
                                            />
                                        </Table>
                                    </Card>
                                </div>
                                {/* <Modal title="Delete" onCancel={onClose} visible={DeleteVisible}
                                    footer={[<>  <Button key="submit" type="primary" onClick={deleteThePackage}>
                                        Ok
                                    </Button>
                                        <Button key="submit" type="primary" onClick={onClose}>
                                            Cancel
                                        </Button></>
                                    ]}
                                >
                                    Are you sure you want to Delete the Package?
                                </Modal> */}
                                <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                                    footer={[<>  <Button key="submit" type="primary" onClick={statusTheCampaign}>
                                        Ok
                                    </Button>
                                        <Button key="submit" type="primary" onClick={onClose}>
                                            Cancel
                                        </Button></>
                                    ]}
                                >
                                    Are you sure you want to Change the Campaign Status?
                                </Modal>
                                {loaded == true ? (
                                    <div className="loading-overlay">
                                        <Spin size="large" />{" "}
                                    </div>
                                ) : (
                                    ""
                                )}</>}

                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default Campaigns;
