import { doc, setDoc } from "firebase/firestore";
import {
    Select, Layout, Row, Col, Table, Spin, Tag, Upload, Button, Modal, Form, Input,
    Card, Popover, Space, notification, Checkbox, Drawer, PageHeader, Tabs, Divider
} from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import { UploadOutlined, EditTwoTone } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import axios from "axios";
import { domain } from "../../src/configuration/ApiConfiguration";
import { Validator } from "jsonschema";
import Moment from "react-moment";
import { async } from "@firebase/util";
import { json } from "react-router-dom";
// const SCHEDULE_SCHEMA = require("../Jsondata/schema/test_schedule_schema.json");

const { TabPane } = Tabs;
const { TextArea, Search } = Input;
const temptableData = [];
const SCHEDULE_SCHEMA = require("../Jsondata/schema/schedule.json");
// const PLAYOFF_SCHEMA = require("../Jsondata/schema/playoff.json");
// const INSIGHTS_SCHEMA = require("../Jsondata/schema/insights.json");
// const AUCTIONS_SCHEMA = require("../Jsondata/schema/auction.json");
// const FANTACY_SCHEMA = require("../Jsondata/schema/fantasy.json");
// const BETS_SCHEMA = require("../Jsondata/schema/bets_schedule.json");
const { Column } = Table;
const { Option } = Select;
const doctypearray = [
    { key: 1, value: "SCHEDULE" },
    { key: 2, value: "PLAY_OFF" },
    { key: 3, value: "INSIGHTS" },
    { key: 4, value: "FANTASY_LINEUP" },
    { key: 5, value: "AUCTION" },
    { key: 6, value: "BETODDS" }
];

function ScheduleBulkupdate(props) {
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(false);
    const [seasonResultVal, setSeasonResultVal] = useState(false);
    const [yearlist, setYearlist] = useState([]);
    const [dataset, setDataset] = useState([]);
    const [doctype, setDoctype] = useState(props.doctype);
    const [msgerror, setMsgerror] = useState("");
    const [filelist, setFilelist] = useState(false);
    const [open, setOpen] = useState(false);
    const [popupClose, setpopupClose] = useState(true);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const [dbTournamentList, setDbTournamentList] = useState([]);
    const [dbSeasonList, setDbSeasonList] = useState([]);
    const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

    const [tableData, setTableData] = useState(null);
    const [filteredTableData, setFilteredTableData] = useState([]);

    const [jsonObject, setJsonObject] = useState(null);
    const [updatedJsonObject, setUpdatedJsonObject] = useState(null);
    const [oldDoc, setOldDoc] = useState(null);
    const [updatedOldDoc, setUpdatedOldDoc] = useState(null);

    const [tabActiveKey, setTabActiveKey] = useState("1");


    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const fileReader = (result) => {
        setFilelist(true);
        setDataset(result);
        setJsonObject(JSON.parse(result));
    };

    const loadProfile = () => {
        setMsgerror("");
        var currentyear = new Date().getFullYear();
        const yearlist = [];
        for (var i = 1980; i <= currentyear; i++) {
            yearlist.push(i);
        }
        setYearlist(yearlist.reverse());
    };

    useEffect(() => {
        loadProfile();
        sportConfigSetting();
    }, []);

    const sportConfigSetting = () => {
        let config11 = localStorage.getItem("sportsList")
        let config12 = config11.split(",");
        setDbTournamentList(config12);

        let config21 = localStorage.getItem("sportYearsList")
        let config22 = config21.split(",");
        setDbSeasonList(config22);

        let config31 = localStorage.getItem("sportSeasonsList")
        let config32 = config31.split(",");
        setDbSeasonTypeList(config32);
    }

    const handleChange = (e) => {
        setDoctype(e);
    };

    const getScheduleSchema = async (docType) => {
        localStorage.removeItem("schemaRes");
        let appId;
        if (currentTournamentDetails.shortName == "NFL") {
            appId = "fc-ops";
        } else {
            appId = "dt20-ops";
        }
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/scheduleschema/get/specific-schema/v2?appId=${appId}&docType=${docType}&seasonType=${jsonObject.seasonType}`
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                localStorage.setItem("schemaRes", JSON.stringify(response.data.data));
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }

    const validateScheduleJson = async (jsonObj, docType = "") => {
        let jsonSchema = null;
        try {
            let jsonValidator = new Validator();
            if (docType === "SCHEDULE") {
                await getScheduleSchema("SCHEDULE");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "PLAY_OFF") {
                await getScheduleSchema("PLAY_OFF");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "INSIGHTS") {
                await getScheduleSchema("INSIGHTS");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "FANTASY_LINEUP") {
                await getScheduleSchema("FANTASY_LINEUP");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "AUCTION") {
                await getScheduleSchema("AUCTION");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "BETODDS") {
                await getScheduleSchema("BETODDS");
                jsonSchema = JSON.parse(localStorage.getItem("schemaRes"));
            } else if (docType === "SCHEMA") {
                return "SCHEMA";
            }


            // if (docType === "SCHEDULE") {
            //     jsonSchema = SCHEDULE_SCHEMA;
            // } else if (docType === "PLAY_OFF") {
            //     jsonSchema = PLAYOFF_SCHEMA;
            // } else if (docType === "INSIGHTS") {
            //     jsonSchema = INSIGHTS_SCHEMA;
            // } else if (docType === "FANTASY_LINEUP") {
            //     jsonSchema = FANTACY_SCHEMA;
            // } else if (docType === "AUCTION") {
            //     jsonSchema = AUCTIONS_SCHEMA;
            // } else if (docType === "BETODDS") {
            //     jsonSchema = BETS_SCHEMA;
            // } else if (docType === "SCHEMA") {
            //     return "SCHEMA";
            // }

            if (jsonSchema) {
                jsonValidator.attributes.contains = function validateContains(instance, schema, options, ctx) {
                    if (typeof instance !== "string") return;
                    if (schema.contains.indexOf(instance) < 0) {
                        return "does not contain the string " + JSON.stringify(schema.contains);
                    }
                };
                let _data = jsonObj || {};
                let validate = jsonValidator.validate(_data, jsonSchema);
                if (validate.errors.length == 0) return null;
                else return validate.errors;
            }
        } catch (err) {
            return null;
        }
        return null;
    };

    const handleTournament = async (e) => {
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${e}`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                let config21 = response.data.data.yearsList;
                setDbSeasonList(config21);

                let config31 = response.data.data.seasonsList;
                setDbSeasonTypeList(config31);
            }
            else {
            }
        }).catch((error) => {
        })
    }

    const onSearch = (e) => {
        var result = temptableData.find(obj => {
            return obj.matchid === e.target.value
        })

        if (result) {
            setTableData([result]);
        } else {
            setTableData(filteredTableData);
        }
    };

    const onFinish = async (values) => {
        // values.season = 2023;
        // values.seasontype = "REGULAR";
        // values.tournament = "IPL";

        setTabActiveKey("1");
        form.resetFields(["newDoc"]);
        setLoaded(true);
        // setJsonObject(JSON.parse(dataset));
        // let jsonObject = JSON.parse(dataset);

        let errorObj = null;
        if (jsonObject) errorObj = await validateScheduleJson(jsonObject, doctype);
        // if (errorObj == "SCHEMA") {
        //   await setDoc(doc(db, "bet_schedule", doctype + "_" + values.tournament), jsonObject);
        //   setLoaded(false);
        //   Modal.success({
        //     content: "schedule Schema Added successfully",
        //     onOk() {
        //       props.onClose();
        //       props.schedulereload();
        //     },
        //   });
        //   return;
        // }
        if (jsonObject.docType != doctype) {
            setLoaded(false);
            Modal.error({
                content: "Please upload valid json file",
                onOk() {
                    setLoaded(false);
                },
            });
            return;
        } else {
            if (errorObj != null) {
                setLoaded(false);
                Modal.error({
                    title: doctype + " Json Schema Validation ",

                    content: (
                        <div style={{ width: "100%", height: "300px", overflow: "auto", padding: "8px" }}>
                            <h4 style={{ color: "#274f9d" }}>Found {errorObj.length} Error(s)</h4>
                            {errorObj.map((item, index) => {
                                let _type = item.path[item.path.length - 1];
                                let _key = item.path.length > 0 ? item.path.join(" >> ") : item.argument;
                                let _msg = item.message;
                                if (item.name == "required") {
                                    _type = item.path.length > 0 ? item.path[0] : "";
                                    _msg = item.argument + " " + "required";
                                }
                                _msg = _type + " " + _msg;

                                return (
                                    <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                                        <span style={{ whiteSpace: "break-spaces", display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex", justifyContent: "left" }}>
                                                <b style={{ paddingRight: "18px", color: "#274f9d" }}>Key</b>:<span> {_key.trim()}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "left" }}>
                                                <b style={{ paddingRight: "12px", color: "purple" }}>Error</b>:<span> {_msg.trim()}</span>{" "}
                                            </div>
                                        </span>
                                    </Tag>
                                );
                            })}
                        </div>
                    ),
                    onOk() {
                        setLoaded(false);
                    },
                });
                return;
            }
        }


        // const checktournament = props.scheduledata.some(
        //   (item) => values.tournament === item.tournamenttitle && Number(values.season) === item.Year && doctype === item.docType
        // );

        // if (checktournament == false) {
        // 

        console.log("****Tournament", jsonObject.tournament, values.tournament);
        console.log("****Season", jsonObject.year, values.season);
        console.log("****SeasonType", jsonObject.seasonType, values.seasontype);
        console.log("****Doctype", jsonObject.docType, doctype);

        if (jsonObject.tournament == values.tournament && jsonObject.year == values.season && jsonObject.seasonType == values.seasontype && jsonObject.docType == doctype) {
            // await setDoc(doc(db, "bet_schedule", values.season + "_" + doctype + "_" + values.tournament), jsonObject);
  
            if(seasonResultVal) {
                jsonObject.seasonResult = seasonResultVal;
            } else {
                delete jsonObject.seasonResult;
            }

            const getresponse = await axios({
                method: 'get',
                url: `${domain}/betops/match/get/all-match-list/v1?tournament=${values.tournament}&season=${values.season}&seasonType=${values.seasontype}`,
            }).then(async (response) => {
                if (response.data.status === "SUCCESS") {
                    setOldDoc(response.data.data.schedule);
                } else {
                    setLoaded(false);
                }
            })
                .catch((error) => {
                    setLoaded(false);
                })


            let schedule = jsonObject;
            schedule["htl"].forEach(homeTeam => {
                homeTeam["rtl"].forEach(roadTeam => {
                    temptableData.push({
                        matchtitle: roadTeam.rt + " vs " + roadTeam.ht,
                        matchno: roadTeam.matchId.slice(4, roadTeam.matchId.length),
                        tournamenttitle: schedule.tournamentTitle,
                        tournament: schedule.tournament,
                        year: schedule.year,
                        seasonType: schedule.seasonType,
                        begins: new Date(schedule.begins.seconds * 1000),
                        ends: new Date(schedule.ends.seconds * 1000),
                        ht: roadTeam.ht,
                        rt: roadTeam.rt,
                        matchid: roadTeam.matchId,
                        res: roadTeam.res,
                        startTs: new Date(roadTeam.startTs.seconds * 1000),
                        endTs: new Date(roadTeam.endTs.seconds * 1000),
                        resultSummary: roadTeam.resultSummary,
                        resHt: roadTeam.resHt,
                        resRt: roadTeam.resRt
                        // let gameNum = roadTeamSchedule.matchId?.slice(4);
                    });
                });
            });

            if (schedule["playOff"]) {
                schedule["playOff"].forEach(playOff => {
                    temptableData.push({
                        matchtitle: playOff.rt + " vs " + playOff.ht,
                        matchno: playOff.matchId.slice(4, playOff.matchId.length),
                        tournamenttitle: schedule.tournamentTitle,
                        tournament: schedule.tournament,
                        year: schedule.year,
                        seasonType: schedule.seasonType,
                        begins: new Date(schedule.begins.seconds * 1000),
                        ends: new Date(schedule.ends.seconds * 1000),
                        ht: playOff.ht,
                        rt: playOff.rt,
                        matchid: playOff.matchId,
                        res: playOff.res,
                        startTs: new Date(playOff.startTs.seconds * 1000),
                        endTs: new Date(playOff.endTs.seconds * 1000),
                        resultSummary: playOff.resultSummary,
                        resHt: playOff.resHt,
                        resRt: playOff.resRt
                    });
                });
            }

            if (temptableData != null && temptableData.length > 0) {
                try {
                    temptableData.sort(function (a, b) {
                        return a.matchno - b.matchno;
                    });
                } catch (e) {
                    console.log(e);
                }
            }

            setTableData(temptableData);
            setFilteredTableData(temptableData);

            setLoaded(false);
            setOpen(true);
        } else {
            setLoaded(false);
            Modal.error({
                content: "Please check the tournament details in json file",
                onOk() {
                    setLoaded(false);
                },
            });
        }

    };

    const hideModal = async () => {
        setConfirmModalVisible(true);
        // setOpen(false);
    }

    const hideTheModal = async () => {
        setOpen(false);
        await onClose();
    }

    const onClose = () => {
        setConfirmModalVisible(false);
    };

    // const getDate = async (date) => {
    //     return (
    //         <Moment format="DD-MM-YYYY hh:mm a">
    //         {date}
    //         </Moment>
    //     );
    // }

    const updateDocument = async () => {
        jsonObject.appId = `${currentTournamentDetails.shortCode}-ops`;
        const body = jsonObject;
        const response = await axios.post(
            `${domain}/betops/collection/update/v2`, body, {
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // }
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: "Schedule Updated successfully",
                    onOk() {
                        window.location.reload(true);
                        props.onClose();
                        props.schedulereload("upcoming", props.tournament, props.season, props.seasonType);
                        // setFilelist(false);
                        // setDataset([]);
                        // setJsonObject(null);
                        // setUploadedFile(null);
                    },
                });
            }
        })
            .catch((error) => {
                console.log(error);
            })

        setTabActiveKey("1");
        setOpen(false);
    }

    const updateMatch = async () => {
        var value = document.getElementById('newDoc').value;
        let editedObj = JSON.parse(value);
        let _i = -1;
        let _j = -1;
        for (let i = 0; i < jsonObject.htl.length; i++) {
            for (let j = 0; j < jsonObject.htl[i].rtl.length; j++) {
                let roadTeam = jsonObject.htl[i].rtl[j];
                if (roadTeam.matchId == editedObj.matchId) {
                    console.log(i, j);
                    _i = i;
                    _j = j;
                    break;
                }
            }
        }

        if (_i != -1 && _j != -1) {
            jsonObject.htl[_i].rtl[_j] = editedObj;
        }


        let _k = -1;
        if (jsonObject.playOff) {
            for (let k = 0; k < jsonObject.playOff.length; k++) {
                let playOff = jsonObject.playOff[k];
                if (playOff.matchId == editedObj.matchId) {
                    _k = k;
                    break;
                }
            }

            if (_k != -1) {
                jsonObject.playOff[_k] = editedObj;
            }
        }

        const args = {
            message: 'Success',
            description:
                'Match Successfully Updated',
        };
        notification.success(args)
    }

    const editAction = (arg) => {
        form.resetFields(["newDoc"]);
        let schedule = oldDoc;
        let value = "";
        schedule["htl"].forEach(homeTeam => {
            homeTeam["rtl"].forEach(roadTeam => {
                if (roadTeam.matchId == arg.matchid) {
                    value = roadTeam;
                    return;
                }
            });
        });
        if (schedule["playOff"]) {
            schedule["playOff"].forEach(playOff => {
                if (playOff.matchId == arg.matchid) {
                    value = playOff;
                    return;
                }
            });
        }
        setUpdatedOldDoc(value);



        let schedule1 = jsonObject;
        let value1 = "";
        schedule1["htl"].forEach(homeTeam => {
            homeTeam["rtl"].forEach(roadTeam => {
                if (roadTeam.matchId == arg.matchid) {
                    value1 = roadTeam;
                    return;
                }
            });
        });

        if (schedule1["playOff"]) {
            schedule1["playOff"].forEach(playOff => {
                if (playOff.matchId == arg.matchid) {
                    value1 = playOff;
                    return;
                }
            });
        }

        setUpdatedJsonObject(value1);
        setTabActiveKey("2");
        setpopupClose(false);
    }

    const backTab = (e) => {
        setTabActiveKey("1");
        setpopupClose(true);
    }

    const changeSeasonResult = (e) => {
        setSeasonResultVal(e.target.checked);
    }

    // const getCurrentTabKey = (e) => {
    //     if (e == "1") {
    //         setTabActiveKey("1");
    //     } else {
    //         let data = {
    //             "matchid": 202301
    //         }
    //         editAction(data);
    //         setTabActiveKey("2");
    //     }
    // }

    return (
        <>
            <Modal
                destroyOnClose={true}
                title="Check Important Fields Values Before Update"
                className="checkFieldsModal"
                open={open}
                // onOk={updateDocument}
                // okText="Save"
                onCancel={hideModal}
                zIndex="2000"
                modalOptions={{ dismissible: false }}
                keyboard={false}
                maskClosable={false}
                closable={popupClose}
            >
                <div>
                    {/* onChange={getCurrentTabKey} */}
                    <Tabs activeKey={tabActiveKey}>
                        <TabPane tab="Table View" key="1">
                            {/* <PageHeader
                                className="site-page-header"
                                extra={
                                    <Space direction="horizontal">
                                        {" "}
                                        <label className="inputlabel">Filter</label>
                                        <Search placeholder="Enter Match Id" onChange={onSearch} enterButton />
                                    </Space>
                                }
                            /> */}
                            <Space direction="horizontal" className="tablefilter">
                                {" "}
                                <label className="inputlabel">Filter</label>
                                <Search placeholder="Enter Match Id" onChange={onSearch} enterButton />
                            </Space>
                            {/* <hr className="filterdivider"></hr> */}

                            <button type="button" className="ant-btn ant-btn-primary savebtn" onClick={updateDocument}>Upload</button>
                            <Table
                                rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                dataSource={tableData}
                                // onChange={handleTableChange}
                                scroll={{ y: "250" }}
                            // style={{ maxHeight: "80vh" }}
                            >
                                <Column width="130px" title="Match Id" dataIndex="matchid" key="matchid" sorter="true" />
                                <Column width="280px" title="Match" dataIndex="matchid" key="matchid" sorter="true"
                                    render={((text, record) => {
                                        return (
                                            <>
                                                <h6>
                                                    <span className="cellGroupSpan">Title :</span> {record.matchtitle}
                                                </h6>
                                                <h6>
                                                    <span className="cellGroupSpan">Start Date : </span>
                                                    <Moment format="DD-MM-YYYY hh:mm a">
                                                        {record.startTs}
                                                    </Moment>
                                                </h6>
                                                <h6>
                                                    <span className="cellGroupSpan">End Date : </span>
                                                    <Moment format="DD-MM-YYYY hh:mm a">
                                                        {record.endTs}
                                                    </Moment>
                                                </h6>
                                            </>
                                        )
                                    }
                                    )}
                                />
                                <Column width="150px" title="Road Team" dataIndex="rt" key="rt" sorter="true"
                                    render={((text, record) => {
                                        return (
                                            <Space size="middle">
                                                <span className={record.res == -1 ? "text-green" : ""}>
                                                    {text}
                                                </span>
                                            </Space>
                                        )
                                    }
                                    )}
                                />
                                <Column width="150px" title="Home Team" dataIndex="ht" key="ht" sorter="true"
                                    render={((text, record) => {
                                        return (
                                            <Space size="middle">
                                                <span className={record.res == 1 ? "text-green" : ""}>
                                                    {text}
                                                </span>
                                            </Space>
                                        )
                                    }
                                    )}
                                />
                                <Column title="Result" dataIndex="res" key="res" sorter="true"
                                    render={((text, record) => {
                                        return (
                                            <>
                                                <h6 className={text == -1 ? "text-red" : text == 1 ? "text-green" : text == 2 ? "text-blue" : text == -2 ? "text-gray" : ""}>
                                                    <span className="cellGroupSpan">Result :</span> {text}
                                                </h6>
                                                <h6>
                                                    <span className="cellGroupSpan">Summary :</span> {record.resultSummary}
                                                </h6>
                                                <h6>
                                                    <span className="cellGroupSpan">Res RT :</span> {record.resRt}
                                                </h6>
                                                <h6>
                                                    <span className="cellGroupSpan">Res HT :</span> {record.resHt}
                                                </h6>
                                            </>
                                        )
                                    }
                                    )}
                                />
                                <Column
                                    width="140px"
                                    title="Quick Actions"
                                    key="matchId"
                                    align="center"
                                    render={(matchId) => (
                                        <Space size="middle">
                                            {matchId.matchid !== undefined ? (

                                                <EditTwoTone onClick={() => editAction(matchId)} title="Edit" style={{ fontSize: '18px', color: '#08c' }} />
                                            ) : (
                                                ""
                                            )}
                                        </Space>
                                    )}
                                />
                            </Table>
                            {tableData &&
                                <h1 className="popuptitle popuptitle1">{tableData[0].tournament} {tableData[0].year} {tableData[0].seasonType} <span>-</span> <Moment format="DD-MM-YYYY">{tableData[0].begins}</Moment> <span>to</span> <Moment format="DD-MM-YYYY">{tableData[0].ends}</Moment></h1>
                            }
                        </TabPane>
                        <TabPane tab="Raw View" key="2">
                            {tableData && updatedJsonObject &&
                                <h1 className="popuptitle popuptitle2">{tableData[0].tournament} {tableData[0].year} {tableData[0].seasonType} <span>-</span> <Moment format="DD-MM-YYYY hh:mm a">{new Date(updatedJsonObject.startTs.seconds * 1000)}</Moment> <span>to</span> <Moment format="DD-MM-YYYY hh:mm a">{new Date(updatedJsonObject.endTs.seconds * 1000)}</Moment> <span>-</span> {updatedJsonObject.rt} vs {updatedJsonObject.ht} <span>-</span> {updatedJsonObject.matchId}</h1>
                            }
                            <img onClick={backTab} src="images/backarrow1.png" className="backbtn"></img>
                            <Form
                                layout="horizontal"
                                form={form}
                            >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6 style={{ marginBottom: "15px" }}>Existing Document</h6>
                                        <Form.Item>
                                            <TextArea style={{ height: "469px" }} value={JSON.stringify(updatedOldDoc, null, 4)} disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 style={{ marginBottom: "15px" }}>New Document</h6>
                                        <Form.Item name="newDoc">
                                            <TextArea id="newDoc" style={{ height: "469px" }} defaultValue={JSON.stringify(updatedJsonObject, null, 4)} />

                                            {/* value={JSON.stringify(updatedJsonObject, null, 4)}   */}
                                        </Form.Item>
                                        <Button type="primary" className="updatematchbtn" onClick={updateMatch}>Continue</Button>
                                    </div>
                                </div>


                            </Form>
                        </TabPane>
                    </Tabs>

                </div>
            </Modal>
            <Modal destroyOnClose={true} zIndex="2500" title="Confirm" onCancel={onClose} visible={confirmModalVisible}
                footer={[<>  <Button key="submit" type="primary" onClick={hideTheModal}>
                    Ok
                </Button>
                    <Button key="submit" type="primary" onClick={onClose}>
                        Cancel
                    </Button></>

                ]}
            >
                Are you sure want to leave this window ?
            </Modal>

            <Form className="drawer-align-cls" form={props.form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="tournament" label="Select Tournaments" rules={[{ required: true, message: "Please Select Tournaments!" }]}>
                            <Select size="default" onChange={handleTournament} placeholder="Select Tournaments" style={{ width: "100%" }}>
                                {dbTournamentList.map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>{" "}
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="season" label="Select Season" rules={[{ required: true, message: "Please Select Season!" }]}>
                            <Select size="default" placeholder="Select Season" style={{ width: "100%" }}>
                                {dbSeasonList.map((item1, index1) => (
                                    <Option key={index1} value={item1}>
                                        {item1}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="seasontype" label="Select SeasonType" rules={[{ required: true, message: "Please Select SeasonType!" }]}>
                            <Select size="default" placeholder="Select SeasonType" style={{ width: "100%" }}>
                                {dbSeasonTypeList.map((item2, index2) => (
                                    <Option key={index2} value={item2}>
                                        {item2}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>{" "}
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="doctype" label="DocType">
                            <Input defaultValue={doctype} style={{ width: "100%" }} disabled />
                        </Form.Item>{" "}
                    </Col>
                </Row>
                <p style={{ marginBottom: '20px' }} className="seasonresultcheckbox">
                    <Checkbox checked={seasonResultVal} onChange={changeSeasonResult}>
                        {/* {label} */}<h6>Season Result</h6>
                    </Checkbox>
                </p>

                {/* <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="seasonresult" label="Select Season Result" className="seasonresultcheckbox">
                        <Checkbox onChange={changeSeasonResult.bind(this)}><h1>cc</h1></Checkbox>                      
                        </Form.Item>
                    </Col>
                </Row> */}

                {/* <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
          <Form.Item name="doctype" label="Select DocType" rules={[{ required: false, message: "Please Select DocType!" }]}>
            <Select size="default" value={doctype} placeholder="Select Document Type" style={{ width: "100%" }} onChange={handleChange}>
              {doctypearray.map((item2, index2) => (
                <Option key={index2} value={item2.value}>
                  {" "}
                  {item2.value}{" "}
                </Option>
              ))}
            </Select>{" "}
            </Form.Item>
          </Col>
        </Row> */}
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Upload
                            {...props}
                            accept="application/json"
                            showUploadList={filelist}
                            onChange={(e) => {
                                setUploadedFile(e.file);
                            }}
                            beforeUpload={(file) => {
                                //File Type Check
                                if (file.type !== "application/json") {
                                    setMsgerror(`${file.name} is not a json file`);
                                    setFilelist(false);
                                } else {
                                    setFilelist(true);
                                    setMsgerror(`${file.name}`);
                                    const reader = new FileReader();

                                    reader.onload = (e) => {
                                        fileReader(e.target.result);
                                    };
                                    reader.readAsText(file);

                                    // Prevent upload
                                    return false;
                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Select</Button>
                            {msgerror}
                        </Upload>{" "}
                    </Col>
                </Row>
                {props.doctype == 'SCHEDULE' ? (
                    <Row gutter={24} className="updatebtn">
                        <Col span={24}>
                            <Form.Item {...tailFormItemLayout}>
                                {filelist == true ? (
                                    <Button type="primary" htmlType="submit">
                                        Continue
                                    </Button>
                                ) : (
                                    <Button disabled type="primary" htmlType="submit">
                                        Continue
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={24} className="updatebtn">
                        <Col span={24}>
                            <Form.Item {...tailFormItemLayout}>
                                {filelist == true ? (
                                    <Button type="primary" htmlType="submit">
                                        Continue
                                    </Button>
                                ) : (
                                    <Button disabled type="primary" htmlType="submit">
                                        Continue
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                )}

            </Form>

      
            {loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default ScheduleBulkupdate;
