import { collection, getDocs, getDoc, doc, setDoc } from "firebase/firestore";
import { Select, Layout, Card, Input, Table, Popover, Row, Col, Space, notification, Checkbox, Button, Modal, Drawer, PageHeader, Spin, Tabs } from "antd";
import { currentTournamentDetails, db, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Editmatch from "./editmatch";
import MatchTabView from "./MatchTabView";
import BetsInfo from "./BetsInfo";
import FantasyInfo from "./FantasyInfo";
import axios from "axios";
import Moment from "react-moment";
import moment from 'moment'
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import ScheduleBulkupdate from "./scheduleBulkUpdate";
import Sidermenu from "../Layout/sidermenu";
import {
  RollbackOutlined,
  EyeOutlined,
  EditTwoTone,
  DashOutlined,
  EditOutlined,
  PlusCircleTwoTone
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { domain } from "../configuration/ApiConfiguration";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
const { TabPane } = Tabs;
function Matchdetails() {
  const [MatchInfoPage, setMatchInfoPage] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [normalDrawervisible, setNormalDrawervisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [viewDrawerInfo, setViewDrawerInfo] = useState([]);
  const [matchinfo, setMatchinfo] = useState([]);
  const [globalPageInfo, setGlobalPageInfo] = useState([]);
  const [editPageInfo, setEditPageInfo] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [matchdetails, setMatchDetails] = useState([]);
  const [matchDetails1, setMatchDetails1] = useState([]);
  const [tournamentValue, setTournamentValue] = useState(currentTournamentDetails.shortName);
  const [seasonType, setSeasonType] = useState("REGULAR");
  const [matchyear, setMatchyear] = useState([]);
  const [seasonTypeList, setSeasonTypeList] = useState([]);
  const [seasonyearValue, setSeasonyearValue] = useState(2023);
  const [matchlistTabType, setMatchlistTabType] = useState("upcoming");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalBetVisible, setIsModalBetVisible] = useState(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [PredictModalVisible, setPredictModalVisible] = useState(false);
  const [resolveArray, setResolveArray] = useState([]);
  const [RewardArray, setRewardArray] = useState([]);
  const [PredictMatchRewardVisible, setPredictMatchRewardVisible] = useState(false);
  const [SkillBetRewardVisible, setSkillBetRewardVisible] = useState(false);
  const [FantasyRewardVisible, setFantasyRewardVisible] = useState(false);
  const [ResolveMatch, setResolveMatch] = useState(false);
  const [RewardMatch, setRewardMatch] = useState(false);
  const [modaltitle, setModaltitle] = useState("");
  const [validation, setvalidation] = useState("");
  const [matchrow, setMatchrow] = useState([]);
  const [insightdata, setInsightdata] = useState([]);
  const [oknotify, setNotify] = useState(false);
  const [isFantasyModalVisible, setFantasyModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [normalDrawertype, setNormalDrawertype] = useState("");
  const [title, setTitle] = useState("");

  const [dbTournamentList, setDbTournamentList] = useState([]);
  const [dbSeasonList, setDbSeasonList] = useState([]);
  const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

  const resolveMatchSubmit = (arg, e) => {
    if (resolveArray.length > 0) {
      for (let i = 0; i < resolveArray.length; i++) {
        if (resolveArray[i] === "Bet") {
          handleOk(arg, "erer")
        }
        else if (resolveArray[i] === "Fantasy") {
          handleResolveFantasyOk(arg, "erer")
        }
        else if (resolveArray[i] === "Predict") {
          handlePredict(arg, "erer")
        }
      }

    }
    else {
      setvalidation("Please select atleast one option")
      return false
    }
    setResolveMatch(false)
  }

  const showContent = () => {
    return (
      <div>
        {/* <p> */}
        <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center", marginRight: "15px" }} />
        <a onClick={() => bulkupdate()}>
          <span className="addbet">Add Schedule</span>
        </a>
        {/* </p> */}
      </div >

    );
  };

  const RewardMatchSubmit = (arg, e) => {

    if (RewardArray.length > 0) {
      for (let i = 0; i < RewardArray.length; i++) {
        if (RewardArray[i] === "Bet") {
          notifyReward("SKILL_BET_RESULT")
        }
        else if (RewardArray[i] === "Fantasy") {
          notifyReward("FANTASY_RESULT")
        }
        else if (RewardArray[i] === "Predict") {
          notifyReward("PREDICT_MATCH_RESULT")
        }
      }

    }
    else {
      setvalidation("Please select atleast one option")
      return false
    }
    setRewardMatch(false)
  }
  const RewardSubmit = (matchid) => {

    setvalidation("")
    setRewardArray([])
    setMatchrow(matchid);
    setRewardMatch(true)
  }
  const resolveSubmit = (matchid) => {

    setvalidation("")
    setResolveArray([])
    setMatchrow(matchid);
    setResolveMatch(true)
  }

  const bulkupdate = () => {
    setNormalDrawertype("bulkupdate");
    setTitle("Add Schedule");
    setNormalDrawervisible(true);
    setOpen(false);
  }

  const showDrawer = async (arg) => {
    setGlobalPageInfo(arg);
    setLoaded(true);
    await getSpecificMatchInfo(arg.matchid, "view");
  };
  const onClose = () => {
    setVisible(false);
    setLoaded(false);
    setNormalDrawervisible(false);
  };
  const PredictMatchReward = async (matchid) => {
    setMatchrow(matchid);
    setPredictMatchRewardVisible(true);
    const title = "Are you sure send bet notification to " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
  };
  const skillBetReward = async (matchid) => {
    setMatchrow(matchid);
    setSkillBetRewardVisible(true);
    const title = "Are you sure send bet notification to " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
  };
  const FantasyReward = async (matchid) => {
    setMatchrow(matchid);
    setFantasyRewardVisible(true);
    const title = "Are you sure send bet notification to " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
  };
  const notifyReward = async (arg) => {

    let body = {
      "matchId": matchrow.matchId,
      "season": globalPageInfo.tyear,
      "seasonType": seasonType,
      "tournament": globalPageInfo.tournament,
      "type": arg
    }
    let notification_content = "Fantasy Reward";
    if (arg === "SKILL_BET_RESULT") {
      notification_content = "Skill Bet Reward";
    }
    else if (arg === "FANTASY_RESULT") {
      notification_content = "Fantasy Reward";
    }
    else if (arg === "PREDICT_MATCH_RESULT") {
      notification_content = "Predict Match Reward";
    }
    const response = await axios.post(
      `${domain}/betops/notification/content/v1`,
      body)
      .then((res) => {

        let args = "";
        if (res.data.status === "SUCCESS") {
          args = {
            message: 'Success',
            description:
              notification_content + ' Notification has been sent successfully',
          };
        }
        else {
          args = {
            message: 'Failed',
            description:
              notification_content + ' Notification failed , Please try again later.',
            duration: 0
          };
        }

        notification.success(args)
      })
      .catch((error) => {
        const args = {
          message: 'Failed',
          description:
            notification_content + ' Notification failed , Please try again later.',
          duration: 0
        };
        notification.error(args)
      })
    setFantasyRewardVisible(false);
    setLoaded(false);
    setNotify(false);
  }
  const notifyBetResult = async () => {
    const currentTimeInMilliseconds = Date.now();
    const res = await setDoc(doc(db, "notification", globalPageInfo.tournament + "_" + matchrow.matchId + "_BET_RESULT_" + currentTimeInMilliseconds), {
      tournament: globalPageInfo.tournament,
      notifId: globalPageInfo.tournament + "_" + matchrow.matchId + "_BET_RESULT_" + currentTimeInMilliseconds,
      matchId: matchrow.matchId,
      cards: [
        {
          title: matchrow.ht,
          icon: globalPageInfo.hticon,
          status: "LOSS",
        },
        {
          title: matchrow.rt,
          icon: globalPageInfo.rticon,
          status: "WIN",
        },
      ],
      channelId: `com.sherpaanalytics.${currentTournamentDetails.majorUsedDocId}.match.result`,
      season: globalPageInfo.tyear,
      title: "Checkout your skillBet™ results on Game " + globalPageInfo.matchno,
      channelName: "Match Result",
      type: "BET_RESULT",
      "seasonType": seasonType,
      model: "",
      desc: "A total of #BET_COUNT# bets were placed",
      topic: "match-result",
    })
      .then((res) => {

        const args = {
          message: 'Success',
          description:
            'Bet Reward Notification has been sent successfully',
        };
        notification.success(args)
      })
      .catch((error) => {

        const args = {
          message: 'Failed',
          description:
            'Bet reward Notification failed , Please try again later.',
          duration: 0
        };
        notification.error(args)
      })
    setIsModalVisible(false)

    setLoaded(false);
    setNotify(false);
  }
  const notifyResult = async () => {
    const currentTimeInMilliseconds = Date.now();
    const res = await setDoc(doc(db, "notification", globalPageInfo.tournament + "_" + matchrow.matchId + "_BET_RESULT_" + currentTimeInMilliseconds), {
      tournament: globalPageInfo.tournament,
      notifId: globalPageInfo.tournament + "_" + matchrow.matchId + "_BET_RESULT_" + currentTimeInMilliseconds,
      matchId: matchrow.matchId,
      cards: [
        {
          title: matchrow.ht,
          icon: globalPageInfo.hticon,
          status: "LOSS",
        },
        {
          title: matchrow.rt,
          icon: globalPageInfo.rticon,
          status: "WIN",
        },
      ],
      channelId: `com.sherpaanalytics.${currentTournamentDetails.majorUsedDocId}.match.result`,
      season: globalPageInfo.tyear,
      title: "Checkout your skillBet™ results on Game " + globalPageInfo.matchno,
      channelName: "Match Result",
      "seasonType": seasonType,
      type: "MATCH_RESULT",
      model: "",
      desc: "",
      topic: "match-result",
    });
    setIsModalNotificationVisible(false)
    Modal.success({
      content: "Notification has been sent successfully",
    });
    setLoaded(false);
    setNotify(false);
  }
  const handleCancel = () => {
    setPredictMatchRewardVisible(false);
    setSkillBetRewardVisible(false);
    setFantasyRewardVisible(false);
    setIsModalVisible(false);
    setFantasyModalVisible(false);
    setIsModalBetVisible(false);
    setIsModalNotificationVisible(false);
    setPredictModalVisible(false);
    setNotify(false);
    setResolveMatch(false)
    setRewardMatch(false)
  };
  const handlePredict = async () => {
    setLoaded(true);

    setPredictModalVisible(false);
    const title = "Are you sure to Predict Match of " + globalPageInfo.matchtitle + " (" + matchrow.matchId + ") ?";
    setModaltitle(title);
    debugger;
    const body = {
      "tournament": globalPageInfo.tournament,
      "season": globalPageInfo.tyear,
      "match_id": matchrow.matchId,
      "season_type": seasonType,
      "appId": `${currentTournamentDetails.shortCode}-ops`
    }
    const response = await axios.post(
      `${domain}/betops/predictMatch/resolve/v3.0`,
      body)
      .then((response) => {
        setLoaded(false);
        if (response.data.status == "SUCCESS") {
          const args = {
            message: 'Success',
            description:
              'Resolve predict has been sent successfully',
          };
          notification.success(args)
        }
        else {
          setNotify(false);
          setPredictModalVisible(false);
          const args = {
            message: 'Failed',
            description:
              response.data.error.message,
            // duration: 0,
          };
          notification.error(args)
        }
      })
      .catch((error) => {
        setLoaded(false);
        setNotify(false);
        setPredictModalVisible(false);
        const args = {
          message: 'Failed',
          description:
            'Predict Resolve Failed, Please try again later.',
          // duration: 0,
        };
        notification.error(args)
      })
  };
  const handleOk = async (arg, e) => {

    setLoaded(true);
    setIsModalBetVisible(false);
    const title = "Are you sure to resolve bets of " + globalPageInfo.matchtitle + " (" + matchrow.matchId + ") ?";
    setModaltitle(title);
    const body = {
      "tournament": globalPageInfo.tournament,
      "season": globalPageInfo.tyear,
      "match_id": matchrow.matchId,
      "season_type": seasonType,
      "appId": `${currentTournamentDetails.shortCode}-ops`
    }
    const response = await axios.post(
      `${domain}/betops/bet/resolve/v3.0`,
      body)
      .then((response) => {
        setLoaded(false);
        if (response.data.status == "SUCCESS") {
          const args = {
            message: 'Success',
            description:
              'Resolve Bet has been sent successfully',
          };
          notification.success(args)
        }
        else {
          setNotify(false);
          const args = {
            message: 'Failed',
            description:
              response.data.error.message,
            // duration: 0,
          };
          notification.error(args)
        }
      })
      .catch((error) => {
        setLoaded(false);
        setNotify(false);
        const args = {
          message: 'Failed',
          // description:
          //   'Bet Resolve Failed, Please try again later.',
          description:
            'Bet Resolve Failed, Please check with valid data.',
          // duration: 0,
        };
        notification.error(args)
      })
  };
  const handleResolveFantasyOk = async (arg, e) => {
    setLoaded(true);
    setFantasyModalVisible(false);
    const body = {
      "tournament": globalPageInfo.tournament,
      "season": globalPageInfo.tyear,
      "match_id": matchrow.matchId,
      "game_provider": arg,
      "season_type": seasonType,
      "appId": `${currentTournamentDetails.shortCode}-ops`
    }

    if (currentTournamentDetails.shortName === "IPL") {
      body.game_provider = "IPL";
    }

    const response = await axios.post(
      `${domain}/betops/fantasy/resolve/v3.0`, body)
      .then((response) => {
        console.log(response)
        setLoaded(false);
        if (response.data.status == "SUCCESS") {
          const args = {
            message: 'Success',
            description:
              'Resolve Fantasy has been sent successfully',
          };
          notification.success(args)
        }
        else {
          setNotify(false);
          const args = {
            message: 'Failed',
            description:
              response.data.error.message,
            // duration: 0,
          };
          notification.error(args)
        }
      })
      .catch((error) => {
        setLoaded(false);
        setNotify(false);
        const args = {
          message: 'Failed',
          description:
            'Fantasy Resolve Failed, Please try again later.',
          // duration: 0,
        };
        notification.error(args)

      })

  }
  const fetchTeams = async () => {
    const teamsCol = collection(db, "teams");
    const teamsSnapshot = await getDocs(teamsCol);
    const teamList = teamsSnapshot.docs.map((doc) => doc.data());


    let activeTeamList = [];
    let tempTeamList;
    for (let i = 0; i < teamList.length; i++) {
      if (teamList[i].active == true) {
        tempTeamList = teamList[i];
        activeTeamList.push(tempTeamList);
      }
    }



    setTeams(activeTeamList);
    const docRef = doc(db, "configuration", "admin_console");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSeasonTypeList(docSnap.data()["seasonType"]);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const getMatches = async (tabType, tourValue, seasonValue, seasonTypeValue) => {
    if (tabType == "upcoming") {
      setLoaded(true);
      const response = await axios({
        method: 'get',
        url: `${domain}/betops/match/get/upcoming-match-list/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${tourValue}&season=${seasonValue}&seasonType=${seasonTypeValue}`,
        headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
      }).then((response) => {
        if (response.data.status === "SUCCESS") {
          fetchSchedule(response.data.data.matchList);
        }
        else {
          if (response.data.error.message == "Auth token is not valid") {
            Modal.error({
              content: response.data.error.message,
              onOk() {
                logout();
              }
            });
          } else {
            Modal.error({
              content: response.data.error.message
            });
          }
        }
      })
        .catch((error) => {
          Modal.error({
            content: "Unable to process your Request, Please try again later."
          });
        })
      setLoaded(false);
    } else if (tabType == "all") {
      setLoaded(true);
      const response = await axios({
        method: 'get',
        url: `${domain}/betops/match/get/all-match-list/v1?tournament=${tourValue}&season=${seasonValue}&seasonType=${seasonTypeValue}`,
      }).then(async (response) => {
        if (response.data.status === "SUCCESS") {
          fetchSchedule(response.data.data.matchList);
        } else {
          setLoaded(false);
        }
      })
        .catch((error) => {
          setLoaded(false);
        })
    }
  }

  const fetchSchedule = async (finaldatas) => {
    const scheduleCol = collection(db, "schedule");
    const scheduleSnapshot = await getDocs(scheduleCol);
    const resultMatch = [];
    const result = scheduleSnapshot.docs.map((doc) => {
      resultMatch.push({ documentID: doc.id });
    });

    console.log(3333333333333333, resultMatch)


    // let docIdMap = finaldatas.map((item, i) => Object.assign({}, item, resultMatch[i]));
    //   const finaldata = finaldatas
    // .filter(item => item.tournament == tournamentValue && item.year == seasonyearValue && item.seasonType == seasonType && item.docType === "SCHEDULE")
    // .map(function (item) {
    //   return item;
    // });
    const scheduleData = finaldatas;

    // const insightsData = arr32
    //   .filter(item => item.tournament == tournamentValue && item.year == seasonyearValue && item.seasonType == seasonType && item.docType == "INSIGHTS")
    //   .map(function (item) {
    //     return item;
    //   });

    // setInsightdata(insightsData);
    const tableData = [];

    const finaldata1 = scheduleData.map(function (element, mid) {
      tableData.push({
        startdate: element.begins,
        tyear: element.year,
        matchtitle: element.rt.team + " vs " + element.ht.team,
        // tourID: element.tourID,
        matchno: element.matchId.slice(4, element.matchId.length),
        tournament: element.tournament,
        tournamenttitle: element.tournamentTitle,
        hticon: element.ht.ic,
        ht: element.ht.team,
        rt: element.rt.team,
        matchid: element.matchId,
        rticon: element.rt.ic,
        res: element.res,
        infoHt: element.infoHt,
        winMetric: element.winMetric,
        winProbHt: element.winProbHt,
        winProbRt: element.winProbRt,
        resHt: element.resHt,
        nextText: element.nextText,
        superOver1: element.superOver1,
        resultSummary: element.resultSummary,
        superOver2: element.superOver2,
        infoRt: element.infoRt,
        matchTs: element.matchTs,
        gameResult: element.gameResult,
        stage: element.stage,
        ballsRem: element.ballsRem,
        group: element.group,
        resRt: element.resRt,
        winBy: element.winBy,
        winner: element.winner,
        documentID: element.documentID,
        mtype: element.mtype,
        hw: element.hw,
        wk: element.wk,
        rw: element.rw,
        ri: element.ri,
        locMatch: element.locMatch,
        stadium: element.stadium,
        matchType: element.matchType,
        ic: element.ic,
        startTs: element.startTs,
        endTs: element.endTs
      });
    });

    // var array3 = finaldata;
    // const matchdata = [];
    // for (var i = 1; i <= finaldata.length; i++) {
    //   matchdata.push({
    //     key: i,
    //     matchId: finaldata.matchId,
    //     ht: finaldata.ht,
    //     rt: finaldata.rt,
    //     winner: finaldata.winner,
    //   });
    // }

    // const datas = arr32
    //   .filter(item => item.tournament == tournamentValue)
    //   .map(function (item) {
    //     return item;
    //   });

    // const arr1s = getUniqueListBy(finaldata, "year");
    // setMatchyear(arr1s);
    // const arr1 = getUniqueListBy(finaldata, 'matchid')

    // let sorted = [...arr1].sort((a, b) => (a.matchid > b.matchid ? 1 : -1))
    // sorted = getUniqueListBy(sorted, 'matchid')
    setMatchDetails(tableData);
    setMatchDetails1(tableData);


    // var array3 = tableData.concat(playoffData);
    // const matchdata = [];
    // for (var i = 1; i <= tableData.length; i++) {
    //   matchdata.push({
    //     key: i,
    //     matchId: tableData.matchId,
    //     ht: tableData.ht,
    //     rt: tableData.rt,
    //     winner: tableData.winner,
    //   });
    // }
    // const datas = arr32
    //   .filter(item => item.tournament == tournamentValue)
    //   .map(function (item) {
    //     return item;
    //   });

    // const arr1s = getUniqueListBy(datas, "year");
    // setMatchyear(arr1s);
    // const arr1 = getUniqueListBy(array3, 'matchid')

    // let sorted = [...arr1].sort((a, b) => (a.matchid > b.matchid ? 1 : -1))
    // sorted = getUniqueListBy(sorted, 'matchid')
    // setMatchDetails(sorted);
    // setMatchDetails1(arr1);
    setLoaded(false);
  }

  const handleTournament = async (e) => {
    const response2 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${e}`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        let config21 = response.data.data.yearsList;
        setDbSeasonList(config21);

        let config31 = response.data.data.seasonsList;
        setDbSeasonTypeList(config31);
      }
      else {
      }
    }).catch((error) => {
    })

    setTournamentValue(e);
    getMatches(matchlistTabType, e, seasonyearValue, seasonType);
  }

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };
  const notify = async (matchid) => {

    setMatchrow(matchid);
    setIsModalVisible(true);
    const title = "Are you sure send bet notification to " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
  };
  const notifyRes = async (matchid) => {

    setMatchrow(matchid);
    setIsModalNotificationVisible(true);
    const title = "Are you sure send notification to " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
  };
  const resolveFantasy = async (matchid) => {

    setMatchrow(matchid);
    const title = "Are you sure to resolve fantasy of " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
    setFantasyModalVisible(true);
  };
  const resolvePredict = async (matchid) => {
    setMatchrow(matchid);
    const title = "Are you sure to resolve Predict of " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
    setPredictModalVisible(true);
  };
  const resolveBet = async (matchid) => {

    setMatchrow(matchid);
    const title = "Are you sure to resolve bets of " + matchid.matchtitle + " (" + matchid.matchid + ") ?";
    setModaltitle(title);
    setIsModalBetVisible(true);
  };
  const sortArray = (type) => {

    const types = {
      matchid: "matchid",
      tournamenttitle: "tournamenttitle",
      matchno: "matchno",
      ht: "ht",
      rt: "rt",
    };
    const sortProperty = types[type];
    const sorted = [...matchdetails].sort((a, b) => {
      return b[sortProperty] - a[sortProperty];
    });

    setMatchDetails(sorted);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    sortArray(sorter.field);
    if (sorter.order == undefined) {
      setMatchDetails(matchdetails.reverse());
    } else if (sorter.order == "descend") {
      sortArray(sorter.field);
    } else {
      setMatchDetails(matchdetails.reverse());
    }
  };

  const handleSeasonYear = (e) => {
    getMatches(matchlistTabType, tournamentValue, e, seasonType);
    setSeasonyearValue(e);
  };

  const handleSeasonType = (e) => {
    setSeasonType(e);
    getMatches(matchlistTabType, tournamentValue, seasonyearValue, e);
  }

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = matchDetails1.filter((item) => {
        return Object.keys(item).some((key) => {
          if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
          return false;
        });
      });

      let sorted = [...filteredData].sort((a, b) => (a.matchid > b.matchid ? 1 : -1))
      sorted = getUniqueListBy(sorted, 'matchid')
      setMatchDetails(sorted);
    } else {
      getMatches(matchlistTabType, tournamentValue, seasonyearValue, seasonType);
    }
  };
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const getSpecificMatchInfo = async (id, action) => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/match/get-match/v2.0?appId=${currentTournamentDetails.shortCode}-ops&tournament=${tournamentValue}&season=${seasonyearValue}&seasonType=${seasonType}&matchId=${id}`,
      headers: {"Authorization" : `Bearer ${localStorage.getItem("aT")}`}
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        if(action == "view") {
          setViewDrawerInfo(response.data.data);
          setVisible(true);
        } else {
          setEditPageInfo(response.data.data);
          setMatchInfoPage(true);
        }
      }
      else {
        if(response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  }

  const newPage = async (arg) => { 
    setGlobalPageInfo(arg);
    await getSpecificMatchInfo(arg.matchid, "edit");
  }

  const handleBack = () => {
    setMatchInfoPage(false)
  }
  const changeResolve = (e, arg) => {

    let data = resolveArray
    if (arg.target.checked) {
      data.push(e)
    }
    else {
      data = data.filter(list => list !== e)
    }
    setResolveArray(data)
  }
  const changeReward = (e, arg) => {

    let data = RewardArray
    if (arg.target.checked) {
      data.push(e)
    }
    else {
      data = data.filter(list => list !== e)
    }
    setRewardArray(data)
  }

  const openPopover = () => {
    setOpen(true);
  }

  const getCurrentTabKey = (e) => {
    if (e == 1) {
      setMatchlistTabType("upcoming");
      getMatches("upcoming", tournamentValue, seasonyearValue, seasonType);
    } else {
      setMatchlistTabType("all");
      getMatches("all", tournamentValue, seasonyearValue, seasonType);
    }
  }

  const handleVisibleChanges = () => {
    setOpen(!open);
  }

  const sportConfigSetting = () => {
    let config11 = localStorage.getItem("sportsList")
    let config12 = config11.split(",");
    setDbTournamentList(config12);

    let config21 = localStorage.getItem("sportYearsList")
    let config22 = config21.split(",");
    setDbSeasonList(config22);

    let config31 = localStorage.getItem("sportSeasonsList")
    let config32 = config31.split(",");
    setDbSeasonTypeList(config32);
  }

  useEffect(() => {
    if (loading) return;
  }, [user, loading]);
  useEffect(async () => {
    fetchTeams();
    await getMatches(matchlistTabType, tournamentValue, seasonyearValue, seasonType);
    sportConfigSetting();
  }, []);
  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <>
        <Sidermenu menu="matchdetails" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                {MatchInfoPage ? <div>
                  <a href="#" style={{ textDecoration: "auto" }} onClick={handleBack}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-square-fill text-danger" viewBox="0 0 16 16">
                    <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
                  </svg><span className="text-primary" style={{ margin: "14px" }}> {globalPageInfo.tournamenttitle + " " + globalPageInfo.matchtitle}
                    </span>
                  </a>
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Match Info" key="1">
                      <MatchTabView tournament={tournamentValue} season={seasonyearValue} seasonType={seasonType} globalInfo={globalPageInfo} matchid={editPageInfo} handleBack={handleBack} getMatches={getMatches} tabType={matchlistTabType} />
                    </TabPane>
                    <TabPane tab="Fantasy Info" key="2">
                      <FantasyInfo tournament={tournamentValue} globalInfo={globalPageInfo} matchid={globalPageInfo} handleBack={handleBack} />
                    </TabPane>
                    <TabPane tab="Bet Info" key="3">
                      <BetsInfo globalInfo={globalPageInfo} matchid={globalPageInfo} matchValue={seasonyearValue} handleBack={handleBack} />
                    </TabPane>
                  </Tabs>
                </div> : ""}
                <div style={MatchInfoPage ? { display: "none" } : { display: "block" }}>
                  <PageHeader
                    className="site-page-header"
                    title="Matches"
                    extra={
                      <Space direction="horizontal">
                        {" "}
                        <Search placeholder="Search here" onChange={onSearch} enterButton />
                        <Select size="default" value={tournamentValue} placeholder="All Tournaments" style={{ width: "200px" }} onChange={(e) => handleTournament(e)}>
                          {dbTournamentList.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}{" "}
                            </Option>
                          ))}
                        </Select>
                        <Select size="default" value={seasonyearValue} style={{ width: "200px" }} onChange={(e) => handleSeasonYear(e)}>
                          {dbSeasonList.map((name, pos) => {
                            return (
                              <Option key={pos} value={name}>
                                {name}{" "}
                              </Option>
                            );
                          })}
                        </Select>
                        <Select disabled={currentTournamentDetails.shortName == "IPL"} size="default" value={seasonType} style={{ width: "200px" }} onChange={(e) => handleSeasonType(e)}>
                          {dbSeasonTypeList.map((name, pos) => {
                            return (
                              <Option key={pos} value={name}>
                                {name}{" "}
                              </Option>
                            );
                          })}
                        </Select>
                        <Popover
                          content={() => showContent()}
                          trigger="click"
                          placement="bottomRight"
                          onOpenChange={handleVisibleChanges}
                          open={open}
                        >
                          <DashOutlined onClick={() => openPopover()} />
                        </Popover>
                      </Space>
                    }
                  />
                  <Tabs defaultActiveKey="1" onChange={getCurrentTabKey}>
                    <TabPane tab="Upcoming Matches" key="1">


                    </TabPane>
                    <TabPane tab="All Matches" key="2">


                    </TabPane>
                  </Tabs>

                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    dataSource={matchdetails}
                    onChange={handleTableChange}
                    scroll={{ y: "250" }}
                  >
                    <Column title="MatchID" dataIndex="matchid" key="matchid" sorter="true" />
                    <Column title="Match Title" dataIndex="matchtitle" key="matchtitle" />
                    <Column title="Tournament" dataIndex="tournamenttitle" key="tournamenttitle" sorter="true" />
                    <Column
                      title="Match Date"
                      dataIndex="matchTs"
                      key="matchTs"
                      render={(text, record) => (
                        <Space size="middle">
                          {text !== undefined ? (
                            <Moment format="DD-MM-YYYY hh:mm a">
                              {text}
                            </Moment>
                          ) : (
                            ""
                          )}
                        </Space>
                      )}
                    />
                    <Column title="Match No" dataIndex="matchno" key="matchno" sorter="true" />
                    <Column title="Road Team" dataIndex="rt" key="rt" sorter="true" />
                    <Column title="Home Team" dataIndex="ht" key="ht" sorter="true" />

                    <Column
                      title="Quick Actions"
                      key="matchId"
                      render={(matchId) => (
                        <Space size="middle">
                          {matchId.matchid !== undefined ? (
                            <>
                              <EyeOutlined onClick={() => showDrawer(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                              <EditTwoTone onClick={() => newPage(matchId)} title="Match Info" style={{ fontSize: '18px', color: '#08c' }} />
                            </>
                          ) : (
                            ""
                          )}
                        </Space>
                      )}
                    />
                  </Table>
                </div>
              </Card>
            </div>
            <Modal title="Bet Notification" onCancel={handleCancel} visible={isModalVisible}
              footer={[
                <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  loading={loading}
                  onClick={notifyBetResult}
                >
                  Ok
                </Button>
              ]}
            >
              {modaltitle}
            </Modal>
            <Modal title="Notification" onCancel={handleCancel} visible={isModalNotificationVisible}
              footer={[
                <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  loading={loading}
                  onClick={notifyResult}
                >
                  Ok
                </Button>
              ]}
            >
              {modaltitle}
            </Modal>



            {currentTournamentDetails.shortName === "NFL" ? (
              <>
                <Modal title="Resolve Bet" onCancel={handleCancel} visible={isModalBetVisible}
                  footer={[
                    // <Checkbox onChange={() => setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleOk.bind(this, "DK")}
                    >
                      DK Resolve
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleOk.bind(this, "YH")}
                    >
                      YH Resolve
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleOk.bind(this, "FD")}
                    >
                      FD Resolve
                    </Button>
                  ]}
                >
                  {modaltitle}
                </Modal>
                <Modal title="Resolve Fantasy" onCancel={handleCancel} visible={isFantasyModalVisible}
                  footer={[
                    // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleResolveFantasyOk.bind(this, "DK")}
                    >
                      DK Resolve
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleResolveFantasyOk.bind(this, "YH")}
                    >
                      YH Resolve
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleResolveFantasyOk.bind(this, "FD")}
                    >
                      FD Resolve
                    </Button>
                  ]}

                >
                  {modaltitle}
                </Modal>
                {ResolveMatch ? <Modal title="Resolve" onCancel={handleCancel} visible={ResolveMatch}
                  footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={resolveMatchSubmit.bind(this, "DK")}
                    >
                      DK Resolve
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={resolveMatchSubmit.bind(this, "YH")}
                    >
                      YH Resolve
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={resolveMatchSubmit.bind(this, "FD")}
                    >
                      FD Resolve
                    </Button>
                  ]}
                >
                  <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <Checkbox onChange={changeResolve.bind(this, "Bet")}>Bet</Checkbox>
                      <Checkbox onChange={changeResolve.bind(this, "Fantasy")}>Fantasy</Checkbox>
                      <Checkbox onChange={changeResolve.bind(this, "Predict")}>Predict</Checkbox>
                    </Col>
                  </Row>
                  {validation ? <span className="text-danger">{validation}</span> : ""}
                </Modal> : ""}
              </>
            ) : (
              <>
                <Modal title="Resolve Bet" onCancel={handleCancel} visible={isModalBetVisible}
                  footer={[
                    // <Checkbox onChange={() => setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleOk.bind(this, null)}
                    >
                      Resolve
                    </Button>
                  ]}
                >
                  {modaltitle}
                </Modal>

                <Modal title="Resolve Fantasy" onCancel={handleCancel} visible={isFantasyModalVisible}
                  footer={[
                    // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={handleResolveFantasyOk.bind(this, null)}
                    >
                      Resolve
                    </Button>
                  ]}

                >
                  {modaltitle}
                </Modal>

                {
                  ResolveMatch ? <Modal title="Resolve" onCancel={handleCancel} visible={ResolveMatch}
                    footer={
                      [
                        <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                          Cancel
                        </Button>,
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={resolveMatchSubmit.bind(this, null)}
                        >
                          Resolve
                        </Button>
                      ]
                    }
                  >
                    < Row gutter={24}>
                      <Col span={24} style={{ textAlign: "left" }}>
                        <Checkbox onChange={changeResolve.bind(this, "Bet")}>Bet</Checkbox>
                        <Checkbox onChange={changeResolve.bind(this, "Fantasy")}>Fantasy</Checkbox>
                        <Checkbox onChange={changeResolve.bind(this, "Predict")}>Predict</Checkbox>
                      </Col>
                    </Row>
                    {validation ? <span className="text-danger">{validation}</span> : ""}
                  </Modal> : ""}
              </>
            )}






            <Modal title="Predict Match" onCancel={handleCancel} visible={PredictModalVisible}
              footer={[
                // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                //   Notify All Players
                // </Checkbox>,
                <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  loading={loading}
                  onClick={handlePredict}
                >
                  Ok
                </Button>
              ]}

            >
              {modaltitle}
            </Modal>

            {RewardMatch ? <Modal title="Notification" onCancel={handleCancel} visible={RewardMatch}
              footer={[
                <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  loading={loading}
                  onClick={RewardMatchSubmit.bind(this, "DK")}
                >
                  Send
                </Button>,
              ]}
            >
              <Row gutter={24}>
                <Col span={24} style={{ textAlign: "left" }}>
                  <Checkbox onChange={changeReward.bind(this, "Bet")}>Bet</Checkbox>
                  <Checkbox onChange={changeReward.bind(this, "Fantasy")}>Fantasy</Checkbox>
                  <Checkbox onChange={changeReward.bind(this, "Predict")}>Predict</Checkbox>
                </Col>
              </Row>
              {validation ? <span className="text-danger">{validation}</span> : ""}
            </Modal> : ""}
            <Drawer title="Resolve and Notification" placement="right" onClose={onClose} visible={visible} className="matchcard">         
              <Editmatch tournament={tournamentValue} season={seasonyearValue} seasonType={seasonType} matchid={viewDrawerInfo} globalInfo={globalPageInfo} RewardSubmit={RewardSubmit} resolveSubmit={resolveSubmit} resolvePredict={resolvePredict} PredictMatchReward={PredictMatchReward} skillBetReward={skillBetReward} FantasyReward={FantasyReward} resolveFantasy={resolveFantasy} resolveBet={resolveBet} notifyRes={notifyRes} notifyResult={notifyResult} notify={notify} onClose={onClose} fetchdata={fetchSchedule} insightdata={insightdata} />
            </Drawer>

            {normalDrawertype == "bulkupdate" ? (
              <Drawer title={title} placement="right" onClose={onClose} visible={normalDrawervisible}>
                <ScheduleBulkupdate tournament={tournamentValue} season={seasonyearValue} seasonType={seasonType} doctype="SCHEDULE" schedulereload={getMatches} onClose={onClose} />
              </Drawer>
            ) : (
              <></>
            )}

            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}
          </Content>
          <Footersection />
        </Layout>
      </>
    </Layout >
  );
}
export default Matchdetails;
