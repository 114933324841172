import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import ReactJson from "react-json-view";
import Moment from "react-moment";
import axios from "axios";
import { domain } from "../../configuration/ApiConfiguration";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Asset(props) {
  const [infoform] = Form.useForm();

  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);

  const [schedule, setSchedule] = useState([]);
  const [tempSchedule, setTempSchedule] = useState([]);
  const [title, setTitle] = useState("");
  const [info2, setInfo2] = useState([]);
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("Add New Asset");
    setVisible(true);
  };
  const showDrawer = (matchid, index) => {
    setCurrentindex(index);
    console.log(matchid);
    infoform.setFieldsValue({
      asset: matchid.asset,
      path: matchid.path,
    });
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.asset);
    setVisible(true);
  };
  const deleterecord = async (matchid, index1) => {
    const arraycheck = tempSchedule;
    arraycheck.asset = Object.keys(arraycheck.asset).reduce(function (obj, key) {
      if (key != matchid.asset) {
        obj[key] = arraycheck.asset[key];
      }
      return obj;
    }, {});
    setTempSchedule(arraycheck);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };

  const onClose = () => {
    infoform.resetFields();
    setVisible(false);
  };

  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };

  const fetchDashboard = async () => {
    let data = null;
    if(tempSchedule.asset) {
      data = tempSchedule;
    } else {
      data = props.docdata;
      setTempSchedule(props.docdata);
    }
    let tabarray = [];
    let datasource = [];
    let columns = [];
    Object.keys(data.asset).map((key) =>
      tabarray.push({
        key: key,
        value: data.asset[key],
      })
    );
    tabarray.forEach((item, index) => {
      datasource.push({
        asset: item.key,
        path: item.value,
        key: index + 1,
      });
    });
    setDatasource(datasource);
  };

  const onInfofinish = async (values) => {
    let filtered = datasource.filter(function (value, index, arr) {
      return index == currentindex;
    });
    const arraycheck = tempSchedule;
    let assettitle = values.asset;
    if (title === "Add New Asset") {
      Object.assign(arraycheck.asset, {
        [assettitle]: values.path,
      });
    } else {
      arraycheck.asset = Object.keys(arraycheck.asset).reduce(function (obj, key) {
        if (key == filtered[0].asset) {
          obj[assettitle] = values.path;
        } else {
          obj[key] = arraycheck.asset[key];
        }
        return obj;
      }, {});
    }
    setTempSchedule(arraycheck);
    // Modal.success({
    //   content: title === "Add New Asset" ? "New Asset Added Successfully" : "Updated Successfully",
    //   onOk() {
    //     onClose();
    //     fetchDashboard();
    //   },
    // });
    onClose();
    fetchDashboard();
  };

  const finaldocsave = async () => {
    setLoaded(true);
    const response = await axios({
      method: 'post',
      url: `${domain}/betops/config/get-update-appConfig/v1`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        "appId": `${currentTournamentDetails.shortCode}-ops`,
        "asset": tempSchedule.asset
      },
    }).then((response) => {
      setLoaded(false);
      Modal.success({
        content: "Saved Successfully",
        onOk() {
          window.location.reload(true);
        },
      });
    })
      .catch((error) => {
        setLoaded(false);
      })
  }
  
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <div>
      <Space direction="horizontal" className="rightspace">
        {" "}
        <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
        <a onClick={() => addnew()}>
          <span className="addschedule">Add New</span>
        </a>
      </Space>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              fetchIndex(rowIndex);
            }, // click row
          };
        }}
        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
        dataSource={datasource}
        scroll={{ y: "340" }}
      >
        <Column title="Asset" dataIndex="asset" key="asset" />
        <Column title="Path " dataIndex="path" key="path" />

        <Column
          title="Quick Actions"
          key="notifId"
          render={(notifId, record, index) => (
            <Space key={index} size="middle">
              <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
              <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
            </Space>
          )}
        />
      </Table>

      <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="small">
        <Form name="horizontal_login" layout="vertical" form={infoform} onFinish={onInfofinish}>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Asset" name="asset" rules={[{ required: true, message: "Input your Asset!" }]}>
                <Input placeholder="Input Your Asset" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Path" name="path" rules={[{ required: true, message: "Input your Path!" }]}>
                <Input placeholder="Input your Path" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  {title === "Add New Asset" ? "Add" : "Update"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
      <Button type="primary" className="configEndbtn" onClick={finaldocsave}>
Save
</Button>
    </div>
  );
}

export default Asset;
