import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Select, Layout, Card, Row, Collapse, Col, Button, Modal, Drawer, Form, Input, Divider, Spin } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import "antd/dist/antd.min.css"
const { Option } = Select;
const { Panel } = Collapse;
function Editmatch(props) {
  const [loaded, setLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [gameresult, setGameresult] = useState(props.matchid.gameResult);
  const [winner, setWinner] = useState("");
  const [winnerresult, setWinnerresult] = useState("");
  const [form] = Form.useForm();
  const [insightform] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [insightresult, setInsightresult] = useState([]);
  const [panelhead, setPanelhead] = useState([]);
  const [panelkey, setPanelkey] = useState([]);
  const [rtTeamLogo, setRtTeamLogo] = useState(null);
  const [htTeamLogo, setHtTeamLogo] = useState(null);
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const handleChange = (e) => {
    setSelectedOption(e);
    if (e == "ht") {
      setWinner(props.matchid.ht);
      setWinnerresult(1);
      setGameresult("WIN");
    } else if (e == "rt") {
      setWinner(props.matchid.rt);
      setWinnerresult(-1);
      setGameresult("LOSS");
    } else if (e == "TIE") {
      setWinner("TIE");
      setWinnerresult(2);
      setGameresult("TIE");
    } else if (e == "CANCELLED") {
      setWinner("CANCELLED");
      setWinnerresult(-2);
      setGameresult("CANCELLED");
    } else if (e == "NOT YET RESULT UPDATED") {
      setWinner("NOT YET RESULT UPDATED");
      setWinnerresult(0);
      setGameresult("NOT YET RESULT UPDATED");
    }
  };
  const showDrawer = () => {
    setPanelkey([]);
    let checkarray = [];
    props.insightdata.map(function (element, mid) {
      element.matchList.map(function (element1, mid1) {
        if (element1.matchId == props.matchid.matchid) {
          checkarray.push(element1.insights);
          setInsightresult(element1.insights);
          checkarray.map(function (element2, mid2) {
            setPanelhead(element2.insightsOrder);
          });
        }
      });
    });
    checkarray.map(function (element2, mid2) {
      var dkey = "";
      for (const [key, value] of Object.entries(element2)) {
        for (const [key1, value1] of Object.entries(element2[key])) {
          dkey = key + " " + key1;
          insightform.setFieldsValue({
            [dkey]: value1,
          });
        }
      }
    });
    setVisible(true);
  };
  const onClose = () => {
    setPanelkey([]);
    insightform.resetFields();
    setVisible(false);
  };
  const loadProfile = async () => {
    await getTeamLogo(props.matchid.rt, true);
    await getTeamLogo(props.matchid.ht, false);
    setWinnerresult(props.matchid.res);
    setSelectedOption(props.matchid.winner);
    form.setFieldsValue({
      infoHt: props.matchid.resHt,
      infoRt: props.matchid.resRt,
      resultSummary: props.matchid.resultSummary === "None" ? null : props.matchid.resultSummary,
      winProbHt: props.matchid.winProbHt,
      winProbRt: props.matchid.winProbRt,
      winMetric: props.matchid.winMetric,
    });
  };
  useEffect(() => {
    loadProfile({});
  }, [props]);
  const callback = (key) => {
    let pkey = [];
    pkey.push(key);
    console.log(pkey);
    setPanelkey(key);
  };

  const getTeamLogo = async (val, roadTeam) => {
    let appId = null;
    if (currentTournamentDetails.shortName == 'NFL') {
        appId = "fc-web";
    } else {
        appId = "dt20-web";
    }
    fetch(`${domain}/betops/asset/get-by-name/v1?appId=${appId}&assetType=team&assetName=${val}`, {
        method: "get"
    })
        .then(data => {
            if (roadTeam) {
                setRtTeamLogo(data.url);
            } else {
                setHtTeamLogo(data.url);
            }
        }).catch(error => {
        });
}

  const oninsightFinish = async (values) => {
    setLoaded(true);
    let obj = {};
    const objectArray = Object.entries(values);
    panelkey.map((item, i) => {
      let finalArray = [];
      let j = 0;
      objectArray.forEach(([key, value]) => {
        console.log(j);
        var array1 = key.split(/[ ]+/);
        if (item == array1[0]) {
          finalArray.push({
            [array1[1]]: value,
          });

          obj[item] = Object.assign({}, ...finalArray);
        }
      });
    });

    const matchDocRef = doc(db, "schedule", props.insightdata[0].documentID);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    let mainarray = [];
    mainarray.push(docSnap.data());
    mainarray.forEach(function (item, index) {
      item.matchList.forEach(function (item1, index1) {
        if (item1.matchId == props.matchid.matchid) {
          for (const [key, value] of Object.entries(item1.insights)) {
            for (const [key1, value1] of Object.entries(obj)) {
              if (key == key1) {
                item1.insights[key1] = value1;
              }
            }
          }
        }
      });
    });
    let obj1 = {};
    mainarray.forEach(function (val, i) {
      obj1 = {
        val,
      };
    });
    const res = await updateDoc(matchDocRef, obj1.val);
    Modal.success({
      content: "Updated Successfully",
      onOk() {
        setLoaded(false);
        onClose();
        props.fetchdata();
      },
    });
  };
  const onFinish = async (values) => {
    setLoaded(true);
    props.matchid.winProbHt = values.winProbHt;
    props.matchid.winProbRt = values.winProbRt;
    props.matchid.winMetric = values.winMetric;
    props.matchid.resRt = values.infoRt;
    props.matchid.resHt = values.infoHt;
    props.matchid.resultSummary = values.resultSummary;
    props.matchid.winner = selectedOption;

    let editedObj = props.matchid;

    const response = await axios({
      method: 'post',
      url: `${domain}/betops/match/update-match/v1`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        "tournament": props.tournament,
        "season": props.season,
        "seasonType": props.seasonType,
        "docType": "SCHEDULE",
        "data": editedObj
      },
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        setLoaded(false);
        Modal.success({
          content: "Updated Successfully",
          onOk() {
            props.onClose();
            props.fetchdata();
          },
        });
      } else {
        setLoaded(false);
      }
    })
      .catch((error) => {
        setLoaded(false);
      })
  };
  const title = props.globalInfo.tournamenttitle + " | Game " + props.globalInfo.matchno;
  return (
    <>
      <Card size="small" title={title} style={{ width: 300 }}>
        <Row gutter={24}>
          <Col span={13} className="rtside">
            <span>
              <img src={rtTeamLogo} width={"25px"} />
            </span>
            <span className="teamname">{props.matchid.rt} </span>
          </Col>
          <Col span={11}>
            <span className="infolabel">{props.matchid.resRt}</span>
          </Col>
        </Row>
        <Row gutter={24} className="mtop">
          <Col span={13} className="htside">
            <span>
              <img src={htTeamLogo} width={"25px"} />
            </span>
            <span className="teamname">{props.matchid.ht} </span>
          </Col>
          <Col span={11}>
            <span className="infolabel">{props.matchid.resHt}</span>
          </Col>
        </Row>

        <Divider className="divider" />
        <Row gutter={24} className="mtop">
          <Col span={24}>
            <p className="matchsummary">{props.matchid.resultSummary}</p>
          </Col>
        </Row>
      </Card>
       <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
       {/* <Row gutter={24}>
          <Col span={20}>
            <p>
              <img src={props.matchid.rticon} width={"25px"} /> <span className="teamname">{props.matchid.rt} </span>
            </p>
          </Col>
          <Col span={4}>
            <span>
              <img src="images/visitor.svg"></img>
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item name="infoRt" label="Info" rules={[{ required: true, message: "Please input your Runs!" }]}>
              <Input placeholder="Runs" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item name="winProbRt" label="Winning Probability" rules={[{ required: true, message: "Please input your Winning Probability!" }]}>
              <Input placeholder="Probability" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={20}>
            <p>
              <img src={props.matchid.hticon} width={"25px"} /> <span className="teamname"> {props.matchid.ht}</span>{" "}
            </p>
          </Col>
          <Col span={4}>
            <span>
              <img src="images/host.svg"></img>
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item name="infoHt" label="Info" rules={[{ required: true, message: "Please input your Runs!" }]}>
              <Input type="text" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item name="winProbHt" label="Winning Probability" rules={[{ required: true, message: "Please input your Winning Probability!" }]}>
              <Input placeholder="Probability" />
            </Form.Item>{" "}
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin="0" className="dividertext">
          <h4>Match Results</h4>
        </Divider>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item name="winMetric" label="Winning Metric" rules={[{ required: true, message: "Please input your Metric!" }]}>
              <Input placeholder="Metric" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item name="resultSummary" label="Results Summary" rules={[{ required: true, message: "Please input your Summary!" }]}>
              <Input placeholder="Summary" />
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <p className="mainlabel"> Win Result</p>
            <Select value={selectedOption} style={{ width: "100%" }} onChange={(e) => handleChange(e)}>
              <Option key="1" value="ht">
                {props.matchid.ht}
              </Option>
              <Option key="2" value="rt">
                {props.matchid.rt}
              </Option>
              <Option key="3" value="TIE">
                TIE
              </Option>
              <Option key="4" value="CANCELLED">
                CANCELLED
              </Option>
              <Option key="5" value="NOT YET RESULT UPDATED">
                NOT YET RESULT UPDATED
              </Option>
            </Select>{" "}
          </Col>
        </Row> */}
        {/* <Row gutter={24} className="rtmp1"> */}
          {/* <Col span={24}> */}
            {/* <Button type="primary" className="m-1" onClick={() => showDrawer()}>
              Insights
            </Button>{" "} */}
            {/* <Button type="primary" className="m-1">
              Insights
            </Button>{" "} */}
            <center><Button type="primary" className="m-1" onClick={() => props.resolveSubmit(props.matchid)}>
              Resolve
            </Button>{" "}
            <Button type="primary" className="m-1" onClick={() => props.RewardSubmit(props.matchid)}>
              Notification
            </Button></center>{" "}
          {/* </Col> */}
        {/* </Row> */}
        {/* <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item shouldUpdate {...tailLayout}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                >
                  Update
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
      <Drawer title="Insights" placement="right" onClose={onClose} visible={visible} className="matchcard">
        <Form form={insightform} name="horizontal_login" layout="vertical" onFinish={oninsightFinish}>
          <Collapse bordered={false} activeKey={panelkey} onChange={callback}>
            {Object.entries(panelhead).map(([key, val]) => (
              <Panel header={val} key={val}>
                {Object.entries(insightresult[val]).map(([key1, value1]) => {
                  return (
                    <Row gutter={24} key={key1}>
                      <Col span={24} style={{ textAlign: "right" }}>
                        <Form.Item name={val + " " + key1} label={key1} rules={[{ required: true, message: "Please input your  " + key1 + "!" }]}>
                          <Input placeholder={key1} value={value1} />
                        </Form.Item>{" "}
                      </Col>
                    </Row>
                  );
                })}
              </Panel>
            ))}
          </Collapse>
          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Form.Item shouldUpdate {...tailLayout}>
                {() => (
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>{" "}
        ,
      </Drawer>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Editmatch;
