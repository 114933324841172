import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { Select, Spin, Layout, Input, Menu, Card, Tabs, Table, Tag, Space, Button, Modal, Drawer, Divider, PageHeader } from "antd";
import { currentTournamentDetails, db } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import AndroidDashboard from "../Modules/DasboardDetails/AndroidDashboard";
import IosDashboard from "../Modules/DasboardDetails/IosDashboard";
import Newdashboard from "./newdashboard";
import Editdashboard from "./editdashboard";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import ReactJson from "react-json-view";
import axios from "axios";
import {
  PlusCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  LoginOutlined
} from "@ant-design/icons";
import { domain } from "./ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
const { TabPane } = Tabs;
function Dashboard() {
  const [ID, setID] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [scheduleAndriod, setScheduleAndriod] = useState([]);
  const [scheduleIOS, setScheduleIOS] = useState([]);
  const [Type, setType] = useState("android");
  const [matchdetails, setMatchDetails] = useState([]);
  const [currApp, setCurrentObj] = useState({});
  const [drawertype, setDrawertype] = useState("");
  const [title, setTitle] = useState("");
  const [currentindex, setCurrentindex] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tournament, setTournament] = useState([]);
  const [keyData, setKeyData] = useState(null);
  const [sportsConfig, setSportsConfig] = useState(null);
  const [betopsConfig, setBetopsConfig] = useState(null);
  const [StatusVisible, setStatusVisible] = useState(false);

  const back = () => {
    setDrawertype("");
    setType("android");
    setMatchDetails([]);
    
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showJsonmodal = async (documentID) => {
    const matchDocRef = doc(db, "schedule", documentID.documentID);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    setJsondata(arraycheck);
    setJsonviewer(true);
  };

  const handleJson = () => {
    setJsonviewer(false);
  };

  const jsoncancel = () => {
    setJsonviewer(false);
  };

  const addnew = () => {
    // setCurrentObj({});
    // setMatchDetails([]);
    setDrawertype("Create Dashboard");
    // setTitle("Create Dashboard");
    // setVisible(true);
  };

  const showDrawer = (matchid, index1) => {
    const res = matchid.tournament;
    setTitle(res);
    setCurrentindex(index1);
    setMatchDetails(matchid);
    setDrawertype("Edit Dashboard");
    setVisible(true);
  };

  // const deleterecord = async (matchid, index1) => {

  //   setLoaded(true);
  //   let dataPayload = {
  //     "appId": currentTournamentDetails.shortCode + "-" + matchid.platform,
  //     "version": matchid.version
  //   }
  //   const response = await axios.delete(
  //     `${domain}/betops/config/dashboard/delete/v1`, { data: dataPayload })
  //     .then((response) => {

  //       setLoaded(false);
  //       if (response.data.status === "SUCCESS") {
  //         Modal.success({
  //           content: response.data.data,
  //         });
  //         setTimeout(() => {
  //           fetchDashboard()
  //         }, 2000);
  //       }
  //       else if (response.data.status === "FAIL") {
  //         Modal.error({
  //           content: response.data.data,
  //         });
  //       }
  //       else {
  //         setLoaded(false);
  //         Modal.error({
  //           content: "Unable to process your Request, Please try again later.",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       setLoaded(false);
  //       Modal.error({
  //         content: "Unable to process your Request, Please try again later.",
  //       });
  //     })
  // };

  const onClose = () => {
    setID("")
    setCurrentObj({ add: null });
    setVisible(false);
    setStatusVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      let data = schedule.filter(list => list.platform === Type);
      let filteredData = data.find(obj => obj.version === lowercasedFilter);
      if (filteredData != undefined) {
        let tempArr = [];
        tempArr.push(filteredData);
        if (Type === "android")
          setScheduleAndriod(tempArr)
        else
          setScheduleIOS(tempArr)
      } else {
        setScheduleAndriod([]);
        setScheduleIOS([]);
      }
    } else {
      fetchDashboard();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchTeams = async () => {
    const teamsCol = collection(db, "teams");
    const teamsSnapshot = await getDocs(teamsCol);
    const teamList = teamsSnapshot.docs.map((doc) => doc.data());
    setTeams(teamList);
  };

  const changeStatus = async (matchid) => {
    setID(matchid)
    setStatusVisible(true);
  };

  const fetchDashboard = async () => {
    const response = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashboard/get-all/v2?appId=${currentTournamentDetails.shortCode}-ops`
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        let IosData = [];
        let androidData = [];
        for (let i = 0; i < response.data.data.dashboardList.length; i++) {
          if (response.data.data.dashboardList[i].platform === "ios") {
            IosData.push(response.data.data.dashboardList[i])
          }
          else if (response.data.data.dashboardList[i].platform === "android") {
            androidData.push(response.data.data.dashboardList[i])
          }
        }
        setSchedule(response.data.data.dashboardList);
        setScheduleAndriod(androidData);
        setScheduleIOS(IosData);
        setKeyData(response.data.data.keyData);
        setSportsConfig(response.data.data.sportConfig);
        setBetopsConfig(response.data.data.betopsConfig);
      }
      else {
        Modal.error({
          content: response.data.error.message
        });
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  };

  const statusTheDashboard = async () => {
    setStatusVisible(false);
    setLoaded(true);
    let id = ID
    let data = {
      "appId": currentTournamentDetails.shortCode + "-ops",
      "version": id.version,
      "docType": id.docType,
      "platform": id.platform
    }
    const response = await axios.post(
      `${domain}/betops/config/dashboard/toggle-status/v1`, data)
      .then((response) => {
        onClose();
        if (response.data.status === "SUCCESS") {
          Modal.success({
            content: response.data.data,
          });
          setTimeout(async () => {
            fetchDashboard();
          }, 2000);
        }
        else if (response.data.status === "FAIL") {
          setLoaded(false);
          Modal.error({
            content: response.data.error.message,
          });
        }
        else {
          setLoaded(false);
          Modal.error({
            content: "Unable to process your Request, Please try again later.",
          });
        }
      })
      .catch((error) => {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later.",
        });
      })
  }

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    // // if (!user) return navigate("/login");
  }, [user, loading]);
  const callback = (key) => {

    setType(key)
  };
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        fetchDashboard();
      }, 2000);
    }
  }, [loaded]);
  return (

    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="dashboard" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background app-dashboard-page" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            {drawertype != "" ?
            <>
              <img onClick={back} src="images/backarrow1.png" className="backbtn"></img>      
              </>
              : ""}
            {drawertype == "Edit Dashboard" ? Type === "android1" ? <AndroidDashboard platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} /> : <IosDashboard platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} sportsconfig={sportsConfig} betopsconfig={betopsConfig} />
              : drawertype == "Create Dashboard" ? Type === "android1" ? <AndroidDashboard platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} /> : <IosDashboard platform={Type} drawertype={drawertype} matchid={matchdetails} keydata={keyData} sportsconfig={sportsConfig} betopsconfig={betopsConfig} />
                : <>
                  <div className="site-card-border-less-wrapper">
                    <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                      <PageHeader
                        className="site-page-header"
                        title="App Dashboard"
                        extra={
                          <>
                            {" "}
                            <Space direction="horizontal">
                              {" "}
                              <Search placeholder="Search here" onChange={onSearch} enterButton />
                              <a onClick={() => addnew()}>
                                <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                              </a>
                            </Space>{" "}
                          </>
                        }
                      />
                      <Tabs tabPosition="left" defaultActiveKey="android" onChange={callback}>
                        <TabPane tab="Android App" key="android">
                          <Table
                            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                            dataSource={scheduleAndriod}
                            scroll={{ y: "340" }}
                          >

                            <Column title="App Id" dataIndex="aid" key="aid" />
                            <Column title="Platform" dataIndex="platform" key="platform" />
                            <Column title="Version" dataIndex="version" key="version" />
                            <Column title="Status" dataIndex="active" key="active"
                              render={(text, record) => (
                                <Space size="middle">
                                  {text == true ? (
                                    <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                  ) : (
                                    <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                  )}
                                </Space>
                              )}
                            />



                            <Column
                              title="Quick Actions"
                              key="notifId"
                              render={(notifId, value, index) => (
                                <Space size="middle">
                                  {/* <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} /> */}
                                  <LoginOutlined onClick={() => changeStatus(notifId)} title="Change Dashboard Status" style={{ fontSize: "18px", color: "#08c" }} />
                                  <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                </Space>
                              )}
                            />
                          </Table>
                        </TabPane>
                        <TabPane tab="IOS App" key="ios">
                          <Table
                            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                            dataSource={scheduleIOS}
                            scroll={{ y: "340" }}
                          >
                            <Column title="App Id" dataIndex="aid" key="aid" />
                            <Column title="Platform" dataIndex="platform" key="platform" />
                            <Column title="Version" dataIndex="version" key="version" />
                            <Column title="Status" dataIndex="active" key="active"
                              render={(text, record) => (
                                <Space size="middle">
                                  {text == true ? (
                                    <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                  ) : (
                                    <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                  )}
                                </Space>
                              )}
                            />
                            <Column
                              title="Quick Actions"
                              key="notifId"
                              render={(notifId, value, index) => (
                                <Space size="middle">
                                  {/* <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} /> */}
                                  <LoginOutlined onClick={() => changeStatus(notifId)} title="Change Dashboard Status" style={{ fontSize: "18px", color: "#08c" }} />
                                  <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                </Space>
                              )}
                            />
                          </Table>
                        </TabPane>
                      </Tabs>
                    </Card>
                  </div>

                  <Modal title="Notification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    Are you sure you want to Notification?
                  </Modal>
                  <Modal title="Json" visible={jsonviewer} onOk={handleJson} onCancel={jsoncancel}>
                    <ReactJson src={jsondata} />
                  </Modal>

                  <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                    footer={[<>  <Button key="submit" type="primary" onClick={statusTheDashboard}>
                      Ok
                    </Button>
                      <Button key="submit" type="primary" onClick={onClose}>
                        Cancel
                      </Button></>
                    ]}
                  >
                    Are you sure you want to Change the Dashboard Status ?
                  </Modal>

                  {loaded == true ? (
                    <div className="loading-overlay">
                      <Spin size="large" />{" "}
                    </div>
                  ) : (
                    ""
                  )}</>}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Dashboard;
