import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from 'firebase/firestore';
import { Select, Layout, Menu, Card, Row, Col, Table, Tag, Space, Button, Modal, Radio, DatePicker, TimePicker, Drawer, Form, Input, Divider, Spin } from 'antd';
import { currentTournamentDetails, logout } from '../settings/config';
import React, { useState, useEffect } from 'react';
import reactCSS from 'reactcss'
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";

//import "antd/dist/antd.min.css"
import "antd/dist/antd.min.css";

import { SketchPicker } from 'react-color';
const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;

var data = require('../Jsondata/schedule.json');
const doctypearray = [
  { key: 1, value: "SCHEDULE" },
  { key: 2, value: "PLAY_OFF" },
  { key: 3, value: "INSIGHTS" }
]
function Addtournament(props) {
  const [loaded, setLoaded] = useState(false);
  const [yearlist, setYearlist] = useState([])
  const [doctype, setDoctype] = useState("Sent")
  const [datetype, setDatetype] = useState()
  const [timetype, setTimetype] = useState()
  const [visible, setVisible] = useState(false)
  const [teams, setTeams] = useState()


  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const loadProfile = () => {
    props.tournamentform.setFieldsValue({
      tournamentTitle: props.matchdetails.tournamentTitle,
      season: props.matchdetails.yearList,
      sport: props.matchdetails.sport,
      tournament: props.matchdetails.tournament
    });
  }

  useEffect(() => {
    var currentyear = new Date().getFullYear()
    const yearlist = []
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i)
    }
    setYearlist(yearlist.reverse())
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    loadProfile({});
  }, [loaded, props]);

  const handleChange = (e) => {
    if (e.target.value == "Scheduled") {
      setVisible(true)
    }
    else {
      setVisible(false)
    }
    setDoctype(e.target.value)

  };
  const onDateChange = (date, dateString) => {
    setDatetype(dateString)

  };
  const onTimeChange = (time, timeString) => {
    //console.log(timeString)
    setTimetype(timeString)

  };

  const onFinish = async (values) => {
    setLoaded(true)
    let finalObj = null;
    let dataurl = null;
    if (props.drawertype === "Add Tournament") {
      finalObj = {
        "appId": `${currentTournamentDetails.shortCode}-ops`,
        "tournament": [
          {
            tournamentName: values.tournament.toLocaleUpperCase(),
            tournamentTitle: values.tournamentTitle,
            yearList: values.season,
            sport: values.sport.toLocaleUpperCase(),
            tournament: values.tournament.toLocaleUpperCase(),
            provider: []
          }
        ]
      };
      dataurl = `${domain}/betops/tournament/config/create/v2.0`;
    }
    else {
      let val = props.schedule[props.fetchindex];
      val.tournamentName = values.tournament.toLocaleUpperCase();
      val.tournamentTitle = values.tournamentTitle;
      val.yearList = values.season;
      val.sport = values.sport.toLocaleUpperCase();
      val.tournament = values.tournament.toLocaleUpperCase();
      if (!val.provider) {
        val.provider = [];
      }
      finalObj = {
        "appId": `${currentTournamentDetails.shortCode}-ops`,
        "tournament": val
      };
      dataurl = `${domain}/betops/tournament/config/update/v2.0`;
    }

    const response = await axios({
      method: 'post',
      url: dataurl,
      headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
      data: finalObj
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        Modal.success({
          content: props.drawertype == "Add Tournament" ? 'New Tournament Added Successfully' : "Updated Successfully",
          onOk() {
            props.onClose();
            props.notification();
            sportsListAndInfoConfig();
          },
        });
      }
      else {
        if (response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
      }
    })
      .catch((error) => {
        Modal.error({
          content: "Unable to process your Request, Please try again later."
        });
      })
    setLoaded(false);
  }

  const sportsListAndInfoConfig = async () => {
    const response1 = await axios({
      method: 'get',
      url: `${domain}/betops/config/dashconfig/get-sports/v1`,
    }).then(async (response) => {
      if (response.data.status === "SUCCESS") {
        localStorage.setItem("sportsList", response.data.data);
        // await sportInfo(response.data.data[0]);
      }
      else {
      }
    }).catch((error) => {
    })
  }

  return (
    <>
      <Form form={props.tournamentform} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Form.Item
              name="sport"
              label="Sport"
              rules={[{ required: true, message: 'Please input your Sport!' }]}
            >
              <Input placeholder="Sport" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: 'left' }}>
            {props.drawertype === "Add Tournament" ?
              <Form.Item
                name="tournament"
                label="Tournament"
                rules={[{ required: true, message: 'Please input your Tournament!' }]}
              >
                <Input placeholder="Tournament" />
              </Form.Item>
              :
              <Form.Item
                name="tournament"
                label="Tournament"
                rules={[{ required: true, message: 'Please input your Tournament!' }]}
              >
                <Input placeholder="Tournament" disabled />
              </Form.Item>
            }
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Form.Item
              name="tournamentTitle"
              label="Tournament Title"
              rules={[{ required: true, message: 'Please input your Tournament Title!' }]}
            >
              <Input placeholder="Tournament Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: 'left' }}>

            <Form.Item
              name="season"
              label="Select Year"
              rules={[{ required: true, message: 'Please Select Year!' }]}
            >
              <Select size="default" mode="multiple" placeholder="Select Season" style={{ width: '100%' }}>
                {yearlist.map((item1, index1) => (
                  <Option key={index1} value={item1}>{item1} </Option>
                ))}
              </Select></Form.Item></Col>


        </Row>
        <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
              >
                {props.drawertype === "Add Tournament" ? "Add" : "Update"}
              </Button>
            </Form.Item>

          </Col>


        </Row>
      </Form>
      {loaded == true ? <div className="loading-overlay">
        <Spin size="large" /> </div>
        : ""}

    </>
  )

}

export default Addtournament;
