import EditPlayer from "./EditPlayer";
import { collection, getDocs, doc, where, query, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { Spin, Card, Row, Col, Table, Avatar, Tag, Button, Modal, Drawer, Form, Input } from "antd";
import { db, currentTournamentDetails, logout } from "../settings/config";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration"; 
import Moment from "react-moment";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import { Validator } from "jsonschema";
import {
    TableOutlined,
    EditTwoTone,
    CodepenOutlined
} from "@ant-design/icons";
import { json } from "react-router-dom";
const SCHEDULE_SCHEMA = require("../Jsondata/schema/Fantasy_schedule.json");
const { Meta } = Card;
function FantasyInfo(props) {
    const [matchInfoFilter, setMatchInfoFilter] = useState(null)
    const [matchInfo, setMatchInfo] = useState(null)
    const [documentID, setDocumentID] = useState(null)
    const [rtImg, setRtImg] = useState(null);
    const [htImg, setHtImg] = useState(null);
    const [tournament, setTournament] = useState([])
    const [playerDetails, setPlayerDetails] = useState(null)
    const [rawViews, setRawViews] = useState(false)
    const [buttonColor, setButtonColor] = useState("Text");
    const [modelShowRaw, setModelShowRaw] = useState(false)
    const [loaded, setLoaded] = useState(true);
    const [form] = Form.useForm();
    const rawView = (arg) => {
        setRawViews(arg)
        setButtonColor(arg === true ? "Raw" : "Text")
    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const handleChanges = (e) => {
        setMatchInfoFilter(JSON.parse(e.target.value))
    }
    const [visible, setVisible] = useState(false);
    useEffect(async () => {
        const scheduleCol = query(collection(db, "fantasy_schedule"), where("tournament", "==", props.matchid.tournament), where("docType", "==", "FANTASY_LINEUP_MATCH"),
            where("matchId", "==", props.matchid.matchid));
        const scheduleSnapshot = await getDocs(scheduleCol);
        scheduleSnapshot.forEach((doc) => {
            let dfdsf = doc.data()
            setMatchInfo(doc.data());
            setDocumentID(doc.id)
            setMatchInfoFilter(doc.data())
            let dd = doc.data()

        });

        setLoaded(false)
        const docRef = doc(db, "configuration", "admin_console");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setTournament(docSnap.data()["tournament"]);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }, []);

    const getPlayerImg = async (playerId, team) => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/asset/get-by-name/v2.0?appId=${currentTournamentDetails.shortCode}-ops&assetType=player&assetName=${playerId}&tournament=${props.tournament}&team=${team}`,
            headers: {"Authorization" : `Bearer ${localStorage.getItem("aT")}`}
          }).then((response) => {
            if (response.data.status === "SUCCESS") {
            //  setSchedule(response.data.data.notifList);
            }
            else {
              if(response.data.error.message == "Auth token is not valid") {
                Modal.error({
                  content: response.data.error.message,
                  onOk() {
                    logout();
                  }
                });
              } else {
                Modal.error({
                  content: response.data.error.message
                });
              }
            }
          })
            .catch((error) => {
              Modal.error({
                content: "Unable to process your Request, Please try again later."
              });
            })
          setLoaded(false);
    }

    const editPlayer = (list) => {
        setVisible(true)
        setPlayerDetails(list)
    }
    const onClose = () => {
        setVisible(false);
    };
    const handleClose = () => {
        setModelShowRaw(false)
    };
    const onFinishRaw = () => {
        setModelShowRaw(true)
    }
    const validateScheduleJson = (jsonObj) => {
        let jsonSchema = SCHEDULE_SCHEMA;
        try {
            let jsonValidator = new Validator();
            if (jsonSchema) {
                jsonValidator.attributes.contains = function validateContains(instance, schema, options, ctx) {
                    if (typeof instance !== "string") return;
                    if (schema.contains.indexOf(instance) < 0) {
                        return "does not contain the string " + JSON.stringify(schema.contains);
                    }
                };
                let _data = jsonObj || {};
                console.log(_data)
                let validate = jsonValidator.validate(_data, jsonSchema);
                if (validate.errors.length == 0) {
                    return null;
                }
                else {
                    return validate.errors;
                }
            }
        } catch (err) {
            return null;
        }
        return null;
    };
    const submitRawUpdate = async () => {
        const matchDocRef = doc(db, "fantasy_schedule", documentID);
        let jsonObject = matchInfoFilter;
        let errorObj = null;
        if (jsonObject) errorObj = validateScheduleJson(matchInfoFilter);
        if (!errorObj) {

            const docSnap = await getDoc(matchDocRef);
            await setDoc(matchDocRef, docSnap.data());
            const res = await updateDoc(matchDocRef, matchInfoFilter);
            console.log(res);
            Modal.success({
                content: "Updated Successfully",
                onOk() {
                },
            });
            props.handleBack();
        }
        if (errorObj != null) {
            handleClose();
            setLoaded(false);
            Modal.error({
                title: " Json Schema Validation ",

                content: (
                    <div style={{ width: "100%", height: "300px", overflow: "auto", padding: "8px" }}>
                        <h4 style={{ color: "#274f9d" }}>Found {errorObj.length} Error(s)</h4>
                        {errorObj.map((item, index) => {
                            let _type = item.path[item.path.length - 1];
                            let _key = item.path.length > 0 ? item.path.join(" >> ") : item.argument;
                            let _msg = item.message;
                            if (item.name == "required") {
                                _type = item.path.length > 0 ? item.path[0] : "";
                                _msg = item.argument + " " + "required";
                            }
                            _msg = _type + " " + _msg;

                            return (
                                <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                                    <span style={{ whiteSpace: "break-spaces", display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                            <b style={{ paddingRight: "18px", color: "#274f9d" }}>Key</b>:<span> {_key.trim()}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "left" }}>
                                            <b style={{ paddingRight: "12px", color: "purple" }}>Error</b>:<span> {_msg.trim()}</span>{" "}
                                        </div>
                                    </span>
                                </Tag>
                            );
                        })}
                    </div>
                ),
                onOk() {
                    setLoaded(false);
                },
            });
            return;
        }
    }
    const gridStyle = {
        width: '25%',
        textAlign: 'center',
    };
    return (
        <div>{matchInfo ? <>
            <table>
                <tr>
                    <td>Tournament: </td>
                    <td>{matchInfo.tournamentTitle} </td>
                </tr>
                <tr>
                    <td>Match Date: </td>
                    <td>{<Moment format="DD-MM-YYYY">
                        {props.matchid.matchTs}
                    </Moment>} </td>
                </tr>
                <tr>
                    <td>Match Number:&nbsp;</td>
                    <td>{props.matchid.matchno} </td>
                </tr>
            </table>
            <Row gutter={12}>
                <Col span={12} offset={20}>
                    <a href="#" style={buttonColor === "Text" ? { color: "blue", marginRight: "10px" } : { color: "gray", marginRight: "10px" }} onClick={() => rawView(false)}><TableOutlined /> <span>Text View</span></a>
                    {" "}
                    <a href="#" style={buttonColor !== "Text" ? { color: "blue" } : { color: "gray" }} onClick={() => rawView(true)}><CodepenOutlined /> <span>Raw View</span></a>
                </Col>
            </Row>
            {rawViews ? <> <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinishRaw}><Row gutter={12}>
                <Col span={12} style={{ textAlign: "right" }}>
                    <Input.TextArea value={JSON.stringify(matchInfoFilter, null, 4)} onChange={handleChanges} rows={20} />
                </Col>
            </Row>
                <Row gutter={12} className="rtmp1">
                    <Col span={12}>
                        <Form.Item shouldUpdate {...tailLayout}>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Update
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row> </Form></> :
                <Row gutter={12}>
                    <Col span={12} style={{ borderRight: "1px solid #8080804a" }}>
                        <div>
                            <p>
                                <img src={props.matchid.rticon} width={"25px"} /> <span className="teamname">{props.matchid.rt} </span> {" "}  <img src="images/visitor.svg"></img>
                            </p>
                            <div className="site-card-wrapper">
                                <table className="table table-bordered">
                                    <tr>
                                        <th>
                                            Points Best X
                                        </th>
                                        <th>
                                            Points Act
                                        </th>
                                        <th>
                                            Salary
                                        </th>
                                        <th>
                                            Points Est
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>{matchInfo.rt.pointsBestIPL}</td>
                                        <td>{matchInfo.rt.pointsActIPL}</td>
                                        <td>{matchInfo.rt.salaryIPL}</td>
                                        <td>{matchInfo.rt.pointsEstIPL}</td>
                                    </tr>
                                </table>

                            </div>
                            <table cellpadding="2" cellspacing="1" className="table table-bordered">
                                <thead><tr><th scope="col">#</th><th scope="col">Player</th></tr></thead>
                                <tbody>

                                    {matchInfo.rt.players.map((list, index) => {
                                        let Points;
                                        if(currentTournamentDetails.shortName === "NFL") {
                                            Points = (<><tr><td>Points Best X</td><td>{list.pointsBestYH}</td><td>{list.pointsBestDK}</td><td>{list.pointsBestFD}</td></tr>
                                            <tr><td>Points Act</td><td>{list.pointsActYH}</td><td>{list.pointsActDK}</td><td>{list.pointsActFD}</td></tr>
                                            <tr><td>Salary $</td><td>{list.salaryYH}</td><td>{list.salaryDK}</td><td>{list.salaryFD}</td></tr>
                                            <tr><td>Points EST</td><td>{list.pointsEstYH}</td><td>{list.pointsEstDK}</td><td>{list.pointsEstFD}</td></tr></>
                                        )
                                        } else {
                                            Points = (<ul style={{ listStyleType: "none" }}>
                                            <li>points Best X1: {list.pointsBestIPL}</li>
                                            <li>points Act: {list.pointsActIPL}</li>
                                            <li>salary Rs Crore: {list.salaryIPL}</li>
                                            <li>points Est: {list.pointsEstIPL}</li>
                                        </ul>)
                                        }
                                    

                                        return (<tr>
                                            <td className=""><h3>{index + 1}</h3></td>
                                            <td className="">
                                                <Card
                                                    style={{ backgroundColor: "#f1f1f1" }}
                                                >
                                                    {currentTournamentDetails.shortName === "NFL" ? (                                                       
                                                        <Meta
                                                            avatar={<Avatar src={`https://api-dev-fc.web.app/betops/asset/get-by-name/v2?appId=fc-ops&assetType=player&assetName=${list.playerId}&tournament=${props.tournament}&team=${list.teamName}`} />}
                                                            title={list.name}
                                                            description={<table cellPadding={5} className="table table-bordered">
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Yahoo</th>
                                                                    <th>Draft King</th>
                                                                    <th>Fan Duel</th>
                                                                </tr>  {Points} </table>}
                                                        />
                                                    ) : (
                                                        <Meta
                                                        avatar={<Avatar src={`https://api-dev-fc.web.app/betops/asset/get-by-name/v2?appId=fc-ops&assetType=player&assetName=${list.playerId}&tournament=${props.tournament}&team=${list.teamName}`} />}
                                                            title={list.name}
                                                            description={<table cellPadding={5} className="table table-bordered">
                                                               {Points} </table>}
                                                        />
                                                    )}

                                                </Card>
                                            </td>
                                            <td className=""><EditTwoTone onClick={() => editPlayer(list, props.matchid)} /></td>
                                        </tr>)
                                        // }
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div >
                            <p>
                                <img src={props.matchid.hticon} width={"25px"} /> <span className="teamname">{props.matchid.ht} </span> {" "}  <img src="images/host.svg"></img>
                            </p>
                            <div className="site-card-wrapper">
                                <table className="table table-bordered">
                                    <tr>
                                        <th>
                                            Points Best X
                                        </th>
                                        <th>
                                            Points Act
                                        </th>
                                        <th>
                                            Salary
                                        </th>
                                        <th>
                                            Points Est
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>{matchInfo.ht.pointsBestIPL}</td>
                                        <td>{matchInfo.ht.pointsActIPL}</td>
                                        <td>{matchInfo.ht.salaryIPL}</td>
                                        <td>{matchInfo.ht.pointsEstIPL}</td>
                                    </tr>
                                </table>
                            </div>
                            <table cellpadding="2" cellspacing="1" className="table table-bordered">
                                <thead><tr><th scope="col">#</th><th scope="col">Player</th></tr></thead>
                                <tbody>
                                    {matchInfo.ht.players.map((list, index) => {
                                        let Points;
                                        if(currentTournamentDetails.shortName === "NFL") {
                                            Points = (<><tr><td>Points Best X</td><td>{list.pointsBestYH}</td><td>{list.pointsBestDK}</td><td>{list.pointsBestFD}</td></tr>
                                            <tr><td>Points Act</td><td>{list.pointsActYH}</td><td>{list.pointsActDK}</td><td>{list.pointsActFD}</td></tr>
                                            <tr><td>Salary $</td><td>{list.salaryYH}</td><td>{list.salaryDK}</td><td>{list.salaryFD}</td></tr>
                                            <tr><td>Points EST</td><td>{list.pointsEstYH}</td><td>{list.pointsEstDK}</td><td>{list.pointsEstFD}</td></tr></>
                                        )
                                        } else {
                                            Points = (<ul style={{ listStyleType: "none" }}>
                                            <li>points Best X1: {list.pointsBestIPL}</li>
                                            <li>points Act: {list.pointsActIPL}</li>
                                            <li>salary Rs Crore: {list.salaryIPL}</li>
                                            <li>points Est: {list.pointsEstIPL}</li>
                                        </ul>)
                                        }
                                       

                                        return (<tr>
                                            <td className=""><h3>{index + 1}</h3></td>
                                            <td className="">
                                                <Card
                                                    style={{ backgroundColor: "#f1f1f1" }}
                                                >
                                                    {currentTournamentDetails.shortName === "NFL" ? (
                                                        <Meta
                                                        avatar={<Avatar src={`https://api-dev-fc.web.app/betops/asset/get-by-name/v2?appId=fc-ops&assetType=player&assetName=${list.playerId}&tournament=${props.tournament}&team=${list.teamName}`} />}
                                                            title={list.name}
                                                            description={<table className="table table-bordered" cellPadding={5}>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Yahoo</th>
                                                                    <th>Draft King</th>
                                                                    <th>Fan Duel</th>
                                                                </tr> {Points} </table>}
                                                        />
                                                    ) : (
                                                        <Meta
                                                        avatar={<Avatar src={`https://api-dev-fc.web.app/betops/asset/get-by-name/v2?appId=fc-ops&assetType=player&assetName=${list.playerId}&tournament=${props.tournament}&team=${list.teamName}`} />}
                                                            title={list.name}
                                                            description={<table className="table table-bordered" cellPadding={5}>
                                                                {Points} </table>}
                                                        />
                                                    )}

                                                </Card>
                                            </td>
                                            <td className=""><EditTwoTone onClick={() => editPlayer(list, props.matchid)} /></td>
                                        </tr>)
                                        // }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>}

            <Modal title="Update Update Fantasy Details" onCancel={handleClose} visible={modelShowRaw}
                footer={[
                    // <Checkbox onChange={()=>setNotify(!oknotify)} checked={oknotify}>
                    //   Notify All Players
                    // </Checkbox>,
                    <Button key="submit" type="primary" onClick={handleClose}>
                        No
                    </Button>,
                    <Button
                        type="primary"
                        onClick={submitRawUpdate}
                    >
                        Yes
                    </Button>
                ]}

            >
                Would you like to save changes?
            </Modal>
            <Drawer title="Update Player Details" placement="right" onClose={onClose} visible={visible} className="matchcard">
                <EditPlayer playerDetails={playerDetails} documentID={documentID} handleBack={props.handleBack} matchid={props.matchid} tournament={props.tournament} onClose={onClose} />
            </Drawer>
        </> : ""
        }
            {
                loaded == true ? (
                    <div className="loading-overlay">
                        <Spin size="large" />{" "}
                    </div>
                ) : (
                    ""
                )
            }
        </div >
    )
}
export default FantasyInfo;