import { Spin, Layout, Input, Card, Table, Space, Button, Modal, PageHeader } from "antd";
import { collection, getDocs, doc, where, query, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails, logout } from "../../settings/config";
import React, { useState, useEffect } from "react";
//import "antd/dist/antd.min.css"
import "antd/dist/antd.min.css";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import axios from "axios";
import {
    PlusCircleTwoTone,
    DeleteTwoTone,
    LoginOutlined,
    DeleteOutlined,
    EditTwoTone
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { domain } from "../../configuration/ApiConfiguration";
import AddCertificate from "./AddCertificate";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
function Certificate() {
    const [ID, setID] = useState("");
    const [rowInfo, setRowInfo] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [StatusVisible, setStatusVisible] = useState(false);
    const [DeleteVisible, setDeleteVisible] = useState(false);
    const [CertificateList, setCertificateList] = useState([]);
    const [keyData, setKeyData] = useState(null);
    const [sportsConfig, setSportsConfig] = useState(null);
    const [tempCertList, setTempCertList] = useState([]);
    const [matchdetails, setMatchDetails] = useState([]);
    const [drawertype, setDrawertype] = useState("");
    const addnew = () => {
        setDrawertype("New Certificate");
    };
    const showDrawer = (matchid, index1) => {
        setMatchDetails(matchid);
        setDrawertype("Edit Certificate");
    };
    // const deleteLanding = async (matchid) => {

    //     setID(matchid.year)
    //     setDeleteVisible(true);
    // };

    const statusTheCertificate = async () => {
        setStatusVisible(false);
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/config/certificate/toggle_status/v2.0?appId=${currentTournamentDetails.shortCode}-ops&year=${rowInfo.year}`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            onClose();
            if (response.data.status === "SUCCESS") {
                Modal.success({
                    content: response.data.data,
                });
                setTimeout(async () => {
                    fetchDashboard();
                }, 2000);
            }
            else if (response.data.status === "FAIL") {
                setLoaded(false);
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }

    // const deleteThePackage = async () => {
    //     setLoaded(true);
    //     let id = ID
    //     let data = {
    //         "year": id,
    //     }
    //     setDeleteVisible(false);
    //     const response = await axios.delete(
    //         `${domain}/betops/config/certificate/delete/v1`, { data: data })
    //         .then((response) => {

    //             onClose()
    //             setLoaded(false);
    //             if (response.data.status === "SUCCESS") {
    //                 Modal.success({
    //                     content: response.data.data,
    //                 });
    //                 setTimeout(async () => {
    //                     fetchDashboard();
    //                 }, 2000);
    //             }
    //             else if (response.data.status === "FAIL") {
    //                 Modal.error({
    //                     content: response.data.data,
    //                 });
    //             }
    //             else {
    //                 setLoaded(false);
    //                 Modal.error({
    //                     content: "Unable to process your Request, Please try again later.",
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             setLoaded(false);
    //             Modal.error({
    //                 content: "Unable to process your Request, Please try again later.",
    //             });
    //         })
    // }

    const changeStatus = async (rowData) => {
        setRowInfo(rowData)
        setStatusVisible(true);
    };

    const onClose = () => {
        setDrawertype("");
        setID("")
        setDeleteVisible(false);
        setStatusVisible(false);
        setRowInfo("")
    };
    const onSearch = (e) => {
        if (e.target.value !== "") {
            let filteredData = tempCertList.find(obj => `${obj.year}` === e.target.value);
            let tempArr = [];
            tempArr.push(filteredData);
            if (tempArr[0].year) {
                setCertificateList(tempArr);
            }
        } else {
            fetchDashboard();
        }
    };

    const fetchDashboard = async () => {
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/config/certificate/get-all/v2.1?appId=${currentTournamentDetails.shortCode}-ops`,
            headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` }
        }).then((response) => {
            if (response.data.status === "SUCCESS") {
                setTempCertList(response.data.data.certificate_list);
                setCertificateList(response.data.data.certificate_list);
                setKeyData(response.data.data.keyData);
                setSportsConfig(response.data.data.sportConfig);
            }
            else {
                if (response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                        content: response.data.error.message,
                        onOk() {
                            logout();
                        }
                    });
                } else {
                    Modal.error({
                        content: response.data.error.message
                    });
                }
            }
        })
            .catch((error) => {
                Modal.error({
                    content: "Unable to process your Request, Please try again later."
                });
            })
        setLoaded(false);
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        fetchDashboard();
    }, [user, loading]);

    useEffect(async () => {
    }, [loaded]);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="certificate" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        {drawertype == "Edit Certificate" ? <AddCertificate Flag="Edit Certificate" Certificate={matchdetails} keydata={keyData} sportsconfig={sportsConfig} onClose={onClose} /> : drawertype == "New Certificate" ? <AddCertificate onClose={onClose} keydata={keyData} sportsconfig={sportsConfig} Flag="New Certificate" />
                            : <>
                                <div className="site-card-border-less-wrapper">
                                    <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                        <PageHeader
                                            className="site-page-header"
                                            title="Certificate List"
                                            extra={
                                                <>
                                                    {" "}
                                                    <Space direction="horizontal">
                                                        {" "}
                                                        <Search placeholder="Search here" onChange={onSearch} enterButton />
                                                        <a onClick={() => addnew()}>
                                                            <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                        </a>
                                                    </Space>{" "}
                                                </>
                                            }
                                        />
                                        <Table
                                            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                            dataSource={CertificateList}
                                            scroll={{ y: "340" }}
                                        >
                                            <Column title="App Id" dataIndex="app" key="app" />
                                            <Column title="Tournament" dataIndex="tournament" key="tournament" />
                                            <Column title="Season" dataIndex="year" key="year" />
                                            <Column title="Status" dataIndex="active" key="active"
                                                render={(text, record) => (
                                                    <Space size="middle">
                                                        {text == true ? (
                                                            <p style={{ marginBottom: "0" }} className="text-success"> Active </p>
                                                        ) : (
                                                            <p style={{ marginBottom: "0" }} className="text-danger"> In Active </p>
                                                        )}
                                                    </Space>
                                                )}
                                            />
                                            <Column
                                                title="Quick Actions"
                                                key="documentID"
                                                render={(documentID, value) => (
                                                    <Space size="middle">
                                                        {/* <DeleteOutlined onClick={() => deleteLanding(documentID)} title="Delete Landing" style={{ fontSize: "18px", color: "#08c" }} /> */}
                                                        <LoginOutlined onClick={() => changeStatus(documentID)} title="Change Certificate Status" style={{ fontSize: "18px", color: "#08c" }} />
                                                        <EditTwoTone onClick={() => showDrawer(documentID)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    </Space>
                                                )}
                                            />
                                        </Table>
                                    </Card>
                                </div>
                                {/* <Modal title="Delete" onCancel={onClose} visible={DeleteVisible}
                                    footer={[<>  <Button key="submit" type="primary" onClick={deleteThePackage}>
                                        Ok
                                    </Button>
                                        <Button key="submit" type="primary" onClick={onClose}>
                                            Cancel
                                        </Button></>
                                    ]}
                                >
                                    Are you sure you want to Delete the Package?
                                </Modal> */}
                                <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                                    footer={[<>  <Button key="submit" type="primary" onClick={statusTheCertificate}>
                                        Ok
                                    </Button>
                                        <Button key="submit" type="primary" onClick={onClose}>
                                            Cancel
                                        </Button></>
                                    ]}
                                >
                                    Are you sure you want to Change the Certificate Status?
                                </Modal>
                                {loaded == true ? (
                                    <div className="loading-overlay">
                                        <Spin size="large" />{" "}
                                    </div>
                                ) : (
                                    ""
                                )}</>}

                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default Certificate;
