import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, getDoc, updateDoc, query, where, orderBy, limit } from "firebase/firestore";
import { Select, Layout, Card, Checkbox, Row, Col, Popover, Form, Input, Table, Tag, Space, Button, Dropdown, Modal, Drawer, Divider, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
// import Editbets from "./editbets";
// import Addbets from "./addbets";
import FantasyBulkupdate from "./fantasyBulkUpdate";
import Moment from "react-moment";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import {
    BellFilled,
    PlusOutlined,
    EditTwoTone,
    EditOutlined,
    DeleteTwoTone,
    DashOutlined
} from "@ant-design/icons";
import { json, useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { TextArea, Search } = Input;
const { Option, OptGroup } = Select;
const { Header, Sider, Footer, Content } = Layout;
const { Column } = Table;
function Fantasylist() {
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(true);
    const [normalDrawervisible, setNormalDrawervisible] = useState(false);
    const [jsonObject, setJsonObject] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [teams, setTeams] = useState([]);
    const [editmatchid, setEditmatchid] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [teaminfo, setTeaminfo] = useState([]);
    const [matchdetails, setMatchDetails] = useState([]);
    const [tournamentValue, setTournamentValue] = useState(currentTournamentDetails.shortName);
    const [matchesValue, setMatchesValue] = useState("Select Matches");
    const [seasonType, setSeasonType] = useState("REGULAR");
    const [seasonyearValue, setSeasonyearValue] = useState(2023);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modaltitle, setModaltitle] = useState("");
    const [matchrow, setMatchrow] = useState([]);
    const [title, setTitle] = useState("");
    const [largeDrawertype, setLargeDrawertype] = useState("");
    const [normalDrawertype, setNormalDrawertype] = useState("");
    const [open, setOpen] = useState(false);
    const [popupClose, setpopupClose] = useState(true);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const [dbTournamentList, setDbTournamentList] = useState([]);
    const [dbSeasonList, setDbSeasonList] = useState([]);
    const [dbSeasonTypeList, setDbSeasonTypeList] = useState([]);

    const showDrawer = async (matchid) => {
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/fantasy/get/data/v1?tournament=${matchid.tournament}&season=${matchid.year}&seasonType=${seasonType}&matchId=${matchid.matchid}&appId=${currentTournamentDetails.shortCode}-ops`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setJsonObject(response.data.data.fantasy);
                setLoaded(false);
                setShowEditModal(true);
            }
            else {
                setLoaded(false);
            }
        }).catch((error) => {
            setLoaded(false);
        })
    };

    const showContent = () => {
        return (
            <div>
                <p>
                    <EditOutlined style={{ fontSize: "16px", color: "#08c", textAlign: "center", marginRight: "15px" }} />
                    <a onClick={() => bulkupdate()}>
                        <span className="addbet">Bulk Update</span>
                    </a>
                </p>
            </div >

        );
    };

    const openPopover = () => {
        setOpen(true);
    }

    const handleVisibleChanges = () => {
        setOpen(!open);
    }

    const onClose = async () => {
        setLoaded(false);
        // setLargeDrawervisible(false);
        setNormalDrawervisible(false);
        setConfirmModalVisible(false);
    };

    const bulkupdate = () => {
        setNormalDrawertype("bulkupdate");
        setTitle("Bulk Update");
        setNormalDrawervisible(true);
        setOpen(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getTableList = async (val1, val2, val3) => {
        setLoaded(true);
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/fantasy/get/match-list/v1?tournament=${val1}&season=${val2}&seasonType=${val3}&appId=${currentTournamentDetails.shortCode}-ops`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                let apiRes = response.data.data.matchList;
                let tableData = [];
                const finaldata = apiRes.map(function (element, mid) {
                    tableData.push({
                        tournament: element.tournament,
                        year: element.year,
                        // matchdate: element.startTs,
                        matchno: element.matchId.slice(4, element.matchId.length),
                        ht: element.ht.teamName,
                        rt: element.rt.teamName,
                        matchid: element.matchId
                    });
                });
                setMatchDetails(tableData);
                setLoaded(false);
            }
            else {
                setLoaded(false);
            }
        }).catch((error) => {
            setLoaded(false);
        })
    }

    const updateDocument = async () => {
        let editedObj = null;
        let value = document.getElementById('newDoc').value;
        editedObj = JSON.parse(value);
        setLoaded(true);
        editedObj.appId = `${currentTournamentDetails.shortCode}-ops`;
        if(!editedObj.seasonType) {
            editedObj.seasonType = "REGULAR";
          }
          if(!editedObj.active) {
            editedObj.active = true;
          }
        const body = editedObj;
        const response = await axios.post(
            `${domain}/betops/fantasy/update/data/v1`, body, {
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // }
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setLoaded(false);
                Modal.success({
                    content: "Fantasy Document Updated Successfully",
                    onOk() {
                        window.location.reload(true);
                    },
                });
            }
        })
            .catch((error) => {
                console.log(error);
            })
        setShowEditModal(false);
    }

    const initialFunction = async (val) => {
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${tournamentValue}`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                let config21 = response.data.data.yearsList;
                setDbSeasonList(config21);

                let config31 = response.data.data.seasonsList;
                setDbSeasonTypeList(config31);
            }
            else {
            }
        }).catch((error) => {
        })

        setMatchesValue("");
        setMatchDetails([]);
        await getTableList(tournamentValue, seasonyearValue, seasonType);
        return;
        const finaldata = val
            .filter(function (item) {
                return item.tournament == tournamentValue && item.year == seasonyearValue;
            })
            .map(function (item) {
                return item;
            });

        const tableData = [];
        const playoffData = [];
        finaldata.map(function (element, mid) {
            let i = 1;
            if (element.playOff !== undefined) {
                const list = element.playOff.map(function (playoff, mid3) {
                    playoffData.push({
                        startdate: element.begins,
                        year: element.year,
                        matchtitle: playoff.rt + " vs " + playoff.ht,
                        tourID: element.tourID,
                        matchno: playoff.matchId.slice(4, playoff.matchId.length),
                        tournament: element.tournament,
                        tournamenttitle: element.tournamentTitle,
                        hticon: playoff.ic,
                        bets: playoff.bets,
                        ht: playoff.ht,
                        rt: playoff.rt,
                        matchid: playoff.matchId,
                        matchTs: playoff?.matchTs?.seconds,
                        documentID: element.documentID,
                        mtype: "playoff",
                    });
                });
            }
            if (element.htl !== undefined) {
                const list = element.htl.map(function (element1, mid1) {
                    const list2 = element1.rtl.map(function (element2, mid2) {
                        tableData.push({
                            startdate: element.begins,
                            year: element.year,
                            matchtitle: element2.rt + " vs " + element2.ht,
                            tourID: element.tourID,
                            matchno: element2.matchId.slice(4, element2.matchId.length),
                            tournament: element.tournament,
                            tournamenttitle: element.tournamentTitle,
                            hticon: element1.ic,
                            bets: element2.bets,
                            ht: element2.ht,
                            rt: element2.rt,
                            matchid: element2.matchId,
                            matchTs: element2?.matchTs?.seconds,
                            documentID: element.documentID,
                            mtype: "schedule",
                        });
                    });
                });
            }
        });
        var array3 = tableData.concat(playoffData);
        let data1 = []
        for (let i = 0; i < array3.length; i++) {
            if (array3[i].startdate)
                data1.push(array3[i])
        }
        let finalteam = await SortandRemoveDuplicateObject(data1);
        setTeaminfo(finalteam);
    }

    const SortandRemoveDuplicateObject = async (val) => {
        const sortByKey = (key) => (a, b) => a[key] - b[key];
        const sortByMatchid = sortByKey("matchid");
        const sortval = val.sort(sortByMatchid);
        const uniqueIds = [];
        const unique = sortval.filter(element => {
            const isDuplicate = uniqueIds.includes(element.matchid);
            if (!isDuplicate) {
                uniqueIds.push(element.matchid);
                return true;
            }
            return false;
        });
        return unique;
    }

    const fetchSchedule = async () => {
        const scheduleCol = collection(db, "bet_schedule");
        const scheduleSnapshot = await getDocs(scheduleCol);
        const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
        const resultArray = [];
        const resultMatch = [];
        const result = scheduleSnapshot.docs.map((doc) => {
            let documentArray = doc.data();
            resultArray.push(doc.data());
            resultMatch.push({ documentID: doc.id });
        });
        let arr3 = resultArray.map((item, i) => Object.assign({}, item, resultMatch[i]));
        let scheduleArray = [];
        let i = 1;
        arr3.map(function (element, i) {
            scheduleArray.push({
                key: i++,
                documentID: element.documentID,
                docType: element.docType,
                Year: element.year,
                tournamenttitle: element.tournament,
            });
        });
        setSchedule(scheduleArray);
    };

    const fetchTeams = async () => {
        const teamsCol = collection(db, "teams");
        const teamsSnapshot = await getDocs(teamsCol);
        const teamList = teamsSnapshot.docs.map((doc) => doc.data());
        setTeams(teamList);
        const docRef = doc(db, "configuration", "admin_console");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            //
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    };
    const handleTournament = async (e) => {
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/config/dashconfig/get-sport_info/v1?sport=${e}`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                let config21 = response.data.data.yearsList;
                setDbSeasonList(config21);

                let config31 = response.data.data.seasonsList;
                setDbSeasonTypeList(config31);
            }
            else {
            }
        }).catch((error) => {
        })

        await fetchTournamentBasedSchedule(e);


        setSeasonyearValue("");
        setSeasonType("");
        setMatchesValue("");
        setMatchDetails([]);

        setTournamentValue(e);
        await getTableList(e, seasonyearValue, seasonType);
    };

    const sortArray = (type) => {
        const types = {
            matchid: "matchid",
            tournamenttitle: "tournamenttitle",
            matchno: "matchno",
            ht: "ht",
            rt: "rt",
        };
        const sortProperty = types[type];
        const sorted = [...matchdetails].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });

        setMatchDetails(sorted);
    };
    const handleTableChange = (pagination, filters, sorter) => {
        // 
        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setMatchDetails(matchdetails.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {

            setMatchDetails(matchdetails.reverse());
        }
    };

    const hideTheModal = async () => {
        setJsonObject(null);
        form.resetFields(["newDoc"]);
        setShowEditModal(false);
        await onClose();
    }

    const ascendingComparator = (a, b) => (a.matchid > b.matchid) ? 1 : -1;

    const onSearch = (e) => { };
    const toLower = (value) => {
        return value.toLowerCase();
    };

    const hideModal = async () => {
        setConfirmModalVisible(true);
    }

    const fetchTournamentBasedSchedule = async (val) => {
        setLoaded(true);
        const scheduleCol = collection(db, "schedule");
        const scheduleSnapshot = await getDocs(scheduleCol);
        const resultArray = [];
        const resultMatch = [];
        const result = scheduleSnapshot.docs.map((doc) => {
            let documentArray = doc.data();
            resultArray.push(doc.data());
            resultMatch.push({ documentID: doc.id });
        });
        let arr3 = resultArray.map((item, i) => Object.assign({}, item, resultMatch[i]));
        const data = arr3
            .filter(function (item) {
                return item.tournament == val;
            })
            .map(function (item) {
                return item;
            });
        setSchedule(data);
        await initialFunction(data);
    }

    const sportConfigSetting = () => {
        let config11 = localStorage.getItem("sportsList")
        let config12 = config11.split(",");
        setDbTournamentList(config12);

        let config21 = localStorage.getItem("sportYearsList")
        let config22 = config21.split(",");
        setDbSeasonList(config22);

        let config31 = localStorage.getItem("sportSeasonsList")
        let config32 = config31.split(",");
        setDbSeasonTypeList(config32);
    }

    const handleSeason = async (e) => {
        setSeasonyearValue(e);
        await getTableList(tournamentValue, e, seasonType);
    }

    const handleSeasonType = async (e) => {
        setSeasonType(e);
        await getTableList(tournamentValue, seasonyearValue, e);
    }

    const deleterecord = async (dataset) => {
        setLoaded(true);
        const response2 = await axios({
            method: 'get',
            url: `${domain}/betops/fantasy/delete/data/v1?tournament=${dataset.tournament}&season=${dataset.year}&seasonType=${seasonType}&appId=${currentTournamentDetails.shortCode}-ops&matchId=${dataset.matchid}`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setLoaded(false);
                Modal.success({
                    content: "Deleted Successfully",
                    onOk() {
                        window.location.reload(true);
                    },
                });
            }
            else {
            }
        }).catch((error) => {
        })
    };

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(async () => {
        await fetchTournamentBasedSchedule(tournamentValue);

        sportConfigSetting();
        if (loading) return;
    }, [user, loading]);

    useEffect(() => {
        fetchTeams();
        fetchSchedule();
    }, [loaded]);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="fantasylist" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Fantasy"
                                    extra={[
                                        <Select value={tournamentValue} size="default" onChange={handleTournament} placeholder="Select Tournaments" style={{ width: "200px" }}>
                                            {dbTournamentList.map((item, index) => (
                                                <Option key={index} value={item}>
                                                    {item}{" "}
                                                </Option>
                                            ))}
                                        </Select>,
                                        <>
                                            <Select value={seasonyearValue} onChange={(e) => handleSeason(e)} size="default" placeholder="Select Season" style={{ width: "200px" }}>
                                                {dbSeasonList.map((item1, index1) => (
                                                    <Option key={index1} value={item1}>
                                                        {item1}{" "}
                                                    </Option>
                                                ))}
                                            </Select>

                                            <Select value={seasonType} size="default" placeholder="Select SeasonType" style={{ width: "200px" }} onChange={(e) => handleSeasonType(e)}>
                                                {dbSeasonTypeList.map((item2, index2) => (
                                                    <Option key={index2} value={item2}>
                                                        {item2}{" "}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <Popover
                                                content={() => showContent()}
                                                trigger="click"
                                                placement="bottomRight"
                                                onOpenChange={handleVisibleChanges}
                                                open={open}
                                            >
                                                <DashOutlined onClick={() => openPopover()} />
                                            </Popover>




                                        </>,
                                    ]}
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={matchdetails}
                                    onChange={handleTableChange}
                                    scroll={{ y: "250" }}
                                >
                                    <Column title="Match No" dataIndex="matchno" key="matchno" />
                                    {/* <Column title="Tournament" dataIndex="tournament" key="tournament" /> */}
                                    {/* <Column title="Year" dataIndex="year" key="year" /> */}
                                    {/* <Column
                                        title="Match Date"
                                        dataIndex="matchdate"
                                        key="matchdate"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text !== undefined ? (
                                                    <Moment format="DD-MM-YYYY hh:mm a">
                                                        {text}
                                                    </Moment>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    /> */}
                                    <Column title="Road Team" dataIndex="rt" key="rt" />
                                    <Column title="Home Team" dataIndex="ht" key="ht" />

                                    <Column
                                        title="Quick Actions"
                                        key="matchId"
                                        render={(matchId) => (
                                            <Space size="middle">
                                                <EditTwoTone onClick={() => showDrawer(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                <DeleteTwoTone onClick={() => deleterecord(matchId)} style={{ fontSize: "18px", color: "#08c" }} />
                                            </Space>
                                        )}
                                    />

                                </Table>
                            </Card>
                        </div>

                        <Modal destroyOnClose={true} zIndex="2500" title="Confirm" onCancel={onClose} open={confirmModalVisible}
                            footer={[<>  <Button key="submit" type="primary" onClick={hideTheModal}>
                                Ok
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>

                            ]}
                        >
                            Are you sure want to leave this window ?
                        </Modal>


                        <Modal
                            destroyOnClose={true}
                            title="Check Important Fields Values Before Update"
                            className="checkFieldsModalRoot checkFieldsModal checkFieldsModal-odds checkFieldsModal-playoff"
                            open={showEditModal}
                            onCancel={hideModal}
                            zIndex="2000"
                            modalOptions={{ dismissible: false }}
                            keyboard={false}
                            maskClosable={false}
                            closable={popupClose}
                        >
                            <div>
                                {loaded == true ? (
                                    <div className="loading-overlay">
                                        <Spin size="large" />{" "}
                                    </div>
                                ) : (
                                    ""
                                )}

                                <button type="button" className="ant-btn ant-btn-primary savebtn" onClick={updateDocument}>Update</button>
                                <Form
                                    layout="horizontal"
                                    form={form}
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Form.Item name="newDoc">
                                                <TextArea id="newDoc" style={{ height: "469px" }} defaultValue={JSON.stringify(jsonObject, null, 4)} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                                {jsonObject &&
                                    <h1 className="popuptitle popuptitle1">FANTASY DOCUMENT <span>-</span> {jsonObject.tournament} <span>-</span> {jsonObject.year} <span>-</span> {jsonObject.seasonType} <span>-</span> {jsonObject.matchId}</h1>
                                }
                            </div>
                        </Modal>

                        {normalDrawertype == "bulkupdate" ? (
                            <Drawer title={title} placement="right" onClose={onClose} visible={normalDrawervisible}>
                                <FantasyBulkupdate doctype="FANTASY_LINEUP" scheduledata={schedule} schedulereload={fetchSchedule} onClose={onClose} />
                            </Drawer>
                        ) : (
                            <></>
                        )}
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default Fantasylist;
