import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, GeoJSON, useMap, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import axios from "axios";
import { Card, Row, Col, Tag, Modal, Form, Input, Button, Layout, Spin } from "antd";
import Headersection from "../../Layout/header";
import { domain } from "../../configuration/ApiConfiguration";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
const { Content } = Layout;
const MyData = (props) => {
    const [loaded, setLoaded] = useState(true);
    const [data, setData] = useState("");
    useEffect(async () => {
        const body = {
            "countryCode": props.countryCode,
        }
        const response = await axios.post(
            `${domain}/betops/geo/get/v1`, body)
            .then((response) => {
                setLoaded(false);
                if (response.data.status === 200) {
                    setData(response.data.message)
                }
                else {
                    Modal.error({
                        content: response.data.message,
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }, [])
    const map = useMap();
    if (data) {
        const geojsonObject = L.geoJSON(data);
        map.fitBounds(geojsonObject.getBounds());
        console.log(geojsonObject);
        return <GeoJSON data={data} />;
    } else {
        return null;
    }
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const LocationMap = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [center, setCenter] = useState();
    const [isInputMapVisible, setIsInputMapVisible] = useState(false);
    const [mapLayers, setMapLayers] = useState([]);
    let [inputs, setInputs] = useState({ name: "", id: "" })
    const [form] = Form.useForm();

    const [geoJsonType, setGeoJsonType] = useState({
        "countryCode": "TEST",
        "name": "Test",
        "geoJson": {
            "type": "FeatureCollection",
            "features": [

            ]
        }
    })

    const ZOOM_LEVEL = 4;
    const _onCreate = async (e) => {

        setIsInputMapVisible(true)
        setCenter(e)

    };
    const _onEdited = (e) => {
        const {
            layers: { _layers },
        } = e;
        Object.values(_layers).map(({ _leaflet_id, editing }) => {
            setMapLayers((layers) =>
                layers.map((l) =>
                    l.id === _leaflet_id
                        ? { ...l, latlngs: { ...editing.latlngs[0] } }
                        : l
                )
            );
        });
    };

    const _onDeleted = (e) => {
        setMapLayers([]);
    };
    const Submit = () => {

        setIsInputMapVisible(true)
    }
    const handleCancel = () => {
        form.setFieldsValue({
            name: "",
            id: "",
        });
        inputs.name = ""
        inputs.id = ""
        setInputs(inputs)
        setIsInputMapVisible(false)
    }
    const AddInputs = () => {

        const { layerType, layer } = center;
        if (layerType === "polygon") {


            let scsds = geoJsonType
            let dsdfdssfd = layer.getLatLngs()[0]
            let coordinates = []
            for (let i = 0; i < dsdfdssfd.length; i++) {
                coordinates.push([dsdfdssfd[i].lng, dsdfdssfd[i].lat])
            }

            scsds.countryCode = inputs.id.toUpperCase();
            scsds.name = inputs.name;
            scsds.geoJson.features.push({
                "type": "Feature",
                "id": inputs.id.toUpperCase(),
                "properties": {
                    "name": inputs.name
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [coordinates]
                }
            })
            form.setFieldsValue({
                name: "",
                id: "",
            });
            inputs.name = ""
            inputs.id = ""
            setInputs(inputs)
            setIsInputMapVisible(false)
            setGeoJsonType(scsds)
            setMapLayers(scsds);
        }
    }
    const CountryName = (e) => {

        inputs.name = e.target.value
        setInputs(inputs)
    }
    const CountryId = (e) => {

        inputs.id = e.target.value
        setInputs(inputs)
    }
    const createGeoJson = async () => {

        let data = mapLayers
        const body = {
            data
        }
        if (data.length === 0) {
            Modal.error({
                content: "Please create the GEOJson.",
            });
            return false
        }
        setLoaded(true)
        const response = await axios.post(
            `${domain}/betops/geo/create/v1`, body.data)
            .then((response) => {
                setLoaded(false);
                if (response.data.status === 200) {
                    Modal.success({
                        content: "GeoJSON added successfully",
                    });
                    setTimeout(() => {
                        props.CloseMap();
                    }, 2000);

                }
                else {
                    Modal.error({
                        content: response.data.message,
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    return (
        <>
            <Layout style={{ minHeight: "100vh" }} hasSider>
                <Sidermenu menu="location_services" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <h2>Location Service Management</h2>
                        <div className="row">
                            <div className="col-md-12"><Button onClick={props.CloseMap} className="btn btn-danger topbtnalign">Back</Button> <br />
                                <br /></div>

                            <div className="col -md-8">
                                <MapContainer
                                    style={{ height: "400px", width: "1000px" }}
                                    doubleClickZoom={false}
                                    zoomControl={props.flag === "Add" ? true : false}
                                    id="mapId"
                                    zoom={5}
                                    center={[67.0166015625, 26.31311263768267]}
                                >
                                    {props.flag === "Add" ? <FeatureGroup>
                                        <EditControl
                                            position="topright"
                                            onCreated={_onCreate}
                                            onEdited={_onEdited}
                                            onDeleted={_onDeleted}
                                            draw={{
                                                rectangle: false,
                                                polyline: false,
                                                circle: false,
                                                circlemarker: false,
                                                marker: false,
                                            }}
                                        />
                                    </FeatureGroup> : <MyData countryCode={props.countryCode} />}

                                    <TileLayer
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                                        attribution="Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
                                    />
                                </MapContainer>
                            </div>
                            {props.flag === "Add" ? <div className="col -md-4">
                                <textarea cols={25} rows={15} value={JSON.stringify(mapLayers, null, 2)}></textarea>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={createGeoJson}
                                >
                                    Create
                                </Button>
                            </div> : ""
                            }
                        </div>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        <Modal title="Add" onCancel={handleCancel} visible={isInputMapVisible}

                            footer={[
                                <Button key="submit" type="primary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            ]}
                        >
                            <Form form={form} name="horizontal_login" layout="vertical" onFinish={AddInputs}>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="name" label="Country Name" rules={[{ required: true, message: "Please input your Country Name" }]}>
                                            <Input placeholder="Country Name" onChange={CountryName} />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="id" label="Country Id" rules={[{ required: true, message: "Please input your Country Id!" }]}>
                                            <Input placeholder="Country Id" onChange={CountryId} />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24} className="rtmp1">
                                    <Col span={24}>
                                        <Form.Item shouldUpdate {...tailLayout}>
                                            {() => (
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Add
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </Content>
                    <Footersection />
                </Layout>
            </Layout>
        </>
    );
};

export default LocationMap;
