// import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc } from "firebase/firestore";
// import {db} from "../settings/config";


let local = null;

// const getConfigData = async () => {
//   const _data = collection(db, "configuration");
//   const _snapshot = await getDocs(_data);
//   const list = _snapshot.docs.map((doc) => doc.data());
//   return list
// }
const _getItem = async () => {
  return new Promise( async(resolve, reject) => { 
    // if(local==null) {
    //   let _list  = await getConfigData();
    //   _list = _list[0]['tournament'];
    //   let _years =[]
    //   for(let i in _list) {
    //     _years[_list[i].tournamentName] = _list[i].yearList
    //   }
    //   local = _years;
    // }
    resolve(local);
  });
}

_getItem();

const localStore = {
  getItem : _getItem,
};
export default localStore;