import Firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import moment from "moment";
import Newdashboard from "./newdashboard";
import Editdashboard from "./editdashboard";
import { Link } from "react-router-dom";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import ReactJson from "react-json-view";
import Moment from "react-moment";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Landinginfo() {
  const [form] = Form.useForm();
  const [infoform] = Form.useForm();
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [pushdata, setPushdata] = useState([]);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [editmatchid, setEditmatchid] = useState([]);
  const [matchinfo, setMatchinfo] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [matchdetails, setMatchDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [matchyear, setMatchyear] = useState([]);
  const [matchValue, setMatchValue] = useState("");
  const [drawertype, setDrawertype] = useState("");
  const [title, setTitle] = useState("");
  const [info2, setInfo2] = useState([]);
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tournament, setTournament] = useState([]);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showJsonmodal = async (documentID) => {
    const matchDocRef = doc(db, "schedule", documentID.documentID);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    setJsondata(arraycheck);
    setJsonviewer(true);
  };
  const callback = (key) => {
    console.log(key);
  };
  const handleJson = () => {
    //downloadFile(arraycheck,documentID.documentID)
    setJsonviewer(false);
  };

  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Info");
    setVisible(true);
    // const docRef = addDoc(collection(db, "schedule"), {
    //     Title: "test",
    //     notifId: "",

    //   });
    //   console.log("Document written with ID: ", docRef.id);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    console.log(blob);
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadocument = async (documentID) => { };

  const deletedocument = (documentID) => {
    const docRef = deleteDoc(doc(db, "schedule", documentID.documentID));
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        window.location.reload(true);
      },
    });
  };
  const showDrawer = (matchid, index) => {
    infoform.setFieldsValue({
      tournament: matchid.tournament,
      contenttype: matchid.contentType,
    });
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const datesplit = (text) => {
    const res = text.split("_");
    var currentTimeInSeconds = Math.floor(res[1] / 1000);
    return currentTimeInSeconds;
  };
  const deleterecord = async (matchid, index1) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());

    let filtered = arraycheck.info2.filter(function (value, index, arr) {
      return index !== index1;
    });
    arraycheck.info2 = filtered;
    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };
  const onClose = () => {
    infoform.resetFields();
    setVisible(false);
  };
  const textpicker = (value, dateString) => {
    setTexttime(dateString);
  };
  const textok = (value) => { };
  const titlepicker = (value, dateString) => {
    console.log(dateString);
    setTitletime(dateString);
  };
  const titleok = (value) => { };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const content = (notifId) => {
    return (
      <div>
        <p>{notifId.title}</p>
        <p>{notifId.desc}</p>
      </div>
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleTitlechange = (e) => {
    setTexttime("");
    setTitletime("");
    setTitledata(e);
  };
  const handleTextchange = (e) => {
    setTexttime("");
    setTitletime("");
    setSubtxtdata(e);
  };
  const handleTournament = (e) => {
    setTournamenttype(e);
  };
  const handleContent = (e) => {
    setContenttype(e);
  };
  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const fetchTeams = async () => {
    const teamsCol = collection(db, "teams");
    const teamsSnapshot = await getDocs(teamsCol);
    const teamList = teamsSnapshot.docs.map((doc) => doc.data());
    setTeams(teamList);
  };
  const onInfofinish = async (values) => {
    console.log(values);
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    if (title === "New Info") {
      arraycheck.info2.push({
        contentType: values.contenttype,
        tournament: values.tournament,
      });
    } else {
      arraycheck.info2[currentindex] = {
        contentType: values.contenttype,
        tournament: values.tournament,
      };
    }

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: title === "New Info" ? "New Info Added Successfully" : "Updated Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };
  const onFinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    var subdate = new Date(texttime);
    var submilliseconds = subdate.getTime();
    var titledate = new Date(titletime);
    var titlemilliseconds = titledate.getTime();
    arraycheck.info1.subTxt1 = {
      data: subtxtdata === "TEXT" ? values.subtxtdata : Firebase.firestore.Timestamp.fromDate(new Date(texttime)),
      dataType: subtxtdata,
    };
    arraycheck.info1.title = {
      data: titledata === "TEXT" ? values.titledata : Firebase.firestore.Timestamp.fromDate(new Date(titletime)),
      dataType: titledata,
    };
    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Updated Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };
  const fetchDashboard = async () => {
    const scheduleCol = collection(db, "configuration");
    const scheduleSnapshot = await getDocs(scheduleCol);
    scheduleSnapshot.docs.map((doc) => {
      if (doc.id === "landingInfoDefault") var docdata = doc.data();
      if (docdata !== undefined) {
        setSchedule(docdata);
        setInfo2(docdata.info2);
        setSubtxtdata(docdata.info1.subTxt1.dataType);
        setTitledata(docdata.info1.title.dataType);
        let textresult = "";
        let titleresult = "";
        var text = new Date(docdata.info1.subTxt1.data);
        textresult = text.toLocaleString().replace(",", "");
        var textstring = moment(textresult).format("YYYY-MM-DD HH:MM:SS");
        setTexttime(textstring);
        var title = new Date(docdata.info1.title.data);
        titleresult = title.toLocaleString().replace(",", "");
        var titlestring = moment(titleresult).format("YYYY-MM-DD HH:MM:SS");
        setTitletime(titlestring);
        form.setFieldsValue({
          subtxtdata: subtxtdata === "TEXT" && docdata.info1.subTxt1.dataType === "TEXT" ? docdata.info1.subTxt1.data : "",
          titledata: titledata === "TEXT" && docdata.info1.title.dataType === "TEXT" ? docdata.info1.title.data : "",
        });
      }
      if (doc.id === "admin_console") {
        var docdata = doc.data();
        if (docdata !== undefined) {
          setTournament(docdata.tournament);
        }
      }
    });
  };
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="landing_page" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader className="site-page-header" title="Landing Info" />
                <Tabs defaultActiveKey="1" onChange={callback}>
                  <TabPane tab="Info1" key="info1">
                    <Form layout="horizontal" form={form} onFinish={onFinish}>
                      <Divider orientation="left" orientationMargin="0">
                        Sub Text
                      </Divider>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          {subtxtdata === "TEXT" ? (
                            <Form.Item label="Data" name="subtxtdata" rules={[{ required: true, message: "Input your sub-text data!" }]}>
                              <Input placeholder="input sub-text data" />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {subtxtdata === "LOCAL_TS" ? (
                            <Form.Item label="Data">
                              <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={textpicker}
                                onOk={textok}
                                value={texttime === "" ? undefined : moment(texttime, "YYYY-MM-DD HH:mm:ss")}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Data Type">
                            <Select size="default" value={subtxtdata} style={{ width: "100%" }} onChange={handleTextchange}>
                              {dataType.map((item3, index3) => (
                                <Option key={index3} value={item3}>
                                  {" "}
                                  {item3}{" "}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider orientation="left" orientationMargin="0">
                        Title
                      </Divider>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          {titledata === "TEXT" ? (
                            <Form.Item label="Data" name="titledata" rules={[{ required: true, message: "Input your title data!" }]}>
                              <Input placeholder="input title data" />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {titledata === "LOCAL_TS" ? (
                            <Form.Item label="Data">
                              <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={titlepicker}
                                onOk={titleok}
                                value={titletime === "" ? undefined : moment(titletime, "YYYY-MM-DD HH:mm:ss")}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Data Type">
                            <Select size="default" value={titledata} style={{ width: "100%" }} onChange={handleTitlechange}>
                              {dataType.map((item2, index2) => (
                                <Option key={index2} value={item2}>
                                  {" "}
                                  {item2}{" "}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Update
                        </Button>
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane tab="Info2" key="info2">
                    <Space direction="horizontal" className="rightspace">
                      {" "}
                      <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                      <a onClick={() => addnew()}>
                        <span className="addschedule">Add New</span>
                      </a>
                    </Space>
                    <Table
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            fetchIndex(rowIndex);
                          }, // click row
                        };
                      }}
                      rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                      dataSource={info2}
                      scroll={{ y: "340" }}
                    >
                      <Column title="Tournament" dataIndex="tournament" key="tournament" />
                      <Column title="Content Type " dataIndex="contentType" key="contentType" />

                      <Column
                        title="Quick Actions"
                        key="notifId"
                        render={(notifId, record, index) => (
                          <Space size="middle">
                            <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                            <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                          </Space>
                        )}
                      />
                    </Table>
                  </TabPane>
                </Tabs>
              </Card>
            </div>

            <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="small">
              <Form name="horizontal_login" layout="vertical" form={infoform} onFinish={onInfofinish}>
                <Row gutter={24}>
                  <Col className="gutter-row" span={24}>
                    <Form.Item label="Tournament" name="tournament" rules={[{ required: true, message: "please select tournament" }]}>
                      <Select size="default" placeholder="Select Tournament" style={{ width: "100%" }}>
                        {tournament.map((item4, index4) => (
                          <Option key={index4} value={item4.tournamentName}>
                            {" "}
                            {item4.tournamentName}{" "}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col className="gutter-row" span={24}>
                    <Form.Item label="Content Type" name="contenttype" rules={[{ required: true, message: "please select content type" }]}>
                      <Select size="default" placeholder="Select Content Type" style={{ width: "100%" }}>
                        {contentType.map((item5, index5) => (
                          <Option key={index5} value={item5}>
                            {" "}
                            {item5}{" "}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} className="rtmp1">
                  <Col span={24}>
                    <Form.Item {...tailFormItemLayout}>
                      <Button type="primary" htmlType="submit">
                        {title === "New Info" ? "Add" : "Update"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>

            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}

            {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Landinginfo;
