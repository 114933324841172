import { collection, getDocs, getDoc, doc, setDoc } from "firebase/firestore";
import { Select, Layout, Card, Input, Pagination, Table, Space, Button, Modal, Drawer, PageHeader, Spin, Tabs, Row, Col, Form, Divider } from "antd";
import { db, currentTournamentDetails, logout } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Editmatch from "./editmatch";
import moment from "moment";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import Moment from "react-moment";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import {
    DeleteOutlined,
    EditTwoTone,
    LoginOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { async } from "@firebase/util";
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;
const { TabPane } = Tabs;
function PackageManager() {
    const [form] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState("ios");
    const [StatusVisible, setStatusVisible] = useState(false);
    const [DeleteVisible, setDeleteVisible] = useState(false);
    const [ID, setID] = useState("");
    const [Operation, setOperation] = useState("Add");
    const [packageList, setPackageList] = useState([]);
    const [PackageFilteredList, setPackageFilteredList] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [teams, setTeams] = useState([]);
    const [editmatchid, setEditmatchid] = useState([]);
    const [matchinfo, setMatchinfo] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [scheduleFil, setScheduleFil] = useState([]);
    const [matchdetails, setMatchDetails] = useState([]);
    const [matchDetails1, setMatchDetails1] = useState([]);
    const [searchValue, setSearchValue] = useState(currentTournamentDetails.shortName);
    const [seasonType, setSeasonType] = useState("REGULAR");
    const [matchyear, setMatchyear] = useState([]);
    const [seasonTypeList, setSeasonTypeList] = useState([]);
    const [matchValue, setMatchValue] = useState(2023);
    const [limit, setLimit] = useState(10);
    const [startAt, setStartAt] = useState(0);
    const [totalRows, setTotalRows] = useState();

    const showDrawer = async (matchid) => {
        setVisible(true);
        setOperation("Edit")
        setID(matchid.id)
        setSelectedOption(matchid.platform)
        form.setFieldsValue({
            appId: matchid.appId,
            name: matchid.name,
            description: matchid.description,
            fmCoins: matchid.fmCoins,
            price: matchid.price,
            provider: matchid.provider,
            cutOffPrice: matchid.cutOffPrice,
            light: matchid.style.light ? matchid.style.light : "#000000",
            dark: matchid.style.dark ? matchid.style.dark : "#000000",
        });
    };
    const addNew = async () => {
        setVisible(true);
        setOperation("Add")
        form.setFieldsValue({
            appId: selectedOption,
            name: null,
            description: null,
            fmCoins: null,
            price: null,
            provider: null,
            cutOffPrice: null,
            light: "#000000",
            dark: "#000000",
        });
    };
    const deletePackage = async (matchid) => {
        setID(matchid.id)
        setDeleteVisible(true);
    };
    const changeStatus = async (matchid) => {
        setID(matchid.id)
        setStatusVisible(true);
    };

    const onPagingChange = (e) => {
        setStartAt(e - 1);
        ListAll(limit, e - 1, null);
    }
    
    const statusThePackage = async () => {
        setLoaded(true);
        let data = {
            "id": ID
        }
        
        const response = await axios.post(
            `${domain}/betops/iap/toggle-status/v1`, data)
            .then((response) => {

                onClose()
                setLoaded(false);
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.data,
                    });
                    setTimeout(async () => {
                        ListAll(10, 0, null);
                    }, 2000);
                }
                else if (response.data.status === "FAIL") {
                    Modal.error({
                        content: response.data.data,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    
    const deleteThePackage = async () => {
        setLoaded(true);
        let dataPayload = {
            "id": ID
        }
        const response = await axios.delete(
            `${domain}/betops/iap/delete/v1`, { data: dataPayload })
            .then((response) => {

                onClose()
                setLoaded(false);
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: response.data.data,
                    });
                    setTimeout(async () => {
                        ListAll(10, 0, null);
                    }, 2000);
                }
                else if (response.data.status === "FAIL") {
                    Modal.error({
                        content: response.data.data,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    const onClose = () => {
        setID("")
        setVisible(false);
        setStatusVisible(false);
        setDeleteVisible(false);
        form.resetFields();
        setOperation("Add")
    };

    const handleMatch = (e) => {
        setMatchValue("");
        setMatchyear([]);
        setMatchDetails([]);
        if (e) {
            const matchdata = teams
                .filter(item => item.tournament == e)
                .map(function (item) {
                    return item;
                });
            const data = scheduleFil
                .filter(item => item.tournament == e)
                .map(function (item) {
                    return item;
                });
            const arr1 = getUniqueListBy(data, "year");
            setSchedule(data);
            setMatchyear(arr1);
            setSearchValue(e);
            setMatchinfo(matchdata);
        }
    };
    const getUniqueListBy = (arr, key) => {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    const onSearch = async (e) => {
        if (e.target.value !== "") {
            let filterValue = "name";
            let filterParams;
            let filterData = "";
            if (filterValue === "name") {
                filterData = PackageFilteredList.filter(list => list.name === e.target.value)
                if (filterData.length > 0) {
                    filterParams = `&search=${e.target.value}`;
                    await ListAll(limit, 0, filterParams);
                } else {
                    setPackageList([]);
                    setTotalRows(0);
                    return false;
                }
            }
        } else {
            await ListAll(10, 0, null);
        }

        // if (e.target.value !== "") {
        //     const lowercasedFilter = e.target.value.toLowerCase();
        //     const filteredData = PackageFilteredList.filter((item) => {
        //         return Object.keys(item).some((key) => {
        //             if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
        //             return false;
        //         });
        //     });
        //     setPackageList(filteredData);
        // } else {
        //     setPackageList(PackageFilteredList);
        // }
    };
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);
    useEffect(async () => {
        ListAll(10, 0, null);
    }, []);
    const handleSchedule = (e) => {

    }
    const ListAll = async (limit, startAt, filterParams) => {
        setLoaded(true);
        let makeUrl;
        if (filterParams) {
            makeUrl = `${domain}/betops/iap/get-list/v2?appId=${currentTournamentDetails.shortCode}-com&limit=${limit}&startAt=${startAt}${filterParams}`
        } else {
            makeUrl = `${domain}/betops/iap/get-list/v2?appId=${currentTournamentDetails.shortCode}-com&limit=${limit}&startAt=${startAt}`
        }
        const response = await axios({
            method: 'get',
            url: makeUrl,
            headers: {"Authorization" : `Bearer ${localStorage.getItem("aT")}`}
        }).then((response) => {
            setLoaded(false);
            if (response.data.status === "SUCCESS") {
                setTotalRows(response.data.data.total);
                let datas = response.data.data.packageList.sort(function (a, b) {
                    return moment(b.createdDate._seconds * 1000)._d.getTime() - moment(a.createdDate._seconds * 1000)._d.getTime();
                });
                setPackageList(datas)
                setPackageFilteredList(datas)
            }
            else {
                setLoaded(false);
                if(response.data.error.message == "Auth token is not valid") {
                    Modal.error({
                      content: response.data.error.message,
                      onOk() {
                        logout();
                      }
                    });
                  } else {
                    Modal.error({
                      content: response.data.error.message
                    });
                  }
            }
        })
            .catch((error) => {
                setLoaded(false);
                // Modal.error({
                //     content: "Unable to process your Request, Please try again later.",
                // });
            })
    }
    const handleTableChange = (pagination, filters, sorter) => {

        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setPackageList(packageList.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {
            setPackageList(packageList.reverse());
        }
    }
    const sortArray = (type) => {

        const types = {
            fmCoins: "fmCoins",
            price: "price",
        };
        const sortProperty = types[type];
        const sorted = [...packageList].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });

        setPackageList(sorted);
    };
    const onFinish = async (values) => {

        setLoaded(true);
        if (Operation === "Edit") {
            let data = {
                "appId": selectedOption === "ios" ? `${currentTournamentDetails.shortCode}-ios` : `${currentTournamentDetails.shortCode}-and`,
                "name": values.name,
                "id": ID,
                "description": values.description,
                "fmCoins": parseInt(values.fmCoins),
                "price": parseFloat(values.price),
                "provider": values.provider,
                "cutOffPrice": parseInt(values.cutOffPrice),
                "style": {
                    "light": values.light,
                    "dark": values.dark,
                }
            }
            const response = await axios.post(
                `${domain}/betops/iap/update/v1`, data)
                .then((response) => {

                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        onClose();
                        Modal.success({
                            content: response.data.data,
                        });
                        setTimeout(async () => {
                            ListAll(10, 0, null);
                        }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.data,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
        else {
            
            let data = {
                "appId": selectedOption === "ios" ? `${currentTournamentDetails.shortCode}-ios` : `${currentTournamentDetails.shortCode}-and`,
                "name": values.name,
                "description": values.description,
                "fmCoins": parseInt(values.fmCoins),
                "price": parseFloat(values.price),
                "provider": values.provider,
                "cutOffPrice": parseInt(values.cutOffPrice),
                "style": {
                    "light": values.light,
                    "dark": values.dark,
                }
            }
            const response = await axios.post(
                `${domain}/betops/iap/create/v1`, data)
                .then((response) => {

                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        onClose();
                        ListAll(10, 0, null);
                        Modal.success({
                            content: response.data.data
                        });
                        // setTimeout(async () => {
                            // ListAll(10, 0, null);
                        // }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.data,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
    }
    const handleChange = (e) => {

        setSelectedOption(e)
    }
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="package_manager" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <div>
                                    <PageHeader
                                        className="site-page-header"
                                        title="Package List"
                                        extra={
                                            <Space direction="horizontal">
                                                {" "}
                                                <label className="inputlabel">Filter</label>
                                                <Search placeholder="Enter name" onChange={onSearch} enterButton />
                                                {/* <Select size="default" value={searchValue} placeholder="All Tournaments" style={{ width: "200px" }} onChange={(e) => handleMatch(e)}>
                                                        {teams.map((item, index) => (
                                                            <Option key={index} value={item.tournament}>
                                                                {item.tournament}{" "}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                    ,
                                                    <Select size="default" value={matchValue} style={{ width: "200px" }} onChange={(e) => handleSchedule(e)}>
                                                        {matchyear.map((name, pos) => {
                                                            return (
                                                                <Option key={pos} value={name.year}>
                                                                    {name.year}{" "}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select> */}
                                                <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                <a onClick={() => addNew()}>
                                                    <span className="addbet">New Package</span>
                                                </a>
                                            </Space>
                                        }
                                    />
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={packageList}
                                        onChange={handleTableChange}
                                        pagination={false}
                                        scroll={{ y: "250" }}
                                    >
                                        <Column title="Name" dataIndex="name" key="name" />
                                        <Column title="App Id" dataIndex="platform" key="platform" />
                                        <Column title="FM Coins" dataIndex="fmCoins" key="fmCoins" sorter="true" />
                                        <Column
                                            title="Created Date"
                                            dataIndex="createdDate"
                                            key="createdDate"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text ? (
                                                        <Moment unix format="DD-MM-YYYY">
                                                            {text._seconds}
                                                        </Moment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        <Column
                                            title="Modified Date"
                                            dataIndex="modifiedDate"
                                            key="modifiedDate"
                                            render={(text, record) => (
                                                <Space size="middle">
                                                    {text ? (
                                                        <Moment unix format="DD-MM-YYYY">
                                                            {text._seconds}
                                                        </Moment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />
                                        {/* <Column title="Cut Off Price" dataIndex="cuttOffPrice" key="cuttOffPrice" /> */}
                                        <Column title="Price" dataIndex="price" key="price" sorter="true" />
                                        <Column title="Status" dataIndex="status" key="status" />

                                        <Column
                                            title="Quick Actions"
                                            key="id"
                                            render={(id) => (
                                                <Space size="middle">
                                                    {id !== undefined ? (
                                                        <>
                                                            <DeleteOutlined onClick={() => deletePackage(id)} title="Delete Package" style={{ fontSize: "18px", color: "#08c" }} />
                                                            <LoginOutlined onClick={() => changeStatus(id)} title="Change Package status" style={{ fontSize: "18px", color: "#08c" }} />
                                                            <EditTwoTone onClick={() => showDrawer(id)} title="Edit Package" style={{ fontSize: '18px', color: '#08c' }} />
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                    <Pagination className="float-end paginationTable manualPagination"
                                    total={totalRows}
                                    showSizeChanger={false}
                                    current={startAt + 1}
                                    pageSize={limit}
                                    onChange={onPagingChange}
                                />
                                </div>


                            </Card>
                        </div>
                        <Modal title="Delete" onCancel={onClose} visible={DeleteVisible}
                            footer={[<>  <Button key="submit" type="primary" onClick={deleteThePackage}>
                                Ok
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>

                            ]}
                        >
                            Are you sure you want to Delete the Package?
                        </Modal>
                        <Modal title="Status" onCancel={onClose} visible={StatusVisible}
                            footer={[<>  <Button key="submit" type="primary" onClick={statusThePackage}>
                                Ok
                            </Button>
                                <Button key="submit" type="primary" onClick={onClose}>
                                    Cancel
                                </Button></>

                            ]}
                        >
                            Are you sure you want to Change the Package Status?
                        </Modal>
                        <Drawer title="Update Package" placement="right" onClose={onClose} visible={visible} className="matchcard">
                            <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please input Package Name!" }]}>
                                            <Input type="text" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please input Description!" }]}>
                                            <Input placeholder="Description" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <p className="mainlabel"> App Id</p>
                                        <Select value={selectedOption} style={{ width: "100%" }} onChange={(e) => handleChange(e)}>
                                            <Option key="1" value="ios">
                                                `${currentTournamentDetails.shortCodeCapitalize}_IOS`
                                            </Option>
                                            <Option key="2" value="android">
                                                `${currentTournamentDetails.shortCodeCapitalize}_Android`
                                            </Option>
                                        </Select>{" "}
                                    </Col>
                                </Row>
                                <br />
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="fmCoins" label="Fm Coins" rules={[{ required: true, message: "Please input fmCoins!" }]}>
                                            <Input placeholder="Fm Coins" type="number" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="price" label="Price" rules={[{ required: true, message: "Please input Price!" }]}>
                                            <Input placeholder="Price" type="number" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="provider" label="Provider" rules={[{ required: true, message: "Please input Provider!" }]}>
                                            <Input placeholder="Provider" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="cutOffPrice" label="CutOff Price" rules={[{ required: true, message: "Please input CutOffPrice!" }]}>
                                            <Input placeholder="CutOff Price" type="number" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="light" label="Light" rules={[{ required: true, message: "Please input Light!" }]}>
                                            <Input placeholder="Light" type="color" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="dark" label="Dark" rules={[{ required: true, message: "Please input Dark!" }]}>
                                            <Input placeholder="Dark" type="color" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>

                                <Row gutter={24} className="rtmp1">
                                    <Col span={24}>
                                        <Form.Item shouldUpdate {...tailLayout}>
                                            {() => (
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Update
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Drawer>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
                    </Content>

                    <Footersection />
                </Layout>
            </>

        </Layout >
    );
}
export default PackageManager;
