import { collection, getDocs, query, doc, updateDoc, deleteDoc, limit } from "firebase/firestore";
import { Space, Spin, Layout, Card, Tabs, Table, Modal, PageHeader, Drawer } from "antd";
import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "antd/dist/antd.min.css";
import Firebase from "firebase/compat/app";
import { PlusCircleTwoTone, DeleteTwoTone, EditTwoTone, UnlockOutlined } from "@ant-design/icons";
import moment from "moment";
import { db, auth } from "../settings/config";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import AddVersion from "./addVersion";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Column } = Table;

function VersionsControl() {
    const [loaded, setLoaded] = useState(false);
    const [title, setTitle] = useState("");

    const [iosList, setIosList] = useState([]);
    const [androidList, setAndroidList] = useState([]);
    const [user, loading] = useAuthState(auth);
    const [visible, setVisible] = useState(false);
    const [VisibleStatus, setVisibleStatus] = useState(false);
    const [currApp, setCurrentObj] = useState({});
    const navigate = useNavigate();
    const { confirm } = Modal;

    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);

    const callback = (key) => {
        //fetchVersionData();
    };

    const addnew = () => {
        setCurrentObj({});
        setTitle("Add New");
        setVisible(true);
    };

    const editVersion = (obj, index) => {
        setCurrentObj(obj);
        setTitle("Edit");
        setVisible(true);
    };

    const changeStatus = async (docObj) => {

        let data = {
            appVersionsConfig: {
                platform: docObj.platform,
                name: docObj.name,
                type: docObj.type,
                code: docObj.code,
                packageName: docObj.packageName || "",
                url: docObj.url || "",
                createdDate: docObj.createdDate,
                modifiedDate: new Date(),
                active: docObj.active ? false : true
            }
        }

        confirm({
            content: "Do you want to change the status ?",
            onOk() {
                setLoaded(true);
                let types = androidList

                if (docObj.platform.toLowerCase() === "android") {
                    types = androidList
                }
                else {
                    types = iosList
                }

                if (data.appVersionsConfig.active) {
                    for (let i = 0; i < types.length; i++) {

                        if (docObj.code === types[i].code) {
                            data = {
                                appVersionsConfig: {
                                    platform: types[i].platform,
                                    name: types[i].name,
                                    type: types[i].type,
                                    code: types[i].code,
                                    packageName: types[i].packageName || "",
                                    url: types[i].url || "",
                                    createdDate: types[i].createdDate,
                                    modifiedDate: new Date(),
                                    active: true
                                }
                            }

                            updateDoc(doc(db, "app_version", types[i].key), data)
                                .then((res) => {

                                    fetchVersionInfo();
                                })
                                .catch((error) => {

                                    fetchVersionInfo();
                                })
                        }
                        else {

                            if (types[i].active) {
                                data = {
                                    appVersionsConfig: {
                                        platform: types[i].platform,
                                        name: types[i].name,
                                        type: types[i].type,
                                        code: types[i].code,
                                        packageName: types[i].packageName || "",
                                        url: types[i].url || "",
                                        createdDate: types[i].createdDate,
                                        modifiedDate: new Date(),
                                        active: false
                                    }
                                }
                                updateDoc(doc(db, "app_version", types[i].key), data)
                                    .then((res) => {
                                        fetchVersionInfo();
                                    })
                                    .catch((error) => {
                                        fetchVersionInfo();
                                    })
                            }
                        }
                    }
                }
                else {
                    updateDoc(doc(db, "app_version", docObj.key), data)
                        .then((res) => {

                            fetchVersionInfo();
                        })
                        .catch((error) => {

                            fetchVersionInfo();
                        })
                }

                onClose();
            },
            onCancel() {
                setVisibleStatus(true);
                setLoaded(false);
            },
        });
    };
    const deleteRecord = async (docObj) => {
        setLoaded(true);
        confirm({
            content: "Do you want to delete the version entry ?",
            onOk() {
                deleteDoc(doc(db, "app_version", docObj.key));
                onClose();
            },
            onCancel() {
                setVisible(false);
                setLoaded(false);
            },
        });
    };

    const onClose = () => {
        setLoaded(false);
        setVisible(false);
        setVisibleStatus(false);
        fetchVersionInfo();
    };
    const datesplit = (text) => {
        var timeset = moment(text.toDate()).format("DD-MM-YYYY HH:MM:SS");
        return timeset;
    };
    const fetchVersionInfo = async () => {
        const _versionCol = collection(db, "app_version");
        const _versionSnapshot = await getDocs(_versionCol);
        let _versionList = _versionSnapshot.docs.map((doc) => {
            return {
                documentID: doc.id,
                appVersionsConfig: doc.data().appVersionsConfig || {},
            };
        });

        let _iosArr = [];
        let _androidArr = [];
        _versionList = _versionList.sort(function (a, b) {
            return a.appVersionsConfig.code - b.appVersionsConfig.code;
        });
        _versionList = _versionList.reverse();
        for (let i in _versionList) {
            let obj = _versionList[i].appVersionsConfig;

            obj.key = _versionList[i].documentID;
            if (obj.platform.toLowerCase() == "ios") {
                _iosArr.push(obj);
            } else if (obj.platform.toLowerCase() == "android") {
                _androidArr.push(obj);
            }
        }
        setAndroidList(_androidArr);
        setIosList(_iosArr);
    };

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setLoaded(false);
            }, 2000);
        }
        fetchVersionInfo();
    }, [loaded]);

    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <Sidermenu menu="versions" />
            <Layout className="site-layout">
                <Headersection />
                <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                    <div className="site-card-border-less-wrapper">
                        <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                            <PageHeader
                                className="site-page-header"
                                title="App Version Control"
                                extra={
                                    <>
                                        {" "}
                                        <Space direction="horizontal">
                                            {" "}
                                            <a onClick={() => addnew()}>
                                                <span class="addbet">Add New</span> <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                            </a>
                                        </Space>{" "}
                                    </>
                                }
                            />
                            <Tabs tabPosition="left" defaultActiveKey="android" onChange={callback}>
                                <TabPane tab="Android App" key="android">
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={androidList}
                                        scroll={{ y: "340" }}
                                    >
                                        <Column title="Version" dataIndex="name" key="name" />
                                        <Column title="Code" dataIndex="code" key="code" />
                                        <Column title="Version Type" dataIndex="type" key="type" />
                                        <Column
                                            title="Status"
                                            dataIndex="active"
                                            key="active"
                                            render={(text, record) => <Space size="middle">{text ? "Active" : "In Active"}</Space>}
                                        />
                                        <Column title="Created Date" dataIndex="createdDate" key="createdDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} />
                                        <Column title="Modified Date" dataIndex="modifiedDate" key="modifiedDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} />
                                        <Column
                                            title="Quick Actions"
                                            key="notifId"
                                            render={(notifId, record, index) => (
                                                <Space key={index} size="middle">
                                                    <DeleteTwoTone onClick={() => deleteRecord(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    <EditTwoTone onClick={() => editVersion(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    <UnlockOutlined onClick={() => changeStatus(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                </TabPane>
                                <TabPane tab="IOS App" key="ios">
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={iosList}
                                        scroll={{ y: "340" }}
                                    >
                                        <Column title="Version" dataIndex="name" key="name" />
                                        <Column title="Code" dataIndex="code" key="code" />
                                        <Column title="Version Type" dataIndex="type" key="type" />
                                        <Column
                                            title="Status"
                                            dataIndex="active"
                                            key="active"
                                            render={(text, record) => <Space size="middle">{text ? "Active" : "In Active"}</Space>}
                                        />
                                        <Column title="Created Date" dataIndex="createdDate" key="createdDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} />
                                        <Column title="Modified Date" dataIndex="modifiedDate" key="modifiedDate" render={(text, record) => <Space size="middle">{text ? datesplit(text) : ""}</Space>} />
                                        <Column
                                            title="Quick Actions"
                                            key="actions"
                                            render={(notifId, record, index) => (
                                                <Space key={index} size="middle">
                                                    <DeleteTwoTone onClick={() => deleteRecord(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    <EditTwoTone onClick={() => editVersion(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                    <UnlockOutlined onClick={() => changeStatus(notifId)} style={{ fontSize: "18px", color: "#08c" }} />
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                </TabPane>
                            </Tabs>
                        </Card>
                        <Drawer title={title + " Version"} placement="right" onClose={onClose} visible={visible} size="small">
                            <AddVersion drawertype={title} onClose={onClose} currObj={currApp} />
                        </Drawer>
                    </div>

                    {loaded == true ? (
                        <div className="loading-overlay">
                            <Spin size="large" />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                </Content>
                <Footersection />
            </Layout>
        </Layout>
    );
}

export default VersionsControl;
