import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { currentTournamentDetails, db } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import ReactJson from "react-json-view";
import Moment from "react-moment";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Aid() {
  const [form, infoform] = Form.useForm();

  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [pushdata, setPushdata] = useState([]);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [editmatchid, setEditmatchid] = useState([]);
  const [matchinfo, setMatchinfo] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [matchdetails, setMatchDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [matchyear, setMatchyear] = useState([]);
  const [matchValue, setMatchValue] = useState("");
  const [drawertype, setDrawertype] = useState("");
  const [title, setTitle] = useState("");
  const [info2, setInfo2] = useState([]);
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showJsonmodal = async (documentID) => {
    const matchDocRef = doc(db, "schedule", documentID.documentID);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    setJsondata(arraycheck);
    setJsonviewer(true);
  };
  const callback = (key) => {
    console.log(key);
  };
  const handleJson = () => {
    //downloadFile(arraycheck,documentID.documentID)
    setJsonviewer(false);
  };

  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Info");
    setVisible(true);
    // const docRef = addDoc(collection(db, "schedule"), {
    //     Title: "test",
    //     notifId: "",

    //   });
    //   console.log("Document written with ID: ", docRef.id);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    console.log(blob);
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadocument = async (documentID) => { };

  const deletedocument = (documentID) => {
    const docRef = deleteDoc(doc(db, "schedule", documentID.documentID));
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        window.location.reload(true);
      },
    });
  };
  const showDrawer = (matchid, index) => {
    console.log(index);
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const datesplit = (text) => {
    const res = text.split("_");
    var currentTimeInSeconds = Math.floor(res[1] / 1000);
    return currentTimeInSeconds;
  };
  const deleterecord = async (matchid, index1) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());

    let filtered = arraycheck.info2.filter(function (value, index, arr) {
      return index !== index1;
    });
    arraycheck.info2 = filtered;
    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };
  const onClose = () => {
    setVisible(false);
  };
  const textpicker = (value, dateString) => {
    var date = new Date(dateString);
    var milliseconds = date.getTime();
    setTexttime(dateString);
  };
  const textok = (value) => {
    console.log(value);
  };
  const titlepicker = (value, dateString) => {
    console.log(dateString);
    setTitletime(dateString);
  };
  const titleok = (value) => {
    console.log(value._i);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const content = (notifId) => {
    return (
      <div>
        <p>{notifId.title}</p>
        <p>{notifId.desc}</p>
      </div>
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleTitlechange = (e) => {
    setTitledata(e);
  };
  const handleTextchange = (e) => {
    setSubtxtdata(e);
  };
  const handleTournament = (e) => {
    setTournamenttype(e);
  };
  const handleContent = (e) => {
    setContenttype(e);
  };
  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const fetchTeams = async () => {
    const teamsCol = collection(db, "teams");
    const teamsSnapshot = await getDocs(teamsCol);
    const teamList = teamsSnapshot.docs.map((doc) => doc.data());
    setTeams(teamList);
  };
  const onInfofinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    if (title === "New Info") {
      arraycheck.info2.push({
        contentType: contenttype,
        tournament: tournamenttype,
      });
    } else {
      arraycheck.info2[currentindex] = {
        contentType: contenttype,
        tournament: tournamenttype,
      };
    }

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: title === "New Info" ? "New Info Added Successfully" : "Updated Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };
  const onFinish = async (values) => {
    console.log(values);
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());

    arraycheck.aid = values.aid;

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Updated Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };
  const fetchDashboard = async () => {
    const scheduleCol = collection(db, "configuration");
    const scheduleSnapshot = await getDocs(scheduleCol);
    const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
    scheduleSnapshot.docs.map((doc) => {
      if (doc.id === currentTournamentDetails.majorUsedDocId) var docdata = doc.data();
      if (docdata !== undefined) {
        let tabarray = [];
        tabarray.push(docdata);
        form.setFieldsValue({
          aid: docdata.aid,
        });
        setSchedule(tabarray);
      }
    });
  };
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <Form layout="horizontal" form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Form.Item label="Aid" name="aid" rules={[{ required: true, message: "Input your Aid!" }]}>
            <Input placeholder="Input aid" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </Form>
  );
}

export default Aid;
