import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { Modal} from "antd";

let countdownInterval;
let timeout;
const { confirm } = Modal;

const SessionTimeout = ({isAuthenticated, logOut}) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const idleTimer = useRef(null);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
        logOut();
     } catch (err) {
        console.error(err);
      }
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated) {
      timeout = setTimeout(() => {
        confirm({
          content: "Session expired. Still want to continue ? Will be signed out in 5 secs.",
          onOk() {
            handleContinue();
          },
          onCancel() {
            handleLogout();
          },
        });

        setTimeoutModalOpen(true);
      }, delay);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={3600000}
      />
    </>
  );
}

export default SessionTimeout;