import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Eightmin from "./gconfig/eightmin";
import Quiz from "./gconfig/quiz";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";
import axios from "axios";
import { domain } from "../../configuration/ApiConfiguration";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];

function Gconfig(props) {
  const [infoform] = Form.useForm();

  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [title, setTitle] = useState("");

  const [tournamenttype, setTournamenttype] = useState();
  const [tournament, setTournament] = useState([]);
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [tabarray, setTabarray] = useState([]);
  const [updatedEightminData, setUpdatedEightminData] = useState(null);
  const [updatedQuizData, setUpdatedQuizData] = useState(null);
  const [tempSchedule, setTempSchedule] = useState([]);

  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Season");
    setVisible(true);
  };

  const showDrawer = (matchid, index) => {
    setCurrentindex(index);
    infoform.setFieldsValue({
      tournamentName: matchid.tournament,
      season: matchid.year,
    });
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const callback = (key) => { };
  const renderSwitch = (param) => {
    switch (param) {
      case "EightMin":
        return <Eightmin docdata={props.docdata.gconfig} updateEightmin={updateEightmin} />;
      case "Quiz":
        return <Quiz docdata={props.docdata.gconfig} updateQuiz={updateQuiz} />;

      default:
        return "foo";
    }
  };

  const updateEightmin = (obj) => {
    setUpdatedEightminData(obj);
  }

  const updateQuiz = (obj) => {
    setUpdatedQuizData(obj);
  }

  const onClose = () => {
    infoform.resetFields();
    setVisible(false);
  };

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const onInfofinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    if (title === "New Season") {
      arraycheck.season.push({
        tournament: values.tournamentName,
        year: values.season,
      });
    } else {
      arraycheck.season[currentindex] = {
        tournament: values.tournamentName,
        year: values.season,
      };
    }

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: title === "New Season" ? "New Season Added Successfully" : "Updated Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };

  const fetchDashboard = async () => {
    let tabarray = [];
    for (var key in props.docdata.gconfig) {
      tabarray.push(key.charAt(0).toUpperCase() + key.slice(1));
    }
    tabarray.sort(function (a, b) {
      return a.localeCompare(b);
    });
    // setSchedule(props.docdata.gconfig[0]);
    setTabarray(tabarray);
    setTempSchedule(props.docdata.gconfig);
    // if (doc.id === "admin_console") {
    //   var docdata = doc.data();
    //   if (docdata !== undefined) {
    //     setTournament(docdata.tournament);
    //   }
    // }
  };

  const finaldocsave = async () => {
    setLoaded(true);
    if (updatedEightminData && updatedEightminData.eightMin) {
      tempSchedule.eightMin = updatedEightminData.eightMin;
    }

    if (updatedQuizData && updatedQuizData.quiz) {
      tempSchedule.quiz = updatedQuizData.quiz;
    }

    const response = await axios({
      method: 'post',
      url: `${domain}/betops/config/get-update-appConfig/v1`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        "appId": `${currentTournamentDetails.shortCode}-ops`,
        "gconfig": tempSchedule
      },
    }).then((response) => {
      setLoaded(false);
      Modal.success({
        content: "Saved Successfully",
        onOk() {
          window.location.reload(true);
        },
      });
    })
      .catch((error) => {
        setLoaded(false);
      })
  }

  useEffect(() => {
    var currentyear = new Date().getFullYear();
    const yearlist = [];
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i);
    }
    setYearlist(yearlist.reverse());
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <div>
      <Tabs tabPosition="left" defaultActiveKey="1" onChange={callback}>
        {tabarray.map((key, value) => {
          return (
            <>
              {key == "EightMin" || key == "Quiz" ? (
                <TabPane tab={key} key={key}>
                  {renderSwitch(key)}
                </TabPane>
              ) : (
                ""
              )}
            </>
          );
        })}
      </Tabs>

      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
      <Button type="primary" className="configEndbtn" onClick={finaldocsave}>
        Save
      </Button>
    </div>
  );
}

export default Gconfig;
