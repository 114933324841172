import { doc, setDoc } from "firebase/firestore";
import { Select, Layout, Row, Col, Table, Spin, Tag, Upload, Button, Modal, Form } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import { Validator } from "jsonschema";

const SCHEDULE_SCHEMA = require("../Jsondata/schema/schedule.json");
const PLAYOFF_SCHEMA = require("../Jsondata/schema/playoff.json");
const INSIGHTS_SCHEMA = require("../Jsondata/schema/insights.json");
const AUCTIONS_SCHEMA = require("../Jsondata/schema/auction.json");
const FANTACY_SCHEMA = require("../Jsondata/schema/fantasy.json");

const { Option } = Select;
const doctypearray = [
  { key: 1, value: "SCHEDULE" },
  { key: 2, value: "PLAY_OFF" },
  { key: 3, value: "INSIGHTS" },
  { key: 4, value: "FANTASY_LINEUP" },
  { key: 5, value: "AUCTION" },
];
function Addschedule(props) {
  const [loaded, setLoaded] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [doctype, setDoctype] = useState(doctypearray[0].value);
  const [msgerror, setMsgerror] = useState("");
  const [filelist, setFilelist] = useState(false);

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const fileReader = (result) => {
    setFilelist(true);
    setDataset(result);
  };
  const loadProfile = () => {
    setMsgerror("");
    var currentyear = new Date().getFullYear();
    const yearlist = [];
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i);
    }
    setYearlist(yearlist.reverse());
  };
  useEffect(() => {
    loadProfile();
  }, []);

  const handleChange = (e) => {
    setDoctype(e);
  };

  const validateScheduleJson = (jsonObj, docType = "") => {

    let jsonSchema = null;

    try {
      let jsonValidator = new Validator();

      if (docType === "SCHEDULE") {
        jsonSchema = SCHEDULE_SCHEMA;
      } else if (docType === "PLAY_OFF") {
        jsonSchema = PLAYOFF_SCHEMA;
      } else if (docType === "INSIGHTS") {
        jsonSchema = INSIGHTS_SCHEMA;
      } else if (docType === "FANTASY_LINEUP") {
        jsonSchema = FANTACY_SCHEMA;
      } else if (docType === "AUCTION") {
        jsonSchema = AUCTIONS_SCHEMA;
      } else if (docType === "SCHEMA") {
        return "SCHEMA";
      }

      if (jsonSchema) {
        jsonValidator.attributes.contains = function validateContains(instance, schema, options, ctx) {
          if (typeof instance !== "string") return;
          if (schema.contains.indexOf(instance) < 0) {
            return "does not contain the string " + JSON.stringify(schema.contains);
          }
        };
        let _data = jsonObj || {};
        let validate = jsonValidator.validate(_data, jsonSchema);
        if (validate.errors.length == 0) return null;
        else return validate.errors;
      }
    } catch (err) {
      return null;
    }
    return null;
  };

  const onFinish = async (values) => {
    setLoaded(true);
    let jsonObject = JSON.parse(dataset);
    let errorObj = null;
    if (jsonObject) errorObj = validateScheduleJson(jsonObject, doctype);
    if (errorObj == "SCHEMA") {
      await setDoc(doc(db, "schedule", doctype + "_" + values.tournament), jsonObject);
      setLoaded(false);
      Modal.success({
        content: "schedule Schema Added successfully",
        onOk() {
          props.onClose();
          props.schedulereload();
        },
      });
      return;
    }
    if (errorObj != null) {
      setLoaded(false);
      Modal.error({
        title: doctype + " Json Schema Validation ",

        content: (
          <div style={{ width: "100%", height: "300px", overflow: "auto", padding: "8px" }}>
            <h4 style={{ color: "#274f9d" }}>Found {errorObj.length} Error(s)</h4>
            {errorObj.map((item, index) => {
              let _type = item.path[item.path.length - 1];
              let _key = item.path.length > 0 ? item.path.join(" >> ") : item.argument;
              let _msg = item.message;
              if (item.name == "required") {
                _type = item.path.length > 0 ? item.path[0] : "";
                _msg = item.argument + " " + "required";
              }
              _msg = _type + " " + _msg;

              return (
                <Tag key={index} color="geekblue" style={{ padding: "8px", marginBottom: "12px", width: "96%" }}>
                  <span style={{ whiteSpace: "break-spaces", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <b style={{ paddingRight: "18px", color: "#274f9d" }}>Key</b>:<span> {_key.trim()}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <b style={{ paddingRight: "12px", color: "purple" }}>Error</b>:<span> {_msg.trim()}</span>{" "}
                    </div>
                  </span>
                </Tag>
              );
            })}
          </div>
        ),
        onOk() {

        },
      });
      return;
    }

    const checktournament = props.scheduledata.some(
      (item) => values.tournament === item.tournamenttitle && Number(values.season) === item.Year && doctype === item.docType
    );
    if (checktournament == false) {
      if (jsonObject.tournament === values.tournament && jsonObject.year === values.season && jsonObject.docType === doctype) {
        await setDoc(doc(db, "schedule", values.season + "_" + doctype + "_" + values.tournament), jsonObject);
        Modal.success({
          content: "Schedule Added successfully",
          onOk() {
            setLoaded(false);
            props.onClose();
            props.schedulereload();
          },
        });
      } else {
        Modal.error({
          content: "Please check the tournament details in json file",
          onOk() {
            setLoaded(false);
          },
        });
      }
    } else {
      Modal.error({
        content: "Already Exists",
        onOk() {
          setLoaded(false);
        },
      });
    }
  };

  return (
    <>
      <Form form={props.form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="tournament" label="Select Tournaments" rules={[{ required: true, message: "Please Select Tournaments!" }]}>
              <Select size="default" placeholder="Select Tournaments" style={{ width: "100%" }}>
                {props.teams.map((item, index) => (
                  <Option key={index} value={item.tournament}>
                    {item.tournament}{" "}
                  </Option>
                ))}
              </Select>
            </Form.Item>{" "}
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="season" label="Select Season" rules={[{ required: true, message: "Please Select Season!" }]}>
              <Select size="default" placeholder="Select Season" style={{ width: "100%" }}>
                {yearlist.map((item1, index1) => (
                  <Option key={index1} value={item1}>
                    {item1}{" "}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Select size="default" value={doctype} placeholder="Select Document Type" style={{ width: "100%" }} onChange={handleChange}>
              {doctypearray.map((item2, index2) => (
                <Option key={index2} value={item2.value}>
                  {" "}
                  {item2.value}{" "}
                </Option>
              ))}
            </Select>{" "}
          </Col>
        </Row>
        <Row gutter={24} className="rtmp1">
          <Col span={24} style={{ textAlign: "left" }}>
            <Upload
              {...props}
              accept="application/json"
              showUploadList={filelist}
              beforeUpload={(file) => {
                //File Type Check
                if (file.type !== "application/json") {
                  setMsgerror(`${file.name} is not a json file`);
                  setFilelist(false);
                } else {
                  setFilelist(true);
                  setMsgerror(`${file.name}`);
                  const reader = new FileReader();

                  reader.onload = (e) => {
                    fileReader(e.target.result);
                  };
                  reader.readAsText(file);

                  // Prevent upload
                  return false;
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {msgerror}
            </Upload>{" "}
          </Col>
        </Row>
        <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              {filelist == true ? (
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button disabled type="primary" htmlType="submit">
                  Update
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Addschedule;
