import { collection, getDocs, doc, deleteDoc, getDoc } from "firebase/firestore";
import { Select, Spin, Layout, Card, Table, Space, Input, Modal, Drawer, PageHeader, Form } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Addschedule from "./addschedule";
import AddSchema from "./addschema";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import ReactJson from "react-json-view";

import {
  PlusCircleTwoTone,
  DeleteTwoTone,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Content } = Layout;
const { Column } = Table;
const { Search } = Input;

const doctypearray = ["SCHEDULE", "PLAY_OFF", "INSIGHTS"];
function Schedule() {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [showAddSchema, setShowAddSchema] = useState(false);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [user, loading, error] = useAuthState(auth);
  const handleJson = () => {
    //downloadFile(arraycheck,documentID.documentID)
    setJsonviewer(false);
  };

  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setVisible(true);
  };
  const addnewSchema = () => {
    setShowAddSchema(true);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadocument = async (documentID) => {
    const matchDocRef = doc(db, "schedule", documentID.documentID);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    downloadFile(arraycheck, documentID.documentID);
  };

  const deletedocument = (documentID) => {
    const docRef = deleteDoc(doc(db, "schedule", documentID.documentID));
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        window.location.reload(true);
      },
    });
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setShowAddSchema(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchTeams = async () => {
    const teamsCol = collection(db, "teams");
    const teamsSnapshot = await getDocs(teamsCol);
    const teamList = teamsSnapshot.docs.map((doc) => doc.data());
    setTeams(teamList);
  };
  const fetchSchedule = async () => {
    const scheduleCol = collection(db, "schedule");
    const scheduleSnapshot = await getDocs(scheduleCol);
    const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
    const resultArray = [];
    const resultMatch = [];
    const result = scheduleSnapshot.docs.map((doc) => {
      let documentArray = doc.data();
      resultArray.push(doc.data());
      resultMatch.push({ documentID: doc.id });
    });
    let arr3 = resultArray.map((item, i) => Object.assign({}, item, resultMatch[i]));
    let scheduleArray = [];
    let i = 1;
    arr3.map(function (element, i) {
      scheduleArray.push({
        key: i++,
        documentID: element.documentID,
        docType: element.docType,
        Year: element.year,
        tournamenttitle: element.tournament,
      });
    });
    setSchedule(scheduleArray);
  };

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => {
          if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
          return false;
        });
      });
      setSchedule(filteredData);
    } else {
      //fetchSearch();
    }
  };

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchTeams(schedule);
    fetchSchedule(schedule);
  }, [loaded]);


  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu="schedule" />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                  className="site-page-header"
                  title="Schedule"
                  extra={
                    <Space direction="horizontal" style={{ marginLeft: "auto", width: "100%" }}>
                      <Search placeholder="Search here" onChange={onSearch} enterButton />
                      {/* <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                      <a onClick={() => addnew()}>
                        <span className="addschedule">Add Schedule</span>
                      </a>
                      <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                      <a onClick={() => addnewSchema()}>
                        <span className="addschedule">Add Schema</span>
                      </a> */}
                    </Space>
                  }
                />
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  dataSource={schedule}
                  scroll={{ y: "340" }}
                >
                  <Column title="Document ID" dataIndex="documentID" key="key" />
                  <Column title="Doc Type" dataIndex="docType" key="key" />
                  <Column title="Tournament" dataIndex="tournamenttitle" key="key" />
                  <Column title="Year" dataIndex="Year" key="key" />

                  <Column
                    title="Quick Actions"
                    key="documentID"
                    render={(documentID) => (
                      <Space size="middle">
                        <a onClick={() => downloadocument(documentID)}>
                          <img src="images/download.svg"></img>{" "}
                        </a>
                        <a onClick={() => deletedocument(documentID)}>
                          <DeleteTwoTone style={{ fontSize: "18px", color: "#08c" }} />{" "}
                        </a>
                      </Space>
                    )}
                  />
                </Table>
              </Card>
            </div>

            <Modal title="Notification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              Are you sure you want to Notification?
            </Modal>
            <Modal title="Json" visible={jsonviewer} onOk={handleJson} onCancel={jsoncancel}>
              <ReactJson src={jsondata} />
            </Modal>

            <Drawer title="Add New Tournament" placement="right" onClose={onClose} visible={visible}>
              <Addschedule scheduledata={schedule} teams={teams} doctypearray={doctypearray} schedulereload={fetchSchedule} onClose={onClose} form={form} />
            </Drawer>
            <Drawer title="Add New Schema" placement="right" onClose={onClose} visible={showAddSchema}>
              <AddSchema scheduledata={schedule} teams={teams} doctypearray={doctypearray} schedulereload={fetchSchedule} onClose={onClose} form={form} />
            </Drawer>
            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}
          </Content>
          <Footersection />
        </Layout>
      </>

    </Layout>
  );
}

export default Schedule;
