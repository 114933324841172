import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { Select, Layout, Menu, Card, Row, Col, Table, Spin, Tag, Upload, Space, Button, Modal, Drawer, Form, Input, Divider } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import { UploadOutlined } from "@ant-design/icons";

import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"

const SCHEDULE_SCHEMA = require("../Jsondata/schema/schedule.json");
const PLAYOFF_SCHEMA = require("../Jsondata/schema/playoff.json");
const INSIGHTS_SCHEMA = require("../Jsondata/schema/insights.json");
const AUCTIONS_SCHEMA = require("../Jsondata/schema/auction.json");
const FANTACY_SCHEMA = require("../Jsondata/schema/fantasy.json");

const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;

const doctypearray = [
  { key: 1, value: "SCHEDULE" },
  { key: 2, value: "PLAY_OFF" },
  { key: 3, value: "INSIGHTS" },
  { key: 4, value: "FANTASY_LINEUP" },
  { key: 5, value: "AUCTION" },
  { key: 5, value: "TEST" },
];
function Addschedule(props) {
  const [loaded, setLoaded] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [yearvalue, setYearvalue] = useState("Select Season");
  const [dataset, setDataset] = useState([]);
  const [doctype, setDoctype] = useState(doctypearray[0].value);
  const [teams, setTeams] = useState(props.teams);
  const [msgerror, setMsgerror] = useState("");
  const [filelist, setFilelist] = useState(false);
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const fileReader = (result) => {
    setFilelist(true);
    setDataset(result);
  };
  const loadProfile = () => {
    setMsgerror("");
    var currentyear = new Date().getFullYear();
    const yearlist = [];
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i);
    }
    setYearlist(yearlist.reverse());
  };
  useEffect(() => {
    loadProfile();
  }, []);

  const handleChange = (e) => {
    setDoctype(e);
  };

  const onFinish = async (values) => {

    setLoaded(true);
    let jsonObject = JSON.parse(dataset);
    let _docName = values.tournament + "_" + doctype + "_SCHEMA";

    const checktournament = props.scheduledata.some((item) => _docName === item.documentID);
    if (checktournament == false) {

      if (jsonObject.docType === doctype) {
        await setDoc(doc(db, "schedule", _docName), jsonObject);
        setLoaded(false);

        Modal.success({
          content: "Schema Added successfully",
          onOk() {
            setLoaded(false);
            props.onClose();
            props.schedulereload();
          },
        });
      } else {

        setLoaded(false);
        Modal.error({
          content: "Please check Schema details in the json file",
          onOk() {
          },
        });
      }
    } else {

      setLoaded(false);
      Modal.error({
        content: "Already Exists",
        onOk() {
        },
      });
    }
  };

  return (
    <>
      <Form form={props.form} name="AddSchemaForm" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="tournament" label="Select Tournaments" rules={[{ required: true, message: "Please Select Tournaments!" }]}>
              <Select size="default" placeholder="Select Tournaments" style={{ width: "100%" }}>
                {props.teams.map((item, index) => (
                  <Option key={index} value={item.tournament}>
                    {item.tournament}{" "}
                  </Option>
                ))}
              </Select>
            </Form.Item>{" "}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Select size="default" value={doctype} placeholder="Select Document Type" style={{ width: "100%" }} onChange={handleChange}>
              {doctypearray.map((item2, index2) => (
                <Option key={index2} value={item2.value}>
                  {" "}
                  {item2.value}{" "}
                </Option>
              ))}
            </Select>{" "}
          </Col>
        </Row>
        <Row gutter={24} className="rtmp1">
          <Col span={24} style={{ textAlign: "left" }}>
            <Upload
              {...props}
              accept="application/json"
              showUploadList={filelist}
              beforeUpload={(file) => {
                //File Type Check
                if (file.type !== "application/json") {
                  setMsgerror(`${file.name} is not a json file`);
                  setFilelist(false);
                } else {
                  setFilelist(true);
                  setMsgerror(`${file.name}`);
                  const reader = new FileReader();

                  reader.onload = (e) => {
                    fileReader(e.target.result);
                  };
                  reader.readAsText(file);
                  return false;
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {msgerror}
            </Upload>{" "}
          </Col>
        </Row>
        <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              {filelist == true ? (
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button disabled type="primary" htmlType="submit">
                  Update
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Addschedule;
