import Firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import ReactJson from "react-json-view";
import Moment from "react-moment";
import axios from "axios";
import { domain } from "../../configuration/ApiConfiguration";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Wkschedlbl(props) {
  const [infoform] = Form.useForm();

  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);

  const [tempSchedule, setTempSchedule] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [title, setTitle] = useState("");
  const [info2, setInfo2] = useState([]);
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const addnew = () => {
    setTexttime("");
    setTitletime("");
    setTitle("Add New Week Schedule");
    setVisible(true);
  };
  const showDrawer = (matchid, index) => {
    console.log(matchid.wkStart);
    infoform.setFieldsValue({
      screenLabel: matchid.screenLabel,
      subLabel: matchid.subLabel,
    });
    var titlestring = moment(matchid.wkStart.toDate()).format("YYYY-MM-DD HH:MM:SS");
    var weekend = moment(matchid.wkEnd.toDate()).format("YYYY-MM-DD HH:MM:SS");
    setTexttime(weekend);
    setTitletime(titlestring);
    setTitle(matchid.screenLabel);
    setVisible(true);
  };

  const datesplit = (text) => {
    // var timeset = moment(text.toDate()).format("DD-MM-YYYY HH:MM:SS");
    // var timeset =  new Date(text._seconds * 1000);
    // let final = moment(timeset, "DD-MM-YYYY HH:MM:SS")
    // return final;
    return text._seconds;
  };

  const deleterecord = async (matchid, index1) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    let filtered = arraycheck.wkSchedLbl.filter(function (value, index, arr) {
      return index !== index1;
    });
    arraycheck.wkSchedLbl = filtered;
    try {
      await updateDoc(matchDocRef, arraycheck);
    } catch (err) {
      console.log(err)
    }
    setLoaded(false);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };
  const onClose = () => {
    setTexttime("");
    setTitletime("");
    infoform.resetFields();

    setVisible(false);
  };

  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const textpicker = (value, dateString) => {
    console.log(dateString);
    setTexttime(dateString);
  };
  const textok = (value) => {
    console.log(value);
  };
  const titlepicker = (value, dateString) => {
    console.log(dateString);
    setTitletime(dateString);
  };
  const titleok = (value) => {
    console.log(value);
  };
  const onInfofinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();

    await setDoc(matchDocRef, docSnap.data());
    if (title === "Add New Week Schedule") {
      arraycheck.wkSchedLbl.push({
        screenLabel: values.screenLabel,
        subLabel: values.subLabel,
        wkStart: Firebase.firestore.Timestamp.fromDate(new Date(titletime)),
        wkEnd: Firebase.firestore.Timestamp.fromDate(new Date(texttime)),
      });
    } else {
      arraycheck.wkSchedLbl[currentindex] = {
        screenLabel: values.screenLabel,
        subLabel: values.subLabel,
        wkStart: Firebase.firestore.Timestamp.fromDate(new Date(titletime)),
        wkEnd: Firebase.firestore.Timestamp.fromDate(new Date(texttime)),
      };
    }
    try {
      await updateDoc(matchDocRef, arraycheck);
    } catch (err) {
      console.log(err)
    }
    setLoaded(false);
    Modal.success({
      content: title === "Add New Week Schedule" ? "New Week Schedule Added Successfully" : "Updated Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };

  const fetchDashboard = async () => {
    let _tmp = props.docdata.wkSchedLbl.map((obj, index) => {
      // obj.key = 'id' + index
      return obj;
    })
    setSchedule(_tmp);
  };

  const finaldocsave = async () => {
    setLoaded(true);
    const response = await axios({
      method: 'post',
      url: `${domain}/betops/config/get-update-appConfig/v1`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        "appId": `${currentTournamentDetails.shortCode}-ops`,
        "asset": tempSchedule.asset
      },
    }).then((response) => {
      setLoaded(false);
      Modal.success({
        content: "Saved Successfully",
        onOk() {
          window.location.reload(true);
        },
      });
    })
      .catch((error) => {
        setLoaded(false);
      })
  }

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <div>
      <Space direction="horizontal" className="rightspace">
        {" "}
        <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
        <a onClick={() => addnew()}>
          <span className="addschedule">Add New</span>
        </a>
      </Space>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              fetchIndex(rowIndex);
            }, // click row
          };
        }}
        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
        dataSource={schedule}
        scroll={{ y: "340" }}
      >
        <Column title="Screen Label" dataIndex="screenLabel" key="screenLabel" />
        <Column title="Sub Label " dataIndex="subLabel" key="subLabel" />
        <Column
          title="Week Start "
          dataIndex="wkStart"
          key="wkStart"
          render={(text, record) => <Space size="middle">{text !== undefined ? datesplit(text) : ""}</Space>}
        />
        <Column
          title="Week end "
          dataIndex="wkEnd"
          key="wkEnd"
          render={(text, record) => <Space size="middle">{text !== undefined ? datesplit(text) : ""}</Space>}
        />

        <Column
          title="Quick Actions"
          key="notifId"
          render={(notifId, record, index) => (
            <Space key={index} size="middle">
              <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
              <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
            </Space>
          )}
        />
      </Table>

      <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="small">
        <Form name="horizontal_login" layout="vertical" form={infoform} onFinish={onInfofinish}>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Screen Label" name="screenLabel" rules={[{ required: true, message: "Input your Screen Label!" }]}>
                <Input placeholder="Input Your Screen Label" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Sub Label" name="subLabel" rules={[{ required: true, message: "Input your Sub Label!" }]}>
                <Input placeholder="Input your Sub Label" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Week Start" rules={[{ type: "object", required: true, message: "Please Select Week Start!" }]}>
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={titlepicker}
                  onOk={titleok}
                  value={titletime === "" ? undefined : moment(titletime, "YYYY-MM-DD HH:mm:ss")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Week End">
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:MM:ss"
                  onChange={textpicker}
                  onOk={textok}
                  value={texttime === "" ? undefined : moment(texttime, "YYYY-MM-DD HH:mm:ss")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  {title === "Add New Week Schedule" ? "Add" : "Update"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
      <Button type="primary" className="configEndbtn" onClick={finaldocsave}>
        Save
      </Button>
    </div>
  );
}

export default Wkschedlbl;
