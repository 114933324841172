import { doc, addDoc, updateDoc, collection } from "firebase/firestore";
import { Select, Row, Col, Button, Modal, Form, Input, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { db, currentTournamentDetails } from "../settings/config";

const { Option } = Select;

function AddVersion(props) {
    const [loaded, setLoaded] = useState(false);
    const [infoform] = Form.useForm();

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const loadVersion = async () => {
        infoform.resetFields();
        if (props.drawertype == "Edit" && props.currObj) {
            infoform.setFieldsValue({
                platform: props.currObj.platform,
                name: props.currObj.name,
                type: props.currObj.type,
                code: props.currObj.code,
                packageName: props.currObj.packageName || "",
                url: props.currObj.url || "",
                active: props.currObj.active,
                createdDate: props.currObj.createdDate,
                modifiedDate: props.currObj.modifiedDate
            });
        }
    };

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setLoaded(false);
            }, 2000);
        }
        loadVersion();
        setLoaded(false);
    }, [loaded, props]);

    const onFinish = async (values) => {
        setLoaded(true);
        console.log(values);

        try {
            let ver = {
                appVersionsConfig: {
                    platform: values.platform,
                    name: values.name,
                    type: values.type,
                    code: values.code,
                    packageName: values.packageName || "",
                    url: values.url || "",
                    active: props.drawertype == "Add New" ? false : props.currObj.active,
                    createdDate: props.drawertype == "Add New" ? new Date() : props.currObj.createdDate,
                    modifiedDate: props.drawertype == "Edit" && props.currObj ? new Date() : "",
                },
            };
            if (props.drawertype == "Edit" && props.currObj) {
                await updateDoc(doc(db, "app_version", props.currObj.key), ver);
                //onClose();
            } else {
                const docRef = await addDoc(collection(db, "app_version"), ver);
                console.log("Document written with ID: ", docRef.id);
            }

            Modal.success({
                content: props.drawertype == "Add Version" ? "New App Version Added Successfully" : "Updated Successfully",
                onOk() {
                    setLoaded(false);
                    props.onClose();
                },
            });
        } catch (e) {
            console.error("Error adding document: ", e);
            Modal.error({
                content: "Error in update",
                onOk() {
                    setLoaded(false);
                    props.onClose();
                },
            });
        }
    };

    return (
        <>
            <Form form={infoform} name="version" layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="platform" label="Select Platform" rules={[{ required: true, message: "Please Select Platform!" }]}>
                            <Select name="platform" size="default" placeholder="Select Platform" style={{ width: "100%" }}>
                                <Option key="ANDROID">{"ANDROID"}</Option>
                                <Option key="IOS">{"IOS"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="name" label="Version" rules={[{ required: true, message: "Please input Version!" }]}>
                            <Input placeholder="0.0.0.0" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="code" label="Version Code" rules={[{ required: true, message: "Please input Version code!" }]}>
                            <Input placeholder="0" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="type" label="Installation Type" rules={[{ required: true, message: "Please select Installation Type!" }]}>
                            <Select name="type" size="default" placeholder="Select Type" style={{ width: "100%" }}>
                                <Option key="FORCED">{"FORCED"}</Option>
                                <Option key="OPTIONAL">{"OPTIONAL"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="url" label="Domain Url" rules={[{ required: false, message: "Please input domain url" }]}>
                            <Input placeholder="Url here" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item name="packageName" label="Package Name" rules={[{ required: false, message: "Please input Package Name!" }]}>
                            <Input placeholder="Package Name" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24} className="rtmp1">
                    <Col span={24}>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                {props.drawertype === "Add New Version" ? "Add" : "Update"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default AddVersion;
