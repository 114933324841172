import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import ActiveTournament from "./ActiveTournament";
import LiveMatches from "./LiveMatches";
import TotalMatches from "./TotalMatches";
import UpcomingMatches from "./UpcomingMatches";
import CompletedMatches from "./CompletedMatches";
import { Chart } from "react-google-charts";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Option } = Select;

const { Content } = Layout;
function DashboardMatchDetails() {
    const [loaded, setLoaded] = useState(true);
    const [highlightBox, setHighlightBox] = useState("1")
    const [user, loading, error] = useAuthState(auth);
    const { TabPane } = Tabs;

    useEffect(async () => {
        setLoaded(true)
        setLoaded(false)
    }, []);
    const highlight = (arg) => {

        setHighlightBox(arg)
    }
    return (

        <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>

            <Row gutter={16}>
                <Col span={5}>
                    <Card bordered={false} style={highlightBox === "1" ? { background: "#c0ddf75c", cursor: "pointer" } : { cursor: "pointer" }} onClick={(e) => highlight("1")}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>03</h2></div>
                        <div>Active Tournaments</div>
                        <div id={highlightBox === "1" ? "copyright" : ""}></div>
                        <div className={highlightBox === "1" ? "matchIden" : ""}></div>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card bordered={false} style={highlightBox === "2" ? { background: "#c0ddf75c", cursor: "pointer" } : { cursor: "pointer" }} onClick={(e) => highlight("2")}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>03</h2></div>
                        <div>Live Matches</div>
                        <div id={highlightBox === "2" ? "copyright" : ""}></div>
                        <div className={highlightBox === "2" ? "matchIden" : ""}></div>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card bordered={false} style={highlightBox === "3" ? { background: "#c0ddf75c", cursor: "pointer" } : { cursor: "pointer" }} onClick={(e) => highlight("3")}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>03</h2></div>
                        <div>Upcoming Matches</div>
                        <div id={highlightBox === "3" ? "copyright" : ""}></div>
                        <div className={highlightBox === "3" ? "matchIden" : ""}></div>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card bordered={false} style={highlightBox === "4" ? { background: "#c0ddf75c", cursor: "pointer" } : { cursor: "pointer" }} onClick={(e) => highlight("4")}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>06</h2></div>
                        <div>Total Matches</div>
                        <div id={highlightBox === "4" ? "copyright" : ""}></div>
                        <div className={highlightBox === "4" ? "matchIden" : ""}></div>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} style={highlightBox === "5" ? { background: "#c0ddf75c", cursor: "pointer" } : { cursor: "pointer" }} onClick={(e) => highlight("5")}>
                        <div><h2 style={{ color: "#2196F3", fontWeight: "bold" }}>02</h2></div>
                        <div>Completed Matches</div>
                        <div id={highlightBox === "5" ? "copyright" : ""}></div>
                        <div className={highlightBox === "5" ? "matchIden" : ""}></div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                {highlightBox === "1" ? <ActiveTournament /> : highlightBox === "2" ? <LiveMatches /> :
                    highlightBox === "3" ? <UpcomingMatches /> : highlightBox === "4" ? <TotalMatches /> : highlightBox === "5" ? <CompletedMatches /> : ""}
            </Row>
        </Content>

    );
}

export default DashboardMatchDetails;
