import { Spin, Layout, Input, Card, Tabs, Row, Col, Table, Space, Button, Modal, Select, Checkbox, PageHeader } from "antd";
import { collection, getDocs, doc, where, query, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails, logout } from "../../settings/config";
import React, { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { domain } from "../../configuration/ApiConfiguration";
import axios from "axios";
import {
    PlusCircleTwoTone,
    MinusCircleOutlined
} from "@ant-design/icons";

function AddCertificate(props) {
    const [loaded, setLoaded] = useState(false);

    const [finalBannerImg, setFinalBannerImg] = useState([]);
    const [finalIcTourn, setFinalIcTourn] = useState([]);
    const [finalIcMap, setFinalIcMap] = useState([]);
    const [finalIcFlag, setFinalIcFlag] = useState([]);
    const [finalIcBadge, setFinalIcBadge] = useState([]);

    const [finalRowDataMap, setFinalRowDataMap] = useState(null);
    const [finalColumnDataMap, setFinalColumnDataMap] = useState(null);

    const { Option } = Select;
    const [certificateConfig, setcertificateConfig] = useState([])
    const [sportsConfigConfig, setSportsConfigConfig] = useState("")
    const [arrays, setArray] = useState(0)
    const [EditDetails, setEditDetais] = useState({ "fav": true, "table": { "rows": [], "columns": [] }, "bannerImg": null, "bannerText": null, "icBadge": [], "icFlag": null, "active": true, "tournament": null, "icTourn": null, "footerText": null, "footerSubText": null, "docType": "RANK_CERT", "bannerSubText": null, "year": null, "app": "fantasycafe" })

    useEffect(async () => {
        setLoaded(true);
        const arraychecks = props.keydata;

        let bannerImg = arraychecks.bannerImg;
        // let bannerImgSplit = bannerImg.split(",");
        setFinalBannerImg(bannerImg);

        let icTourn = arraychecks.icTourn;
        // let bannerImgSplit = bannerImg.split(",");
        setFinalIcTourn(icTourn);

        let icMap = arraychecks.icMap;
        // let bannerImgSplit = bannerImg.split(",");
        setFinalIcMap(icMap);

        let icFlag = arraychecks.icFlag;
        // let bannerImgSplit = bannerImg.split(",");
        setFinalIcFlag(icFlag);

        let icBadge = arraychecks.icBadge;
        // let bannerImgSplit = bannerImg.split(",");
        setFinalIcBadge(icBadge);

        setFinalRowDataMap(arraychecks.rows);

        setFinalColumnDataMap(arraychecks.columns);

        setcertificateConfig(arraychecks);



        setSportsConfigConfig(props.sportsconfig);

        if (props.Flag === "New Certificate") {
        }
        else {
            let details = props.Certificate;
            let ff = arraychecks
            for (let i = 0; i < details.icBadge.length; i++) {
                let filterName = arraychecks.icBadge.filter(list => list.name === details.icBadge[i].name)
                if (filterName[i] != undefined) {
                    details.icBadge[i] = filterName[0]
                }
            }
            for (let i = 0; i < details.table.columns.length; i++) {
                let filterName = arraychecks.columns.filter(list => list.label === details.table.columns[i].label)
                if (filterName[i] != undefined) {
                    details.table.columns[i] = filterName[0]
                }
            }
            for (let i = 0; i < details.table.rows.length; i++) {
                let filterName = arraychecks.rows.filter(list => list.label === details.table.rows[i].label)
                if (filterName[i] != undefined) {
                    details.table.rows[i] = filterName[0]
                }
            }
            setEditDetais(details)
        }
        setLoaded(false)
    }, [])

    const setRowData = (index, e) => {
        let data = EditDetails
        if (e.length === 0) {
            data.table.rows[index].label = ""
            data.table.rows[index].data = ""
        }
        else {
            // if(data.table.rows[index].label[0].label) {
            //     data.table.rows[index].label[0].label = e.target.value
            // } else {
            //     data.table.rows[index].label = e.target.value
            // }

            data.table.rows[index].label = e.target.value

            // data.table.rows[index].data = e[0].data
        }
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const removeRowRata = (index, e) => {
        let data = EditDetails
        data.table.rows.splice(index, 1)
        for (let i = 0; i < data.table.rows.length; i++) {
            if (!data.table.rows[i].label) {
                data.table.rows[i].label = [{ label: "", data: "" }]
            }
        }
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const addRowData = (e) => {

        let data = EditDetails
        data.table.rows.push({ "data": "", "label": "" })
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const changeRowData = (index, e) => {
        let data = EditDetails
        data.table.rows[index].data = e
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const setColumsData = (index, e) => {
        let data = EditDetails
        if (e.length === 0) {
            data.table.columns[index].label = ""
            data.table.columns[index].data = ""
        }
        else {
            // if(data.table.columns[index].label[0].label) {
            //     data.table.columns[index].label[0].label = e.target.value
            // } else {
            data.table.columns[index].label = e.target.value
            // }


            // data.table.columns[index].label = e
            // data.table.columns[index].data = e[0].data
        }
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const removeColumsData = (index, e) => {
        let data = EditDetails
        data.table.columns.splice(index, 1)
        for (let i = 0; i < data.table.columns.length; i++) {
            if (!data.table.columns[i].label) {
                data.table.columns[i].label = [{ label: "", data: "" }]
            }
        }
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const addColumsData = (e) => {
        let data = EditDetails
        data.table.columns.push({ "data": "", "label": "" })
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const changeColumsData = (index, e) => {
        let data = EditDetails
        data.table.columns[index].data = e
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const setBadgeData = (index, e) => {
        let data = EditDetails
        if (e.length === 0) {
            data.icBadge[index].name = ""
            data.icBadge[index].ic = ""
        }
        else {
            data.icBadge[index].name = e
            data.icBadge[index].ic = e[0].ic
        }
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const removeBadgeRata = (index, e) => {
        let data = EditDetails
        data.icBadge.splice(index, 1)
        for (let i = 0; i < data.icBadge.length; i++) {
            if (!data.icBadge[i].label) {
                data.icBadge[i].label = [{ name: "", ic: "" }]
            }
        }
        setEditDetais(data)
        setArray(arrays + 1)
    }
    const addBadgeData = (e) => {
        let data = EditDetails
        data.icBadge.push({ "ic": "", "name": "GENERAL" })
        setEditDetais(data)
        setArray(arrays + 1)
    }
    // const changeBadgeData = (index, e) => {
    //     let data = EditDetails
    //     data.icBadge[index].ic = e.target.value
    //     setEditDetais(data)
    //     setArray(arrays + 1)
    // }

    const changeBadgeData = (e) => {
        let data = EditDetails
        let temp = null;
        temp = {
            name: "GENERAL",
            ic: e
        };
        data.icBadge.push(temp);
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const handleChangeEditDetails = (e) => {
        let data = EditDetails;
        data[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const handleTournamentChange = (e) => {
        let data = EditDetails;
        data.tournament = e
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const handleBannerImgChange = (e) => {
        let data = EditDetails;
        data.bannerImg = e
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const handleIcFlagChange = (e) => {
        let data = EditDetails;
        data.icFlag = e
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const handleIcTournChange = (e) => {
        let data = EditDetails;
        data.icTourn = e
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const handleIcMapChange = (e) => {
        let data = EditDetails;
        data.icMap = e
        setEditDetais(data)
        setArray(arrays + 1)
    }

    const updateCertificate = async (e) => {
        let data = EditDetails
        for (let i = 0; i < data.icBadge.length; i++) {
            if (data.icBadge[i].name) {
                if (data.icBadge[i].name[0].name) {
                    data.icBadge[i].name = data.icBadge[i].name[0].name
                }
            }
        }

        for (let i = 0; i < data.table.columns.length; i++) {
            if (data.table.columns[i].label) {
                if (data.table.columns[i].label[0].label) {
                    data.table.columns[i].label = data.table.columns[i].label[0].label
                }
            }
        }
        for (let i = 0; i < data.table.rows.length; i++) {
            if (data.table.rows[i].label) {
                if (data.table.rows[i].label[0].label) {
                    data.table.rows[i].label = data.table.rows[i].label[0].label
                }
            }

        }
        data.table.columns = data.table.columns.filter(list => list.label)
        data.table.rows = data.table.rows.filter(list => list.label)
        data.icBadge = data.icBadge.filter(list => list.name)
        data.appId = `${currentTournamentDetails.shortCode}-ops`;
        let year = parseInt(data.year);
        data.year = year;
        if (props.Flag === "Edit Certificate") {
            setLoaded(true)
            const response = await axios({
                method: 'post',
                url: `${domain}/betops/config/certificate/update/v2.0`,
                headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
                data: data
            }).then((response) => {
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: "Updated Successfully",
                        onOk() {
                            props.onClose();
                            window.location.reload();
                        },
                    });
                }
                else {
                    if (response.data.error.message == "Auth token is not valid") {
                        Modal.error({
                            content: response.data.error.message,
                            onOk() {
                                logout();
                            }
                        });
                    } else {
                        Modal.error({
                            content: response.data.error.message
                        });
                    }
                }
            })
                .catch((error) => {
                    Modal.error({
                        content: "Unable to process your Request, Please try again later."
                    });
                })
            setLoaded(false);
        }
        else {
            setLoaded(true)
            const response = await axios({
                method: 'post',
                url: `${domain}/betops/config/certificate/create/v2.0`,
                headers: { "Authorization": `Bearer ${localStorage.getItem("aT")}` },
                data: data
            }).then((response) => {
                if (response.data.status === "SUCCESS") {
                    Modal.success({
                        content: "Created Successfully",
                        onOk() {
                            props.onClose();
                            window.location.reload();
                        },
                    });
                }
                else {
                    if (response.data.error.message == "Auth token is not valid") {
                        Modal.error({
                            content: response.data.error.message,
                            onOk() {
                                logout();
                            }
                        });
                    } else {
                        Modal.error({
                            content: response.data.error.message
                        });
                    }
                }
            })
                .catch((error) => {
                    Modal.error({
                        content: "Unable to process your Request, Please try again later."
                    });
                })
            setLoaded(false);
        }
    }
    return (
        <div className="drawer-align-cls certificatepage">
            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader
                    className="site-page-headerr"
                    style={{ paddingLeft: "0" }}
                    title={props.Flag === "New Certificate" ? "Add Certificate" : "Edit Certificate"}
                />

                <div className="row">
                    <div className="col-md-3">
                        <label>Tournament</label>
                        <Select size="default" value={EditDetails.tournament} placeholder="Select Tournament" title="Select Tournament" className="m-1" style={{ width: "100%" }} onChange={handleTournamentChange} >
                            {sportsConfigConfig ? sportsConfigConfig.tournamentList.map((lists, index) => {

                                return (<Option key={lists.tournament} value={lists.tournament}>
                                    {lists.tournamentTitle}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                    </div>
                    <div className="col-md-3">
                        <label>Year</label>
                        <input type="number" placeholder="Year" title="Year" name="year" value={EditDetails.year} className="form-control m-1" onChange={handleChangeEditDetails} />
                    </div>
                    <div className="col-md-3">
                        <label>Banner Img</label>
                        <Select name="bannerImg" size="default" value={EditDetails && EditDetails.bannerImg ? EditDetails.bannerImg : ""} placeholder="Select Banner Img" title="Select Banner Img" className="m-1" style={{ width: "100%" }} onChange={handleBannerImgChange} >
                            {finalBannerImg ? finalBannerImg.map((lists, index) => {
                                return (<Option key={lists} value={lists}>
                                    {lists}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                        {/* <input type="text" placeholder="Banner Img" title="Banner Img" name="bannerImg" value={EditDetails.bannerImg} className="form-control m-1" onChange={handleChangeEditDetails} /> */}
                    </div>
                    <div className="col-md-3">
                        <label>Banner SubText</label>
                        <input type="text" placeholder="Banner SubText" title="Banner SubText" name="bannerSubText" value={EditDetails.bannerSubText} className="form-control m-1" onChange={handleChangeEditDetails} />
                    </div>
                    <div className="col-md-3">
                        <label>Banner Text</label>
                        <input type="text" placeholder="Banner Text" title="Banner Text" name="bannerText" value={EditDetails.bannerText} className="form-control m-1" onChange={handleChangeEditDetails} />
                    </div>
                    <div className="col-md-3">
                        <label>Footer SubText</label>
                        <input type="text" placeholder="Footer SubText" title="Footer SubText" name="footerSubText" value={EditDetails.footerSubText} className="form-control m-1" onChange={handleChangeEditDetails} />
                    </div>
                    <div className="col-md-3">
                        <label>Footer Text</label>
                        <input type="text" placeholder="Footer Text" title="Footer Text" name="footerText" value={EditDetails.footerText} className="form-control m-1" onChange={handleChangeEditDetails} />
                    </div>
                    <div className="col-md-3">
                        <label>ic Flag</label>
                        <Select name="icFlag" size="default" value={EditDetails && EditDetails.icFlag ? EditDetails.icFlag : ""} placeholder="Select Ic Flag" title="Select Ic Flag" className="m-1" style={{ width: "100%" }} onChange={handleIcFlagChange} >
                            {finalIcFlag ? finalIcFlag.map((lists, index) => {
                                return (<Option key={lists} value={lists}>
                                    {lists}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                        {/* <input type="text" placeholder="ic Flag" title="ic Flag" name="icFlag" value={EditDetails.icFlag} className="form-control m-1" onChange={handleChangeEditDetails} /> */}
                    </div>
                    <div className="col-md-3">
                        <label>ic Tourn</label>
                        <Select name="icTourn" size="default" value={EditDetails && EditDetails.icTourn ? EditDetails.icTourn : ""} placeholder="Select Ic Tourn" title="Select Ic Tourn" className="m-1" style={{ width: "100%" }} onChange={handleIcTournChange} >
                            {finalIcTourn ? finalIcTourn.map((lists, index) => {
                                return (<Option key={lists} value={lists}>
                                    {lists}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                        {/* <input type="text" placeholder="ic Tourn" title="ic Tourn" name="icTourn" value={EditDetails.icTourn} className="form-control m-1" onChange={handleChangeEditDetails} /> */}
                    </div>
                    <div className="col-md-3">
                        <label>ic Map</label>
                        <Select name="icMap" size="default" value={EditDetails && EditDetails.icMap ? EditDetails.icMap : ""} placeholder="Select Ic Map" title="Select Ic Map" className="m-1" style={{ width: "100%" }} onChange={handleIcMapChange} >
                            {finalIcMap ? finalIcMap.map((lists, index) => {
                                return (<Option key={lists} value={lists}>
                                    {lists}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                        {/* <input type="text" placeholder="ic Map" title="ic Map" name="icMap" value={EditDetails.icMap} className="form-control m-1" onChange={handleChangeEditDetails} /> */}
                    </div>
                    <div className="col-md-3">
                        <label>Ic Badge</label>
                        <Select size="default" value={EditDetails && EditDetails.icBadge && EditDetails.icBadge[0] && EditDetails.icBadge[0].ic ? EditDetails.icBadge[0].ic : ""} placeholder="Select Ic Badge" title="Select Ic Badge" className="m-1" style={{ width: "100%" }} onChange={changeBadgeData} >
                            {finalIcBadge ? finalIcBadge.map((lists, index) => {
                                return (<Option key={lists.ic} value={lists.ic}>
                                    {lists.ic}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                        {/* <input type="text" placeholder="Ic Badge" title="Ic Badge" onChange={changeBadgeData} value={EditDetails && EditDetails.icBadge[0] ? EditDetails.icBadge[0].ic : ""} className="form-control m-1" /> */}
                    </div>
                    <div className="col-md-3">
                        <label className="enablechkboxlabel">Fav</label>{" "}
                        <Checkbox name="fav" onChange={handleChangeEditDetails} checked={EditDetails.fav}></Checkbox>
                    </div>
                </div>
                {/* <Row gutter={12}>
                <Col span={12}>
                    <h5 className="text-left">Ic Badge</h5>
                    {EditDetails.icBadge.map((list, index) => {
                        return (<><Row gutter={12} className="m-2">
                            <Col span={8} style={{ textAlign: "right" }}>
                            <Input placeholder="ic" onChange={changeBadgeData.bind(this, index)} value={list.ic} className="form-control" />
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                            {certificateConfig ? <Typeahead
                                    selectHintOnEnter="true"
                                    id="BranchID"
                                    labelKey="name"
                                    onChange={setBadgeData.bind(this, index)}
                                    options={certificateConfig.icBadge}
                                    placeholder="--Select--"
                                    selected={list.name}
                                /> : ""}                               
                            </Col>
                            <Col span={1} style={{ textAlign: "right" }}>
                                <MinusCircleOutlined style={{ color: "#cb3837", fontSize: "20px" }} onClick={removeBadgeRata.bind(this, index)} /></Col>
                        </Row> </>)
                    })}
                    <div style={{ marginLeft: "15px" }}>Add New <PlusCircleTwoTone onClick={addBadgeData} style={{ color: "#1890ff", fontSize: "20px" }} /></div>
                </Col>
            </Row> */}
                <Row gutter={12}>
                    <Col span={12}>
                        <h5 className="text-left">Rows</h5>
                        {EditDetails.table.rows.map((list, index) => {
                            return (<><Row gutter={12} className="m-2">
                                <Col span={8}>
                                    <Select name="rowData" size="default" value={list.data} placeholder="Data" title="Data" className="m-1" style={{ width: "100%" }} onChange={changeRowData.bind(this, index)} >
                                        {finalRowDataMap && finalRowDataMap.length > 0 ? finalRowDataMap.map((lists, index) => {
                                            return (<Option key={lists.data} value={lists.data}>
                                                {lists.data}{" "}
                                            </Option>)
                                        }) : ""}
                                    </Select>

                                    {/* <Input placeholder="Data" onChange={changeRowData.bind(this, index)} value={list.data} className="form-control" /> */}
                                </Col>
                                <Col span={8} style={{ textAlign: "right" }}>
                                    {certificateConfig ?
                                        <input type="text" placeholder="Label" title="Label" name="label" value={list.label} className="form-control m-1"  onChange={setRowData.bind(this, index)} />
                                        // <Input placeholder="Label" onChange={setRowData.bind(this, index)} className="form-control" />

                                        // onChange={setRowData.bind(index, this)}


                                        // <Typeahead
                                        //     selectHintOnEnter="true"
                                        //     id="BranchID"
                                        //     labelKey="label"
                                        //     onChange={setRowData.bind(this, index)}
                                        //     options={certificateConfig.rows}
                                        //     placeholder="--Select--"
                                        //     selected={list.label}
                                        // /> 
                                        : ""}

                                </Col>
                                <Col span={1} style={{ textAlign: "right" }}>
                                    <MinusCircleOutlined style={{ color: "#cb3837", fontSize: "20px" }} onClick={removeRowRata.bind(this, index)} /></Col>
                            </Row> </>)
                        })}
                        <div style={{ marginLeft: "15px" }}>Add New <PlusCircleTwoTone onClick={addRowData} style={{ color: "#1890ff", fontSize: "20px" }} /></div>
                    </Col>
                    <Col span={12}>
                        <h5 className="text-left">Columns</h5>
                        {EditDetails ?
                            <>
                                {EditDetails.table.columns.map((list, index) => {
                                    return (<><Row gutter={12} className="m-2">
                                        <Col span={8}>
                                            <Select name="columnData" size="default" value={list.data} placeholder="Data" title="Data" className="m-1" style={{ width: "100%" }} onChange={changeColumsData.bind(this, index)} >
                                                {finalColumnDataMap && finalColumnDataMap.length > 0 ? finalColumnDataMap.map((lists, index) => {
                                                    return (<Option key={lists.data} value={lists.data}>
                                                        {lists.data}{" "}
                                                    </Option>)
                                                }) : ""}
                                            </Select>
                                            {/* <Input placeholder="Data" onChange={changeColumsData.bind(this, index)} value={list.data} className="form-control" /> */}
                                        </Col>
                                        <Col span={8} style={{ textAlign: "right" }}>
                                            {certificateConfig ?
                                                //  <Typeahead
                                                //     selectHintOnEnter="true"
                                                //     id="BranchID"
                                                //     labelKey="label"
                                                //     onChange={setColumsData.bind(this, index)}
                                                //     options={certificateConfig.columns}
                                                //     placeholder="--Select--"
                                                //     selected={list.label}
                                                // />
                                                <input type="text" placeholder="Label" title="Label" name="label" value={list.label} className="form-control m-1"  onChange={setColumsData.bind(this, index)} />
                                                // <Input placeholder="Label" onChange={setColumsData.bind(this, index)} className="form-control" />
                                                : ""}
                                        </Col>
                                        <Col span={1} style={{ textAlign: "right" }}>
                                            <MinusCircleOutlined style={{ color: "#cb3837", fontSize: "20px" }} onClick={removeColumsData.bind(this, index)} /></Col>
                                    </Row> </>)
                                })}
                            </>
                            : ""}

                        <div style={{ marginLeft: "15px" }}>Add New <PlusCircleTwoTone onClick={addColumsData} style={{ color: "#1890ff", fontSize: "20px" }} /></div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "center", marginBottom: "15px" }}>
                        <Button className="btn btn-primary m-1" onClick={updateCertificate}> {props.Flag === "New Certificate" ? "Add" : "Update"}</Button>
                        <Button className="btn btn-secondary m-1" onClick={props.onClose}>Cancel</Button>
                    </Col>
                </Row>
                {loaded == true ? (
                    <div className="loading-overlay">
                        <Spin size="large" />{" "}
                    </div>
                ) : (
                    ""
                )}
            </Card>
        </div>
    )
}

export default AddCertificate;