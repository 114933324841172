import { Spin, Select, Row, Col, Tabs, Button, DatePicker, Modal, Drawer, Form, Input, Layout, Card, Table, Space, PageHeader } from "antd";
import Moment from "react-moment";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails } from "../../settings/config";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import Firebase from "firebase/compat/app";
import { domain } from "../../configuration/ApiConfiguration";
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusCircleTwoTone,
    EditTwoTone,
    DeleteTwoTone
} from "@ant-design/icons";
import { Handler } from "leaflet";

const { Content } = Layout;
const { Column } = Table;
const { Search } = Input;

function IOSLanding(props) {
    const [BannerArray, setBannerArray] = useState(0)
    const [loaded, setLoaded] = useState(false);
    const { TabPane } = Tabs;
    const [form] = Form.useForm();
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const { Option, OptGroup } = Select;
    const [visible, setvisible] = useState(false);
    const [serviceList, setserviceList] = useState(false);
    const [actionList, setActionList] = useState(false);

    const [responseDataNewTable, setResponseDataNewTable] = useState("")
    const [visibleActionList, setVisibleActionList] = useState(false);
    const [Components, setComponents] = useState(false);
    const [version, setversion] = useState("");
    const [versionVisible, setversionVisible] = useState(false);
    const [visibleComponent, setvisibleComponent] = useState(false);
    const [componentListIndex, setcomponentListIndex] = useState(false);
    const [serviceListIndex, setserviceListIndex] = useState(0);
    const [landingIndex, setLandingIndex] = useState(0);
    const [actionIndex, setActionIndex] = useState(0);
    const [responseDataNew, setResponseDataNew] = useState("")
    const [responseDataNewFilter, setResponseDataNewFilter] = useState("")
    const [responseDataNewDisplay, setResponseDataNewFilterDisplay] = useState("")
    const [landingConfig, setLandingConfig] = useState("")
    const [betopsConfigConfig, setBetopsConfigConfig] = useState("")
    const [vModelFilter, setVModelFilter] = useState([])
    const [headingTitle, setHeadingTitle] = useState("");
    useEffect(async () => {
        setLoaded(true);
        await sportsListAndInfoConfig();

        setLandingConfig(props.keydata);

        if (props.drawertype === "Edit Landing") {
            let tempArr = [];
            for(let i=0;i<props.matchid.landing.length;i++) {
                if(props.matchid.landing[i].tabType == null) {
                    props.matchid.landing.splice(i, 1);
                }
            }
            setResponseDataNewTable(props.matchid);
            setResponseDataNew(props.matchid)
            setHeadingTitle("Update Landing Info");
            setResponseDataNewFilterDisplay(props.matchid)
        }
        else {
            setResponseDataNewTable({ landing: [] });
            setResponseDataNew({ landing: [] })
            setHeadingTitle("Add Landing Info");
        }
        setLoaded(false)
    }, []);

    const sportsListAndInfoConfig = async (e) => {
        const response1 = await axios({
            method: 'get',
            url: `${domain}/betops/config/dashconfig/get-sports/v1`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setBetopsConfigConfig(response.data.data);
                localStorage.setItem("sportsList", response.data.data);
                // await sportInfo(response.data.data[0]);
            }
            else {
            }
        }).catch((error) => {
        })
    }

    const DeleteLanding = (e, record, index) => {
        let data = responseDataNew;
        let dtaa = responseDataNew;
        dtaa = dtaa.landing.filter((list, indexs) => {
            return (indexs != index)
        })
        data.landing = dtaa
        setResponseDataNew(data)
        setResponseDataNewTable(data)
        setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(data)))
        setBannerArray(BannerArray + 1)
    }
    const EditLanding = (e, record, index) => {
        setLandingIndex(index)
        setvisible(true)
        setBannerArray(BannerArray + 1)
    }
    const addnewLanding = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        if (data.landing) {
            data.landing.push({
                componentList: [],
                tabData: {
                    minMatch: null,
                    vTitle: {
                        data: null,
                        dataSrc: null,
                        dataType: null
                    },
                    vType: null
                },
                tabType: null
            })
        }
        else {
            data.landing = []
            data.landing.push({
                componentList: [],
                tabData: {
                    minMatch: null,
                    vTitle: {
                        data: null,
                        dataSrc: null,
                        dataType: null
                    },
                    vType: null
                },
                tabType: null
            })
        }
        setLandingIndex(data.landing.length - 1)
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        setvisible(true)
    }
    const onClose = () => {
        if (responseDataNewFilter) {
            setResponseDataNew(responseDataNewFilter)
            setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(responseDataNewFilter)))

        }
        else {
            let data = responseDataNew;
            if (data.landing.length > 0) {
                if (!data.landing[landingIndex].tabType) {
                    data.landing.splice(landingIndex, 1)
                }
            }
            setResponseDataNew(data)
            setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(data)))

        }
        setvisible(false)
        setversionVisible(false)
        setBannerArray(BannerArray + 1)

    }
    const onCloseComponent = () => {
        if (responseDataNewFilter) {
            setResponseDataNew(responseDataNewFilter)
        }
        else {
            let data = responseDataNew
            let objectData = data.landing[landingIndex].componentList[componentListIndex]
            let checkObject = Object.keys(objectData).length === 0 && objectData.constructor === Object
            if (!objectData.comType) {
                data.landing[landingIndex].componentList.splice(componentListIndex, 1);
            }
        }
        setComponents(false)
        setvisibleComponent(false)
    }
    const handleChangeLandingtabData = (index, e) => {

        let data = responseDataNew;
        data.landing[index].tabData[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeLandingtabDataVtitle = (index, e) => {

        let data = responseDataNew;
        data.landing[index].tabData.vTitle[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeLanding = (index, e) => {

        let data = responseDataNew;
        data.landing[index][e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const addNewComponentList = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew;
        if (data.landing[landingIndex].componentList) {
            data.landing[landingIndex].componentList.push({ comType: null, vModel: null })
            setcomponentListIndex(data.landing[landingIndex].componentList.length - 1)
        }
        else {
            data.landing[landingIndex].componentList = []
            data.landing[landingIndex].componentList.push({ comType: null, vModel: null })
            setcomponentListIndex(data.landing[landingIndex].componentList.length - 1)
        }
        setvisibleComponent(true)
        setComponents(true)
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleComTypeChange = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex] = { comType: e.value }
        let vmodelList = landingConfig.comTypeList.filter(list => list.comType === e.value);
        setVModelFilter(vmodelList)
        setResponseDataNew(data)
    }
    const handleVModelChange = (index, e) => {


        let data = responseDataNew;
        let configure = landingConfig.vModelList.filter(list => list.vModel === index);

        for (let i = 0; i < configure[0].inputList.length; i++) {
            if (i === 0) {

                data.landing[landingIndex].componentList[componentListIndex] =
                {
                    "vModel": index,
                    "comType": data.landing[landingIndex].componentList[componentListIndex].comType,
                    "command": null,
                }
                if (configure[0].inputList[i] === "style") {
                    data.landing[landingIndex].componentList[componentListIndex].style = {
                        "fontColor": "#000000",
                        "type": null,
                        "bgColor": "#000000",
                    }
                }
                else if (configure[0].inputList[i] === "actionList") {
                    data.landing[landingIndex].componentList[componentListIndex].actionList = []
                    break;
                }
                else if (configure[0].inputList[i] === "command") { }
                else {
                    data.landing[landingIndex].componentList[componentListIndex][configure[0].inputList[i]] = {
                        "data": null,
                        "dataType": null,
                        "dataSrc": null,
                        "dataFormat": null,
                    }
                }

            }
            else {
                if (configure[0].inputList[i] === "style") {
                    data.landing[landingIndex].componentList[componentListIndex].style = {
                        "fontColor": "#000000",
                        "type": null,
                        "bgColor": "#000000",
                    }
                }
                else if (configure[0].inputList[i] === "actionList") {
                    data.landing[landingIndex].componentList[componentListIndex].actionList = []
                    break;
                }
                else if (configure[0].inputList[i] === "command") { }
                else {
                    data.landing[landingIndex].componentList[componentListIndex][configure[0].inputList[i]] = {
                        "data": null,
                        "dataType": null,
                        "dataSrc": null,
                        "dataFormat": null,
                    }
                }
            }

        }
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const EditComponentList = (index, e) => {

        let data = responseDataNew
        let vmodelList = landingConfig.comTypeList.filter(list => list.comType === data.landing[landingIndex].componentList[e].comType);
        setVModelFilter(vmodelList)
        setcomponentListIndex(e)
        setvisibleComponent(true)
        setComponents(true)
        setBannerArray(BannerArray + 1)
    }
    const DeleteComponentList = (index, e) => {

        let data = responseDataNew;
        let dtaa = responseDataNew;
        dtaa = dtaa.landing[landingIndex].componentList.filter((list, indexs) => {

            return (indexs != e)
        })
        data.landing[landingIndex].componentList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const updateConfig = async () => {
        setversionVisible(false)
        let data = responseDataNew
        data.appId = currentTournamentDetails.shortCode + "-ops";

        if (props.drawertype === "Edit Landing") {
            setLoaded(true)
            // let dataPayload = {
            //     "appId": currentTournamentDetails.shortCode + "-ios",
            //     "version": version,
            //     "landing": data.landing
            // }
            const response = await axios.post(
                `${domain}/betops/config/landing/update/v1`, data)
                .then((response) => {

                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        Modal.success({
                            content: response.data.data,
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.error.message,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
        else {
            setLoaded(true)
            let dataPayload = {
                "appId": data.appId,
                "version": version,
                "docType": "landing_config",
                "aid": "fantasycafe",
                "platform": props.platform,
                "active": true,
                "landing": data.landing
            }
            const response = await axios.post(
                `${domain}/betops/config/landing/create/v1`, dataPayload)
                .then((response) => {

                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        Modal.success({
                            content: response.data.data,
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.error.message,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
    }
    const versionModelVisible = (e) => {

        if (props.drawertype == "Edit Landing") {
            setversion(responseDataNew.version)
            setversionVisible(true)
        }
        else {
            setversionVisible(true)
        }
    }
    const versionChange = (e) => {

        setversion(e.target.value)
    }
    const handleChangeComponentData = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex][e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentTitle = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].title[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentDataCommand = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].command = e

        let info = landingConfig.commandTypeList.filter(list => list.command === e)
        if (info[0].actionData) {
            data.landing[landingIndex].componentList[componentListIndex].actionData = {
                "provider": null,
                "keyName": null,
                "dataSrc": null,
            }
        }
        else {
            if (data.landing[landingIndex].componentList[componentListIndex].actionData)
                delete data.landing[landingIndex].componentList[componentListIndex].actionData
        }

        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentStyle = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].style[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentActionData = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionData[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentSubText = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].subText[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentDescription = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].description[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentTitleImg = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].titleImg[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeComponentactionListActionData = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].actionData[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeActiontitleDataType = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].title.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeActionDatatitleImgDataSrc = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].titleImg.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeActionDatatitleImgDataType = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].titleImg.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentactionDataDataSrc = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionData.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponenttitleImgDataType = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].titleImg.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponenttitleImgDataSrc = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].titleImg.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentsubTextDataSrc = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].subText.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentDescriptionDataSrc = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].description.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentDescriptionDataType = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].description.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentTitledataSrc = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].title.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentTitledataType = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].title.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentsubTextDataType = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].subText.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeActiontitleDataSrc = (index, e) => {

        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].title.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeActionactionDataDataSrc = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].actionData.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentactionListStyle = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].style[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentactionListTitleImg = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].titleImg[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentDataActionList = (index, e) => {
        let data = responseDataNew;

        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].command = e
        let info = landingConfig.commandTypeList.filter(list => list.command === e)
        if (info[0].actionData) {
            data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].actionData = {
                "provider": null,
                "keyName": null,
                "dataSrc": null,
            }
        }
        else {
            if (data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].actionData)
                delete data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].actionData
        }
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentactionListSubText = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].subText[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentactionListDescription = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].description[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeComponentactionListTitle = (index, e) => {
        let data = responseDataNew;
        data.landing[landingIndex].componentList[componentListIndex].actionList[actionIndex].title[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handletabTypeChange = (index, e) => {
        let data = responseDataNew;
        data.landing[index].tabType = e

        data.landing[index].tabData.vType = "";
        data.landing[index].tabData.minMatch = "";

        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handlevTypeChange = (index, e) => {

        let data = responseDataNew;
        data.landing[index].tabData.vType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleMainDataSrcChange = (index, e) => {

        let data = responseDataNew;
        data.landing[index].tabData.vTitle.dataSrc = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleMainDataTypeChange = (index, e) => {

        let data = responseDataNew;
        data.landing[index].tabData.vTitle.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const DeleteActionList = (index, e) => {
        let data = responseDataNew;
        let dtaa = responseDataNew;
        dtaa = dtaa.landing[landingIndex].componentList[componentListIndex].actionList.filter((list, indexs) => {

            return (indexs != e)
        })
        data.landing[landingIndex].componentList[componentListIndex].actionList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const EditActionList = (index) => {
        setActionIndex(index);  
        let data = responseDataNew
        setserviceListIndex(index)
        let vmodelList = landingConfig.comTypeList.filter(list => list.comType === data.landing[landingIndex].componentList[componentListIndex].comType);
        setVModelFilter(vmodelList)
        setVisibleActionList(true)
        setActionList(true)
    }
    const addNewActionList = (index, e) => {

        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        let configure = landingConfig.vModelList.filter(list => list.vModel === data.landing[landingIndex].componentList[componentListIndex].vModel);
        let indexxx = serviceListIndex
        if (data.landing[landingIndex].componentList[componentListIndex].actionList.length === 0) {
            indexxx = 0;
            data.landing[landingIndex].componentList[componentListIndex].actionList.push({})
        }
        else {
            indexxx = data.landing[landingIndex].componentList[componentListIndex].actionList.length - 1;
        }
        for (let i = 0; i < configure[0].inputList.length; i++) {
            if (i === 0) {
                data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx].command = null
                if (configure[0].inputList[i] === "style") {
                    data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx][configure[0].inputList[i]] = {
                        "fontColor": "#000000",
                        "type": null,
                        "bgColor": "#000000",
                    }
                }
                else if (configure[0].inputList[i] === "actionData") {
                    data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx][configure[0].inputList[i]] = {
                        "provider": null,
                        "keyName": null,
                        "dataSrc": null,
                    }
                }
                else if (configure[0].inputList[i] === "actionList") { }
                else if (configure[0].inputList[i] === "command") { }
                else {
                    data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx][configure[0].inputList[i]] = {
                        "data": null,
                        "dataType": null,
                        "dataSrc": null,
                        "dataFormat": null,
                    }
                }

            }
            else {
                if (configure[0].inputList[i] === "style") {
                    data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx][configure[0].inputList[i]] = {
                        "fontColor": "#000000",
                        "type": null,
                        "bgColor": "#000000",
                    }
                }
                else if (configure[0].inputList[i] === "actionData") {
                    data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx][configure[0].inputList[i]] = {
                        "provider": null,
                        "keyName": null,
                        "dataSrc": null,
                    }
                }
                else if (configure[0].inputList[i] === "actionList") { }
                else if (configure[0].inputList[i] === "command") { }
                else {
                    data.landing[landingIndex].componentList[componentListIndex].actionList[indexxx][configure[0].inputList[i]] = {
                        "data": null,
                        "dataType": null,
                        "dataSrc": null,
                        "dataFormat": null,
                    }
                }
            }
        }

        setserviceListIndex(data.landing[landingIndex].componentList[componentListIndex].actionList.length - 1)
        setVisibleActionList(true)
        setActionList(true)
    }
    const onCloseactionList = () => {
        if (responseDataNewFilter) {
            setResponseDataNew(responseDataNewFilter)
        }
        else {
        }
        setVisibleActionList(false)
        setActionList(false)
    }
    const onSaveactionList = () => {
        setVisibleActionList(false)
        setActionList(false)
        setResponseDataNewFilter("")
    }
    const onSaveComponent = () => {
        let data = responseDataNew
        let objectData = data.landing[landingIndex].componentList[componentListIndex]
        let checkObject = Object.keys(objectData).length === 0 && objectData.constructor === Object
        if (!objectData.comType) {
            data.landing[landingIndex].componentList.splice(componentListIndex, 1);
        }
        setComponents(false)
        setvisibleComponent(false)
        setResponseDataNewFilter("")
    }
    const onSaveLanding = () => {
        setResponseDataNewFilter("")
        let data = responseDataNew;
        if (!data.landing[landingIndex].tabType) {
            data.landing.splice(landingIndex, 1)
        }
        setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(data)))
        setResponseDataNew(data)
        setResponseDataNewTable(data)
        setvisible(false)
        setversionVisible(false)
        setBannerArray(BannerArray + 1)

    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <Layout className="site-layout" style={{ marginLeft: 0 }}>
                        <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                            <div className="site-card-border-less-wrapper">
                                <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                    <PageHeader
                                        className="site-page-header"
                                        title={headingTitle}
                                        extra={
                                            <Space direction="horizontal" style={{ marginLeft: "auto", width: "100%" }}>
                                                <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
                                                <a onClick={() => addnewLanding()}>
                                                    <span className="addschedule">Add</span>
                                                </a>
                                            </Space>
                                        }
                                    />

                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        // dataSource={responseDataNewDisplay.landing ? [...responseDataNewDisplay.landing] : []}
                                        dataSource={responseDataNewTable.landing}
                                        scroll={{ y: "340" }}
                                    >
                                        <Column title="Tab Type" dataIndex="tabType" key="key" />

                                        <Column
                                            title="Quick Actions"
                                            key="text"
                                            render={(text, record, index) => (
                                                <Space size="middle">
                                                    <a onClick={() => EditLanding(this, text, index)}>
                                                        <EditTwoTone style={{ fontSize: "18px", color: "#08c" }} />{" "}
                                                    </a>
                                                    <a onClick={() => DeleteLanding(this, text, index)}>
                                                        <DeleteTwoTone style={{ fontSize: "18px", color: "#08c" }} />{" "}
                                                    </a>
                                                </Space>
                                            )}
                                        />
                                    </Table>
                                    {props.drawertype == "Create Landing" ?
                                        <Button className="btn btn-primary m-4 p-TB-0" onClick={versionModelVisible}>Create</Button>
                                        :
                                        <Button className="btn btn-primary m-4 p-TB-0" onClick={versionModelVisible}>Update</Button>
                                    }
                                </Card>
                            </div>
                        </Content>
                    </Layout>
                </div>
            </div>

            <Drawer className="drawer-align-cls" title="Landing Info" placement="right" onClose={onClose} visible={visible} size="small">
                <div>
                    {responseDataNew?.landing ? responseDataNew.landing.map((list, index) => {
                        if (landingIndex === index) {
                            return (<>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>Tab Type</h5>
                                    </div>

                                    <div className="col-md-12">
                                        <label>tab type</label>
                                        <Select size="default" value={list.tabType} placeholder="Select Tab Type" title="Select Tab Type" className="m-1 mb-0" style={{ width: "200px" }} onChange={handletabTypeChange.bind(this, index)} >

                                            {landingConfig ? landingConfig.tabTypeList.map((listtabType, index) => {
                                                return (<Option key={componentListIndex} value={listtabType.tabType}>
                                                    {listtabType.tabType}{" "}
                                                </Option>)
                                            }) : ""}

                                        </Select>
                                    </div>
                                    {list.tabType ? <><div className="col-md-12">
                                        <h5>V Type</h5>
                                    </div>
                                        <div className="col-md-12">

                                            <label>v type</label>
                                            <Select size="default" value={list.tabData.vType} placeholder="Select V Type" title="Select V Type" className="m-1 mb-0" style={{ width: "200px" }} onChange={handlevTypeChange.bind(this, index)}>
                                                {landingConfig ? landingConfig.tabTypeList.map((lists, index) => {
                                                    if (lists.tabType === list.tabType) {
                                                        return (lists.vTypeList.map((listVtype, index) => {
                                                            return (<Option key={listVtype} value={listVtype}>
                                                                {listVtype}{" "}
                                                            </Option>)
                                                        }))
                                                    }
                                                    else {
                                                        return ""
                                                    }

                                                }) : ""}

                                            </Select>
                                        </div>
                                    </> : ""}
                                    {list.tabData.vType ? <> <div className="col-md-12">
                                        <h5>Tab Data</h5>
                                    </div>
                                        <div className="col-md-6">

                                            <label>min match</label>
                                            <input type="number" placeholder="Min Match" name="minMatch" value={list.tabData.minMatch} className="form-control m-1 mb-0" onChange={handleChangeLandingtabData.bind(this, index)} />
                                        </div>

                                        <div className="col-md-12">
                                            <h5>V Title</h5>
                                        </div>

                                        <div className="col-md-6">
                                            <label>data type</label>

                                            <Select size="default" value={list.tabData.vTitle.dataType} placeholder="Select Data Type" title="Select  Data Type" className="m-1" style={{ width: "200px" }} onChange={handleMainDataTypeChange.bind(this, index)} >
                                                {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                    return (<Option key={lists.dataType} value={lists.dataType}>
                                                        {lists.dataType}{" "}
                                                    </Option>)
                                                }) : ""}

                                            </Select>
                                        </div>

                                        <div className="col-md-6">
                                            <label>data src</label>

                                            <Select size="default" value={list.tabData.vTitle.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleMainDataSrcChange.bind(this, index)} >
                                                {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                    return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                        {lists.dataSrc}{" "}
                                                    </Option>)
                                                }) : ""}

                                            </Select>
                                        </div>
                                        <div className="col-md-6">

                                            <label>data</label>

                                            <input type="text" placeholder="Data" name="data" value={list.tabData.vTitle.data} className="form-control m-1" onChange={handleChangeLandingtabDataVtitle.bind(this, index)} />
                                        </div>
                                    </> : ""}
                                </div>
                            </>
                            )
                        }
                        else {
                            return ""
                        }

                    }) : ""}
                    {responseDataNew ? responseDataNew.landing[landingIndex]?.componentList.map((list, index) => {
                        return (<table className="table table-bordered">
                            <tr>
                                <td>Component List {index + 1}</td>
                                <td><EditOutlined color="blue" onClick={() => EditComponentList(this, index)} /></td>
                                <td><DeleteOutlined color="red" onClick={() => DeleteComponentList(this, index)} /></td>
                            </tr>
                        </table>
                        )
                    })
                        : ""}
                           <div className="col-md-12" style={{ marginBottom: "30px" }}>
                        <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                        <a onClick={() => addNewComponentList(this)}>
                            <span className="addbet">New Component List</span>
                        </a>
                    </div>
                    <Button type="primary" onClick={onSaveLanding}>Save</Button>
                </div>
            </Drawer>
            {Components ? <Drawer className="drawer-align-cls" title="Component List" placement="right" onClose={onCloseComponent} visible={visibleComponent} size="small">
                <div className="row">
                    <div className="col-md-12">
                        <h5>Com Type</h5>
                    </div>
                    <div className="col-md-6">

                        <label>com type</label>

                        <Select size="default" value={responseDataNew?.landing[landingIndex]?.componentList[componentListIndex]?.comType} placeholder="Select Com Type" title="Select Com Type" className="m-1" style={{ width: "200px" }} onChange={(e, componentListIndex) => handleComTypeChange(e, componentListIndex)}>
                            {landingConfig ? landingConfig.comTypeList.map((list, index) => {
                                return (<Option key={list.comType} value={list.comType}>
                                    {list.comType}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                    </div>
                    {responseDataNew?.landing[landingIndex]?.componentList[componentListIndex]?.comType ? <>  <div className="col-md-12">
                        <h5>V Model</h5>
                    </div>
                        <div className="col-md-6">

                            <label>v model</label>

                            <Select size="default" title="Select VModel" value={responseDataNew?.landing[landingIndex]?.componentList[componentListIndex]?.vModel} placeholder="Select VModel" className="m-1" style={{ width: "200px" }} onChange={(e, componentListIndex) => handleVModelChange(e, componentListIndex)}>
                                {vModelFilter ? vModelFilter[0].vModelList.map((list, index) => {
                                    return (<Option key={list} value={list}>
                                        {list}{" "}
                                    </Option>)
                                }) : ""}

                            </Select>
                        </div></> : ""}
                    {responseDataNew ? responseDataNew.landing ? responseDataNew.landing[landingIndex].componentList ? responseDataNew.landing[landingIndex].componentList.map((list, index) => {

                        if (componentListIndex === index) {
                            return (
                                list.actionList ?
                                    <div>
                                        {list.actionList.map((list, index) => {
                                            return (<table className="table table-bordered">
                                                <tr>
                                                    <td>Action List {index + 1}</td>
                                                    <td><EditOutlined color="blue" onClick={() => EditActionList(index)} /></td>
                                                    <td><DeleteOutlined color="red" onClick={() => DeleteActionList(index)} /></td>
                                                </tr>
                                            </table>
                                            )
                                        })}
                                        < div className="col-md-12" style={{ marginBottom: "30px" }}>
                                            <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                                            <a onClick={() => addNewActionList(this)}>
                                                <span className="addbet">New Action List</span>
                                            </a>
                                        </div></div>
                                    :
                                    <>
                                        {responseDataNew?.landing[landingIndex]?.componentList[componentListIndex]?.vModel ?
                                            <div className="col-md-6">
                                                <label>command</label>

                                                <Select size="default" title="Select Command" value={list.command} placeholder="Select Command" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentDataCommand.bind(this, index)}>
                                                    {landingConfig ? landingConfig.commandTypeList.map((list, index) => {
                                                        return (<Option key={list.command} value={list.command}>
                                                            {list.command}{" "}
                                                        </Option>)
                                                    }) : ""}

                                                </Select>
                                            </div> : ""}


                                        {list.title ? <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Title</h5>
                                                </div>

                                                <div className="col-md-6">

                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.title.data} className="form-control m-1" onChange={handleChangeComponentTitle.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>
                                                    <input type="text" placeholder="Data Format" title="dataFormat" name="dataFormat" value={list.title.dataFormat} className="form-control m-1" onChange={handleChangeComponentTitle.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <Select size="default" value={list.title.dataType} placeholder="Select Data Type" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentTitledataType.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                            return (<Option key={lists.dataType} value={lists.dataType}>
                                                                {lists.dataType}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <Select size="default" value={list.title.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentTitledataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                            return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                                {lists.dataSrc}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                            </div>
                                        </> : ""} :
                                        {list.description ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Description</h5>
                                                </div>

                                                <div className="col-md-6">

                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.description.data} className="form-control m-1" onChange={handleChangeComponentDescription.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <Select size="default" value={list.description.dataType} placeholder="Select Data Type" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentDescriptionDataType.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                            return (<Option key={lists.dataType} value={lists.dataType}>
                                                                {lists.dataType}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <Select size="default" value={list.description.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentDescriptionDataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                            return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                                {lists.dataSrc}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>
                                                    <input type="text" placeholder="dataFormat" title="dataFormat" name="dataFormat" value={list.description.dataFormat} className="form-control m-1" onChange={handleChangeComponentDescription.bind(this, index)} />
                                                </div>
                                            </div> : ""}
                                        {list.subText ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Sub Text</h5>
                                                </div>

                                                <div className="col-md-6">
                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.subText.data} className="form-control m-1" onChange={handleChangeComponentSubText.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <Select size="default" value={list.subText.dataType} placeholder="Select Data Type" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentsubTextDataType.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                            return (<Option key={lists.dataType} value={lists.dataType}>
                                                                {lists.dataType}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <Select size="default" value={list.subText.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentsubTextDataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                            return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                                {lists.dataSrc}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>
                                                    <input type="text" placeholder="Data Format" title="dataFormat" name="dataFormat" value={list.subText.dataFormat} className="form-control m-1" onChange={handleChangeComponentSubText.bind(this, index)} />
                                                </div>

                                            </div> : ""}
                                        {list.titleImg ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Title Image</h5>
                                                </div>
                                                <div className="col-md-6">

                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.titleImg.data} className="form-control m-1" onChange={handleChangeComponentTitleImg.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <Select size="default" value={list.titleImg.dataType} placeholder="Select Data Type" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponenttitleImgDataType.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                            return (<Option key={lists.dataType} value={lists.dataType}>
                                                                {lists.dataType}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <Select size="default" value={list.titleImg.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponenttitleImgDataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                            return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                                {lists.dataSrc}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>
                                                    <input type="text" placeholder="Data Format" title="dataFormat" name="dataFormat" value={list.titleImg.dataFormat} className="form-control m-1" onChange={handleChangeComponentTitleImg.bind(this, index)} />

                                                </div>
                                            </div> : ""}
                                        {list.style ? <div className="row">
                                            <div className="col-md-12">
                                                <h5>Style</h5>
                                            </div>
                                            <div className="col-md-6">
                                                <label>font color</label>
                                                <input type="color" placeholder="Font Color" title="Font Color" name="fontColor" value={list.style.fontColor} className="form-control m-1" onChange={handleChangeComponentStyle.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>bg color</label>
                                                <input type="color" placeholder="Bg Color" title="Bg Color" name="bgColor" value={list.style.bgColor} className="form-control m-1" onChange={handleChangeComponentStyle.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Type</label>
                                                <input type="text" placeholder="Type" title="Type" name="type" value={list.style.type} className="form-control m-1" onChange={handleChangeComponentStyle.bind(this, index)} />
                                            </div>
                                        </div> : ""}
                                        {list.actionData ? <div className="row">
                                            <div className="col-md-12">
                                                <h5>Action Data</h5>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" placeholder="Provider" title="Provider" name="provider" value={list.actionData.provider} className="form-control m-1" onChange={handleChangeComponentActionData.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>data src</label>
                                                <Select size="default" value={list.actionData.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentactionDataDataSrc.bind(this, index)} >
                                                    {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                        return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                            {lists.dataSrc}{" "}
                                                        </Option>)
                                                    }) : ""}

                                                </Select>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" placeholder="Key Name" title="Key Name" name="keyName" value={list.actionData.keyName} className="form-control m-1" onChange={handleChangeComponentActionData.bind(this, index)} />
                                            </div>
                                        </div> : ""}
                                    </>
                            )
                        }
                        else {
                            return ""
                        }

                    }) : "" : "" : ""}
                </div>
                <Button type="primary" onClick={onSaveComponent}>Save</Button>


            </Drawer> : ""}
            {actionList ? <Drawer className="drawer-align-cls" title="Action List" placement="right" onClose={onCloseactionList} visible={visibleActionList} size="small">
                <div className="row">
                    {responseDataNew.landing[landingIndex].componentList[componentListIndex].actionList.map((list, index) => {

                        if (actionIndex === index) {
                            return (
                                <div>

                                    <>
                                        <div className="col-md-6">
                                            <label>command</label>

                                            <Select size="default" title="Select Command" value={list.command} placeholder="Select Command" className="m-1" style={{ width: "200px" }} onChange={handleChangeComponentDataActionList.bind(this, index)}>
                                                {landingConfig ? landingConfig.commandTypeList.map((list, index) => {
                                                    return (<Option key={list.command} value={list.command}>
                                                        {list.command}{" "}
                                                    </Option>)
                                                }) : ""}

                                            </Select>
                                        </div>
                                        {list.title ? <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Title</h5>
                                                </div>

                                                <div className="col-md-6">

                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.title.data} className="form-control m-1" onChange={handleChangeComponentactionListTitle.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <Select size="default" value={list.title.dataType} placeholder="Select Data Type" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeActiontitleDataType.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                            return (<Option key={lists.dataType} value={lists.dataType}>
                                                                {lists.dataType}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <Select size="default" value={list.title.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeActiontitleDataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                            return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                                {lists.dataSrc}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>
                                                    <input type="text" placeholder="Data Format" title="Data Format" name="dataFormat" value={list.title.dataFormat} className="form-control m-1" onChange={handleChangeComponentactionListTitle.bind(this, index)} />
                                                </div>
                                            </div>
                                        </> : ""}
                                        {list.description ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Description</h5>
                                                </div>

                                                <div className="col-md-6">
                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.description.data} className="form-control m-1" onChange={handleChangeComponentactionListDescription.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <input type="text" placeholder="Data Type" title="Data Type" name="dataType" value={list.description.dataType} className="form-control m-1" onChange={handleChangeComponentactionListDescription.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <input type="text" placeholder="Data Src" title="Data Src" name="dataSrc" value={list.description.dataSrc} className="form-control m-1" onChange={handleChangeComponentactionListDescription.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>

                                                    <input type="text" placeholder="Data Format" title="Data Format" name="dataFormat" value={list.description.dataFormat} className="form-control m-1" onChange={handleChangeComponentactionListDescription.bind(this, index)} />
                                                </div>
                                            </div> : ""}
                                        {list.subText ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Sub Text</h5>
                                                </div>

                                                <div className="col-md-6">

                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.subText.data} className="form-control m-1" onChange={handleChangeComponentactionListSubText.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <input type="text" placeholder="Data Type" title="Data Type" name="dataType" value={list.subText.dataType} className="form-control m-1" onChange={handleChangeComponentactionListSubText.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <input type="text" placeholder="Data Src" title="Data Src" name="dataSrc" value={list.subText.dataSrc} className="form-control m-1" onChange={handleChangeComponentactionListSubText.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>
                                                    <input type="text" placeholder="Data Format" title="Data Format" name="dataFormat" value={list.subText.dataFormat} className="form-control m-1" onChange={handleChangeComponentactionListSubText.bind(this, index)} />
                                                </div>

                                            </div> : ""}
                                        {list.titleImg ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Title Image</h5>
                                                </div>
                                                <div className="col-md-6">

                                                    <label>data</label>
                                                    <input type="text" placeholder="Data" title="Data" name="data" value={list.titleImg.data} className="form-control m-1" onChange={handleChangeComponentactionListTitleImg.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data type</label>
                                                    <Select size="default" value={list.titleImg.dataType} placeholder="Select Data Type" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeActionDatatitleImgDataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataTypeList.map((lists, index) => {
                                                            return (<Option key={lists.dataType} value={lists.dataType}>
                                                                {lists.dataType}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data src</label>
                                                    <Select size="default" value={list.titleImg.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeActionDatatitleImgDataSrc.bind(this, index)} >
                                                        {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                            return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                                {lists.dataSrc}{" "}
                                                            </Option>)
                                                        }) : ""}

                                                    </Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>data format</label>

                                                    <input type="text" placeholder="Data Format" title="Data Format" name="dataFormat" value={list.titleImg.dataFormat} className="form-control m-1" onChange={handleChangeComponentactionListTitleImg.bind(this, index)} />
                                                </div>
                                            </div> : ""}
                                        {list.style ? <div className="row">
                                            <div className="col-md-12">
                                                <h5>Style</h5>
                                            </div>
                                            <div className="col-md-6">
                                                <label>font color</label>
                                                <input type="color" placeholder="Font Color" title="Font Color" name="fontColor" value={list.style.fontColor} className="form-control m-1" onChange={handleChangeComponentactionListStyle.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>bg color</label>
                                                <input type="color" placeholder="Bg Color" title="Bg Color" name="bgColor" value={list.style.bgColor} className="form-control m-1" onChange={handleChangeComponentactionListStyle.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Type</label>
                                                <input type="text" placeholder="Type" title="Type" name="type" value={list.style.type} className="form-control m-1" onChange={handleChangeComponentactionListStyle.bind(this, index)} />
                                            </div>
                                        </div> : ""}
                                        {list.actionData ? <div className="row">
                                            <div className="col-md-12">

                                                <h5>Action Data</h5>
                                            </div>
                                            <div className="col-md-6">
                                                <label>provider</label>
                                                <input type="text" placeholder="Provider" title="Provider" name="provider" value={list.actionData.provider} className="form-control m-1" onChange={handleChangeComponentactionListActionData.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>data src</label>
                                                <Select size="default" value={list.actionData.dataSrc} placeholder="Select Data Src" title="Select  Data Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeActionactionDataDataSrc.bind(this, index)} >
                                                    {landingConfig ? landingConfig.dataSrcList.map((lists, index) => {
                                                        return (<Option key={lists.dataSrc} value={lists.dataSrc}>
                                                            {lists.dataSrc}{" "}
                                                        </Option>)
                                                    }) : ""}

                                                </Select>
                                            </div>
                                            <div className="col-md-6">
                                                <label>key name</label>
                                                <input type="text" placeholder="Key Name" title="Key Name" name="keyName" value={list.actionData.keyName} className="form-control m-1" onChange={handleChangeComponentactionListActionData.bind(this, index)} />
                                            </div>
                                        </div> : ""}
                                    </>
                                </div>
                            )
                        }
                        else {
                            return ""
                        }

                    })}
                </div>
                <Button type="primary" onClick={onSaveactionList}>Save</Button>
            </Drawer> : ""}
            {loaded == true ? (
                <div className="loading-overlay">
                    <Spin size="large" />{" "}
                </div>
            ) : (
                ""
            )}
            <Modal title="Add" onCancel={onClose} visible={versionVisible}
                footer={[
                    <Button key="submit" type="primary" onClick={onClose}>
                        Cancel
                    </Button>
                ]}
            >
                <Form form={form} name="horizontal_login" layout="vertical" onFinish={updateConfig}>
                    <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="version" label="Enter the version" initialValue={version} rules={[{ required: true, message: "Please input Version Number" }]}>
                                <Input placeholder="Version Number" onChange={versionChange} />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                    <Row gutter={24} className="rtmp1">
                        <Col span={24}>
                            <Form.Item shouldUpdate {...tailLayout}>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Add
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div >
    )
}
export default IOSLanding;