import { Layout } from "antd";
import React from "react";
import "antd/dist/antd.min.css"
import { currentTournamentDetails } from "../settings/config";


const { Footer } = Layout;
function Footersection() {
  return (
    <Footer>
      <hr style={{ color: "#212121" }} />
      <div> <small className="float-start text-muted"> {currentTournamentDetails.fullName} ©2023 Powered by Sherpa </small><small className="float-end text-muted">V{currentTournamentDetails.version}</small></div>
    </Footer>
  );
}
export default Footersection;
