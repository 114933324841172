import { collection, getDocs, query, where } from "firebase/firestore";
import { Select, Layout, Card, Col, Input, Table, DatePicker, Space, Drawer, Modal, PageHeader, Spin, List } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import moment from "moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { domain } from "../../configuration/ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";
import localStore from "../../utils/services";
const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function Feedback() {
    const { RangePicker } = DatePicker;
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    //const [scheduleData, setScheduleData] = useState([]);
    const [feedbackData, setFeedbackData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [transactionsBet, setTransactionsBet] = useState([]);
    const [feedbackDataDup, setFeedbackDetailsDup] = useState([]);
    const [feedbackDetails, setFeedbackDetails] = useState([]);
    const showDrawer = async (matchid) => {
        setDataFilter(matchid);
        setVisible(true);
    };
    const onChange = (value, dateString) => {

        if (value) {
            console.log('Selected Time: ', value);
            console.log('Formatted Selected Time: ', dateString);
            let actualFeedBackList = feedbackDataDup
            let filters = actualFeedBackList.filter((list) => {
                let actualDate = moment(list.createdDate._seconds * 1000)

                if (value[0]._d.getTime() <= actualDate._d.getTime() && value[1]._d.getTime() >= actualDate._d.getTime()) {

                }
                return (
                    value[0]._d.getTime() <= actualDate._d.getTime() && value[1]._d.getTime() >= actualDate._d.getTime()
                )
            })
            setFeedbackDetails(filters)
        }
        else {
            setFeedbackDetails(feedbackDataDup)
        }
    };
    const fetchFeedBackDetails = async () => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/feedbackemail/all-list/v1?appId=${currentTournamentDetails.shortCode}-web`,
        }).then((response) => {

            setLoaded(false);
            if (response.data.status === "SUCCESS") {
                let datas = response.data.data.sort(function (a, b) {
                    return moment(b.createdDate._seconds * 1000) - moment(a.createdDate._seconds * 1000);
                });
                setFeedbackDetails(datas)
                setFeedbackDetailsDup(datas)
            }
            else {
                setLoaded(false);
                // Modal.error({
                //     content: response.data.data,
                // });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
        setLoaded(false)
    };
    const sortArray = (type) => {
        const types = {
            matchId: "matchId",
        };
        const sortProperty = types[type];
        const sorted = [...feedbackDetails].sort((a, b) => {
            return b[sortProperty] - a[sortProperty];
        });
        setFeedbackDetails(sorted);
    };
    const handleTableChange = (pagination, filters, sorter) => {
        sortArray(sorter.field);
        if (sorter.order == undefined) {
            setFeedbackDetails(feedbackDetails.reverse());
        } else if (sorter.order == "descend") {
            sortArray(sorter.field);
        } else {
            setFeedbackDetails(feedbackDetails.reverse());
        }
    };
    const onSearch = (e) => {

        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = feedbackDataDup.filter((item) => {
                return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
            });
            setFeedbackDetails(filteredData);
        } else {
            setFeedbackDetails(feedbackDataDup)
        }
    };
    const onClose = () => {
        setVisible(false);
    };
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
    }, [user, loading]);
    useEffect(async () => {
        fetchFeedBackDetails();
    }, []);
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>

            <>
                <Sidermenu menu="feed_back" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <PageHeader
                                    className="site-page-header"
                                    title="Website Feedback"
                                    extra={
                                        <Space key="p1" direction="horizontal">
                                            <RangePicker
                                                showTime={{ format: 'HH:mm' }}
                                                format="DD-MM-YYYY HH:mm"
                                                onChange={onChange}
                                            />
                                            <Search placeholder="Search here" onChange={onSearch} enterButton />{" "}
                                        </Space>
                                    }
                                />
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={feedbackDetails}
                                    onChange={handleTableChange}
                                    scroll={{ y: "250" }}
                                >
                                    <Column title="Aid" dataIndex="aid" key="aid" />
                                    <Column title="Name" dataIndex="name" key="name" />
                                    <Column title="Email" dataIndex="email" key="email" />
                                    <Column title="Mobile" dataIndex="mobile" key="mobile"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text ? text : "NA"}
                                            </Space>
                                        )} />
                                    <Column title="Subject" dataIndex="subject" key="subject"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text.length < 12 ? text : text.substring(0, 12) + "..."}
                                            </Space>
                                        )}
                                    />
                                    <Column
                                        title="Created Date"
                                        dataIndex="createdDate"
                                        key="createdDate"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                {text !== undefined ? (
                                                    <Moment unix format="DD-MM-YYYY HH:mm">
                                                        {text._seconds}
                                                    </Moment>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />
                                    <Column
                                        title="Quick Actions"
                                        key="matchId"
                                        style={{ textAlign: "center" }}
                                        render={(matchId, index) => (
                                            <Space key={index} size="middle">
                                                {matchId !== undefined ? (
                                                    <a onClick={() => showDrawer(matchId)}>
                                                        <EyeOutlined style={{ fontSize: "18px", color: "#08c" }} />
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </Space>
                                        )}
                                    />
                                </Table>
                            </Card>
                        </div>

                        <Drawer title="Details" placement="right" onClose={onClose} visible={visible} size="middle">
                            <br />
                            <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Subject</h4>
                            <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{dataFilter.subject}</p>
                            <h4 style={{ color: "#0099f3", fontSize: "16px", marginBottom: "1rem" }}>Body</h4>
                            <p style={{ fontSize: "14px", marginBottom: "3rem" }}>{dataFilter.body}</p>
                        </Drawer>

                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout>
    );
}

export default Feedback;
