import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Quiz(props) {
  const [infoform] = Form.useForm();
  const [form] = Form.useForm();
  const [seasonDrawerTitle, setSeasonDrawerTitle] = useState(null);
  const [visibleSeason, setVisibleSeason] = useState(false);
  const [seasonobj, setSeasonobj] = useState(null);
  const [mainindex, setMainindex] = useState();
  const [particularRecord, setParticularRecord] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [title, setTitle] = useState("");
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [tournament, setTournament] = useState([]);
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [tempSchedule, setTempSchedule] = useState([]);
  
  const addnew = () => {
    setParticularRecord(null);
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Quiz");
    setVisible(true);
  };
  const showDrawer = (matchid, index) => {
    if (!matchid.seasons) {
      matchid.seasons = [];
    }
    setParticularRecord(matchid);
    setCurrentindex(index);
    setMainindex(index);
    infoform.setFieldsValue({
      tournamentName: matchid.tournament,
      season: matchid.years,
      yearTitle: matchid.yearTitle,
    });
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const onCloseSeason = () => {
    form.resetFields();
    setVisibleSeason(false);
  }
  const deleterecord = async (matchid, index1) => {
    const arraycheck = tempSchedule;
    let filtered = arraycheck.quiz.filter(function (value, index, arr) {
      return index !== index1;
    });
    arraycheck.quiz = filtered;
    setTempSchedule(arraycheck);
    props.updateQuiz(arraycheck);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };
  const yearShow = (list) => {
    return list.toString();
  };
  const onClose = () => {
    infoform.resetFields();
    setVisible(false);
  };

  const seasonSubmit = async (event) => {
    if(seasonDrawerTitle == "add") {
      if(particularRecord == null) {
        let temp = {
          seasons: [event]
        }
        setParticularRecord(temp);
      } else {
        particularRecord.seasons.push(event);
      }
    } else {
      particularRecord.seasons[currentindex] = event;
    }
    onCloseSeason();
  }

  const addSeason = async () => {
    setSeasonDrawerTitle("add");
    form.setFieldsValue({
      season: null,
      seasonType: null,
    });
    setVisibleSeason(true);
  }

  const editSeason = async (e, index) => {
    setSeasonDrawerTitle("edit");
    form.setFieldsValue({
      season: e.season,
      seasonType: e.seasonType,
    });
    setVisibleSeason(true);
    setCurrentindex(index);
    // let data = particularRecord;
    // let dtaa = data.seasons.filter((list, indexs) => {
    //   return (indexs != index)
    // })
    // data.seasons = dtaa;
    // setParticularRecord(data);
    // setCurrentindex(index);  
  }

  const deleteSeason = async (e, index) => {
    let data = particularRecord;
    let dtaa = data.seasons.filter((list, indexs) => {
      return (indexs != index)
    })
    data.seasons = dtaa;
    setParticularRecord(data);
    setCurrentindex(index);
  }

  const onInfofinish = async (values) => {
    const arraycheck = tempSchedule;
    if (title === "New Quiz") {
      console.log(values);
      arraycheck.quiz.push({
        tournament: values.tournamentName,
        yearTitle: values.yearTitle,
        years: values.season,
        seasons: particularRecord.seasons
      });
    } else {
      arraycheck.quiz[mainindex] = {
        tournament: values.tournamentName,
        yearTitle: values.yearTitle,
        years: values.season,
        seasons: particularRecord.seasons
      };
    }
    setTempSchedule(arraycheck);
    props.updateQuiz(arraycheck);
    // Modal.success({
    //   content: title === "New Quiz" ? "New Quiz Added Successfully" : "Updated Successfully",
    //   onOk() {
    //     onClose();
    //     fetchDashboard();
    //   },
    // });
    onClose();
    fetchDashboard();
  };

  const fetchDashboard = async () => {
    let data = null;
    if(tempSchedule.quiz) {
      data = tempSchedule;
    } else {
      data = props.docdata;
      setTempSchedule(props.docdata);
    }

          let _tmp = data.quiz.map((obj, index) => {
            // obj.key = 'id' + index
            return obj;
          })
          setSchedule(_tmp);
      // if (doc.id === "admin_console") {
      //   var docdata = doc.data();
      //   if (docdata !== undefined) {
      //     setTournament(docdata.tournament);
      //   }
      // }
  };
  useEffect(() => {
    var currentyear = new Date().getFullYear();
    const yearlist = [];
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i);
    }
    setYearlist(yearlist.reverse());
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <div>
      <Space direction="horizontal" className="rightspace">
        {" "}
        <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
        <a onClick={() => addnew()}>
          <span className="addschedule">Add New</span>
        </a>
      </Space>
      <Table dataSource={schedule} pagination={{ pageSize: 10 }} scroll={{ y: "340" }}>
        <Column title="Tournament" dataIndex="tournament" key="tournament" />
        <Column title="Year Title" dataIndex="yearTitle" key="yearTitle" />
        <Column title="Years List" dataIndex="years" key="years" render={(years, record, index) => <Space size="middle">{yearShow(years)}</Space>} />

        <Column
          title="Quick Actions"
          key="notifId"
          render={(notifId, record, index) => (
            <Space size="middle">
              <DeleteTwoTone onClick={() => deleterecord(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
              <EditTwoTone onClick={() => showDrawer(notifId, index)} style={{ fontSize: "18px", color: "#08c" }} />
            </Space>
          )}
        />
      </Table>{" "}
      <Drawer forceRender={true} title={title} placement="right" onClose={onClose} visible={visible} size="small">
      <br />
        <Form name="horizontal_login" layout="vertical" form={infoform} onFinish={onInfofinish}>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="tournamentName" label="Select Tournament" rules={[{ required: true, message: "Please Select Tournament!" }]}>
                <Select size="default" placeholder="Select Tournament" style={{ width: "100%" }}>
                  {/* {tournament.map((item1, index1) => (
                    <Option key={index1} value={item1.tournamentName}>
                      {item1.tournamentName}{" "}
                    </Option>
                  ))} */}
                     <Option value="NFL">
                      NFL
                    </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="yearTitle" label="Year Title" rules={[{ required: true, message: "Please Select Year Title!" }]}>
                <Input placeholder="Year Title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="season" label="Select Year" rules={[{ required: true, message: "Please Select Year!" }]}>
                <Select size="default" mode="multiple" placeholder="Select Season" style={{ width: "100%" }}>
                  {yearlist.map((item1, index1) => (
                    <Option key={index1} value={item1}>
                      {item1}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Space direction="horizontal" className="rightspace">
            {" "}
            <PlusCircleTwoTone style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />
            <a onClick={() => addSeason()}>
              <span className="addschedule">Add New Season</span>
            </a>
          </Space>

          {particularRecord && particularRecord.seasons.length >= 0 ?
            <div className="col-md-12">
              <table className="table table-bordered">
                <tr>
                  <th>SL No</th>
                  <th>Season</th>
                  <th>Season Type</th>
                  <th>Quick Actions</th>
                </tr>
                {particularRecord && particularRecord.seasons.length > 0 ? particularRecord.seasons.map((list, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{list.season}</td>
                      <td>{list.seasonType}</td>
                      <td><EditOutlined color="blue" onClick={() => editSeason(list, index)} />
                        <DeleteOutlined color="red" onClick={() => deleteSeason(this, index)} /></td>
                    </tr>
                  )
                }) : ""}
              </table>
            </div>
            : ""}
          <br />

          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  {title === "New Quiz" ? "New" : "Update"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

      <Drawer placement="right" title="Season" onClose={onCloseSeason} visible={visibleSeason} size="small">
        <br />
        <Form form={form} name="horizontal_login" layout="vertical" onFinish={seasonSubmit}>
          <Row gutter={24}>
            <Col span={24} style={{ textAlign: "right" }}>
              <Form.Item name="season" label="Season">
                <Input placeholder="Enter Season" />
              </Form.Item>{" "}
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Form.Item name="seasonType" label="Season Type">
                <Input placeholder="Enter Season Type" />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row gutter={24} className="rtmp1">
            <Col span={24}>
              <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Quiz;
