import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, query, where, addDoc, deleteDoc, getDoc } from "firebase/firestore";
import { Select, Spin, Layout, Menu, Card, Row, Col, Table, Tag, Popover, Space, Input, Button, Modal, Drawer, Divider, PageHeader, Form } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import axios from "axios";
import {
    SmallDashOutlined

} from "@ant-design/icons";
import { domain } from "../configuration/ApiConfiguration";
import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const { Search } = Input;

const doctypearray = ["GAMERS", "PLAY_OFF", "INSIGHTS"];
function Gamers() {
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [teams, setTeams] = useState([]);
    const [gamers, setgamers] = useState([]);
    const [gamersDup, setgamersDup] = useState([]);
    const [filterKey, setFilterKey] = useState("Name");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [visibles, setVisibles] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [status, setStatus] = useState(null);

    const hide = () => {
        setVisibles(false);
    };
    const ModelView = (notifId, status) => {

        setVisibles(true);
        // notifId.status = status
        setUserDetails(notifId);
        setStatus(status)
    };
    const showContent = (notifId) => {
        if (notifId.status) {
            let arrayList = []
            let arrayListDetails = ["ACTIVE", "SUSPEND", "DELETE"]
            for (let i = 0; i < arrayListDetails.length; i++) {
                if (arrayListDetails[i].toLowerCase() === notifId.status.toLowerCase()) {
                }
                else {
                    arrayList.push(arrayListDetails[i])
                }
            }
            return (
                <div>
                    <p><a href="#" onClick={() => ModelView(notifId, arrayList[0])}>{arrayList[0]}</a></p>
                    <p><a href="#" onClick={() => ModelView(notifId, arrayList[1])}>{arrayList[1]}</a></p>
                </div >

            );
        }
        else {
            return (
                <div>
                    <p><a href="#" onClick={() => ModelView(notifId, "ACTIVE")}>ACTIVE</a></p>
                    <p><a href="#" onClick={() => ModelView(notifId, "SUSPEND")}>SUSPEND</a></p>
                    <p><a href="#" onClick={() => ModelView(notifId, "DELETE")}>DELETE</a></p>
                </div >

            );
        }



    };
    const submitStatus = async () => {
        let apiData = ""
        if (status === "DELETE")
            apiData = `betops/auth/user/delete/v1`
        if (status === "ACTIVE")
            apiData = `betops/auth/user/activate/v1`
        if (status === "SUSPEND")
            apiData = `betops/auth/user/suspend/v1`

        setLoaded(true);
        hide();
        const response = await axios({
            method: 'post',
            url: `${domain}/${apiData}`,
            data: { "uid": userDetails.UID }
        }).then((response) => {

            setLoaded(false);
            if (response.data.status === 200) {
                Modal.success({
                    content: response.data.message,
                    onOk() { fetchGamers() },
                });
            }
            else {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            }
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    const downloadCsv = (objArray) => {

        var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var str =
            `${Object.keys(arr[0])
                .map((value) => `"${value}"`)
                .join(',')
            }` + '\r\n';
        var csvContent = arr.reduce((st, next) => {
            st +=
                `${Object.values(next)
                    .map((value) => `"${value}"`)
                    .join(',')
                }` + '\r\n';
            return st;
        }, str);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'GamersList.csv';
        element.click();
    }
    const downloaddata = async () => {
        const gamersCol = collection(db, "users");
        const gamersSnapshot = await getDocs(gamersCol);
        const gamersList = gamersSnapshot.docs.map((doc) => doc.data());
        const resultArray = [];
        const resultMatch = [];
        const result = gamersSnapshot.docs.map((doc) => {
            let documentArray = doc.data();
            resultArray.push(doc.data());
            resultMatch.push({ documentID: doc.id });
        });

        let arr3 = resultArray.map((item, i) => Object.assign({}, item, resultMatch[i]));
        let gamersArray = [];
        let i = 1;
        arr3.map(function (element, i) {
            gamersArray.push({
                key: i++,
                Name: element.name,
                loginType: element.login_typ,
                emailID: element.email,
                mobileNo: element.mob,
                UID: element.uid,
            });
        });
        downloadCsv(gamersArray);
    };
    const fetchTeams = async () => {
        const teamsCol = collection(db, "teams");
        const teamsSnapshot = await getDocs(teamsCol);
        const teamList = teamsSnapshot.docs.map((doc) => doc.data());
        setTeams(teamList);
    };
    const fetchGamers = async () => {
        const gamersCol = collection(db, "users");
        const gamersSnapshot = await getDocs(gamersCol);
        const gamersList = gamersSnapshot.docs.map((doc) => doc.data());
        const resultArray = [];
        const resultMatch = [];
        const result = gamersSnapshot.docs.map((doc) => {
            let documentArray = doc.data();
            resultArray.push(doc.data());
            resultMatch.push({ documentID: doc.id });
        });
        let arr3 = resultArray.map((item, i) => Object.assign({}, item, resultMatch[i]));
        let gamersArray = [];
        let i = 1;
        arr3.map(function (element, i) {
            gamersArray.push({
                key: i++,
                Name: element.name,
                loginType: element.login_typ,
                emailID: element.email,
                status: element.status,
                mobileNo: element.mob,
                UID: element.uid,
            });
        });
        setgamers(gamersArray);
        setgamersDup(gamersArray);
    };
    const onSearch = (e) => {
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();

            const filteredData = gamersDup.filter((item) => item[filterKey] ? item[filterKey].toString().toLowerCase().includes(lowercasedFilter) : "");
            setgamers(filteredData);
        } else {
            setgamers(gamersDup)
        }
    }
    useEffect(() => {

        if (loading) return;
        // if (!user) return navigate("/login");
    }, [user, loading]);
    useEffect(() => {

        if (loaded) {
            setTimeout(() => {
                setLoaded(false);
            }, 2000);
        }
        fetchTeams(gamers);
        fetchGamers(gamers);
    }, [loaded]);
    const changeFilter = (e) => {
        setFilterKey(e)
    }
    return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
        <>
            <Sidermenu menu="gamers" />
            <Layout className="site-layout">
                <Headersection />
                <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                    <div className="site-card-border-less-wrapper">
                        <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                            <PageHeader
                                className="site-page-header"
                                title="Profile"
                                extra={
                                    <Space direction="horizontal" style={{ marginLeft: "auto", width: "100%" }}>
                                        <label>Filter Type</label>
                                        <Select size="default" value={filterKey} style={{ width: "200px" }} onChange={changeFilter}>
                                            <Option value="Name">Name </Option>
                                            <Option value="loginType">Login Type </Option>
                                            <Option value="emailID">Email ID </Option>
                                            <Option value="mobileNo">Mobile No </Option>
                                            <Option value="UID">UID </Option>
                                        </Select>
                                        <Search placeholder="Search here" onChange={onSearch} enterButton />
                                        <Button onClick={downloaddata}>Download CSV{" "} <img src="images/download.svg"></img></Button>
                                    </Space>
                                }
                            />
                            <Table
                                rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                dataSource={gamers}
                                scroll={{ y: "340" }}
                            >
                                <Column title="Name" dataIndex="Name" key="key" />
                                <Column title="Login Type" dataIndex="loginType" key="key" />
                                <Column title="Email ID" dataIndex="emailID" key="key" />
                                <Column title="Status" dataIndex="status" key="key" />
                                <Column title="Mobile No." dataIndex="mobileNo" key="key" />
                                <Column title="UID" dataIndex="UID" key="key" />
                                <Column
                                    title="Quick Actions"
                                    key="documentID"
                                    render={(documentID) => (

                                        <Space size="middle">
                                            <Popover
                                                content={() => showContent(documentID)}
                                                trigger="click"
                                            >
                                                <SmallDashOutlined />
                                            </Popover>
                                        </Space>
                                    )}
                                />
                            </Table>
                        </Card>
                    </div>
                    <Modal title="Change Status" onCancel={hide} visible={visibles}
                        footer={[
                            <Button key="submit" type="primary" onClick={hide}>
                                No
                            </Button>,
                            <Button
                                type="primary"
                                onClick={submitStatus}
                            >
                                Yes
                            </Button>
                        ]}

                    >
                        Would you like to save changes?
                    </Modal>
                    {loaded == true ? (
                        <div className="loading-overlay">
                            <Spin size="large" />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                </Content>
                <Footersection />
            </Layout>
        </>
        </Layout>
    );
}

export default Gamers;
