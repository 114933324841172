import { collection, getDocs } from "firebase/firestore";
import { Layout, Card, Row, Col, Tabs, Divider, Select, Table, PageHeader, Spin } from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Moment from "react-moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { Chart } from "react-google-charts";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
const { Option } = Select;
const { Column } = Table;
const { Content } = Layout;
function TotalMatches() {
    const [loaded, setLoaded] = useState(true);
    const [matchdetails, setMatchdetails] = useState([{
        matchid: "MAID001", matchtitle: "CSK VS DC", tournamenttitle: "T20 IPL 2022",
        date: "12/09/2022", time: "6:30 PM PST", teamA: "Chennai Super Kings", teamB: "Delhi Capitals", matchNo: "Match 01",
    }, {
        matchid: "MAID002", matchtitle: "CSK VS MI", tournamenttitle: "T20 IPL 2022",
        date: "12/10/2022", time: "6:30 PM PST", teamA: "Chennai Super Kings", teamB: "Mumbai Indians", matchNo: "Match 02",
    }, {
        matchid: "MAID003", matchtitle: "MI VS RCB", tournamenttitle: "T20 IPL 2022",
        date: "02/10/2022", time: "6:30 PM PST", teamA: "Mumbai Indians", teamB: "Royal Challangers Bangalore", matchNo: "Match 03",
    }, {
        matchid: "MAID004", matchtitle: "MI VS SRH", tournamenttitle: "T20 IPL 2022",
        date: "15/10/2022", time: "6:30 PM PST", teamA: "Mumbai Indians", teamB: "Sun Rises Hydrabad", matchNo: "Match 04",
    }, {
        matchid: "MAID005", matchtitle: "CSK VS RCB", tournamenttitle: "T20 IPL 2022",
        date: "17/11/2022", time: "6:30 PM PST", teamA: "Chennai Super Kings", teamB: "Royal Challangers Bangalore", matchNo: "Match 05",
    }, {
        matchid: "MAID006", matchtitle: "RCB VS DC", tournamenttitle: "T20 IPL 2022",
        date: "21/12/2022", time: "6:30 PM PST", teamA: "Royal Challangers Bangalore", teamB: "Delhi Capitals", matchNo: "Match 06",
    }
    ])
    const [user, loading, error] = useAuthState(auth);
    const { TabPane } = Tabs;

    useEffect(async () => {
        setLoaded(true)
        setLoaded(false)
    }, []);

    return (

        <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <Card bordered={false} bodyStyle={{ padding: "8px" }} style={{ borderBottom: "2px solid #2196F3" }}>
                <div><span style={{ fontWeight: "600" }}>Total Matches</span></div>
            </Card>
            <br />
            <Table
                rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                dataSource={matchdetails}
                // onChange={handleTableChange}
                scroll={{ y: "250" }}
            >
                <Column title="MatchID" dataIndex="matchid" key="matchid" sorter="true" />
                <Column title="Match Title" dataIndex="matchtitle" key="matchtitle" />
                <Column title="Tournament" dataIndex="tournamenttitle" key="tournamenttitle" sorter="true" />
                <Column title="Date" dataIndex="date" key="date" sorter="true" />
                <Column title="Time" dataIndex="time" key="time" sorter="true" />
                <Column title="Team A" dataIndex="teamA" key="teamA" sorter="true" />
                <Column title="Team B" dataIndex="teamA" key="teamA" sorter="true" />
                <Column title="Match No" dataIndex="matchNo" key="matchNo" sorter="true" />
            </Table>
        </Content>

    );
}

export default TotalMatches;
