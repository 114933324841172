import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Radio,
  DatePicker,
  TimePicker,
  Drawer,
  Form,
  Input,
  Divider,
  Spin,
} from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import React, { useState, useEffect } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import "antd/dist/antd.min.css";
const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;

var data = require("../Jsondata/schedule.json");
const doctypearray = [
  { key: 1, value: "SCHEDULE" },
  { key: 2, value: "PLAY_OFF" },
  { key: 3, value: "INSIGHTS" },
];

function Editdashboard(props) {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [doctype, setDoctype] = useState("Sent");
  const [datetype, setDatetype] = useState();
  const [timetype, setTimetype] = useState();
  const [visible, setVisible] = useState(false);
  const [teams, setTeams] = useState();
  const [backcolor, setBackcolor] = useState();
  const [textcolor, setTextcolor] = useState();
  const [backrgbcolor, setBackrgbcolor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  const [textrgbcolor, setTextrgbcolor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });

  const [textdisplay, setTextdisplay] = useState(false);
  const [backdisplay, setBackdisplay] = useState(false);
  const hex2rgba = (hex, a = 1) => {
    if (hex && hex !== undefined) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return { r: r, g: g, b: b, a: a };
    }
    return {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    };
  };
  const styles = reactCSS({
    default: {
      color: {
        width: "25px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${backrgbcolor.r}, ${backrgbcolor.g}, ${backrgbcolor.b}, ${backrgbcolor.a})`,
      },
      color1: {
        width: "25px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${textrgbcolor.r}, ${textrgbcolor.g}, ${textrgbcolor.b}, ${textrgbcolor.a})`,
      },
      swatch: {
        padding: "5px 3px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        position: "relative",
        top: "-27px",
        float: "right",
        right: "5px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const handleChangeComplete = (color) => {
    setBackcolor(color.hex);
    setBackrgbcolor(color.rgb);
  };
  const handleChangeColor = (color) => {
    setTextcolor(color.hex);
    setTextrgbcolor(color.rgb);
  };
  const handleClick = () => {
    setBackdisplay(!backdisplay);
  };

  const handleClose = () => {
    setBackdisplay(false);
  };
  const handleClick1 = () => {
    setTextdisplay(!textdisplay);
  };

  const handleClose1 = () => {
    setTextdisplay(false);
  };
  const loadProfile = () => {
    // if (
    //   !props.dashboarddetails.highltBackClr ||
    //   !props.dashboarddetails.highltTxtClr ||
    //   (!props.dashboarddetails.highltBackClr && !props.dashboarddetails.highltTxtClr)
    // ) {
    //   setBackrgbcolor(hex2rgba("#ffff"));
    //   setTextrgbcolor(hex2rgba("#ffff"));
    //   setBackcolor("");
    //   setTextcolor("");
    // } else {
    //   setBackrgbcolor(hex2rgba(props.dashboarddetails.highltBackClr));
    //   setTextrgbcolor(hex2rgba(props.dashboarddetails.highltTxtClr));
    //   setBackcolor(props.dashboarddetails.highltBackClr);
    //   setTextcolor(props.dashboarddetails.highltTxtClr);
    // }

    setBackrgbcolor(hex2rgba(props.dashboarddetails.highltBackClr) || hex2rgba("#ffff"));
    setTextrgbcolor(hex2rgba(props.dashboarddetails.highltTxtClr) || hex2rgba("#ffff"));
    setBackcolor(props.dashboarddetails.highltBackClr || "");
    setTextcolor(props.dashboarddetails.highltTxtClr || "");

    form.setFieldsValue({
      tournamentName: props.dashboarddetails.tournament,
      highltBackClr: props.dashboarddetails.highltBackClr,
      highltTxtClr: props.dashboarddetails.highltTxtClr,
      displayName: props.dashboarddetails.displayName,
      highlightText: props.dashboarddetails.highlightText,
    });
  };
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }

    loadProfile({});
  }, [loaded, props]);

  const handleChange = (e) => {
    if (e.target.value == "Scheduled") {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setDoctype(e.target.value);
  };
  const onDateChange = (date, dateString) => {
    setDatetype(dateString);
  };
  const onTimeChange = (time, timeString) => {
    console.log(timeString);
    setTimetype(timeString);
  };

  const onFinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "dashboard");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();

    await setDoc(matchDocRef, docSnap.data());

    arraycheck["android"]["dashboard"][props.fetchindex] = {
      highlightText: values.highlightText,
      displayName: values.displayName,
      highltTxtClr: textcolor,
      tournament: values.tournamentName,
      highltBackClr: backcolor,
    };

    try {
      await updateDoc(matchDocRef, arraycheck);
    } catch (e) {
      console.log(e);
    }

    Modal.success({
      content: "Updated Successfully",
      onOk() {
        setLoaded(false);
        props.onClose();
        props.notification();
      },
    });
  };

  return (
    <>
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="tournamentName" label="Select Tournament" rules={[{ required: true, message: "Please Select Tournament!" }]}>
              <Select size="default" placeholder="Select Tournament" style={{ width: "100%" }}>
                {props.tournament.map((item1, index1) => (
                  <Option key={index1} value={item1.tournamentName}>
                    {item1.tournamentName}{" "}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="displayName" label="Display Name" rules={[{ required: true, message: "Please input your Display Name!" }]}>
              <Input placeholder="Display Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="highlightText" label="Highlight Text" rules={[{ required: true, message: "Please input your Hightlight Text!" }]}>
              <Input placeholder="Hightlight Text" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="highltTxtClr" label="Highlight Text color">
              <Input placeholder="Highlight Text color" value={textcolor} />
              <div style={styles.swatch} onClick={handleClick1}>
                <div style={styles.color1} />
              </div>

              {textdisplay ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={handleClose1} />
                  <SketchPicker color={"#fff"} onChange={handleChangeColor} />
                </div>
              ) : null}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="highltBackClr" label="Highlight Back color">
              <Input placeholder="Highlight Back color" value={backcolor}></Input>
              <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
              </div>

              {backdisplay ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={handleClose} />
                  <SketchPicker color={"#fff"} onChange={handleChangeComplete} />
                </div>
              ) : null}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Editdashboard;
