import { Select, Row, Col, Tabs, Button, DatePicker, Modal, Drawer, Form, Input, Space, Table, PageHeader } from "antd";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { currentTournamentDetails } from "../../settings/config";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css"
import Firebase from "firebase/compat/app";
import { domain } from "../../configuration/ApiConfiguration";
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    DeleteTwoTone,
    EditTwoTone,
    PlusCircleTwoTone
} from "@ant-design/icons";

const { Column } = Table;

function IosDashboard(props) {
    const [VmodelBanner, setVmodelBanner] = useState([])
    const [BannerArray, setBannerArray] = useState(0)
    const [loaded, setLoaded] = useState(true);
    const { TabPane } = Tabs;
    const [form] = Form.useForm();
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const { Option, OptGroup } = Select;
    const [visible, setvisible] = useState(false);
    const [visibleTab, setvisibleTab] = useState(false);
    const [visibleGroup, setvisibleGroup] = useState(false);
    const [visibleBannertab, setvisibleBannertab] = useState(false);
    const [visibleServicetab, setvisibleServicetab] = useState(false);
    const [statsServiceGroup, setstatsServiceGroup] = useState(false);
    const [visibleServiceGrouptab, setvisibleServiceGrouptab] = useState(false);
    const [stats, setstats] = useState(false);
    const [version, setversion] = useState("");
    const [versionVisible, setversionVisible] = useState(false);
    const [groupss, setgroupss] = useState(false);
    const [tabListIndex, setTabListIndex] = useState(0);
    const [serviceGroupListIndex, setServiceGroupListIndex] = useState(0);
    const [serviceListIndex, setServiceListIndex] = useState(0);
    const [ServiceGroupCmd, setServiceGroupCmd] = useState("");
    const [bannerListIndex, setBannerListIndex] = useState(0);
    const [statsService, setstatsService] = useState(false);
    const [groupIndex, setGroupIndex] = useState(false);
    const [responseDataNewDisplay, setResponseDataNewFilterDisplay] = useState("")
    const [responseDataNew, setResponseDataNew] = useState("")
    const [responseDataNewFilter, setResponseDataNewFilter] = useState("")
    const [sportsConfig, setSportsConfig] = useState("")
    const [dashboard_config, setDashboard_config] = useState("")
    const [betopsConfigConfig, setBetopsConfigConfig] = useState("")
    const [vModelFilter, setVModelFilter] = useState([])

    const [bannerlistTableArr1, setBannerlistTableArr1] = useState(null);
    const [bannerlistTableArr2, setBannerlistTableArr2] = useState(null);

    const [tablistTableArr, setTablistTableArr] = useState(null);

    useEffect(async () => {
        await sportsListAndInfoConfig();

        setDashboard_config(props.keydata);

        setSportsConfig(props.sportsconfig)

        setBetopsConfigConfig(props.betopsconfig)

        if (props.drawertype === "Edit Dashboard") {
            setResponseDataNew(props.matchid);
            setResponseDataNewFilterDisplay(props.matchid);
            // setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(response.data.data)))
            bannerTabTableChange(props.matchid);
        }
        else {
            setResponseDataNew({ dashboard: { tabList: [], bannerList: [] } })
        }
    }, []);

    const sportsListAndInfoConfig = async (e) => {
        const response1 = await axios({
            method: 'get',
            url: `${domain}/betops/config/dashconfig/get-sports/v1`,
        }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                // setBetopsConfigConfig(response.data.data);
            }
            else {
            }
        }).catch((error) => {
        })
    }

    const bannerTabTableChange = (val) => {
        let bannerListArr1 = [];
        let bannerListArr2 = [];
        for (let i = 0; i < val.dashboard.bannerList.length; i++) {
            let temp = val.dashboard.bannerList[i];

            if (temp.vModel == "bm1_name_banner") {
                let bannerInfo1 = null;
                bannerInfo1 = {
                    "title": temp.title.data,
                    "message": temp.message.data,
                    "vModel": temp.vModel,
                    "targetTs": temp.targetTs.data,
                    "rIconImgSrc": temp.rIcon.data,
                    "rIconPlaceholder": temp.rIcon.placeHolder,
                    "rIconFilename": temp.rIcon.fileName,
                    "rIconImgType": temp.rIcon.dataType,
                    "lIconImgSrc": temp.lIcon.data,
                    "lIconPlaceholder": temp.lIcon.placeHolder,
                    "lIconFilename": temp.lIcon.fileName,
                    "lIconImgType": temp.lIcon.dataType
                };
                bannerListArr1.push(bannerInfo1);
            } else {
                let bannerInfo2 = null;
                if (temp.targetTs) {
                    bannerInfo2 = {
                        "title": temp.title.data,
                        "message": temp.message.data,
                        "vModel": temp.vModel,
                        "targetTs": temp.targetTs.data,
                    };
                } else {
                    bannerInfo2 = {
                        "title": temp.title.data,
                        "message": temp.message.data,
                        "vModel": temp.vModel,
                        "targetTs": "-",
                    };
                }

                bannerListArr2.push(bannerInfo2);
            }
        }

        setBannerlistTableArr1(bannerListArr1);
        setBannerlistTableArr2(bannerListArr2);





        let tabListArr = [];
        for (let i = 0; i < val.dashboard.tabList.length; i++) {
            let temp = val.dashboard.tabList[i];

            let tabInfo = null;
            tabInfo = {
                "tabNo": i + 1,
                "title": temp.title,
                "highltBackClr": temp.highltBackClr,
                "highltTxtClr": temp.highltTxtClr,
                "highlightText": temp.highlightText
            };
            tabListArr.push(tabInfo);
        }

        setTablistTableArr(tabListArr);
    }

    const addnewBannerList = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        if (data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList) {
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.push({
                "targetTs": "",
                "message": "",
                "vModel": "",
                "title": {
                    "data": "",
                    "dataType": ""
                }
            })
        }
        else {
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList = []
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.push({
                "targetTs": "",
                "message": "",
                "vModel": "",
                "title": {
                    "data": "",
                    "dataType": ""
                }
            })
        }
        setvisibleBannertab(true)
        setstats(true)
        setBannerListIndex(responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.length - 1)
    }
    const addnewServiceList = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        if (data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList) {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList.push({
                "serviceGroupList": [],
                "vModel": ""
            })
        }
        else {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList = []
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList.push({
                "serviceGroupList": [],
                "vModel": ""
            })
        }
        setvisibleServicetab(true)
        setstatsService(true)
        setServiceGroupListIndex(responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList.length - 1)
    }
    const addNewServiceGroup = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        setvisibleServiceGrouptab(true)
        let data = responseDataNew
        if (data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.length === 0) {
            setServiceListIndex(0)
        }
        else {
            setServiceListIndex(responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.length)
        }
        setstatsServiceGroup(true)
    }
    const addNewServiceGroupList = (e, index) => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key] = {
            "command": "MODULE_NAV_SKILLBET",
            "titlePostTag": "",
            "title": "",
            "enable": false,
            "visible": false,
            "titleImg": {
                "dataType": "",
                "data": ""
            },
            "actionData": {
                "seasonType": "",
                "provider": "",
                "season": "",
                "tournament": "",
                "sport": ""
            },
        }
        if (e === "MODULE_NAV_SKILLBET") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key].command = "MODULE_NAV_SKILLBET"
        }
        else if (e === "MODULE_NAV_QUIZ") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key].command = "MODULE_NAV_QUIZ"
        }
        else if (e === "MODULE_NAV_FANTASY") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key].command = "MODULE_NAV_FANTASY"
        }
        else if (e === "MODULE_NAV_ADD_FUNNY_MONEY") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key] = {
                "command": "MODULE_NAV_ADD_FUNNY_MONEY",
                "titlePostTag": "",
                "title": "",
                "enable": false,
                "visible": false,
                "titleImg": {
                    "dataType": "",
                    "data": ""
                },
                "style": {
                    "bgColor": "#000000",
                    "fontColor": "#000000",
                    "icon": ""
                }
            }
        }

        else if (e === "MODULE_NAV_MY_ACTIVITY") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key].command = "MODULE_NAV_MY_ACTIVITY"
        }
        else if (e === "MODULE_NAV_SCHEDULE") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index.key].command = "MODULE_NAV_SCHEDULE"
        }
        setServiceListIndex(index.key)
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const addnewBanner = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        data.dashboard.bannerList.push({})
        setBannerListIndex(data.dashboard.bannerList.length - 1)
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        setvisible(true)
    }
    const EditService = (e, index) => {
        setstatsServiceGroup(true)
        setvisibleServiceGrouptab(true)
        setServiceListIndex(index)
    }
    const EditBanner = (e, index) => {
        setBannerListIndex(index)
        setvisibleBannertab(true)
        setstats(true)

    }
    const DeleteService = (e, index) => {

        let data = responseDataNew
        let dtaa = responseDataNew;
        dtaa = dtaa.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList.filter((list, indexs) => {

            return (indexs != index)
        })
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const DeleteBanner = (e, index) => {
        let data = responseDataNew
        let dtaa = responseDataNew;
        dtaa = dtaa.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.filter((list, indexs) => {

            return (indexs != index)
        })
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const DeleteGroup = (e, index) => {
        let data = responseDataNew;
        let dtaa = responseDataNew;

        dtaa = dtaa.dashboard.tabList[tabListIndex].group.filter((list, indexs) => {

            return (indexs != index)
        })
        data.dashboard.tabList[tabListIndex].group = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const DeleteTab = (e, index) => {
        let data = responseDataNew;
        let dtaa = responseDataNew;
        dtaa = dtaa.dashboard.tabList.filter((list, indexs) => {

            return (indexs != index)
        })
        data.dashboard.tabList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        bannerTabTableChange(data);
    }
    const DeleteBannerArray = (e, index) => {
        let data = responseDataNew;
        let dtaa = responseDataNew;
        dtaa = dtaa.dashboard.bannerList.filter((list, indexs) => {

            return (indexs != index)
        })
        data.dashboard.bannerList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        bannerTabTableChange(data);
    }
    const EditBannerArray = (e, index) => {
        setBannerListIndex(index)
        setvisible(true)
        setBannerArray(BannerArray + 1)
    }
    const EditTab = (e, index) => {
        setTabListIndex(index)
        setvisibleTab(true)
        setBannerArray(BannerArray + 1)
    }
    const addnewTab = () => {
        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew
        data.dashboard.tabList.push({
            "title": "",
            // "gameProvider": "",
            "highlightText": "",
            "tournament": "",
            "highltTxtClr": "",
            // "sport": "",
            "highltBackClr": "",
            "group": []
        })
        setTabListIndex(data.dashboard.tabList.length - 1)
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        setvisibleTab(true)
    }
    const handleChangeFieldTab = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[index][e.target.name] = e.target.value;
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldProviderTab = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[index].gameProvider = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleTournamentChange = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[index].tournament = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleBannerTournamentChange = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index].tournament = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleSportChange = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[index].sport = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabService = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index][e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleBannerEnableChange = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index][e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeFieldTabServiceAction = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].actionData[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabServiceStyle = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].style[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabServiceTitle = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].titleImg[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const onClose = () => {
        if (responseDataNewFilter) {
            setResponseDataNew(responseDataNewFilter)
            setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(responseDataNewFilter)))
        }

        setvisible(false)
        setvisibleTab(false)
        setversionVisible(false)
    }
    const onCloseBanner = () => {
        if (responseDataNewFilter) {
            setResponseDataNew(responseDataNewFilter)
        }
        setvisibleServiceGrouptab(false)
        setstatsServiceGroup(false)
        setServiceGroupCmd(false)
        setServiceGroupCmd("")
        setstats(false)
        setstatsService(false)
    }
    const onCloseGroup = () => {
        if (responseDataNewFilter) {
            setResponseDataNew(responseDataNewFilter)
        }
        setvisibleGroup(false)
        setstats(false)
        setgroupss(false)
    }
    const handleChangeField = (index, e) => {
        let data = responseDataNew
        let temp = null;
        if (data && data.dashboard.bannerList[index][e.target.name] && data.dashboard.bannerList[index][e.target.name].dataType) {
            temp = {
                "data": e.target.value,
                "dataType": data.dashboard.bannerList[index][e.target.name].dataType
            };
        } else {
            temp = {
                "data": e.target.value,
                "dataType": ""
            };
        }

        data.dashboard.bannerList[index][e.target.name] = temp;
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeTabGroupTitleDatatype = (index, e) => {
        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].title.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeTitleDatatype = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index].title.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeMsgDatatype = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index].message.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeTargettsDatatype = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index].targetTs.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const handleChangeFieldRl = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index].rIcon[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldLi = (index, e) => {
        let data = responseDataNew
        data.dashboard.bannerList[index].lIcon[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeDate = (index, e) => {

        let data = responseDataNew
        if (e)
            data.dashboard.bannerList[index].targetTs = e
        else
            data.dashboard.bannerList[index].targetTs = "";
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleVmodelBanner = (index, e) => {

        let data = responseDataNew
        if (e === "bm1_name_banner") {
            data.dashboard.bannerList[index] = {
                "targetTs": "",
                "rIcon": {
                    "imgSrc": "",
                    "placeHolder": "",
                    "imgType": "",
                    "fileName": ""
                },
                "message": "",
                "vModel": e,
                "title": {
                    "data": "",
                    "dataType": ""
                },
                "lIcon": {
                    "imgSrc": "",
                    "placeHolder": "",
                    "imgType": "",
                    "fileName": ""
                },

            }
        }
        else {
            data.dashboard.bannerList[index] = {
                "targetTs": "",
                "message": "",
                "vModel": e,
                "title": {
                    "data": "",
                    "dataType": ""
                }
            }
        }

        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        setVmodelBanner(e)
    }
    const handleVmodelBannerTab = (e, index) => {

        let data = responseDataNew
        if (e === "bm1_name_banner") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.length - 1] = {
                "targetTs": "",
                "rIcon": {
                    "imgSrc": "",
                    "placeHolder": "",
                    "imgType": "",
                    "fileName": ""
                },
                "message": "",
                "vModel": e,
                "title": {
                    "data": "",
                    "dataType": ""
                },
                "lIcon": {
                    "imgSrc": "",
                    "placeHolder": "",
                    "imgType": "",
                    "fileName": ""
                },

            }
        }
        else {
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.length - 1] = {
                "targetTs": "",
                "message": "",
                "vModel": e,
                "title": {
                    "data": "",
                    "dataType": ""
                }
            }
        }

        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        setVmodelBanner(e)
    }
    const EditServiceGroup = (e, index) => {
        setstatsServiceGroup(true)
        setvisibleServiceGrouptab(true)
        setServiceListIndex(index)
    }
    const DeleteServiceGroup = (e, index) => {
        let data = responseDataNew
        let dtaa = responseDataNew;
        dtaa = dtaa.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.filter((list, indexs) => {

            return (indexs != index)
        })
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList = dtaa
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handlevTypeTab = (e, index) => {

        let data = responseDataNew
        if (e === "banner") {
            data.dashboard.tabList[tabListIndex].group[groupIndex].vType = e
        }
        else {
            data.dashboard.tabList[tabListIndex].group[groupIndex].vType = e
        }

        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
        setVmodelBanner(e)
    }
    const addnewGrop = (e, index) => {

        let saaas = JSON.parse(JSON.stringify(responseDataNew));
        setResponseDataNewFilter(saaas)
        let data = responseDataNew;
        data.dashboard.tabList[tabListIndex].group.push({ groupName: "", vType: "" })

        setResponseDataNew(data)
        setGroupIndex(data.dashboard.tabList[tabListIndex].group.length - 1)
        setBannerArray(BannerArray + 1)
        setvisibleGroup(true)
        setgroupss(true)
    }
    const EditGroup = (e, index) => {
        setGroupIndex(index)
        setvisibleGroup(true)
        setgroupss(true)
    }
    const handleChangeFieldGoup = (index, e) => {

        let data = responseDataNew
        if (e.target) {
            data.dashboard.tabList[tabListIndex].group[groupIndex][e.target.name] = e.target.value
        }
        else {
            data.dashboard.tabList[tabListIndex].group[groupIndex].groupName = e
        }

        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldGoupService = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].vModel = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabLi = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].lIcon[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabRl = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].rIcon[e.target.name] = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabRiType = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].rIcon.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabRlSrc = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].rIcon.data = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabLiType = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].lIcon.dataType = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabLiSrc = (index, e) => {

        let data = responseDataNew
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].lIcon.data = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeFieldTabs = (index, e) => {
        let data = responseDataNew
        let temp = null;
        if (data && data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index][e.target.name] && data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index][e.target.name].dataType) {
            temp = {
                "data": e.target.value,
                "dataType": data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index][e.target.name].dataType
            };
        } else {
            temp = {
                "data": e.target.value,
                "dataType": ""
            };
        }
        data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index][e.target.name] = temp;
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleChangeDates = (index, e) => {
        let data = responseDataNew;
        if (e)
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].targetTs = e
        else
            data.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[index].targetTs = "";
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }

    const updateConfig = async () => {
        setversionVisible(false)
        let data = responseDataNew;

        data.appId = currentTournamentDetails.shortCode + "-ops";

        if (data.modifiedDate) {
            delete data.modifiedDate;
        }

        if (props.drawertype === "Edit Dashboard") {
            setLoaded(true);
            const response = await axios.post(
                `${domain}/betops/config/dashboard/update/v1`, data)
                .then((response) => {

                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        Modal.success({
                            content: response.data.data,
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.error.message,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
        else {
            setLoaded(true);
            let dataPayload = {
                "appId": currentTournamentDetails.shortCode + "-ops",
                "docType": "DASHBOARD_CONFIG",
                "active": true,
                "aid": "fantasycafe",
                "version": version,
                "platform": props.platform,
                "dashboard": data.dashboard
            }
            const response = await axios.post(
                `${domain}/betops/config/dashboard/create/v1`, dataPayload)
                .then((response) => {

                    setLoaded(false);
                    if (response.data.status === "SUCCESS") {
                        Modal.success({
                            content: response.data.data,
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                    }
                    else if (response.data.status === "FAIL") {
                        Modal.error({
                            content: response.data.error.message,
                        });
                    }
                    else {
                        setLoaded(false);
                        Modal.error({
                            content: "Unable to process your Request, Please try again later.",
                        });
                    }
                })
                .catch((error) => {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                })
        }
    }

    const versionModelVisible = (e) => {

        if (props.drawertype == "Edit Dashboard") {
            updateConfig();
        }
        else {
            setversionVisible(true)
        }
    }
    const versionChange = (e) => {
        setversion(e.target.value)
    }
    const addStatsServiceGroupList = (e) => {
        setvisibleServiceGrouptab(false)
        setstatsServiceGroup(false)
        setResponseDataNewFilter("")
    }
    const addStatsList = (e) => {
        setvisibleBannertab(false)
        setstats(false)
        setResponseDataNewFilter("")
    }
    const addGroupList = (e) => {
        setvisibleGroup(false)
        setgroupss(false)
        setResponseDataNewFilter("")
    }
    const addTabList = (e) => {
        setvisibleTab(false)
        setResponseDataNewFilter("")
        setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(responseDataNew)))
        bannerTabTableChange(responseDataNew);
    }
    const addData = async () => {
        setResponseDataNewFilter("")
        setvisible(false)
        setResponseDataNewFilterDisplay(JSON.parse(JSON.stringify(responseDataNew)))
        await bannerTabTableChange(responseDataNew);
    }
    const handleACTIONSportChange = (index, e) => {
        let data = responseDataNew;
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].actionData.sport = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleACTIONSeasonChange = (index, e) => {
        let data = responseDataNew;
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].actionData.season = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleACTIONTournamentChange = (index, e) => {

        let data = responseDataNew;
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].actionData.tournament = e
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const handleACTIONSeasonTypeChange = (index, e) => {

        let data = responseDataNew;
        data.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[index].actionData.seasonType = e.target.value
        setResponseDataNew(data)
        setBannerArray(BannerArray + 1)
    }
    const addStatsServicetab = () => {
        setResponseDataNewFilter("")
        setvisibleServicetab(false)
        setstatsService(false)
    }
    return (
        <div>
            <Tabs defaultActiveKey="1" className="dashboard-tab" style={{ marginLeft: "-6px", marginRight: "-16px" }}>
                <TabPane tab="Banner List" key="1">
                    <div className="row">
                        <div className="col-md-12 text-end">
                            <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                            <a onClick={() => addnewBanner()}>
                                <span className="addbet">New Banner</span>
                            </a>
                        </div>
                        <br />
                        <br />
                        {bannerlistTableArr1 && bannerlistTableArr1[0] ?
                            <>
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={bannerlistTableArr1}
                                    scroll={{ y: "340" }}
                                >
                                    <Column title="Title" dataIndex="title" key="title" />
                                    <Column title="Message" dataIndex="message" key="message" />
                                    <Column title="VModel" dataIndex="vModel" key="vModel" />
                                    <Column title="Date" dataIndex="targetTs" key="targetTs" />
                                    <Column title="Ricon Image Source" dataIndex="rIconImgSrc" key="rIconImgSrc" />
                                    <Column title="Ricon  Placeholder" dataIndex="rIconPlaceholder" key="rIconPlaceholder" />
                                    <Column title="Ricon File Name" dataIndex="rIconFilename" key="rIconFilename" />
                                    <Column title="Ricon Image Type" dataIndex="rIconImgType" key="rIconImgType" />
                                    <Column title="Licon Image Source" dataIndex="lIconImgSrc" key="lIconImgSrc" />
                                    <Column title="Licon  Placeholder" dataIndex="lIconPlaceholder" key="lIconPlaceholder" />
                                    <Column title="Licon File Name" dataIndex="lIconFilename" key="lIconFilename" />
                                    <Column title="Licon Image Type" dataIndex="lIconImgType" key="lIconImgType" />
                                    <Column
                                        title="Quick Actions"
                                        key="rowInfo"
                                        render={(rowInfo, value, index) => (
                                            <Space size="middle">
                                                <EditTwoTone onClick={() => EditBannerArray(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                <DeleteTwoTone onClick={() => DeleteBannerArray(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                            </Space>
                                        )}
                                    />

                                </Table>
                                <br />
                            </>
                            : ""}

                        {bannerlistTableArr2 && bannerlistTableArr2[0] ?
                            <Table
                                rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                dataSource={bannerlistTableArr2}
                                scroll={{ y: "340" }}
                            >
                                <Column title="Title" dataIndex="title" key="title" />
                                <Column title="Message" dataIndex="message" key="message" />
                                <Column title="VModel" dataIndex="vModel" key="vModel" />
                                <Column title="Date" dataIndex="targetTs" key="targetTs" />
                                <Column
                                    title="Quick Actions"
                                    key="rowInfo"
                                    render={(rowInfo, value, index) => (
                                        <Space size="middle">
                                            <EditTwoTone onClick={() => EditBannerArray(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                            <DeleteTwoTone onClick={() => DeleteBannerArray(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                        </Space>
                                    )}
                                />

                            </Table>
                            : ""}

                        <div className="col-md-12">
                            {props.drawertype == "Create Dashboard" ?
                                <Button className="btn btn-primary topbtnalign" onClick={versionModelVisible}>Create</Button>
                                :
                                <Button className="btn btn-primary topbtnalign" onClick={versionModelVisible}>Update</Button>
                            }
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Tab List" key="2">
                    <div className="row">
                        <div className="col-md-12 text-end">
                            <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                            <a onClick={() => addnewTab()}>
                                <span className="addbet">New Tab</span>
                            </a>
                        </div>
                        <br />
                        <br />
                        <div className="col-md-12">
                            {tablistTableArr && tablistTableArr[0] ?
                                <Table
                                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                    dataSource={tablistTableArr}
                                    scroll={{ y: "340" }}
                                >
                                    <Column title="Tab" dataIndex="tabNo" key="tabNo" />
                                    <Column title="Title" dataIndex="title" key="title" />
                                    <Column title="Highlight Text" dataIndex="highlightText" key="highlightText" />
                                    <Column title="Highlight Text Color" dataIndex="highltTxtClr" key="highltTxtClr" />
                                    <Column title="Highlight Back Color" dataIndex="highltBackClr" key="highltBackClr" />
                                    <Column
                                        title="Quick Actions"
                                        key="rowInfo"
                                        render={(rowInfo, value, index) => (
                                            <Space size="middle">
                                                <EditTwoTone onClick={() => EditTab(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                                <DeleteTwoTone onClick={() => DeleteTab(rowInfo, index)} style={{ fontSize: "18px", color: "#08c" }} />
                                            </Space>
                                        )}
                                    />

                                </Table>
                                : ""}
                        </div>
                        <div className="col-md-12">
                            {props.drawertype == "Create Dashboard" ?
                                <Button className="btn btn-primary topbtnalign" onClick={versionModelVisible}>Create</Button>
                                :
                                <Button className="btn btn-primary topbtnalign" onClick={versionModelVisible}>Update</Button>
                            }
                        </div>
                    </div>
                </TabPane>
            </Tabs>
            <Drawer placement="right" className="drawer-align-cls" title="Banner" onClose={onClose} visible={visible} size="small">
                <br />
                {/* <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}> */}
                {responseDataNew ? responseDataNew.dashboard.bannerList.map((list, index) => {
                    return (bannerListIndex == index ? <><div className="col-md-12 mt-3">
                        <label>V Model</label>
                        <Select size="default" value={list.vModel} placeholder="Select V Model" title="Select V Model" className="m-1" style={{ width: "200px" }} onChange={handleVmodelBanner.bind(this, index)} >
                            {dashboard_config ? dashboard_config.vModelList.map((lists, index) => {
                                return (<Option key={lists} value={lists}>
                                    {lists}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                    </div>
                        {list.vModel ? <div className="col-md-12"><div className="row">{list.vModel === "bm1_name_banner" ? <>
                            <div className="col-md-6">
                                <label>RIcon Image Src</label>
                                <input type="text" placeholder="RIcon Image Src" name="imgSrc" value={list.rIcon.data} className="form-control m-1" onChange={handleChangeFieldRl.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>RIcon Place Holder</label>
                                <input type="text" placeholder="RIcon Place Holder" name="placeHolder" value={list.rIcon.placeHolder} className="form-control m-1" onChange={handleChangeFieldRl.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>RIcon Image Type</label>
                                <input type="text" placeholder="RIcon Image Type" name="imgType" value={list.rIcon.dataType} className="form-control m-1" onChange={handleChangeFieldRl.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>RIcon File Name</label>
                                <input type="text" placeholder="RIcon File Name" name="fileName" value={list.rIcon.fileName} className="form-control m-1" onChange={handleChangeFieldRl.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>LIcon Image Src</label>
                                <input type="text" placeholder="LIcon Image Src" name="imgSrc" value={list.lIcon.data} className="form-control m-1" onChange={handleChangeFieldLi.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>LIcon Place Holder</label>
                                <input type="text" placeholder="LIcon Place Holder" name="placeHolder" value={list.lIcon.placeHolder} className="form-control m-1" onChange={handleChangeFieldLi.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>LIcon Image Type</label>
                                <input type="text" placeholder="LIcon Image Type" name="imgType" value={list.lIcon.dataType} className="form-control m-1" onChange={handleChangeFieldLi.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>LIcon File Name</label>
                                <input type="text" placeholder="LIcon File Name" name="fileName" value={list.lIcon.fileName} className="form-control m-1" onChange={handleChangeFieldLi.bind(this, index)} />
                            </div>
                        </>
                            : ""}
                            <div className="col-md-6">
                                <label>Title</label>
                                <input type="text" placeholder="Title" name="title" value={list.title.data} className="form-control m-1" onChange={handleChangeField.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>Data Type</label>
                                <Select size="default" value={list.title.dataType} placeholder="Select Data Type" title="Select Data Type" className="m-1" style={{ width: "200px" }} onChange={handleChangeTitleDatatype.bind(this, index)} >
                                    {dashboard_config ? dashboard_config.dataTypeList.map((lists, index) => {
                                        return (<Option key={lists.dataType} value={lists.dataType}>
                                            {lists.dataType}{" "}
                                        </Option>)
                                    }) : ""}
                                </Select>
                            </div>
                            <div className="col-md-6">
                                <label>Message</label>
                                <input type="text" placeholder="Message" name="message" value={list.message.data} className="form-control m-1" onChange={handleChangeField.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>Data Type</label>
                                <Select size="default" value={list.message.dataType} placeholder="Select Data Type" title="Select Data Type" className="m-1" style={{ width: "200px" }} onChange={handleChangeMsgDatatype.bind(this, index)} >
                                    {dashboard_config ? dashboard_config.dataTypeList.map((lists, index) => {
                                        return (<Option key={lists.dataType} value={lists.dataType}>
                                            {lists.dataType}{" "}
                                        </Option>)
                                    }) : ""}
                                </Select>
                            </div>
                            <div className="col-md-6">
                                <label>Date</label>
                                <input type="text" placeholder="Date" name="targetTs" value={list.targetTs ? list.targetTs.data : ""} className="form-control m-1" onChange={handleChangeField.bind(this, index)} />
                            </div>
                            <div className="col-md-6">
                                <label>Data Type</label>
                                <Select size="default" value={list.targetTs ? list.targetTs.dataType : ""} placeholder="Select Data Type" title="Select Data Type" className="m-1" style={{ width: "200px" }} onChange={handleChangeTargettsDatatype.bind(this, index)} >
                                    {dashboard_config ? dashboard_config.dataTypeList.map((lists, index) => {
                                        return (<Option key={lists.dataType} value={lists.dataType}>
                                            {lists.dataType}{" "}
                                        </Option>)
                                    }) : ""}
                                </Select>
                            </div>
                            <div className="col-md-6">
                                <label>Tournament</label>
                                <Select size="default" value={list.tournament} placeholder="Select Tournament" title="Select Tournament" className="m-1" style={{ width: "200px" }} onChange={handleBannerTournamentChange.bind(this, index)} >
                                    {sportsConfig ? sportsConfig.tournamentList.map((lists, index) => {
                                        return (<Option key={lists.tournament} value={lists.tournament}>
                                            {lists.tournamentTitle}{" "}
                                        </Option>)
                                    }) : ""}
                                </Select>
                            </div>
                            <div className="col-md-6">
                                <label className="enablechkboxlabel">Enable</label>
                                <input type="checkbox" name="enable" value={list.enable} checked={list.enable} className="m-1" onChange={handleBannerEnableChange.bind(this, index)} />
                            </div>
                        </div> </div> : ""}
                    </> : ""
                    )
                }) : ""}
                <center><Button className="btn btn-primary topbtnalign" onClick={addData}>Add</Button></center>
            </Drawer>
            <Drawer placement="right" className="drawer-align-cls" title="Tab" onClose={onClose} visible={visibleTab} size="small">
                <br />
                {/* <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}> */}
                {responseDataNew ? responseDataNew.dashboard.tabList.map((list, index) => {
                    if (index == tabListIndex) {
                        return (
                            <div className="col-md-12">
                                <div className="row"><>
                                    <div className="col-md-6">
                                        <label>Display Name</label>
                                        <input type="text" placeholder="Display Name" name="title" value={list.title} className="form-control m-1" onChange={handleChangeFieldTab.bind(this, index)} />
                                    </div>
                                    {/* <div className="col-md-6">
                                        <label>Game Provider</label>
                                        <Select size="default" value={list.gameProvider} placeholder="Select Game Provider" title="Select  Game Provider" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldProviderTab.bind(this, index)} >
                                            {sportsConfig ? sportsConfig.provider.map((lists, index) => {
                                                return (<Option key={lists.pid} value={lists.pid}>
                                                    {lists.name}{" "}
                                                </Option>)
                                            }) : ""}
                                        </Select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>sport</label>

                                        <Select size="default" value={list.sport} placeholder="Select Sport" title="Select Sport" className="m-1" style={{ width: "200px" }} onChange={handleSportChange.bind(this, index)} >
                                            {betopsConfigConfig ? betopsConfigConfig.sportList.map((listtabType, index) => {
                                                return (<Option key={listtabType} value={listtabType}>
                                                    {listtabType}{" "}
                                                </Option>)
                                            }) : ""}

                                        </Select>
                                    </div> */}
                                    {/* {list.sport ? <> */}
                                    <div className="col-md-6">
                                        <label>Tournament</label>
                                        <Select size="default" value={list.tournament} placeholder="Select Tournament" title="Select Tournament" className="m-1" style={{ width: "200px" }} onChange={handleTournamentChange.bind(this, index)} >
                                            {sportsConfig ? sportsConfig.tournamentList.map((lists, index) => {
                                                return (<Option key={lists.tournament} value={lists.tournament}>
                                                    {lists.tournamentTitle}{" "}
                                                </Option>)
                                            }) : ""}
                                        </Select>
                                    </div>
                                    {/* </> : ""} */}
                                    <div className="col-md-6">
                                        <label>Highlight Text</label>
                                        <input type="text" placeholder="Highlight Text" name="highlightText" value={list.highlightText} className="form-control m-1" onChange={handleChangeFieldTab.bind(this, index)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Highlt Txt Color</label>
                                        <input type="color" placeholder="Highlt Txt Color" name="highltTxtClr" value={list.highltTxtClr} className="form-control m-1" onChange={handleChangeFieldTab.bind(this, index)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Highlt Back Color</label>
                                        <input type="color" placeholder="Highlt Back Color" name="highltBackClr" value={list.highltBackClr} className="form-control m-1" onChange={handleChangeFieldTab.bind(this, index)} />
                                    </div>
                                </>
                                    {responseDataNew.dashboard.tabList.length > 0 ? responseDataNew.dashboard.tabList[tabListIndex].group.map((list, index) => {
                                        if (list.vType) {
                                            return (<div className="col-md-12"><table className="table table-bordered">
                                                <tr>
                                                    <td>Group {index + 1}</td>
                                                    <td><EditOutlined color="blue" onClick={() => EditGroup(this, index)} /></td>
                                                    <td><DeleteOutlined color="red" onClick={() => DeleteGroup(this, index)} /></td>
                                                </tr>
                                            </table></div>
                                            )
                                        }
                                        else {
                                            return ""
                                        }

                                    }) : ""}

                                    <div className="col-md-12">
                                        <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                                        <a onClick={() => addnewGrop(this, index)}>
                                            <span className="addbet">New Group</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return ""
                    }

                }) : ""}
                <br />
                <Button className="btn btn-primary topbtnalign" onClick={addTabList}>Save</Button>

            </Drawer>
            {groupss ? <Drawer placement="right" className="drawer-align-cls" title="Group" onClose={onCloseGroup} visible={visibleGroup} size="small">
                <br />
                {responseDataNew ? responseDataNew.dashboard.tabList.length > 0 ? responseDataNew.dashboard.tabList[tabListIndex].group.length > 0 ? <>
                    {responseDataNew.dashboard.tabList[tabListIndex].group.map((list, index) => {
                        if (index == groupIndex) {
                            return (<>
                                <div className="col-md-12">
                                    <label>V Type</label>
                                    <Select size="default" value={list.vType} placeholder="Select V Model" title="Select V Type" className="m-1" style={{ width: "200px" }} onChange={(e, index) => handlevTypeTab(e, groupIndex)} >
                                        {dashboard_config ? dashboard_config.vTypeList.map((lists, index) => {
                                            return (<Option key={lists} value={lists}>
                                                {lists}{" "}
                                            </Option>)
                                        }) : ""}
                                    </Select>
                                </div>
                                <div className="col-md-6">
                                    <label>Group Name</label>
                                    <Select size="default" value={list.groupName} placeholder="Select Group Name" title="Select Group Name" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldGoup.bind(this, groupIndex)} >
                                        {dashboard_config ? dashboard_config.groupList.map((lists, index) => {
                                            return (<Option key={lists} value={lists}>
                                                {lists}{" "}
                                            </Option>)
                                        }) : ""}
                                    </Select>
                                </div>
                                {/* <div className="col-md-6">
                                    <label>V Type</label>
                                    <input type="text" placeholder="V Type" disabled name="vType" value={list.vType} className="form-control m-1" onChange={handleChangeFieldGoup.bind(this, groupIndex)} />
                                </div> */}
                            </>
                            )
                        }
                        else {
                            return ""
                        }
                    })}
                    {responseDataNew.dashboard.tabList.length > 0 ? responseDataNew.dashboard.tabList[tabListIndex].group.length > 0 ?
                        responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList ?
                            responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList.map((list, index) => {
                                if (list.vModel) {
                                    return (<table className="table table-bordered">
                                        <tr>
                                            <td>service List {index + 1}</td>
                                            <td><EditOutlined color="blue" onClick={() => EditService(this, index)} /></td>
                                            <td><DeleteOutlined color="red" onClick={() => DeleteService(this, index)} /></td>
                                        </tr>
                                    </table>
                                    )
                                }
                                else {
                                    return ""
                                }

                            }) : "" : "" : ""}
               {/* {responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList ?
                            responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.map((list, index) => {
                                if (list.vModel) {
                                    return (<table className="table table-bordered">
                                        <tr>
                                            <td>service List {index + 1}</td>
                                            <td><EditOutlined color="blue" onClick={() => EditService(this, index)} /></td>
                                            <td><DeleteOutlined color="red" onClick={() => DeleteService(this, index)} /></td>
                                        </tr>
                                    </table>
                                    )
                                }
                                else {
                                    return ""
                                }

                            }) : "" }                             */}
                    {responseDataNew.dashboard.tabList.length > 0 ? responseDataNew.dashboard.tabList[tabListIndex].group.length > 0 ?
                        responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList ?
                            responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.map((list, index) => {
                                if (list.vModel) {
                                    return (<table className="table table-bordered">
                                        <tr>
                                            <td>banner List {index + 1}</td>
                                            <td><EditOutlined color="blue" onClick={() => EditBanner(this, index)} /></td>
                                            <td><DeleteOutlined color="red" onClick={() => DeleteBanner(this, index)} /></td>
                                        </tr>
                                    </table>
                                    )
                                }
                                else {
                                    return ""
                                }

                            }) : "" : "" : ""}
                    <Button className="btn btn-primary topbtnalign" onClick={addGroupList}>Save</Button>
                    {responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].vType === "banner" ?
                        <div className="col-md-12">
                            <br />
                            <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                            <a onClick={() => addnewBannerList(this)}>
                                <span className="addbet">New Banner</span>
                            </a>
                        </div>
                        : responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].vType === "service" ? <div className="col-md-12">
                            <br />
                            <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                            <a onClick={() => addnewServiceList(this)}>
                                <span className="addbet">New Service</span>
                            </a>
                        </div> : ""}</>
                    : "" : "" : ""}
            </Drawer> : ""}
            {stats ? <Drawer placement="right" className="drawer-align-cls" title="Tab Banner" onClose={onCloseBanner} visible={visibleBannertab} size="small">
                <br />
                {responseDataNew ? <> <div className="col-md-12">
                    <label>V Model</label>
                    <Select size="default" value={responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex] ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[bannerListIndex] ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList[bannerListIndex].vModel : "" : ""} placeholder="Select V Model" title="Select V Model" className="m-1" style={{ width: "200px" }} onChange={(e, groupIndex) => handleVmodelBannerTab(e, groupIndex)} >
                        {dashboard_config ? dashboard_config.vModelList.map((lists, index) => {
                            return (<Option key={lists} value={lists}>
                                {lists}{" "}
                            </Option>)
                        }) : ""}
                    </Select>
                </div>
                    {responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].bannerList.map((list, index) => {
                        if (index == bannerListIndex) {
                            return (<>
                                {list.vModel ? <div className="col-md-12"><div className="row">
                                    {list.vModel === "bm1_name_banner" ? <>
                                        <div className="col-md-12">
                                            <h5>RIcon</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <label>RIcon Image Src</label>
                                            <Select size="default" value={list.rIcon.data} placeholder="Select RIcon Image Src" title="Select RIcon Image Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldTabRlSrc.bind(this, index)} >
                                                {dashboard_config ? dashboard_config.imageSrcList.map((lists, index) => {
                                                    return (<Option key={lists} value={lists}>
                                                        {lists}{" "}
                                                    </Option>)
                                                }) : ""}
                                            </Select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>RIcon Image Type</label>
                                            <Select size="default" value={list.rIcon.dataType} placeholder="Select RIcon Image Type" title="Select RIcon Image Type" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldTabRiType.bind(this, index)} >
                                                {dashboard_config ? dashboard_config.imgTypeList.map((lists, index) => {
                                                    return (<Option key={lists} value={lists}>
                                                        {lists}{" "}
                                                    </Option>)
                                                }) : ""}
                                            </Select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>RIcon Place Holder</label>
                                            <input type="text" placeholder="RIcon Place Holder" name="placeHolder" value={list.rIcon.placeHolder} className="form-control m-1" onChange={handleChangeFieldTabRl.bind(this, index)} />
                                        </div>
                                        <div className="col-md-6">
                                            <label>RIcon File Name</label>
                                            <input type="text" placeholder="RIcon File Name" name="fileName" value={list.rIcon.fileName} className="form-control m-1" onChange={handleChangeFieldTabRl.bind(this, index)} />
                                        </div>

                                        <div className="col-md-12">
                                            <h5>LIcon</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <label>LIcon Image Src</label>
                                            <Select size="default" value={list.lIcon.data} placeholder="Select LIcon Image Src" title="Select LIcon Image Src" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldTabLiSrc.bind(this, index)} >
                                                {dashboard_config ? dashboard_config.imageSrcList.map((lists, index) => {
                                                    return (<Option key={lists} value={lists}>
                                                        {lists}{" "}
                                                    </Option>)
                                                }) : ""}
                                            </Select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>LIcon Image Type</label>
                                            <Select size="default" value={list.lIcon.dataType} placeholder="Select LIcon Image Type" title="Select Image Type" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldTabLiType.bind(this, index)} >
                                                {dashboard_config ? dashboard_config.imgTypeList.map((lists, index) => {
                                                    return (<Option key={lists} value={lists}>
                                                        {lists}{" "}
                                                    </Option>)
                                                }) : ""}
                                            </Select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>LIcon Place Holder</label>
                                            <input type="text" placeholder="LIcon Place Holder" name="placeHolder" value={list.lIcon.placeHolder} className="form-control m-1" onChange={handleChangeFieldTabLi.bind(this, index)} />
                                        </div>
                                        <div className="col-md-6">
                                            <label>LIcon File Name</label>
                                            <input type="text" placeholder="LIcon File Name" name="fileName" value={list.lIcon.fileName} className="form-control m-1" onChange={handleChangeFieldTabLi.bind(this, index)} />
                                        </div>
                                    </>
                                        : ""}
                                    <div className="col-md-6">
                                        <label>Title</label>
                                        <input type="text" placeholder="Title" name="title" value={list.title.data} className="form-control m-1" onChange={handleChangeFieldTabs.bind(this, index)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Data Type</label>
                                        <Select size="default" value={list.title.dataType} placeholder="Select Data Type" title="Select Data Type" className="m-1" style={{ width: "200px" }} onChange={handleChangeTabGroupTitleDatatype.bind(this, index)} >
                                            {dashboard_config ? dashboard_config.dataTypeList.map((lists, index) => {
                                                return (<Option key={lists.dataType} value={lists.dataType}>
                                                    {lists.dataType}{" "}
                                                </Option>)
                                            }) : ""}
                                        </Select>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <label>Message</label>
                                        <input type="text" placeholder="Message" name="message" value={list.message.data} className="form-control m-1" onChange={handleChangeFieldTabs.bind(this, index)} />
                                    </div> */}
                                    <div className="col-md-6">
                                        <label>Date</label>
                                        <input type="text" placeholder="Date" name="targetTs" value={list.targetTs ? list.targetTs.data : ""} className="form-control m-1" onChange={handleChangeFieldTabs.bind(this, index)} />
                                    </div>
                                </div> </div> : ""}
                            </>)
                        }
                        else {
                            return ""
                        }
                    })
                        : ""}</> : ""}
                <Button className="btn btn-primary topbtnalign" onClick={addStatsList}>Save</Button>
            </Drawer> : ""}
            {statsService ? <Drawer placement="right" title="Service List" className="drawer-align-cls" onClose={onCloseBanner} visible={visibleServicetab} size="small">
                <br />
                {responseDataNew ? <>
                    <div className="col-md-6">
                        <label>V Model</label>
                        <Select size="default" value={responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].vModel : ""} placeholder="Select V Model" title="Select V Model" className="m-1" style={{ width: "200px" }} onChange={handleChangeFieldGoupService.bind(this, serviceGroupListIndex)} >
                            {dashboard_config ? dashboard_config.vModelList.map((lists, index) => {
                                return (<Option key={lists} value={lists}>
                                    {lists}{" "}
                                </Option>)
                            }) : ""}
                        </Select>
                    </div>
                    {responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.map((list, index) => {
                        if (list.command) {
                            return (<table className="table table-bordered">
                                <tr>
                                    <td>service Group List {index + 1}</td>
                                    <td><EditOutlined color="blue" onClick={() => EditServiceGroup(this, index)} /></td>
                                    <td><DeleteOutlined color="red" onClick={() => DeleteServiceGroup(this, index)} /></td>
                                </tr>
                            </table>
                            )
                        }
                        else {
                            return ""
                        }
                    })
                        : ""}
                    <div className="col-md-12">
                        <PlusOutlined style={{ fontSize: "22px", color: "#08c", textAlign: "center" }} />{" "}
                        <a onClick={() => addNewServiceGroup(this)}>
                            <span className="addbet">New Service Group List</span>
                        </a>
                    </div>

                </> : ""}
                <Button className="btn btn-primary topbtnalign" onClick={addStatsServicetab}>Save</Button>
            </Drawer> : ""
            }
            {
                statsServiceGroup ? <Drawer placement="right" title="Service" className="drawer-align-cls" onClose={onCloseBanner} visible={visibleServiceGrouptab} size="small">
                    <br />
                    {responseDataNew ?
                        responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.length > 0 ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList[serviceListIndex] ? "" : <div className="col-md-12">
                            <label>Command</label>
                            <Select size="default" value="" placeholder="Select Id" className="m-1" style={{ width: "200px" }} onChange={(e, serviceListIndex) => addNewServiceGroupList(e, serviceListIndex)}>
                                {dashboard_config ? dashboard_config.commandTypeList.map((lists, index) => {
                                    return (<Option key={index} value={lists}>
                                        {lists}{" "}
                                    </Option>)
                                }) : ""}
                            </Select>
                        </div> :

                            <div className="col-md-12">
                                <label>Command</label>
                                <Select size="default" value="" placeholder="Select Id" className="m-1" style={{ width: "200px" }} onChange={(e, serviceListIndex) => addNewServiceGroupList(e, serviceListIndex)}>
                                    {dashboard_config ? dashboard_config.commandTypeList.map((lists, index) => {
                                        return (<Option key={index} value={lists}>
                                            {lists}{" "}
                                        </Option>)
                                    }) : ""}
                                </Select>
                            </div> : "" : ""}
                    {responseDataNew ? <>
                        {responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList ? responseDataNew.dashboard.tabList[tabListIndex].group[groupIndex].serviceGroupList[serviceGroupListIndex].serviceList.map((list, index) => {

                            if (index == serviceListIndex) {

                                return (<>
                                    <div className="col-md-12">
                                        <label>Command</label>
                                        <Select size="default" value={list.command} placeholder="Select Id" className="m-1" style={{ width: "200px" }} onChange={(e, index) => addNewServiceGroupList(e, index)}>
                                            {dashboard_config ? dashboard_config.commandTypeList.map((lists, index) => {
                                                return (<Option key={index} value={lists}>
                                                    {lists}{" "}
                                                </Option>)
                                            }) : ""}
                                        </Select>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Title Post Tag</label>
                                                <input type="text" placeholder="Title Post Tag" name="titlePostTag" value={list.titlePostTag} className="form-control m-1" onChange={handleChangeFieldTabService.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Title</label>
                                                <input type="text" placeholder="Title" name="title" value={list.title} className="form-control m-1" onChange={handleChangeFieldTabService.bind(this, index)} />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Enable</label>
                                                <input type="checkbox" name="enable" value={list.enable} checked={list.enable} className="m-1" onChange={handleChangeFieldTabService.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Visible</label>
                                                <input type="checkbox" name="visible" value={list.visible} checked={list.visible} className="m-1" onChange={handleChangeFieldTabService.bind(this, index)} />
                                            </div>
                                            <div className="col-md-12">
                                                <h5>Title Image</h5>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Data</label>
                                                <input type="text" placeholder="Data" name="data" value={list.titleImg.data} className="form-control m-1" onChange={handleChangeFieldTabServiceTitle.bind(this, index)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Data Type</label>
                                                <input type="text" placeholder="Data Type" name="dataType" value={list.titleImg.dataType} className="form-control m-1" onChange={handleChangeFieldTabServiceTitle.bind(this, index)} />
                                            </div>

                                            {list.command === "MODULE_NAV_ADD_FUNNY_MONEY" ? <><div className="col-md-12">
                                                <h5>Style</h5>
                                            </div>
                                                <div className="col-md-6">
                                                    <label>Bg Color</label>
                                                    <input type="color" placeholder="Bg Color" name="bgColor" value={list.style.bgColor} className="form-control m-1" onChange={handleChangeFieldTabServiceStyle.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Font Color</label>
                                                    <input type="color" placeholder="Font Color" name="fontColor" value={list.style.fontColor} className="form-control m-1" onChange={handleChangeFieldTabServiceStyle.bind(this, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Icon</label>
                                                    <input type="text" placeholder="Icon" name="icon" value={list.style.icon} className="form-control m-1" onChange={handleChangeFieldTabServiceStyle.bind(this, index)} />
                                                </div></> :
                                                <>
                                                    {list.actionData ? <>
                                                        <div className="col-md-12">
                                                            <h5>Action Data</h5>
                                                        </div>
                                                        {betopsConfigConfig ?
                                                            <div className="col-md-6">
                                                                <label>sport</label>
                                                                <Select size="default" value={list.actionData.sport} placeholder="Select Sport" title="Select Sport" className="m-1" style={{ width: "200px" }} onChange={handleACTIONSportChange.bind(this, index)} >
                                                                    {/* {betopsConfigConfig ? betopsConfigConfig.map((listtabType, index) => {
                                                                        return (<Option key={listtabType} value={listtabType}>
                                                                            {listtabType}{" "}
                                                                        </Option>)
                                                                    }) : ""} */}
                                                                     {sportsConfig ? sportsConfig.tournamentList.map((lists, index) => {
                                                                        return (<Option key={lists.tournament} value={lists.tournament}>
                                                                            {lists.tournamentTitle}{" "}
                                                                        </Option>)
                                                                    }) : ""}
                                                                </Select>
                                                            </div> : ""

                                                        }

                                                        {list.actionData.sport ? <>
                                                            <div className="col-md-6">
                                                                <label>tournament</label>
                                                                {/* <Select size="default" value={list.actionData.tournament} placeholder="Select Tournament" title="Select Tournament" className="m-1" style={{ width: "200px" }} onChange={handleACTIONTournamentChange.bind(this, index)} >
                                                                    {betopsConfigConfig ? betopsConfigConfig.map((lists, index) => {
                                                                        return (<Option key={lists} value={lists}>
                                                                            {lists}{" "}
                                                                        </Option>)
                                                                    }) : ""}

                                                                </Select> */}
                                                                <Select size="default" value={list.actionData.tournament} placeholder="Select Tournament" title="Select Tournament" className="m-1" style={{ width: "200px" }} onChange={handleACTIONTournamentChange.bind(this, index)} >
                                                                    {sportsConfig ? sportsConfig.tournamentList.map((lists, index) => {
                                                                        return (<Option key={lists.tournament} value={lists.tournament}>
                                                                            {lists.tournamentTitle}{" "}
                                                                        </Option>)
                                                                    }) : ""}
                                                                </Select>
                                                            </div></> : ""}

                                                        {list.actionData.tournament ? <> <div className="col-md-6">
                                                            <label>season</label>
                                                            <input type="text" placeholder="Season" name="season" value={list.actionData.season} className="form-control m-1" onChange={handleACTIONSeasonChange.bind(this, index)} />

                                                            {/* <Select size="default" value={list.actionData.season} placeholder="Select Season" title="Select Season" className="m-1" style={{ width: "200px" }} onChange={handleACTIONSeasonChange.bind(this, index)} >
                                                                {sportsConfig ? sportsConfig.tournamentList.map((lists, index) => {
                                                                    if (lists.tournament === list.actionData.tournament) {
                                                                        return (lists.seasonList.map((lists, index) => {
                                                                            return (<Option key={lists.season} value={lists.season}>
                                                                                {lists.season}{" "}
                                                                            </Option>)
                                                                        }))
                                                                    }
                                                                    else {
                                                                        return ""
                                                                    }
                                                                }) : ""}
                                                            </Select> */}
                                                        </div></> : ""}
                                                        {list.actionData.season ? <> <div className="col-md-6">
                                                            <label>season type</label>
                                                            <input type="text" placeholder="Season Type" name="seasonType" value={list.actionData.seasonType} className="form-control m-1" onChange={handleACTIONSeasonTypeChange.bind(this, index)} />

                                                            {/* <Select size="default" value={list.actionData.seasonType} placeholder="Select Season Type" title="Select Season Type" className="m-1" style={{ width: "200px" }} onChange={handleACTIONSeasonTypeChange.bind(this, index)} >
                                                                {sportsConfig ? sportsConfig.tournamentList.map((lists, index) => {
                                                                    if (lists.tournament === list.actionData.tournament) {
                                                                        return (lists.seasonList.map((listss, index) => {
                                                                            if (listss.season === list.actionData.season) {
                                                                                return (<Option key={listss.seasonType} value={listss.seasonType}>
                                                                                    {listss.seasonType}{" "}
                                                                                </Option>)
                                                                            }
                                                                            else {
                                                                                return ""
                                                                            }
                                                                        }))
                                                                    }
                                                                    else {
                                                                        return ""
                                                                    }
                                                                }) : ""}
                                                            </Select> */}
                                                        </div></> : ""}
                                                        {list.command === "MODULE_NAV_QUIZ" || "MODULE_NAV_MY_ACTIVITY" || "MODULE_NAV_SCHEDULE" ? "" : <div className="col-md-6">
                                                            <label>Provider</label>
                                                            <input type="text" placeholder="Provider" name="provider" value={list.actionData.provider} className="form-control m-1" onChange={handleChangeFieldTabServiceAction.bind(this, index)} />
                                                        </div>}

                                                    </> : ""}
                                                </>}


                                        </div> </div>
                                </>)
                            }
                            else {
                                return ""
                            }
                        })
                            : ""}</> : ""}

                    <Button className="btn btn-primary topbtnalign" onClick={addStatsServiceGroupList}>Save</Button>
                </Drawer> : ""
            }
            <Modal title="Add" onCancel={onClose} visible={versionVisible}
                footer={[
                    <Button key="submit" type="primary" onClick={onClose}>
                        Cancel
                    </Button>
                ]}
            >
                <Form form={form} name="horizontal_login" layout="vertical" onFinish={updateConfig}>
                    <Row gutter={24}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Form.Item name="version" label="Enter the version" rules={[{ required: true, message: "Please input Version Number" }]}>
                                <Input placeholder="Version Number" onChange={versionChange} />
                            </Form.Item>{" "}
                        </Col>
                    </Row>
                    <Row gutter={24} className="rtmp1">
                        <Col span={24}>
                            <Form.Item shouldUpdate {...tailLayout}>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Add
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div >
    )
}
export default IosDashboard;