import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Spin,
  Layout,
  DatePicker,
  Input,
  Menu,
  Card,
  Tabs,
  Row,
  Popover,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Drawer,
  Divider,
  PageHeader,
  Form,
} from "antd";
import { db, currentTournamentDetails } from "../../settings/config";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import Eightmin from "./gconfig/eightmin";
import Quiz from "./gconfig/quiz";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function Gconfig() {
  const [infoform] = Form.useForm();

  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [title, setTitle] = useState("");

  const [tournamenttype, setTournamenttype] = useState();
  const [tournament, setTournament] = useState([]);
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [tabarray, setTabarray] = useState([]);
  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Season");
    setVisible(true);
  };
  const showDrawer = (matchid, index) => {
    setCurrentindex(index);
    infoform.setFieldsValue({
      tournamentName: matchid.tournament,
      season: matchid.year,
    });
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const callback = (key) => { };
  const renderSwitch = (param) => {
    switch (param) {
      case "EightMin":
        return <Eightmin />;
      case "Quiz":
        return <Quiz />;

      default:
        return "foo";
    }
  };

  const onClose = () => {
    infoform.resetFields();
    setVisible(false);
  };

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const onInfofinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", currentTournamentDetails.majorUsedDocId);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    if (title === "New Season") {
      arraycheck.season.push({
        tournament: values.tournamentName,
        year: values.season,
      });
    } else {
      arraycheck.season[currentindex] = {
        tournament: values.tournamentName,
        year: values.season,
      };
    }

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: title === "New Season" ? "New Season Added Successfully" : "Updated Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };

  const fetchDashboard = async () => {
    const scheduleCol = collection(db, "configuration");
    const scheduleSnapshot = await getDocs(scheduleCol);
    const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
    let tabarray = [];
    scheduleSnapshot.docs.map((doc) => {
      if (doc.id === currentTournamentDetails.majorUsedDocId) {
        var docdata = doc.data();
        if (docdata !== undefined) {
          for (var key in docdata.gconfig) {
            tabarray.push(key.charAt(0).toUpperCase() + key.slice(1));
          }
          console.log(tabarray);
          tabarray.sort(function (a, b) {
            return a.localeCompare(b);
          });
          setSchedule(docdata.gconfig[0]);
          setTabarray(tabarray);
        }
      }
      if (doc.id === "admin_console") {
        var docdata = doc.data();
        if (docdata !== undefined) {
          setTournament(docdata.tournament);
        }
      }
    });
  };
  useEffect(() => {
    var currentyear = new Date().getFullYear();
    const yearlist = [];
    for (var i = 1980; i <= currentyear; i++) {
      yearlist.push(i);
    }
    setYearlist(yearlist.reverse());
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <div>
      <Tabs tabPosition="left" defaultActiveKey="1" onChange={callback}>
        {tabarray.map((key, value) => {
          return (
            <TabPane tab={key} key={key}>
              {renderSwitch(key)}
            </TabPane>
          );
        })}
      </Tabs>

      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Gconfig;
