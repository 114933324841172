import { collection, getDocs, doc, setDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { Select, Spin, Layout, Input, Card, Tabs, Table, Modal, PageHeader, Form, Button } from "antd";
import { db, currentTournamentDetails } from "../settings/config";
import Headersection from "../Layout/header";
import Footersection from "../Layout/footer";
import Sidermenu from "../Layout/sidermenu";
import React, { useState, useEffect } from "react";
//import "antd/dist/antd.min.css"
import "antd/dist/antd.min.css";

import moment from "moment";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import Aid from "./FantasyCafe/aid";
import Asset from "./FantasyCafe/asset";
import Gconfig from "./FantasyCafe/gconfig";
import Poolconfig from "./FantasyCafe/poolconfig";
import Season from "./FantasyCafe/season";
import Seasonend from "./FantasyCafe/seasonend";
import Seasonstart from "./FantasyCafe/seasonstart";
import Wkschedlbl from "./FantasyCafe/wkschedlbl";
import { Link } from "react-router-dom";

import ReactJson from "react-json-view";
import Moment from "react-moment";
import {
  DeleteFilled,
  EditFilled,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PlusCircleTwoTone,
  DeleteTwoTone,
  VideoCameraTwoTone,
  ScheduleTwoTone,
  FileSearchOutlined,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { auth } from "../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option } = Select;
const { TabPane } = Tabs;
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { Column } = Table;
const dataType = ["TEXT", "LOCAL_TS", "CONDITION_BY_DATE"];
const contentType = ["UPCOMING_MATCH"];
function FantasyCafe() {
  const [form, infoform] = Form.useForm();
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isKey, setIsKey] = useState(false);
  const [pushdata, setPushdata] = useState([]);
  const [teams, setTeams] = useState([]);
  const [jsondata, setJsondata] = useState(false);
  const [jsonviewer, setJsonviewer] = useState(false);
  const [editmatchid, setEditmatchid] = useState([]);
  const [matchinfo, setMatchinfo] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [title, setTitle] = useState("");
  const [info2, setInfo2] = useState([]);
  const [subtxtdata, setSubtxtdata] = useState();
  const [titledata, setTitledata] = useState();
  const [texttime, setTexttime] = useState();
  const [titletime, setTitletime] = useState();
  const [tournamenttype, setTournamenttype] = useState();
  const [contenttype, setContenttype] = useState();
  const [currentindex, setCurrentindex] = useState();
  const [tabarray, setTabarray] = useState([]);
  const [activetab, setActivetab] = useState("Aid");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showJsonmodal = async (documentID) => {
    const matchDocRef = doc(db, "schedule", documentID.documentID);
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    setJsondata(arraycheck);
    setJsonviewer(true);
  };
  const callback = (key) => {
    switch (key) {
      case key === "Aid":
        setActivetab(<Aid />);
        return <Aid />;
      case key === "Asset":
        setActivetab(<Asset />);
        return <Asset />;
      default:
        return "foo";
    }
  };

  const tabsclick = (key) => {
    setIsKey(key);
    if(isConfirm) {
      setConfirmModalVisible(true);
    } else {
      setConfirmModalVisible(false);
      switch (key) {
        case key === "Aid":
          setActivetab(<Aid />);
          return <Aid />;
        case key === "Asset":
          setActivetab(<Asset />);
          return <Asset />;
        default:
          return "foo";
      }
    }
  }

  const handleJson = () => {
    //downloadFile(arraycheck,documentID.documentID)
    setJsonviewer(false);
  };

  const jsoncancel = () => {
    setJsonviewer(false);
  };
  const addnew = () => {
    setTournamenttype("Select Tournament");
    setContenttype("Select Content Type");
    setTitle("New Info");
    setVisible(true);
    // const docRef = addDoc(collection(db, "schedule"), {
    //     Title: "test",
    //     notifId: "",

    //   });
    //   console.log("Document written with ID: ", docRef.id);
  };
  const downloadFile = (data, docid) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const a = document.createElement("a");
    a.download = docid;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadocument = async (documentID) => { };

  const deletedocument = (documentID) => {
    const docRef = deleteDoc(doc(db, "schedule", documentID.documentID));
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        window.location.reload(true);
      },
    });
  };
  const showDrawer = (matchid, index) => {
    console.log(index);
    setTournamenttype(matchid.tournament);
    setContenttype(matchid.contentType);
    setTitle(matchid.tournament);
    setVisible(true);
  };
  const datesplit = (text) => {
    const res = text.split("_");
    var currentTimeInSeconds = Math.floor(res[1] / 1000);
    return currentTimeInSeconds;
  };
  const deleterecord = async (matchid, index1) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());

    let filtered = arraycheck.info2.filter(function (value, index, arr) {
      return index !== index1;
    });
    arraycheck.info2 = filtered;
    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Deleted Successfully",
      onOk() {
        onClose();
        fetchDashboard();
      },
    });
  };

  const onClose = () => {
    setVisible(false);
    setConfirmModalVisible(false);
  };

  const hideTheModal = () => {
    setConfirmModalVisible(false);
    let key = isKey;
    switch (key) {
      case key === "Aid":
        setActivetab(<Aid />);
        return <Aid />;
      case key === "Asset":
        setActivetab(<Asset />);
        return <Asset />;
      default:
        return "foo";
    }
  };

  const textpicker = (value, dateString) => {
    var date = new Date(dateString);
    var milliseconds = date.getTime();
    setTexttime(dateString);
  };
  const textok = (value) => {
    console.log(value);
  };
  const titlepicker = (value, dateString) => {
    console.log(dateString);
    setTitletime(dateString);
  };
  const titleok = (value) => {
    console.log(value._i);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const onSearch = (e) => {
    if (e.target.value !== "") {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
      });
      setSchedule(filteredData);
    } else {
      fetchDashboard();
    }
  };
  const content = (notifId) => {
    return (
      <div>
        <p>{notifId.title}</p>
        <p>{notifId.desc}</p>
      </div>
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleTitlechange = (e) => {
    setTitledata(e);
  };
  const handleTextchange = (e) => {
    setSubtxtdata(e);
  };
  const handleTournament = (e) => {
    setTournamenttype(e);
  };
  const handleContent = (e) => {
    setContenttype(e);
  };
  const fetchIndex = (rowindex) => {
    setCurrentindex(rowindex);
  };
  const fetchTeams = async () => {
    const teamsCol = collection(db, "teams");
    const teamsSnapshot = await getDocs(teamsCol);
    const teamList = teamsSnapshot.docs.map((doc) => doc.data());
    setTeams(teamList);
  };
  const onInfofinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    if (title === "New Info") {
      arraycheck.info2.push({
        contentType: contenttype,
        tournament: tournamenttype,
      });
    } else {
      arraycheck.info2[currentindex] = {
        contentType: contenttype,
        tournament: tournamenttype,
      };
    }

    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: title === "New Info" ? "New Info Added Successfully" : "Updated Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };
  const onFinish = async (values) => {
    setLoaded(true);
    const matchDocRef = doc(db, "configuration", "landingInfoDefault");
    const docSnap = await getDoc(matchDocRef);
    const arraycheck = docSnap.data();
    await setDoc(matchDocRef, docSnap.data());
    var subdate = new Date(texttime);
    var submilliseconds = subdate.getTime();
    var titledate = new Date(titletime);
    var titlemilliseconds = titledate.getTime();
    arraycheck.info1.subTxt1 = {
      data: subtxtdata === "TEXT" ? values.subtxtdata : submilliseconds,
      dataType: subtxtdata,
    };
    arraycheck.info1.title = {
      data: titledata === "TEXT" ? values.titledata : titlemilliseconds,
      dataType: titledata,
    };
    await updateDoc(matchDocRef, arraycheck);
    setLoaded(false);
    Modal.success({
      content: "Updated Successfully",
      onOk() {
        fetchDashboard();
      },
    });
  };

  const tabConfirm = (param) => {
    setIsConfirm(param);
  }

  const renderSwitch = (param) => {
    switch (param) {
      case "Aid":
        return <Aid docdata={schedule} tabconfirm={tabConfirm} />;
      case "Asset":
        return <Asset docdata={schedule} tabconfirm={tabConfirm} />;
      case "Gconfig":
        return <Gconfig docdata={schedule} tabconfirm={tabConfirm} />;
      case "PoolConfig":
        return <Poolconfig docdata={schedule} />;
      case "Season":
        return <Season docdata={schedule} tabconfirm={tabConfirm} />;
      case "SeasonEnd":
        return <Seasonend docdata={schedule} />;
      case "SeasonStart":
        return <Seasonstart docdata={schedule} />;
      case "WkSchedLbl":
        return <Wkschedlbl docdata={schedule} />;
      default:
        return "foo";
    }
  };

  const fetchDashboard = async () => {
    // const scheduleCol = collection(db, "configuration");
    // const scheduleSnapshot = await getDocs(scheduleCol);
    // const scheduleList = scheduleSnapshot.docs.map((doc) => doc.data());
    // let tabarray = [];
    // scheduleSnapshot.docs.map((doc) => {
    // if (doc.id === currentTournamentDetails.majorUsedDocId) var docdata = doc.data();
    // if (docdata !== undefined) {
    // for (var key in docdata) {
    //   tabarray.push(key.charAt(0).toUpperCase() + key.slice(1));
    // }
    // tabarray.sort(function (a, b) {
    //   return a.localeCompare(b);
    // });
    // setSchedule(docdata);
    // setTabarray(tabarray);
    // }
    // });

    const response = await axios({
      method: 'get',
      url: `${domain}/betops/config/get-appConfig/v1?appId=${currentTournamentDetails.shortCode}-ops`,
    }).then((response) => {
      setLoaded(false);
      if (response.data.status === "SUCCESS") {
        let docdata = response.data.data;

        delete docdata.wkSchedLbl;
        delete docdata.seasonStart;
        delete docdata.seasonEnd;
        delete docdata.poolConfig;

        let tabarray = [];
        for (var key in docdata) {
          tabarray.push(key.charAt(0).toUpperCase() + key.slice(1));
        }
        tabarray.sort(function (a, b) {
          return a.localeCompare(b);
        });
        setSchedule(docdata);
        setTabarray(tabarray);
      }
      else {
        setLoaded(false);
      }
    })
      .catch((error) => {
        setLoaded(false);
      })
  };

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    // // if (!user) return navigate("/login");
  }, [user, loading]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    fetchDashboard();
  }, [loaded]);
  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>

      <>
        <Sidermenu menu={currentTournamentDetails.majorUsedDocId} />
        <Layout className="site-layout">
          <Headersection />
          <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                <PageHeader className="site-page-header" title="Season Config" extra={[]} />
                {/* <Tabs defaultActiveKey="1" onTabClick={tabsclick} onChange={callback}> */}
                {/* <Tabs defaultActiveKey="1" onTabClick={tabsclick}> */}
                     <Tabs defaultActiveKey="1" onChange={callback}>
                  {tabarray.map((key, value) => {
                    return (
                      <TabPane tab={key} key={key}>
                        {renderSwitch(key)}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </Card>
            </div>

            {loaded == true ? (
              <div className="loading-overlay">
                <Spin size="large" />{" "}
              </div>
            ) : (
              ""
            )}

            {/* <Table columns={columns} dataSource={matchdetails} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} /> */}
          </Content>
          <Footersection />
        </Layout>
      </>
      <Modal title="Confirm" onCancel={onClose} visible={confirmModalVisible}
        footer={[<>  <Button key="submit" type="primary" onClick={hideTheModal}>
          Ok
        </Button>
          <Button key="submit" type="primary" onClick={onClose}>
            Cancel
          </Button></>

        ]}
      >
        Are you sure want to leave this tab before save ?
      </Modal>
    </Layout>
  );
}

export default FantasyCafe;
