
import { Layout, Card, Input, Table, Space, Modal, PageHeader, Spin, Drawer, Form, Button, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import { domain } from "../../configuration/ApiConfiguration";
import { Editor } from "@tinymce/tinymce-react";
import Moment from "react-moment";
import Headersection from "../../Layout/header";
import Footersection from "../../Layout/footer";
import Sidermenu from "../../Layout/sidermenu";
import { auth } from "../../settings/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { EditOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Content } = Layout;
const { Column } = Table;

function LeaderBoard() {
    const [form] = Form.useForm();
    const [leaderBoardList, setLeaderBoardList] = useState([]);
    const [leaderBoardFilteredList, setLeaderBoardFilteredList] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [row, setRow] = useState([]);
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const showDrawer = async (row) => {

        setVisible(true);
        setRow(row);
        form.setFieldsValue({
            name: row.name,
            points: row.points,
            rank: row.rank,
            uid: row.uid,
        });
    };

    const onClose = () => {
        setRow("");
        setVisible(false);
        form.resetFields();
    };
    const onSearch = (e) => {
        if (e.target.value !== "") {
            const lowercasedFilter = e.target.value.toLowerCase();
            const filteredData = leaderBoardFilteredList.filter((item) => {
                return Object.keys(item).some((key) => {
                    if (item[key]) return item[key].toString().toLowerCase().includes(lowercasedFilter);
                    return false;
                });
            });
            setLeaderBoardList(filteredData);
        } else {
            setLeaderBoardList(leaderBoardFilteredList);
        }
    };

    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) return;
    }, [user, loading]);

    useEffect(async () => {
        ListAll();
    }, []);

    const ListAll = async (e) => {
        setLoaded(true);
        const response = await axios({
            method: 'get',
            url: `${domain}/betops/leaderboard/list/v1`,
        }).then((response) => {

            setLoaded(false);
            setLeaderBoardList(response.data.data)
            setLeaderBoardFilteredList(response.data.data)
        })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    const onFinish = async (values) => {

        setLoaded(true)
        let data = {
            "uid": row.uid,
            "points": values.points,
            "rank": values.rank,
            "name": values.name,
        }
        const response = await axios.post(
            `${domain}/betops/leaderboard/update/v1`, data)
            .then((response) => {
                setLoaded(false);
                if (response.data.status === "SUCCESS") {
                    setVisible(false);
                    Modal.success({
                        content: response.data.data,
                    });
                    ListAll();
                }
                else if (response.data.status === "FAIL") {
                    Modal.error({
                        content: response.data.data,
                    });
                }
                else {
                    setLoaded(false);
                    Modal.error({
                        content: "Unable to process your Request, Please try again later.",
                    });
                }
            })
            .catch((error) => {
                setLoaded(false);
                Modal.error({
                    content: "Unable to process your Request, Please try again later.",
                });
            })
    }
    return (
        <Layout style={{ minHeight: "100vh" }} hasSider>
            <>
                <Sidermenu menu="leader_board" />
                <Layout className="site-layout">
                    <Headersection />
                    <Content className="site-layout-background" style={{ margin: "24px 16px 0", minHeight: 280 }}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="" bordered={false} style={{ width: "100%", margin: "0" }}>
                                <div>
                                    <PageHeader
                                        className="site-page-header"
                                        title="Leader Board List"
                                        extra={
                                            <Space direction="horizontal">
                                                {" "}
                                                <Search placeholder="Search here" onChange={onSearch} enterButton />
                                            </Space>
                                        }
                                    />
                                    <Table
                                        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                                        dataSource={leaderBoardList}
                                        scroll={{ y: "250" }}
                                    >
                                        <Column title="Name" dataIndex="name" key="name" />
                                        <Column title="Points" dataIndex="points" key="points" />
                                        <Column title="Rank" dataIndex="rank" key="rank" />
                                        <Column
                                            title="Quick Actions"
                                            key="uid"
                                            render={(uid) => (
                                                <Space size="middle">
                                                    {uid !== undefined ? (
                                                        <>
                                                            <EditOutlined onClick={() => showDrawer(uid)} title="Edit LeaderBoard" style={{ fontSize: '18px', color: '#08c' }} />
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                            )}
                                        />


                                    </Table>
                                </div>
                            </Card>
                        </div>
                        {loaded == true ? (
                            <div className="loading-overlay">
                                <Spin size="large" />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        <Drawer title="Leader Board Update" placement="right" onClose={onClose} visible={visible} className="drawer-align-cls email-drawer-align-cls">
                            <br />
                            <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please input the Name!" }]}>
                                            <Input disabled type="text" placeholder="Name" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="points" label="Points" rules={[{ required: true, message: "Please input the Points!" }]}>
                                            <Input type="number" placeholder="Points" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Form.Item name="rank" label="Rank" rules={[{ required: true, message: "Rank!" }]}>
                                            <Input type="number" placeholder="Rank" />
                                        </Form.Item>{" "}
                                    </Col>
                                </Row>
                                <Row gutter={24} className="rtmp1">
                                    <Col span={24}>
                                        <Form.Item shouldUpdate {...tailLayout}>
                                            {() => (
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                // disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                                                >
                                                    Update
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Drawer>

                    </Content>
                    <Footersection />
                </Layout>
            </>

        </Layout >
    );
}

export default LeaderBoard;
