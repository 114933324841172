import * as Firebase from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import {
  Select,
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Radio,
  DatePicker,
  TimePicker,
  Drawer,
  Form,
  Input,
  Divider,
  Spin,
} from "antd";
import { currentTournamentDetails, logout } from "../settings/config";
import axios from "axios";
import { domain } from "../configuration/ApiConfiguration";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import {
  DownOutlined
} from "@ant-design/icons";
const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Column } = Table;

var data = require("../Jsondata/schedule.json");
const doctypearray = [
  { key: 1, value: "SCHEDULE" },
  { key: 2, value: "PLAY_OFF" },
  { key: 3, value: "INSIGHTS" },
];
function Addalert(props) {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(false);
  const [yearlist, setYearlist] = useState([]);
  const [doctype, setDoctype] = useState("Sent");
  const [datetype, setDatetype] = useState();
  const [timetype, setTimetype] = useState();
  const [visible, setVisible] = useState(false);
  const [teams, setTeams] = useState();
  const [currentAppTypeValue, setCurrentAppTypeValue] = useState(null);
  const [allTypeList, setAllTypeList] = useState(null);
  const options = [];


  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const loadProfile = () => {
    form.setFieldsValue({
      matchtype: "",
      topic: "generic",
      desc: "",
      title: ""
    });
  };
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 2000);
    }
    getAppTypeList();
    loadProfile({});
  }, [loaded]);

  const getAppTypeList = () => {
    const response = axios({
        method: 'get',
        url: `${domain}/betops/config/get-appTypes/v1?appId=${currentTournamentDetails.shortCode}-ops&docType=APP_TYPE_CONFIG`,
    }).then((response) => {
        if (response.data.status === "SUCCESS") {
        //   let dataMap = [
        //     {
        //         "clientType": "MOB",
        //         "name": "DreamT20 Android",
        //         "active": true,
        //         "aid": "dt20-and"
        //     },
        //     {
        //         "clientType": "MOB",
        //         "name": "DreamT20 IOS",
        //         "active": true,
        //         "aid": "dt20-ios"
        //     },
        //     {
        //       "clientType": "MOB",
        //       "name": "DreamT20 Betops",
        //       "active": true,
        //       "aid": "dt20-ops"
        //   },
        //   {
        //     "clientType": "MOB",
        //     "name": "DreamT20 Test",
        //     "active": false,
        //     "aid": "dt20-test"
        // }
        // ];
          let dataMap = response.data.data.clientList;
          let arrayMap = [];
          for (let i = 0; i < dataMap.length; i++) {
            arrayMap.push({
              label: dataMap[i].name,
              value: dataMap[i].aid,
              isdisabled: dataMap[i].active
            });
          }      
          setAllTypeList(arrayMap);
        }
        else {
            setLoaded(false);
        }
    })
        .catch((error) => {
            setLoaded(false);
        })
  }

  const handleChange = (e) => {
    if (e.target.value == "Scheduled") {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setDoctype(e.target.value);
  };
  const onDateChange = (date, dateString) => {
    setDatetype(dateString);
  };
  const onTimeChange = (time, timeString) => {
    console.log(timeString);
    setTimetype(timeString);
  };

  const handleApptypeChange = (value) => {
    let makeAppTypeString = ``;
    if(value[0]) {
      for(let i=0; i<value.length; i++) {
        let temp = value[i];
        makeAppTypeString += temp;
        if(i != value.length-1) {
          makeAppTypeString += `,`
        }
      }
    }
    setCurrentAppTypeValue(makeAppTypeString);
  };

  const onFinish = async (values) => {
    setLoaded(true);
    var currentTimeInMilliseconds = Date.now();
    const response = await axios({
      method: 'post',
      url: `${domain}/betops/notification/send-notif/v2.0`,
      headers: {"Authorization" : `Bearer ${localStorage.getItem("aT")}`},
      data: {
        "notifType": "TOPIC",
        "title": values.title,
        "topic": values.topic,
        "desc": values.desc,
        "app_type": currentAppTypeValue,
        "channelName": "Generic",
        "notifId": "Generic_" + currentTimeInMilliseconds,
        "channelId": `com.sherpaanalytics.${currentTournamentDetails.majorUsedDocId}.generic`,
        "data": {
          "type": "GENERIC"
        }
      },
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setLoaded(false);
        Modal.success({
          content: "Notification has been sent successfully",
          onOk() {
            setLoaded(false);
            props.onClose();
            props.notification();
            setCurrentAppTypeValue(null);
          },
        });
       }
       else {
        if(response.data.error.message == "Auth token is not valid") {
          Modal.error({
            content: response.data.error.message,
            onOk() {
              logout();
            }
          });
        } else {
          Modal.error({
            content: response.data.error.message
          });
        }
       }
    })
      .catch((error) => {
        setLoaded(false);
        Modal.error({
          content: "Unable to process your Request, Please try again later.",
          onOk() {
            logout();
          }
        });
        setCurrentAppTypeValue(null);
      })
  };

  return (
    <>
      <br></br>
      <Form className="customalertform" form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        {/* <Row gutter={24}>
                    <Col span={24} style={{ textAlign: 'left' }}>
                    <Form.Item
                            name="matchtype"
                            label="Type"
                            rules={[{ required: true, message: 'Please input your Type!' }]}
                        >
                            <Input placeholder="Type" />
                        </Form.Item>
                    </Col>


                </Row>
                */}

        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="apptype" label="App Type" rules={[{ required: true, message: "Please input your App Type!" }]}>        
              <Select
                mode="multiple"
                showArrow
                suffixIcon={<DownOutlined />}
                style={{ width: '100%' }}
                onChange={handleApptypeChange}
              >
                    {(allTypeList) ?
                    <>
                       {allTypeList.map((item, index) => (
                            <Option disabled={!item.isdisabled} key={index} value={item.value}>
                              {item.label}{" "}
                            </Option>
                          ))}
                    </>             
                          : ""}
                        </Select>
                        
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="title" label="Title" rules={[{ required: true, message: "Please input your Title!" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            {/* <Form.Item name="topic" label="Topic" rules={[{ required: true, message: "Please input your Topic!" }]}>
              <Input placeholder="Topic" />
            </Form.Item> */}

            <Form.Item name="topic" label="Topic" rules={[{ required: true, message: "Please input your Topic!" }]}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Form.Item name="desc" label="Desc" rules={[{ required: true, message: "Please input your Desc!" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "left" }}>
            <Radio.Group onChange={handleChange} value={doctype}>
              <Radio value="Sent">Now</Radio>
              <Radio value="Scheduled">Scheduled</Radio>
            </Radio.Group>
          </Col>
        </Row>
        {visible ? (
          <>
            <Row gutter={24} className="rtmp1">
              <Col span={12} style={{ textAlign: "left" }}>
                <DatePicker onChange={onDateChange} />
              </Col>
              <Col span={12} style={{ textAlign: "left" }}>
                <TimePicker onChange={onTimeChange} />
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Row gutter={24} className="rtmp1">
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loaded == true ? (
        <div className="loading-overlay">
          <Spin size="large" />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Addalert;
